import { Disclosure, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import WrapperCard from "../../components/ui/redesign/WrapperCard";
import ToolTipRe from "../../components/ui/redesign/ToolTip";
import AbsDetailTable from "./AbsDetailTable";
import { total39, total41 } from "../AbsPortfolio";
import ChartTypeSelector from "../../components/redesign/charts/components/ChartTypeSelector";
import TopTab from "../../components/redesign/psics/TopTab";
import DoughnutChart from "../../components/redesign/charts/DoughnutChart";

const tabs = [
  { icon: "/icon/overview_list.svg", name: "Overview" },
  {
    icon: "/icon/chart_section.svg",
    name: "Vehicle Information",
  },
];
const AbsDataView = ({ selectedRows }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const data1 = [
    {
      title: "% Vehicles Assessed",
      data: [
        { name: "Assessed", y: 99 },
        { name: "Not yet assessed", y: 1 },
      ],
    },
    {
      title: "% Vehicles By Type",
      data: [
        { name: "All other", y: 65 },
        { name: "Trucks", y: 35 },
      ],
    },
    {
      title: "% Vehicles EU Green Taxonomy Aligned",
      data: [
        { name: "Aligned", y: 18.7 },
        { name: "Not aligned", y: 82.3 },
      ],
    },
    {
      title: "% Vehicles By Fuel Type",
      data: [
        { name: "Petrol", y: 55 },
        { name: "Electric", y: 15 },
        { name: "Hybrid", y: 10 },
        { name: "Diesel", y: 8 },
        { name: "Hydrogen", y: 7 },
        { name: "Autogas", y: 3 },
        { name: "Unknown", y: 2 },
      ],
    },
  ];

  return (
    <WrapperCard className={"mt-8 mx-4 py-4"}>
      <div className="flex flex-col gap-y-1 ">
        {selectedRows.map((d) => {
          const total = d.ticker == "VCL-41" ? total41 : total39;

          return (
            <WrapperCard className={"my-2 mx-4"}>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="py-1 w-full">
                      <div
                        className={
                          "text-gray-500 font-semibold text-left  bg-transparent px-2 mx-4 flex items-center justify-between"
                        }
                      >
                        Results for {d.ticker}
                        <img
                          src={`/icon/${!open ? "plus" : "minus"}.svg`}
                          className={`h-6 w-6 `}
                        />
                      </div>
                    </Disclosure.Button>
                    <Transition
                      as={Fragment}
                      show={open}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Disclosure.Panel>
                        {open && <div className="w-full h-1 bg-gray-100" />}
                        <div className="w-full">
                          <TopTab
                            tabs={tabs}
                            active={selectedTab}
                            onChange={setSelectedTab}
                          />
                        </div>
                        {selectedTab == 0 && (
                          <div className="col-span-1">
                            <p
                              className={
                                "ml-8 mt-2 text-lg font-bold leading-7 text-gray-600"
                              }
                            >
                              Overview of {d?.ticker}
                            </p>
                            <WrapperCard className="p-2 mx-4 my-4 flex items-center justify-center flex-col">
                              <div className="grid grid-cols-4 w-full gap-x-4">
                                <div className="col-span-1  ">
                                  <p className="text-sm font-semibold mb-2 leading-tight text-gray-600">
                                    # of vehicles
                                  </p>
                                  <p className="text-sm  leading-tight text-gray-600">
                                    {total.totalCount}
                                  </p>
                                </div>
                                <div className="col-span-1">
                                  <ToolTipRe
                                    onTop={false}
                                    toolTipBase={
                                      <div className="flex">
                                        <p className="text-sm text-left font-semibold leading-tight text-gray-600 mb-2">
                                          Min Tailpipe Emissions
                                        </p>
                                        <img src="/redesign/portfolio_impact/info_outline.svg" />
                                      </div>
                                    }
                                  >
                                    <p className="text-sm font-medium leading-normal text-gray-600">
                                      TODO Explanation
                                    </p>
                                  </ToolTipRe>
                                  <p className="text-sm leading-tight text-gray-600">
                                    {total.minEwltp}
                                  </p>
                                </div>
                                <div className="col-span-1">
                                  <ToolTipRe
                                    onTop={false}
                                    toolTipBase={
                                      <div className="flex">
                                        <p className="text-sm font-semibold mb-2 text-left leading-tight text-gray-600">
                                          Avg Tailpipe emissions
                                        </p>
                                        <img src="/redesign/portfolio_impact/info_outline.svg" />
                                      </div>
                                    }
                                  >
                                    <p className="text-sm font-medium leading-normal text-gray-600">
                                      TODO Explanation
                                    </p>
                                  </ToolTipRe>
                                  <p className="text-sm leading-tight text-gray-600">
                                    {total.averageEwltp}
                                  </p>
                                </div>
                                <div className="col-span-1">
                                  <p className="text-sm font-semibold mb-2 leading-tight text-gray-600">
                                    Avg Z score
                                  </p>
                                  <p className="text-sm leading-tight text-gray-600">
                                    {total.averageZScore}
                                  </p>
                                </div>
                              </div>
                            </WrapperCard>

                            <WrapperCard className="p-2 mx-0 mt-8 flex items-center justify-center flex-col">
                              <p className="text-base font-bold leading-7 text-gray-600">
                                Vehicle breakdown
                              </p>
                              <AbsDetailTable idx={d.ticker == "VCL-41"} />
                            </WrapperCard>
                          </div>
                        )}
                        {selectedTab == 1 && (
                          <div className="col-span-1 flex flex-wrap">
                            {data1.map((d) => (
                              <DoughnutChart data={d.data} title={d.title} />
                            ))}
                          </div>
                        )}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </WrapperCard>
          );
        })}
      </div>
    </WrapperCard>
  );
};

export default AbsDataView;
