import { createSlice } from '@reduxjs/toolkit';

const generalSlice = createSlice({
  name: 'general',
  initialState: {
    sidebarSection: 'scenario',
    industries: [],
    publicCompanies: [],
    productGroups: {},
    sessionId: null,
    email: null,
    admin: false
  },
  reducers: {
    setSidebarSection(state, action) {
      state.sidebarSection = action.payload
    },
    setSessionId(state, action) {
      state.sessionId = action.payload.token;
    },
    setEmail(state, action) {
      state.email = action.payload.email;
      if (action.payload?.email?.endsWith("@rgsciences.com") || action.payload?.email?.endsWith("@yolk.digital")) {
        state.admin = true;
      }
    },
    setIndustries(state, action) {
      state.industries = action.payload;
    },
    setPublicCompanies(state, action) {
      state.publicCompanies = action.payload;
    },
    setProductGroups(state, action) {
      state.productGroups = action.payload;
    }
  },
});

export const generalActions = generalSlice.actions;

export default generalSlice;