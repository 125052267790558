import React from "react";
import { formatNumber } from "../../../services/utilities";
import SimpleColumnChart from "../charts/SimpleColumnChart";

const RevenueDetails = ({ data, color, subtitle }) => {
  //const portfolioData = data.map((item) => item.productCategoryRevenue);

  const data10 = data.slice(0, 10);

  const chartData = {
    data: [data10.map((c) => c["Category Revenue"])],
    categories: data10.map((c) => c["Company Name"]),
  };

  return (
    <div>
      <p className="text-xs font-medium leading-4 text-gray-600 mb-8">
        {subtitle}
      </p>
      <p className="text-xs leading-none text-gray-600 mb-4">
        Top 10 companies in this product category
      </p>

      <div className="w-full flex ">
        <div className="w-2/5">
          <SimpleColumnChart
            portfolio1={"YES"}
            data={chartData}
            color={color}
            toolTipCustomFormatter={(index) => {
              const cat = chartData?.categories[index];

              return `<div class="bg-white p-4 flex flex-col shadow-md rounded-lg">
                <div class="w-fit flex gap-x-2 mb-4 items-center">
                  <div class="leading-tight text-center text-xs font-medium  text-gray-600">
                    ${cat}
                  </div>
                </div>
                <div class="flex items-center gap-x-1 w-fit">
                  
                        <div class="text-xs leading-none text-gray-600">
                          Category revenue:
                        </div>
                        <div class="text-gray-600 text-xs font-semibold">$ ${formatNumber(
                          data?.[index]["Category Revenue"]
                        )}</div>
                      </div>
                <div class="flex items-center gap-x-1 w-fit">
                  
                        <div class="text-xs leading-none text-gray-600">
                          Category ratio:
                        </div>
                        <div class="text-gray-600 text-xs font-semibold">
                          ${(
                            data?.[index]["Product Category Revenue Shares"] *
                            100
                          ).toFixed(2)}%
                        </div>
                      </div>
               
              </div>`;
            }}
          />
        </div>
        <div className="w-3/5">
          <div className="inline-block py-2 align-middle w-full">
            <div className="grid grid-cols-7">
              <div className="col-span-3 cursor-pointer flex items-center rounded-tl-lg px-2 py-3 border-b-[1px] border-gray-200 bg-gray-50">
                <p className="text-xs font-semibold leading-tight pr-2 text-gray-500">
                  Company name
                </p>
              </div>
              <div className="col-span-2 cursor-pointer flex items-center justify-center  px-2 py-3 border-b-[1px] border-gray-200 bg-gray-50">
                <p className="text-xs font-semibold text-right leading-tight pr-2 text-gray-500">
                  Category Revenue
                </p>
              </div>
              <div className="col-span-2 cursor-pointer flex items-center justify-center  px-2 py-3 border-b-[1px] border-gray-200 bg-gray-50">
                <p className="text-xs font-semibold text-right leading-tight pr-2 text-gray-500">
                  Category ratio
                </p>
              </div>
            </div>
            <div
              className="h-[400px] grid grid-cols-8 overflow-y-scroll supervisortable"
              style={{
                height: "fit-content",
              }}
            >
              {data10.map((c, idx) => (
                <div
                  className={`${
                    idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                  } w-full grid-flow-col grid grid-cols-8 col-span-8 h-fit`}
                >
                  <div
                    className={`col-span-3 my-auto ${
                      idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                    }`}
                  >
                    <p className="text-xs font-semibold leading-tight text-gray-500 px-2 py-4">
                      {c["Company Name"]}
                    </p>
                  </div>
                  <div
                    className={`col-span-2 my-auto ${
                      idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                    }`}
                  >
                    <p className="text-xs font-semibold leading-tight  text-gray-500 px-2 py-4 text-right">
                      $ {formatNumber(c["Category Revenue"])}
                    </p>
                  </div>
                  <div
                    className={`col-span-2 my-auto ${
                      idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                    }`}
                  >
                    <p className="text-xs font-semibold leading-tight  text-gray-500 px-2 py-4 text-right">
                      {(c["Product Category Revenue Shares"] * 100).toFixed(2)}%
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueDetails;
