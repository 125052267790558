export const STEP1_SECTORS = {
  type: "checkbox",
  name: "temp",
  options: [
    {
      label: "Commercial Services",
      value: "Commercial Services",
    },
    {
      label: "Communications",
      value: "Communications",
    },
    {
      label: "Consumer Durables",
      value: "Consumer Durables",
    },
    {
      label: "Consumer Non-Durables",
      value: "Consumer Non-Durables",
    },
    {
      label: "Consumer Services",
      value: "Consumer Services",
    },
    {
      label: "Distribution Services",
      value: "Distribution Services",
    },
    {
      label: "Electronic Technology",
      value: "Electronic Technology",
    },
    {
      label: "Energy Minerals",
      value: "Energy Minerals",
    },
    {
      label: "Finance",
      value: "Finance",
    },
    {
      label: "Health Services",
      value: "Health Services",
    },
    {
      label: "Health Technology",
      value: "Health Technology",
    },
    {
      label: "Industrial Services",
      value: "Industrial Services",
    },
    {
      label: "Miscellaneous",
      value: "Miscellaneous",
    },
    {
      label: "Non-Energy Minerals",
      value: "Non-Energy Minerals",
    },
    {
      label: "Process Industries",
      value: "Process Industries",
    },
  ],
};
export const STEP1_INDUSTRIES = {
  type: "checkbox",
  name: "temp1",
  options: [
    {
      label: "Automotive Aftermarket",
      value: "Automotive Aftermarket",
    },
    {
      label: "Electronics/Appliances",
      value: "Electronics/Appliances",
    },
    {
      label: "Home Furnishings",
      value: "Home Furnishings",
    },
    {
      label: "Homebuilding",
      value: "Homebuilding",
    },
    {
      label: "Motor Vehicles",
      value: "Motor Vehicles",
    },
    {
      label: "Other Consumer Specialties",
      value: "Other Consumer Specialties",
    },
    {
      label: "Recreational Products",
      value: "Recreational Products",
    },
    {
      label: "Tools & Hardware",
      value: "Tools & Hardware",
    },
    {
      label: "Apparel/Footwear",
      value: "Apparel/Footwear",
    },
    {
      label: "Beverages: Alcoholic",
      value: "Beverages: Alcoholic",
    },
    {
      label: "Beverages: Non-Alcoholic",
      value: "Beverages: Non-Alcoholic",
    },
    {
      label: "Consumer Sundries",
      value: "Consumer Sundries",
    },
    {
      label: "Food: Major Diversified",
      value: "Food: Major Diversified",
    },
    {
      label: "Food: Meat/Fish/Dairy",
      value: "Food: Meat/Fish/Dairy",
    },
    {
      label: "Food: Specialty/Candy",
      value: "Food: Specialty/Candy",
    },
    {
      label: "Household/Personal Care",
      value: "Household/Personal Care",
    },
    {
      label: "Tobacco",
      value: "Tobacco",
    },
    // Continue with the rest of the industry options in the same format...

    {
      label: "Broadcasting",
      value: "Broadcasting",
    },
    {
      label: "Cable/Satellite TV",
      value: "Cable/Satellite TV",
    },
    {
      label: "Casinos/Gaming",
      value: "Casinos/Gaming",
    },
    {
      label: "Hotels/Resorts/Cruise lines",
      value: "Hotels/Resorts/Cruise lines",
    },
    {
      label: "Media Conglomerates",
      value: "Media Conglomerates",
    },
    {
      label: "Movies/Entertainment",
      value: "Movies/Entertainment",
    },
    {
      label: "Other Consumer Services",
      value: "Other Consumer Services",
    },
    {
      label: "Publishing: Books/Magazines",
      value: "Publishing: Books/Magazines",
    },
    {
      label: "Publishing: Newspapers",
      value: "Publishing: Newspapers",
    },
    {
      label: "Restaurants",
      value: "Restaurants",
    },
    {
      label: "Electronics Distributors",
      value: "Electronics Distributors",
    },
    {
      label: "Food Distributors",
      value: "Food Distributors",
    },
    {
      label: "Medical Distributors",
      value: "Medical Distributors",
    },
    {
      label: "Wholesale Distributors",
      value: "Wholesale Distributors",
    },
    {
      label: "Aerospace & Defense",
      value: "Aerospace & Defense",
    },
    {
      label: "Computer Communications",
      value: "Computer Communications",
    },
    {
      label: "Computer Peripherals",
      value: "Computer Peripherals",
    },
    {
      label: "Computer Processing Hardware",
      value: "Computer Processing Hardware",
    },
    {
      label: "Electronic Components",
      value: "Electronic Components",
    },
    {
      label: "Electronic Equipment/Instruments",
      value: "Electronic Equipment/Instruments",
    },
    {
      label: "Electronic Production Equipment",
      value: "Electronic Production Equipment",
    },
    {
      label: "Semiconductors",
      value: "Semiconductors",
    },
    {
      label: "Telecommunications Equipment",
      value: "Telecommunications Equipment",
    },
    {
      label: "Coal",
      value: "Coal",
    },
    {
      label: "Integrated Oil",
      value: "Integrated Oil",
    },
    {
      label: "Oil & Gas Production",
      value: "Oil & Gas Production",
    },
    {
      label: "Oil Refining/Marketing",
      value: "Oil Refining/Marketing",
    },
    {
      label: "Finance/Rental/Leasing",
      value: "Finance/Rental/Leasing",
    },
    {
      label: "Financial Conglomerates",
      value: "Financial Conglomerates",
    },
    {
      label: "Insurance Brokers/Services",
      value: "Insurance Brokers/Services",
    },
    {
      label: "Investment Banks/Brokers",
      value: "Investment Banks/Brokers",
    },
    {
      label: "Investment Managers",
      value: "Investment Managers",
    },
    {
      label: "Life/Health Insurance",
      value: "Life/Health Insurance",
    },
    {
      label: "Major Banks",
      value: "Major Banks",
    },
    {
      label: "Multi-Line Insurance",
      value: "Multi-Line Insurance",
    },
    {
      label: "Property/Casualty Insurance",
      value: "Property/Casualty Insurance",
    },
    {
      label: "Real Estate Development",
      value: "Real Estate Development",
    },
    {
      label: "Real Estate Investment Trusts",
      value: "Real Estate Investment Trusts",
    },
    {
      label: "Regional Banks",
      value: "Regional Banks",
    },
    {
      label: "Savings Banks",
      value: "Savings Banks",
    },
    {
      label: "Specialty Insurance",
      value: "Specialty Insurance",
    },
    {
      label: "Hospital/Nursing Management",
      value: "Hospital/Nursing Management",
    },
    {
      label: "Managed Health Care",
      value: "Managed Health Care",
    },
    {
      label: "Medical/Nursing Services",
      value: "Medical/Nursing Services",
    },
    {
      label: "Services to the Health Industry",
      value: "Services to the Health Industry",
    },
    {
      label: "Biotechnology",
      value: "Biotechnology",
    },
    {
      label: "Medical Specialties",
      value: "Medical Specialties",
    },
    {
      label: "Pharmaceuticals: Generic",
      value: "Pharmaceuticals: Generic",
    },
    {
      label: "Pharmaceuticals: Major",
      value: "Pharmaceuticals: Major",
    },
    {
      label: "Pharmaceuticals: Other",
      value: "Pharmaceuticals: Other",
    },
    {
      label: "Contract Drilling",
      value: "Contract Drilling",
    },
    {
      label: "Engineering & Construction",
      value: "Engineering & Construction",
    },
    {
      label: "Environmental Services",
      value: "Environmental Services",
    },
    {
      label: "Oil & Gas Pipelines",
      value: "Oil & Gas Pipelines",
    },
    {
      label: "Oilfield Services/Equipment",
      value: "Oilfield Services/Equipment",
    },
    {
      label: "Investment Trusts/Mutual Funds",
      value: "Investment Trusts/Mutual Funds",
    },
    {
      label: "Miscellaneous",
      value: "Miscellaneous",
    },
    {
      label: "Aluminum",
      value: "Aluminum",
    },
    {
      label: "Construction Materials",
      value: "Construction Materials",
    },
    {
      label: "Forest Products",
      value: "Forest Products",
    },
    {
      label: "Other Metals/Minerals",
      value: "Other Metals/Minerals",
    },
    {
      label: "Precious Metals",
      value: "Precious Metals",
    },
    {
      label: "Steel",
      value: "Steel",
    },
    {
      label: "Agricultural Commodities /Milling",
      value: "Agricultural Commodities /Milling",
    },
    {
      label: "Chemicals: Agricultural",
      value: "Chemicals: Agricultural",
    },
    {
      label: "Chemicals: Major Diversified",
      value: "Chemicals: Major Diversified",
    },
    {
      label: "Chemicals: Specialty",
      value: "Chemicals: Specialty",
    },
    {
      label: "Containers/Packaging",
      value: "Containers/Packaging",
    },
    {
      label: "Industrial Specialties",
      value: "Industrial Specialties",
    },
    {
      label: "Pulp & Paper",
      value: "Pulp & Paper",
    },
    {
      label: "Textiles",
      value: "Textiles",
    },
  ],
};
export const STEP2_TEMP = {
  type: "customidx",
  name: "customidx",
  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};
export const STEP3_TEMP = {
  type: "switch",
  name: "temp3",
  title: "Title if you want",
  description: "Info if you want any explanation",
  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};
export const STEP4_TEMP = {
  type: "switch",
  name: "temp4",
  options: [
    {
      label: "Advanced Therapies",
      value: "Advanced Therapies",
    },
    {
      label: "Automation/Robotics",
      value: "Automation/Robotics",
    },
    {
      label: "Carbon Capture and Storage",
      value: "Carbon Capture and Storage",
    },
    {
      label: "Carbon Reduction and Removal",
      value: "Carbon Reduction and Removal",
    },
    {
      label: "Circular Economy",
      value: "Circular Economy",
    },
    {
      label: "Clean Energy (narrow)",
      value: "Clean Energy (narrow)",
    },
    {
      label: "Cloud Computing",
      value: "Cloud Computing",
    },
    {
      label: "Datafication/Digitization",
      value: "Datafication/Digitization",
    },
    {
      label: "Electrified Heating",
      value: "Electrified Heating",
    },
    {
      label: "Energy Storage",
      value: "Energy Storage",
    },
    {
      label: "Health & Wellness",
      value: "Health & Wellness",
    },
    {
      label: "Human Mobility",
      value: "Human Mobility",
    },
    {
      label: "Material Science Innovations",
      value: "Material Science Innovations",
    },
    {
      label: "Medical Technologies",
      value: "Medical Technologies",
    },
    {
      label: "Mental Wellbeing",
      value: "Mental Wellbeing",
    },
    {
      label: "Nuclear Power",
      value: "Nuclear Power",
    },
    {
      label: "Nutritional Value Chain",
      value: "Nutritional Value Chain",
    },
    {
      label: "Renewable/Alternative Energy",
      value: "Renewable/Alternative Energy",
    },
    {
      label: "Resource Efficiency",
      value: "Resource Efficiency",
    },
    {
      label: "Sustainable Infrastructure",
      value: "Sustainable Infrastructure",
    },
    {
      label: "Transportation Innovation",
      value: "Transportation Innovation",
    },
  ],
};
export const STEP5_TEMP = {
  type: "checkbox",
  name: "temp5",
  title: "Title if you want",

  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};
export const STEP6_TEMP = {
  type: "checkbox",
  name: "temp6",
  title: "Title if you want",

  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};
export const STEPTHEME_IMPACT = {
  type: "switch",
  name: "improve_impact",
  title: "Optimize Impact Score",
  description: "This will result in better scores",
  options: [
    { label: "Exclude bottom % impact", value: "exclude_impact" },
    { label: "Impact Tilt", value: "impact_tilt" },
  ],
};
export const STEPTHEME_IMPACT_ENV = {
  type: "switch",
  name: "improve_impact_env",
  title: "Optimize environmental impact",
  description: "This will result in better scores",
  options: [
    { label: "Exclude bottom % impact", value: "exclude_impact" },
    { label: "Enviromment improvers", value: "impact_tilt" },
  ],
};
export const STEPSLIDER_TEMP = {
  type: "slider",
  name: "slider_6",
  title: "Percentage of revenues aligned with the EU Taxonomy",
  min: 1,
  max: 100,
  step: 1,
  description: "Info if you want any explanation",
  unit: "%",
  options: [{ label: "Temp1", value: "temp1" }],
};
export const STEPTHEME_IMPACT_CUSTOMER = {
  type: "switch",
  name: "improve_impact_customer",
  title: "Optimize customer impact",
  description: "This will result in better scores",
  options: [
    { label: "Exclude bottom % impact", value: "exclude_impact" },
    { label: "Customer improvers", value: "impact_tilt" },
  ],
};
export const STEPTHEME_CUSTOM = {
  type: "input",
  name: "custom_theme",
  title: "Enter any theme you want to add",
  options: [
    { label: "Exclude bottom % impact", value: "exclude_impact" },
    { label: "Customer improvers", value: "impact_tilt" },
  ],
};
export const STEPEXCLUSION_CUSTOM = {
  type: "input",
  name: "custom_exclusion",
  title: "Enter anything you want to exclude",
  options: [
    { label: "Exclude bottom % impact", value: "exclude_impact" },
    { label: "Customer improvers", value: "impact_tilt" },
  ],
};
export const STEPEXCL_MARKETCAP = {
  type: "switch",
  name: "marketcap",
  title: "Which market cap are you interested in?",
  description:
    "Small cap (up to 2 billion)\nMid cap (up to 10 billion)\nLarge cap (above)",
  options: [
    { label: "Small cap", value: "small_cap" },
    { label: "Medium cap", value: "medium_cap" },
    { label: "Large cap", value: "large_cap" },
  ],
};
export const STEPTHEME_IMPACT_EMPLOYEE = {
  type: "switch",
  name: "improve_impact_employee",
  title: "Optimize employee impact",
  description: "This will result in better scores",
  options: [
    { label: "Exclude bottom % impact", value: "exclude_impact" },
    { label: "Employee improvers", value: "impact_tilt" },
  ],
};

export const STEPSDG = {
  type: "switch",
  name: "sdg",
  title: "Select SDG with positive impact",
  options: [
    {
      label: "No Poverty",
      value: "No Poverty",
    },
    {
      label: "Zero Hunger",
      value: "Zero Hunger",
    },
    {
      label: "Good Health and Well-being",
      value: "Good Health and Well-being",
    },
    {
      label: "Quality Education",
      value: "Quality Education",
    },
    {
      label: "Gender Equality",
      value: "Gender Equality",
    },
    {
      label: "Clean Water and Sanitation",
      value: "Clean Water and Sanitation",
    },
    {
      label: "Affordable and Clean Energy",
      value: "Affordable and Clean Energy",
    },
    {
      label: "Decent Work and Economic Growth",
      value: "Decent Work and Economic Growth",
    },
    {
      label: "Industry, Innovation and Infrastructure",
      value: "Industry Innovation and Infrastructure",
    },
    {
      label: "Reduced Inequality",
      value: "Reduced Inequality",
    },
    {
      label: "Sustainable Cities and Communities",
      value: "Sustainable Cities and Communities",
    },
    {
      label: "Responsible Consumption and Production",
      value: "Responsible Consumption and Production",
    },
    {
      label: "Climate Action",
      value: "Climate Action",
    },
    {
      label: "Life Below Water",
      value: "Life Below Water",
    },
    {
      label: "Life on Land",
      value: "Life on Land",
    },
    {
      label: "Peace, Justice and Strong Institutions",
      value: "Peace Justice and Strong Institutions",
    },
    {
      label: "Partnerships for the Goals",
      value: "Partnerships for the Goals",
    },
  ],
};

export const STEPSLIDERREVENUE_TEMP = {
  type: "slider",
  name: "slider_7",
  min: 1000,
  max: 10000000,
  step: 1000,
  title: "Select revenue category",
  unit: "$",
  description: "Info if you want any explanation",
  options: [{ label: "Temp1", value: "temp1" }],
};
export const STEP7_TEMP = {
  type: "checkbox",
  name: "temp7",
  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};

//STEP8,9,10temp
export const STEP8_TEMP = {
  type: "switch",
  name: "temp8",
  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};
export const STEP9_TEMP = {
  type: "checkbox",
  description: "Info if you want any explanation",
  title: "TODO text",
  name: "temp9",
  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};
export const STEP10_TEMP = {
  type: "checkbox",
  name: "temp10",
  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};
export const STEP11_TEMP = {
  type: "switch",
  title: "Title if you want",
  description: "Info if you want any explanation",
  name: "temp11",
  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};
export const STEP12_TEMP = {
  type: "checkbox",
  description: "Info if you want any explanation",

  title: "Title if you want",

  name: "temp12",
  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};
export const STEP13_TEMP = {
  type: "checkbox",
  title: "Title if you want",

  name: "temp13",
  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};
export const STEP14_TEMP = {
  title: "Title if you want",
  type: "checkbox",
  name: "temp14",
  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};
export const STEP15_TEMP = {
  title: "Title if you want",

  type: "checkbox",
  name: "temp15",
  options: [
    { label: "Temp1", value: "temp1" },
    { label: "Temp2", value: "temp2" },
  ],
};

export const STEP1_REGIONS = {
  type: "checkbox",
  name: "regions",
  /*   title: "Title if you want",
  description: "Info if you want any explanation", */

  options: [
    { label: "Global", value: "global" },
    { label: "Africa", value: "africa" },
    { label: "Asia", value: "asia" },
    { label: "Europe", value: "europe" },
    { label: "North America", value: "north_america" },
    { label: "Oceania", value: "oceania" },
    { label: "South America", value: "south_america" },
  ],
};

export const STEP1_COUNTRIES = {
  type: "checkbox",
  name: "countries",
  search: true,
  options: [
    { label: "Afghanistan", value: "AFG" },
    { label: "Albania", value: "ALB" },
    { label: "Algeria", value: "DZA" },
    { label: "Andorra", value: "AND" },
    { label: "Angola", value: "AGO" },
    { label: "Antigua and Barbuda", value: "ATG" },
    { label: "Argentina", value: "ARG" },
    { label: "Armenia", value: "ARM" },
    { label: "Australia", value: "AUS" },
    { label: "Austria", value: "AUT" },
    { label: "Azerbaijan", value: "AZE" },
    { label: "Bahamas", value: "BHS" },
    { label: "Bahrain", value: "BHR" },
    { label: "Bangladesh", value: "BGD" },
    { label: "Barbados", value: "BRB" },
    { label: "Belarus", value: "BLR" },
    { label: "Belgium", value: "BEL" },
    { label: "Belize", value: "BLZ" },
    { label: "Benin", value: "BEN" },
    { label: "Bhutan", value: "BTN" },
    { label: "Bolivia", value: "BOL" },
    { label: "Bosnia and Herzegovina", value: "BIH" },
    { label: "Botswana", value: "BWA" },
    { label: "Brazil", value: "BRA" },
    { label: "Brunei", value: "BRN" },
    { label: "Bulgaria", value: "BGR" },
    { label: "Burkina Faso", value: "BFA" },
    { label: "Burundi", value: "BDI" },
    { label: "Cabo Verde", value: "CPV" },
    { label: "Cambodia", value: "KHM" },
    { label: "Cameroon", value: "CMR" },
    { label: "Canada", value: "CAN" },
    { label: "Central African Republic", value: "CAF" },
    { label: "Chad", value: "TCD" },
    { label: "Chile", value: "CHL" },
    { label: "China", value: "CHN" },
    { label: "Colombia", value: "COL" },
    { label: "Comoros", value: "COM" },
    { label: "Congo (Congo-Brazzaville)", value: "COG" },
    { label: "Costa Rica", value: "CRI" },
    { label: "Croatia", value: "HRV" },
    { label: "Cuba", value: "CUB" },
    { label: "Cyprus", value: "CYP" },
    { label: "Czechia (Czech Republic)", value: "CZE" },
    { label: "Democratic Republic of the Congo", value: "COD" },
    { label: "Denmark", value: "DNK" },
    { label: "Djibouti", value: "DJI" },
    { label: "Dominica", value: "DMA" },
    { label: "Dominican Republic", value: "DOM" },
    { label: "Ecuador", value: "ECU" },
    { label: "Egypt", value: "EGY" },
    { label: "El Salvador", value: "SLV" },
    { label: "Equatorial Guinea", value: "GNQ" },
    { label: "Eritrea", value: "ERI" },
    { label: "Estonia", value: "EST" },
    { label: "Eswatini", value: "SWZ" },
    { label: "Ethiopia", value: "ETH" },
    { label: "Fiji", value: "FJI" },
    { label: "Finland", value: "FIN" },
    { label: "France", value: "FRA" },
    { label: "Gabon", value: "GAB" },
    { label: "Gambia", value: "GMB" },
    { label: "Georgia", value: "GEO" },
    { label: "Germany", value: "DEU" },
    { label: "Ghana", value: "GHA" },
    { label: "Greece", value: "GRC" },
    { label: "Grenada", value: "GRD" },
    { label: "Guatemala", value: "GTM" },
    { label: "Guinea", value: "GIN" },
    { label: "Guinea-Bissau", value: "GNB" },
    { label: "Guyana", value: "GUY" },
    { label: "Haiti", value: "HTI" },
    { label: "Holy See", value: "VAT" },
    { label: "Honduras", value: "HND" },
    { label: "Hungary", value: "HUN" },
    { label: "Iceland", value: "ISL" },
    { label: "India", value: "IND" },
    { label: "Indonesia", value: "IDN" },
    { label: "Iran", value: "IRN" },
    { label: "Iraq", value: "IRQ" },
    { label: "Ireland", value: "IRL" },
    { label: "Israel", value: "ISR" },
    { label: "Italy", value: "ITA" },
    { label: "Ivory Coast", value: "CIV" },
    { label: "Jamaica", value: "JAM" },
    { label: "Japan", value: "JPN" },
    { label: "Jordan", value: "JOR" },
    { label: "Kazakhstan", value: "KAZ" },
    { label: "Kenya", value: "KEN" },
    { label: "Kiribati", value: "KIR" },
    { label: "Kuwait", value: "KWT" },
    { label: "Kyrgyzstan", value: "KGZ" },
    { label: "Laos", value: "LAO" },
    { label: "Latvia", value: "LVA" },
    { label: "Lebanon", value: "LBN" },
    { label: "Lesotho", value: "LSO" },
    { label: "Liberia", value: "LBR" },
    { label: "Libya", value: "LBY" },
    { label: "Liechtenstein", value: "LIE" },
    { label: "Lithuania", value: "LTU" },
    { label: "Luxembourg", value: "LUX" },
    { label: "Madagascar", value: "MDG" },
    { label: "Malawi", value: "MWI" },
    { label: "Malaysia", value: "MYS" },
    { label: "Maldives", value: "MDV" },
    { label: "Mali", value: "MLI" },
    { label: "Malta", value: "MLT" },
    { label: "Marshall Islands", value: "MHL" },
    { label: "Mauritania", value: "MRT" },
    { label: "Mauritius", value: "MUS" },
    { label: "Mexico", value: "MEX" },
    { label: "Micronesia", value: "FSM" },
    { label: "Moldova", value: "MDA" },
    { label: "Monaco", value: "MCO" },
    { label: "Mongolia", value: "MNG" },
    { label: "Montenegro", value: "MNE" },
    { label: "Morocco", value: "MAR" },
    { label: "Mozambique", value: "MOZ" },
    { label: "Myanmar (formerly Burma)", value: "MMR" },
    { label: "Namibia", value: "NAM" },
    { label: "Nauru", value: "NRU" },
    { label: "Nepal", value: "NPL" },
    { label: "Netherlands", value: "NLD" },
    { label: "New Zealand", value: "NZL" },
    { label: "Nicaragua", value: "NIC" },
    { label: "Niger", value: "NER" },
    { label: "Nigeria", value: "NGA" },
    { label: "North Korea", value: "PRK" },
    { label: "North Macedonia", value: "MKD" },
    { label: "Norway", value: "NOR" },
    { label: "Oman", value: "OMN" },
    { label: "Pakistan", value: "PAK" },
    { label: "Palau", value: "PLW" },
    { label: "Palestine State", value: "PSE" },
    { label: "Panama", value: "PAN" },
    { label: "Papua New Guinea", value: "PNG" },
    { label: "Paraguay", value: "PRY" },
    { label: "Peru", value: "PER" },
    { label: "Philippines", value: "PHL" },
    { label: "Poland", value: "POL" },
    { label: "Portugal", value: "PRT" },
    { label: "Qatar", value: "QAT" },
    { label: "Romania", value: "ROU" },
    { label: "Russia", value: "RUS" },
    { label: "Rwanda", value: "RWA" },
    { label: "Saint Kitts and Nevis", value: "KNA" },
    { label: "Saint Lucia", value: "LCA" },
    { label: "Saint Vincent and the Grenadines", value: "VCT" },
    { label: "Samoa", value: "WSM" },
    { label: "San Marino", value: "SMR" },
    { label: "Sao Tome and Principe", value: "STP" },
    { label: "Saudi Arabia", value: "SAU" },
    { label: "Senegal", value: "SEN" },
    { label: "Serbia", value: "SRB" },
    { label: "Seychelles", value: "SYC" },
    { label: "Sierra Leone", value: "SLE" },
    { label: "Singapore", value: "SGP" },
    { label: "Slovakia", value: "SVK" },
    { label: "Slovenia", value: "SVN" },
    { label: "Solomon Islands", value: "SLB" },
    { label: "Somalia", value: "SOM" },
    { label: "South Africa", value: "ZAF" },
    { label: "South Korea", value: "KOR" },
    { label: "South Sudan", value: "SSD" },
    { label: "Spain", value: "ESP" },
    { label: "Sri Lanka", value: "LKA" },
    { label: "Sudan", value: "SDN" },
    { label: "Suriname", value: "SUR" },
    { label: "Sweden", value: "SWE" },
    { label: "Switzerland", value: "CHE" },
    { label: "Syria", value: "SYR" },
    { label: "Tajikistan", value: "TJK" },
    { label: "Tanzania", value: "TZA" },
    { label: "Thailand", value: "THA" },
    { label: "Timor-Leste", value: "TLS" },
    { label: "Togo", value: "TGO" },
    { label: "Tonga", value: "TON" },
    { label: "Trinidad and Tobago", value: "TTO" },
    { label: "Tunisia", value: "TUN" },
    { label: "Turkey", value: "TUR" },
    { label: "Turkmenistan", value: "TKM" },
    { label: "Tuvalu", value: "TUV" },
    { label: "Uganda", value: "UGA" },
    { label: "Ukraine", value: "UKR" },
    { label: "United Arab Emirates", value: "ARE" },
    { label: "United Kingdom", value: "GBR" },
    { label: "United States", value: "USA" },
    { label: "Uruguay", value: "URY" },
    { label: "Uzbekistan", value: "UZB" },
    { label: "Vanuatu", value: "VUT" },
    { label: "Venezuela", value: "VEN" },
    { label: "Vietnam", value: "VNM" },
    { label: "Yemen", value: "YEM" },
    { label: "Zambia", value: "ZMB" },
    { label: "Zimbabwe", value: "ZWE" },
  ],
};

export const STEP1_EXCLUSIONS_FAITH_BASED_RULEBOOK = {
  type: "switch",
  name: "faith_based_rulebook",
  title: "Faith Based RuleBook",
  description: "Select the rulebook you want to use.",
  options: [
    {
      label: "AAOIFI",
      value: "AAOIFI",
    },
    {
      label: "USCCB",
      value: "USCCB",
    },
  ],
};
export const STEP1_EXCLUSIONS_BUSINESS_INVOLVMENTS = {
  type: "switch",
  name: "business_involvements",
  title: "Business involvements",
  description: "Select the business involvements you want to exclude.",
  options: [
    {
      label: "Abortion",
      value: "Abortion",
    },
    {
      label: "Adult entertainment",
      value: "Adult entertainment",
    },
    {
      label: "Alcohol",
      value: "Alcohol",
    },
    {
      label: "Cannabis",
      value: "Cannabis",
    },
    {
      label: "Contraceptives",
      value: "Contraceptives",
    },
    {
      label: "Defense & weapons",
      value: "Defense & weapons",
    },
    {
      label: "Fast Food",
      value: "Fast Food",
    },
    {
      label: "For profit prisons",
      value: "For profit prisons",
    },
    {
      label: "Fossil Fuel",
      value: "Fossil Fuel",
    },
    {
      label: "Fracking",
      value: "Fracking",
    },
    {
      label: "Gambling",
      value: "Gambling",
    },
    {
      label: "Hazardous Waste",
      value: "Hazardous Waste",
    },
    {
      label: "High Sugar",
      value: "High Sugar",
    },
    {
      label: "Nuclear Power",
      value: "Nuclear Power",
    },
    {
      label: "Tobacco, e-cigs, and vaping",
      value: "Tobacco, e-cigs, and vaping",
    },
    {
      label: "Burma",
      value: "Burma",
    },
    {
      label: "Cuba",
      value: "Cuba",
    },
    {
      label: "Iran",
      value: "Iran",
    },
    {
      label: "North Korea",
      value: "North Korea",
    },
    {
      label: "Russia/Ukraine",
      value: "Russia/Ukraine",
    },
    {
      label: "Sudan",
      value: "Sudan",
    },
    {
      label: "Syria",
      value: "Syria",
    },
    {
      label: "Venezuela",
      value: "Venezuela",
    },
  ],
};

export const STEP_REVIEW = {
  type: "review",
  name: "review",
  title: "Review",
  description: "Review your selections",
  options: [
    {
      label: "Review",
      value: "review",
    },
  ],
};
