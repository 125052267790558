import React, { Fragment, useState } from "react";
import WrapperCard from "../../ui/redesign/WrapperCard";
import PrimaryButton from "../../ui/redesign/PrimaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { AllRoutes } from "../../../pages/Routes";
import { enqueueSnackbar } from "notistack";
import { useLoadingAndError } from "../../../services/hooks";
import LoadingErrorWrapper from "../../../api/LoadingErrorWrapper";
import LoadingWrapper from "../../../api/LoadingWrapper";
import { signIn } from "../../../api/api";
import { useUser } from "../../../context/UserContext";
import { Dialog, Transition } from "@headlessui/react";
import ContactUs from "../../../pages/ContactUs";
import Logo from "../Logo";

const SignInPage = () => {
  const history = useHistory();
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);

  const { toggleLoading, isLoading } = useLoadingAndError();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signIn: signInStore } = useUser();
  const handleSignIn = async () => {
    toggleLoading();
    try {
      await signIn(email, password);
      history.push(AllRoutes.dashboard);
      signInStore({ email });
      toggleLoading();
    } catch (error) {
      enqueueSnackbar("Incorrect email or password, please try again", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
      toggleLoading();
    }
  };

  return (
    <div className="w-full h-full m-auto flex justify-center items-center">
      <Transition appear show={isForgotPasswordModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsForgotPasswordModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-1 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-0 text-left align-middle shadow-xl transition-all">
                  <ContactUs />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <WrapperCard className="px-8 py-16 flex flex-col w-[600px]">
        <Logo className={"mx-auto"} />
        <p className="text-2xl leading-8 font-semibold text-gray-500 mt-8 mb-4 text-center">
          Welcome to our data platform
        </p>
        <p className="text-lg leading-7 text-gray-500 text-center mb-10">
          Please login to continue!
        </p>
        <div className="relative mb-4">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email"
            className="shadow-sm w-full text-gray-600  pl-10 border outline-none focus:ring-1 focus:ring-gray-400 border-gray-300 rounded-xl px-3 py-2 placeholder-gray-500 placeholder-sm"
          />
          <img
            src="/icon/email.svg"
            alt="email icon"
            className="absolute left-3 top-2.5 h-6 fill-slate-500"
          />
        </div>
        <div className="relative mb-8">
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            type="password"
            className="shadow-sm w-full text-gray-600  pl-10 border outline-none focus:ring-1 focus:ring-gray-400 border-gray-300 rounded-xl px-3 py-2 placeholder-gray-500 placeholder-sm"
          />
          <img
            src="/icon/password.svg"
            alt="password icon"
            className="absolute left-3 top-2.5 h-6 fill-slate-500"
          />
        </div>
        <LoadingWrapper isLoading={isLoading}>
          <PrimaryButton full={true} onClick={handleSignIn} text={"Sign in"} />
        </LoadingWrapper>
        <p
          onClick={() => setIsForgotPasswordModalOpen(true)}
          className="text-xs mt-2 cursor-pointer leading-7 text-gray-500 text-center mb-4"
        >
          Forgot your password?
        </p>
        <span className="mt-6 text-base leading-sm text-my-tailwind-gray-500 text-center">
          Don’t have an account?{" "}
          <span
            onClick={() => {
              history.push(AllRoutes.signUp);
            }}
            className="font-semibold cursor-pointer text-my-rift-primary-green-600"
          >
            Sign Up
          </span>
        </span>
      </WrapperCard>
    </div>
  );
};

export default SignInPage;
