/* import components */
import ScrollToTop from "./components/hoc/ScrollTop";

/* import packages, functions */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import EUTaxonomy from "./pages/EUTaxonomy";
import PSICS from "./pages/PSICS";
import PortfolioImpact from "./pages/PortfolioImpact";
import SDGAlignment from "./pages/SDGAlignment";
import {
  trackPageView,
  useHubspotScript,
  useHubspotTracking,
  usePageTracking,
  useScript,
} from "./services/hooks";
import { fetchIndustries } from "./store/general/actions";

import { useLocation } from "react-router-dom";
import { fetchAllSearchData } from "./api/api";
import HeaderRe from "./components/redesign/Header";
import SidebarRe from "./components/redesign/Sidebar";
import SignInPage from "./components/redesign/auth/SignInPage";
import SignUpPage from "./components/redesign/auth/SignUpPage";
import { useDataContext } from "./context/PortfolioContext";
import AbsPortfolio from "./pages/AbsPortfolio";
import CompanyEUTaxonomy from "./pages/CompanyEUTaxonomy";
import CompanyImpact from "./pages/CompanyImpact";
import CompanyPSICS from "./pages/CompanyPSICS";
import CreatePortfolio from "./pages/create_portfolio/CreatePortfolio";
import CompanySDGAlignment from "./pages/CompanySdgAlignment";
import ContactUs from "./pages/ContactUs";
import { AllRoutes } from "./pages/Routes";
import FeatureSoon from "./pages/Soon";
import Upgrade from "./pages/Upgrade";
import PrivateCompany from "./pages/company_impact/PrivateCompany";
import Reporting from "./pages/reporting/Reporting";
import UploadPortfolio from "./pages/upload_portfolio/UploadPortfolio";
import MyPortfolio from "./pages/my_portfolio/MyPortfolio";
import ReportingPage from "./pages/reporting/ReportingPage";
import CreatePortfolioPage from "./pages/create_portfolio/CreatePortfolioPage";
import Opening from "./pages/Opening";

function App() {
  const dispatch = useDispatch();

  const sessionId = useSelector((state) => state.general.sessionId);
  const { setAllSearchData } = useDataContext();

  // GA Page tracking hook
  usePageTracking();

  useHubspotScript("//js-eu1.hs-scripts.com/25322535.js");

  /* 
   
  setPathPageView("/home");

  //TODO SET IDENTITY AFTER USER LOGGED IN IF NEEDED FOR HUBSPOT
  setIdentity("your-email@provider.com"); */

  useEffect(() => {
    async function fetchData() {
      const allData = await fetchAllSearchData();
      //TODO ALL DATA SHOULD INCLUDE UNIQUE IDS AS WELL
      setAllSearchData(allData);
    }
    fetchData();
  }, []);

  // Get initial data if session existst
  useEffect(() => {
    if (sessionId) {
      dispatch(fetchIndustries());
    }
  }, [sessionId]);

  const { handleClearData, initialYear, setYear, isFullWidth } =
    useDataContext();

  let location = useLocation();

  const [password, setPassword] = useState("");

  useEffect(() => {
    //handleClearData();
    setYear(initialYear);
  }, [location]);

  /*   if (password != "superadmin") {
    return (
      <div className="m-auto w-full h-full flex flex-col my-auto p-32 items-center justify-center">
        <h1>Site access password</h1>
        <input type="password" onChange={(e) => setPassword(e.target.value)} />
      </div>
    );
  } */

  const url = useLocation().pathname;
  const isAuth = url.includes("auth");

  //RESPONSIVNESS ADD :sm :md: lg: things like that tailwindcss /feature/responsive branch has some input
  return (
    <div id="rgs">
      <>
        <RouteChangeTracker />
        <main className="flex flex-col h-screen">
          <div className="flex flex-1 overflow-hidden">
            {!isAuth && !isFullWidth && (
              <div
                style={{
                  boxShadow: "0px 2px 20px 4px rgba(0, 0, 0, 0.06)",
                }}
                className="md:flex bg-white w-64 px-4 py-6 hidden  shadow-my-md rounded-tr-2xl rounded-br-2xl"
              >
                <SidebarRe key={"desktop"} />
              </div>
            )}
            <div className="flex flex-1 flex-col overflow-y-auto">
              {!isAuth && (
                <div className="block md:flex ">
                  <HeaderRe />
                </div>
              )}
              <div className="flex flex-1 bg-gray-50 overflow-y-auto paragraph mb-4">
                <ScrollToTop />
                <Switch>
                  <Route
                    path={AllRoutes.create_portfolio}
                    //component={<></>}
                    component={CreatePortfolioPage}
                    exact
                  />
                  <Route
                    path={AllRoutes.upload_portfolio}
                    component={UploadPortfolio}
                    exact
                  />
                  <Route
                    path={AllRoutes.signIn}
                    //component={<></>}
                    component={SignInPage}
                    exact
                  />
                  <Route
                    path={AllRoutes.signUp}
                    //component={<></>}
                    component={SignUpPage}
                    exact
                  />
                  <Route
                    path={AllRoutes.dashboard}
                    component={Dashboard}
                    exact
                  />
                  <Route path={AllRoutes.opening} component={Opening} exact />
                  {/*  <Route exact path="/">
                  <Redirect to={AllRoutes.portfolio_overview} />
                </Route> */}
                  {/* PORTFOLIO */}
                  <Route
                    path={AllRoutes.portfolio_impact}
                    component={PortfolioImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.portfolio_overview}
                    component={PortfolioImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.customer_impact}
                    component={PortfolioImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.employees_impact}
                    component={PortfolioImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.environmental_impact}
                    component={PortfolioImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.portfolio_information}
                    component={PortfolioImpact}
                    exact
                  />
                  {/* COMPANY */}
                  <Route
                    path={AllRoutes.company_impact}
                    component={CompanyImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.company_overview}
                    component={CompanyImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.company_customer_impact}
                    component={CompanyImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.company_employees_impact}
                    component={CompanyImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.company_environmental_impact}
                    component={CompanyImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.contact_us}
                    component={ContactUs}
                    exact
                  />
                  <Route path={AllRoutes.upgrade} component={Upgrade} exact />
                  <Route
                    path={AllRoutes.portfolio_psics}
                    component={PSICS}
                    exact
                  />
                  <Route
                    path={AllRoutes.company_psics}
                    component={CompanyPSICS}
                    exact
                  />
                  <Route
                    path={AllRoutes.portfolio_eu_taxonomy}
                    component={EUTaxonomy}
                    exact
                  />
                  <Route
                    path={AllRoutes.company_eu_taxonomy}
                    component={CompanyEUTaxonomy}
                    exact
                  />
                  <Route
                    path={AllRoutes.portfolio_sdg_alignment}
                    component={SDGAlignment}
                    exact
                  />
                  <Route
                    path={AllRoutes.company_sdg_alignment}
                    component={CompanySDGAlignment}
                    exact
                  />
                  <Route
                    path={AllRoutes.abs_climate}
                    component={AbsPortfolio}
                    exact
                  />
                  <Route
                    path={AllRoutes.private_equity}
                    //component={FeatureSoon}
                    component={PrivateCompany}
                    exact
                  />
                  <Route
                    path={AllRoutes.private_company_impact}
                    component={CompanyImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.private_company_overview}
                    component={CompanyImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.private_company_customer_impact}
                    component={CompanyImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.private_company_employees_impact}
                    component={CompanyImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.private_company_environmental_impact}
                    component={CompanyImpact}
                    exact
                  />
                  <Route
                    path={AllRoutes.my_portfolio}
                    component={MyPortfolio}
                  />
                  <Route
                    path={AllRoutes.reporting}
                    component={ReportingPage}
                    exact
                  />
                  <Route path="*" component={FeatureSoon} />
                  {/*                 TODO PORTFOLIO AND COMPANY VIEW
                   */}{" "}
                  {/*  <Route
                  path={AllRoutes.eu_taxonomy}
                  component={EUTaxonomy}
                  exact
                />
                <Route
                  path={AllRoutes.sdg_alignment}
                  component={SDGAlignment}
                  exact
                />
                <Route path={AllRoutes.raw_data} component={RawData} exact /> */}
                  {/*   <Route
                  path="/analysis/company/:companyId"
                  component={CompanyAnalysis}
                />
                <Route
                  path="/analysis/industry/:industryId"
                  component={IndustryAnalysis}
                />
                <Route path="/portfolio" component={Portfolio} exact />
                {/* Protected routes
                <AdminRoute
                  path="/product-groups"
                  component={ProductGroups}
                  exact
                />
                <AdminRoute
                  path="/thematic-search"
                  component={ThematicSearch}
                  exact
                />
                */}
                </Switch>
              </div>
            </div>
          </div>
        </main>
      </>
    </div>
  );
}

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return null;
};

export default App;
