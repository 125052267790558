import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ShowMoreChevron = ({ open, reverse }) => {
  return (
    <svg
      className={classNames(
        open ? `rotate-90 text-gray-500` : "text-gray-300",
        "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
      )}
      viewBox="0 0 20 20"
      aria-hidden="true"
    >
      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
    </svg>
  );
};

export default ShowMoreChevron;
