import React from "react";
import { twMerge } from "tailwind-merge";

const SecondaryButton = ({ text, onClick, disabled, iconUrl, full, small, className }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={twMerge(
        `flex gap-x-2 items-center justify-center rounded-md  border-[1px] border-gray-500 bg-gray-100 py-2.5 px-4 text-sm font-normal text-gray-600 shadow-sm hover:bg-gray-200 ${
          full ? "w-full" : "w-fit"
        } ${disabled ? "bg-gray-300/50" : "cursor-pointer"}`,
        small && "py-1",
        className
      )}
    >
      {iconUrl && <img className="flex-shrink-0" src={iconUrl} />}
      <span className="whitespace-nowrap">{text}</span>
    </button>
  );
};

export default SecondaryButton;
