import axios from "axios";
import { AllRoutes } from "../pages/Routes";
import { rowData } from "./constants";

const BASE_URL = "https://rift-api-staging-ugpbdvwvya-ew.a.run.app/api";

export const apiClient = axios.create({
  baseURL: `${BASE_URL}`,
});

export const authToken = "authToken";
export const authEmail = "authEmail";
export const authPermissions = "authPermissions";

apiClient.interceptors.request.use(
  (config) => {
    // Try to get the token from local storage
    const token = localStorage.getItem(authToken);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const API = {
  portfolio_overview: "portfolio/overview",
  stakeholder_level: "portfolio/stakeholder-level-details",
  psics: "portfolio/psics",
  information: "portfolio/details",
  contact: "contact-us",
  company_overview: "company/overview",
  company_stakeholder_level: "company/stakeholder-level-details",
  company_information: "company/details",
  company_psics: "company/psics",
  searchdata: "available-assets",
  portfolio_eu_taxonomy: "portfolio/eu-taxonomy",
  company_eu_taxonomy: "company/eu-taxonomy",
  portfolio_sdg_alignment: "portfolio/sdg-alignment",
  company_sdg_alignment: "company/sdg-alignment",
  abs_portfolio: "abs/overview",
  sign_in: "auth/sign-in",
  sign_up: "auth/sign-up",
};

export const API_ENUMS = {
  employee: "employee",
  customer: "customer",
  environment: "environment",
  overview: "portfolio_overview",
  information: "portfolio_information",
};

const compareFormat = (data) => {
  return data.join(";");
};

export const fetchPortfolioOverview = async (portfolio_names, year) => {
  if (portfolio_names?.length != 2) return;
  const { data } = await apiClient.get(API.portfolio_overview, {
    params: { portfolio_names: compareFormat(portfolio_names), year: year },
  });
  return data;
};
export const fetchPSICS = async (portfolio_names, year) => {
  if (portfolio_names?.length != 2) return;

  /*   //throw error if permission denied
  throw new Error("403"); 
 */
  const { data } = await apiClient.get(API.psics, {
    params: { portfolio_names: compareFormat(portfolio_names), year: year },
  });
  return data;
};
export const fetchImpactCategory = async (category, portfolio_names, year) => {
  if (portfolio_names?.length != 2) return;

  if (category == API_ENUMS.information) {
    return await fetchPortfolioInformation(portfolio_names, year);
  }

  const { data } = await apiClient.get(API.stakeholder_level, {
    params: {
      stakeholder: category,
      portfolio_names: compareFormat(portfolio_names),
      year: year,
    },
  });
  return data;
};

export const fetchPortfolioInformation = async (portfolio_names, year) => {
  if (portfolio_names?.length != 2) return true;

  const { data } = await apiClient.get(API.information, {
    params: { portfolio_names: compareFormat(portfolio_names), year: year },
  });
  return data;
};

export const sendContactUs = async (name, email, message) => {
  try {
    const response = await apiClient.post(API.contact, {
      name: name,
      email: email,
      message: message,
    });
    return response;
  } catch (error) {
    console.error("sendContactUs Error", error.response);
    throw error;
  }
};

//COMPANY SAME
export const fetchCompanyOverview = async (portfolio_names, year) => {
  if (portfolio_names?.length != 1) return;
  const { data } = await apiClient.get(API.company_overview, {
    params: { company_name: portfolio_names[0], year: year },
  });
  return data;
};
export const fetchCompanyImpactCategory = async (
  category,
  portfolio_names,
  year
) => {
  if (portfolio_names?.length != 1) return;

  if (category == API_ENUMS.information) {
    return await fetchCompanyPortfolioInformation(portfolio_names, year);
  }

  const { data } = await apiClient.get(API.company_stakeholder_level, {
    params: {
      stakeholder: category,
      company_name: portfolio_names[0],
      year: year,
    },
  });
  return data;
};

export const fetchCompanyPortfolioInformation = async (
  portfolio_names,
  year
) => {
  if (portfolio_names?.length != 1) return;

  const { data } = await apiClient.get(API.company_information, {
    params: { company_name: portfolio_names[0], year: year },
  });
  return data;
};

export const fetchCompanyPSICS = async (portfolio_names, year) => {
  if (portfolio_names?.length != 1) return;

  const { data } = await apiClient.get(API.company_psics, {
    params: { company_name: portfolio_names[0], year: year },
  });
  return data;
};

export const fetchAbsPortfolio = async () => {
  const { data } = await apiClient.get(API.abs_portfolio);
  return data;
};

export const fetchAllSearchData = async () => {
  const { data } = await apiClient.get(API.searchdata);
  return data;
};

export const fetchPortfolioEuTaxonomy = async (portfolio_names, year) => {
  if (portfolio_names?.length != 2) return;

  const { data } = await apiClient.get(API.portfolio_eu_taxonomy, {
    params: { portfolio_names: compareFormat(portfolio_names), year: year },
  });
  return data;
};

export const fetchCompanyEuTaxonomy = async (portfolio_names, year) => {
  if (portfolio_names?.length != 1) return;

  const { data } = await apiClient.get(API.company_eu_taxonomy, {
    params: { company_name: portfolio_names[0], year: year },
  });
  return data;
};
export const fetchPortfolioSDG = async (portfolio_names, year) => {
  if (portfolio_names?.length != 2) return;

  const { data } = await apiClient.get(API.portfolio_sdg_alignment, {
    params: { portfolio_names: compareFormat(portfolio_names), year: year },
  });
  return data;
};

export const fetchCompanySDG = async (portfolio_names, year) => {
  if (portfolio_names?.length != 1) return;

  const { data } = await apiClient.get(API.company_sdg_alignment, {
    params: { company_name: portfolio_names[0], year: year },
  });
  return data;
};

export const signIn = async (username, password) => {
  try {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    // Add other parameters as necessary
    params.append("grant_type", "");
    params.append("scope", "");
    params.append("client_id", "");
    params.append("client_secret", "");

    const { data } = await apiClient.post("/login", params.toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    localStorage.setItem(authToken, data.token); // Adjust according to your API response
    localStorage.setItem(authEmail, data.email); // Adjust according to your API response
    localStorage.setItem(authPermissions, data.permissions);
    return data;
  } catch (error) {
    console.error("SignIn Error", error.response);
    throw error;
  }
};
export const signUp = async (email, password) => {
  try {
    const params = new URLSearchParams();
    params.append("username", email);
    params.append("password", password);
    // Append any additional sign-up parameters here
    params.append("grant_type", "");
    params.append("scope", "");
    params.append("client_id", "");
    params.append("client_secret", "");

    const { data } = await apiClient.post("/register", params.toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    // Handle sign-up response, potentially setting a token as well
    return data;
  } catch (error) {
    console.error("SignUp Error", error.response);
    throw error;
  }
};

export const signOutApi = () => {
  localStorage.removeItem(authToken);
  localStorage.removeItem(authEmail);
  // You might want to redirect the user to the sign-in page or perform other cleanup actions here
};

export const uploadPortfolioUploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  //TODO REMOVE SLEEP AFTER API IMPLEMENTATION
  //IF ANY ERROR IS HAPPENING ITS ALREADY HANDLED ON THE CLIENT SIDE BUT SERVER SENDS BACK THE ERROR

  //sleep 2 seconds
  await new Promise((r) => setTimeout(r, 2000));

  /*   const { data } = await apiClient.post("/portfolio/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }); */

  return rowData;
};

export const uploadPortfolioSave = async (portfolio_name, portfolio) => {
  await new Promise((r) => setTimeout(r, 2000));
  //TODO REMOVE SLEEP AFTER API IMPLEMENTATION

  /* 
  const { data } = await apiClient.post("/portfolio/save", {
    portfolio_name: portfolio_name,
    components: portfolio,
  }); */
  const data = { status: "success" };

  return data;
};

export const createPortfolioByFilters = async (filters) => {
  //TODO REMOVE SLEEP AFTER API IMPLEMENTATION

  await new Promise((r) => setTimeout(r, 2000));
  /*   const { data } = await apiClient.post("/portfolio/create", {
    filters: filters,
  }); */
  const data = { status: "success" };

  return data;
};
