import React from "react";
import { twMerge } from "tailwind-merge";

const WrapperCard = ({
  children,
  wideBorder,
  noneBorder,
  className,
  topRightChild,
  onClick,
  id,
}) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className={twMerge(
        className,
        `relative ${
          noneBorder
            ? ""
            : wideBorder
            ? "border-[12px] md:border-[24px] shadow"
            : "border-4 shadow"
        } ${
          id
            ? "rounded-none border-[8px] border-my-rift-primary-green-100"
            : "rounded-2xl border-white"
        } bg-white ${topRightChild ? "pt-4" : ""}`
      )}
    >
      {children}
      {topRightChild && (
        <p className="absolute top-0 right-0">{topRightChild}</p>
      )}
    </div>
  );
};

export default WrapperCard;
