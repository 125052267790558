import React from "react";
import { formatMoney, formatNumber } from "../../../services/utilities";

const ProductsServicesDetail = ({ data, subtitle }) => {
  const details = data;

  return (
    <div>
      <p className="text-xs font-medium leading-none text-gray-600">
        {subtitle}
      </p>
      <div className="mt-8">
        <table className="min-w-full ">
          <thead>
            <tr className="px-2.5 pt-1 pb-2">
              <th
                scope="col"
                className="py-1 pl-2.5 pr-2.5 text-left text-xs leading-none text-my-tailwind-gray-600 font-normal bg-my-tailwind-gray-50 rounded-tl-lg rounded-bl-lg"
              >
                Company Name
              </th>
              <th
                scope="col"
                className=" py-1 px-2.5 text-right text-xs leading-none text-my-tailwind-gray-600 font-normal sm:table-cell bg-my-tailwind-gray-50"
              >
                Category Revenue
              </th>
              <th
                scope="col"
                className=" py-1 px-2.5 text-right text-xs leading-none text-my-tailwind-gray-600 font-normal sm:table-cell bg-my-tailwind-gray-50 rounded-tr-lg rounded-br-lg"
              >
                Category Ratio
              </th>
            </tr>
          </thead>
          <tbody>
            {details.map((company) => (
              <tr key={company.id} className="px-2.5">
                <td className="py-3.5 px-2.5 text-sm">
                  <div className="text-xs font-semibold leading-none text-my-tailwind-gray-600 ">
                    {company.name}
                  </div>
                </td>
                <td className=" py-3.5 px-3 text-right text-xs font-semibold leading-none text-my-tailwind-gray-600 sm:table-cell">
                  $ {formatNumber(company.revenue)}
                </td>
                <td className=" py-3.5 px-3 text-right text-xs font-semibold leading-none text-my-tailwind-gray-600 sm:table-cell">
                  <div className="text-xs font-semibold leading-none text-my-tailwind-gray-600 ">
                    {(company.ratio * 100).toFixed()}%
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductsServicesDetail;
