import React, { useEffect, useState } from "react";
import WrapperCard from "../../components/ui/redesign/WrapperCard";
import Stepper from "../../components/redesign/Stepper";
import SecondaryButton from "../../components/ui/redesign/SeconaryButton";
import PrimaryButton from "../../components/ui/redesign/PrimaryButton";
import Reporting from "./Reporting";
import PortfolioCard from "../../components/redesign/portfolio_impact/PortfolioCard";
import { useDataContext } from "../../context/PortfolioContext";
import { trackCustomEvent, useLoadingAndError } from "../../services/hooks";
import {
  API_ENUMS,
  fetchImpactCategory,
  fetchPSICS,
  fetchPortfolioOverview,
} from "../../api/api";
import LoadingErrorWrapper from "../../api/LoadingErrorWrapper";
import { REPORT_DATA } from "./reportdata";
import PremiumFeature from "../../components/redesign/PremiumSoon";
import { useUser } from "../../context/UserContext";

const ReportingPage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [apiResult, setApiResult] = useState();
  const {
    companyList,
    selectedCompanies,
    setCompanyList,
    setSelectedCompanies,
  } = useDataContext();
  const { toggleLoading, isLoading, error, toggleError } = useLoadingAndError();

  const steps = [
    { stepNumber: "01", title: "Select portfolio" },
    { stepNumber: "02", title: "Preview report" },
    { stepNumber: "03", title: "Generate report" },
  ];

  useEffect(() => {
    setApiResult(null);
    setSelectedCompanies([]);
  }, []);

  const handleStepChange = (step) => {
    if (step == 0 && error) {
      toggleError(null);
    }
    if (step == 1) handleAndFetchAllData();
    setCurrentStep(step);
  };

  const handleAndFetchAllData = async () => {
    trackCustomEvent("report", {
      label: selectedCompanies,
      category: "User Selected Portfolios",
    });

    toggleLoading();
    try {
      const portfolioOverview = await fetchPortfolioOverview(selectedCompanies);
      const psics = await fetchPSICS(selectedCompanies);
      const portfolioEnvironment = await fetchImpactCategory(
        API_ENUMS.environment,
        selectedCompanies
      );
      const portfolioEmployee = await fetchImpactCategory(
        API_ENUMS.employee,
        selectedCompanies
      );
      const portfolioCustomer = await fetchImpactCategory(
        API_ENUMS.customer,
        selectedCompanies
      );
      //if any of data is null or error then throw error
      if (
        !portfolioOverview ||
        !portfolioEnvironment ||
        !portfolioEmployee ||
        !portfolioCustomer ||
        !psics
      ) {
        throw new Error("Error fetching data");
      }
      setApiResult({
        overview: portfolioOverview,
        environment: portfolioEnvironment,
        employee: portfolioEmployee,
        customer: portfolioCustomer,
        psics: psics,
      });
      toggleLoading();
    } catch (error) {
      console.log(error);
      toggleError(error);
    }
  };

  //TODO WITH THE CUSTOM OWN PORTFOLIO DATA REPORTING THIS NEED TO BE CHECKED IF EVERY DATA IS THERE

  // TEMPORARY REMOVAL for other users
  const { isAdmin } = useUser();

  if (!isAdmin)
    return (
      <div className="w-full h-full pt-60 bg-white flex items-center justify-center">
        <PremiumFeature noneBorder />
      </div>
    );

  return (
    <div className="w-full flex flex-col gap-y-4 m-8 mb-64">
      <WrapperCard className={"p-4"}>
        <div className="w-2/3 mx-auto">
          <Stepper
            steps={steps}
            activeStep={currentStep}
            changeStep={setCurrentStep}
          />
        </div>
        {isLoading || error ? (
          <LoadingErrorWrapper isLoading={isLoading} error={error} />
        ) : (
          <div>
            {currentStep == 0 && (
              <div>
                <div className="grid grid-cols-5 gap-x-6 items-center mt-8">
                  <div className="col-span-2">
                    <WrapperCard>
                      <PortfolioCard
                        isPrimary={true}
                        isEmpty={companyList?.[0] == null}
                        type={"simple"}
                        title={companyList?.[0]?.name}
                        value={companyList?.[0]?.value}
                        percentage={companyList?.[0]?.percentage}
                      />
                    </WrapperCard>
                  </div>
                  <div className="col-span-1 text-2xl text-center text-gray-700 font-bold uppercase">
                    VS
                    <div className="text-xs text-gray-600 font-normal normal-case">
                      Select 2 portfolios to compare
                    </div>
                  </div>
                  <div className="col-span-2">
                    <WrapperCard>
                      <PortfolioCard
                        isPrimary={false}
                        type={"simple"}
                        title={companyList?.[1]?.name}
                        value={companyList?.[1]?.value}
                        percentage={companyList?.[1]?.percentage}
                        isEmpty={companyList?.[1] == null}
                      />
                    </WrapperCard>
                  </div>
                </div>
              </div>
            )}
            {(currentStep == 1 || currentStep == 2) && (
              <Reporting
                nextStep={() => setCurrentStep((prev) => prev + 1)}
                data={apiResult}
                currentStep={currentStep}
              />
            )}
            {currentStep == 3 && (
              <div className="flex flex-col gap-y-3">
                <img
                  alt="Success saved portfolio illustration"
                  src="/redesign/illustration/save_portfolio.svg"
                  className="w-64 mx-auto my-8"
                />
                <PrimaryButton
                  className="mx-auto"
                  full={false}
                  onClick={() => {
                    setCurrentStep(0); /* handleStepChange(currentStep + 1) */
                    setSelectedCompanies([]);
                    setCompanyList([]);
                  }}
                  text={"Create another report"}
                />
              </div>
            )}
          </div>
        )}
      </WrapperCard>
      {!isLoading && currentStep < 3 && (
        <div
          style={{
            width: "calc(100% - 264px + 8px)", //calc 100% - 264px
          }}
          className="fixed bottom-0 py-4 px-8 right-0 w-full  bg-white rounded-t-xl shadow-md flex  gap-x-8 justify-end"
        >
          {currentStep != 0 && (
            <SecondaryButton
              //disabled={watch("file") ? false : true}
              onClick={() => handleStepChange(currentStep - 1)}
              text={"Previous step"}
            />
          )}
          {currentStep != 1 && (
            <PrimaryButton
              disabled={currentStep == 0 && selectedCompanies.length < 2}
              onClick={() => handleStepChange(currentStep + 1)}
              text={currentStep >= 1 ? "Download report" : "Next"}
            />
          )}
          {currentStep == 1 && apiResult && (
            <PrimaryButton
              disabled={currentStep == 0 && selectedCompanies.length < 2}
              onClick={() => handleStepChange(currentStep + 1)}
              text={currentStep >= 1 ? "Download report" : "Next"}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ReportingPage;
