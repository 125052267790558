import React from "react";
import { useDataContext } from "../../context/PortfolioContext";
import { classNames, formatNumber } from "../../services/utilities";
import TabOverViewInformation from "./TabOverViewInformation";

const PortfolioCardOverview = ({
  isEmpty,
  title,
  value,
  percentage,
  isPrimary,
}) => {
  const { triggerSearchDropdown, removeCompany } = useDataContext();

  if (isEmpty) {
    return (
      <div className="rounded-2xl bg-gray-50 p-5 flex flex-col">
        <button
          type="button"
          className="relative block w-full rounded-lg border-[1px] border-dashed border-gray-200 p-4 my-auto mx-auto text-center hover:border-gray-400 focus:outline-none focus:ring-0 "
          style={{
            height: "108px",
          }}
          onClick={triggerSearchDropdown}
        >
          <div className="flex w-fit items-center justify-center mx-auto">
            <img
              className="w-6 h-6 rounded-lg"
              src="/redesign/portfolio_impact/plus_circle.svg"
            />
            <p className="ml-2 flex-1 text-sm font-bold leading-tight text-gray-600 text-start">
              Select portfolio to compare
            </p>
          </div>
        </button>
      </div>
    );
  }

  return (
    <div className="rounded-2xl bg-gray-50 p-2 md:p-5  relative grid grid-cols-1 md:grid-cols-2">
      <img
        onClick={() => removeCompany(title)}
        src="/redesign/portfolio_impact/cancel.svg"
        className="absolute right-1 top-1 cursor-pointer"
      />
      <div className="flex flex-col col-span-1">
        <div className="inline-flex space-x-2 items-center justify-start">
          <img
            className="w-4 h-4"
            src="/redesign/portfolio_impact/portfolio.svg"
          />
          <p className="text-sm font-bold leading-tight text-gray-600">
            {title}
          </p>
        </div>
        <div
          className={`w-10 h-1.5 ${
            isPrimary
              ? "bg-my-tailwind-light-blue-500"
              : "bg-my-tailwind-purple-500"
          } rounded-full mt-2`}
        />
      </div>
      <div className="flex flex-col col-span-1">
        <dd className="flex flex-col justify-start">
          <p className="text-sm leading-tight text-gray-400">
            Companies covered:
          </p>
          <p className="text-sm leading-tight text-gray-500 font-semibold">
            {percentage?.toFixed(0)}%
          </p>
        </dd>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 col-span-1 md:col-span-2 w-full mt-4">
        <div className="">
          <p className="text-sm leading-tight text-gray-500 mb-1">
            Impact on customers
          </p>
          <p
            className={classNames(
              value[0] >= 0 ? "text-my-tailwind-green-500" : "text-red-500",
              "text-3xl font-semibold text-gray-900"
            )}
          >
            ${formatNumber(value[0])}
          </p>
        </div>
        <div className="">
          <p className="text-sm leading-tight text-gray-500 mb-1">
            Impact on employees
          </p>
          <p
            className={classNames(
              value[1] >= 0 ? "text-my-tailwind-green-500" : "text-red-500",
              "text-3xl font-semibold text-gray-900"
            )}
          >
            ${formatNumber(value[1])}
          </p>
        </div>
        <div className="">
          <p className="text-sm leading-tight text-gray-500 mb-1">
            Impact on environment
          </p>
          <p
            className={classNames(
              value[2] >= 0 ? "text-my-tailwind-green-500" : "text-red-500",
              "text-3xl font-semibold text-gray-900"
            )}
          >
            ${formatNumber(value[2])}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PortfolioCardOverview;
