import React from "react";
import { Link } from "react-router-dom";
import { AllRoutes } from "../../pages/Routes";
import WrapperCard from "../ui/redesign/WrapperCard";

const PremiumFeature = ({ noneBorder }) => {
  return (
    <WrapperCard noneBorder={noneBorder}>
      <div className="mx-auto w-fit h-fit my-auto rounded-2xl min-h-[100vh]">
        <div className="flex flex-col py-8 px-20 justify-center items-center mx-auto">
          <img className="my-8" src="/redesign/general/premium.svg" />
          <p className="text-3xl font-bold leading-9 text-gray-600">
            You don't currently have access to this feature.
          </p>
          <p className="text-base leading-normal text-center my-4 text-gray-600">
            This feature is only available to users with a subscription to the
            <b> RIFT</b> Platform.
            <br />
            <br />
          </p>
          <Link
            to={AllRoutes.contact_us}
            className="inline-flex space-x-1 items-center justify-center w-64 px-6 py-3 bg-gray-100 shadow border rounded-md border-gray-500"
          >
            <p className="text-base font-medium leading-normal text-gray-600">
              Contact Us
            </p>
          </Link>
        </div>
      </div>
    </WrapperCard>
  );
};

export default PremiumFeature;
