import React from "react";
import { useDataContext } from "../../../context/PortfolioContext";
import {
  classNames,
  formatNumber,
  isSimpleCompany,
} from "../../../services/utilities";
import SemiCircleChartMemo from "../charts/SemiCircleChart";

export const PortfolioCardType = {
  simple: "simple",
  eu: "eu",
  sdg: "sdg",
};

const PortfolioCard = ({
  isEmpty,
  id,
  title,
  value,
  percentage,
  isPrimary,
  closeable = true,
  type = PortfolioCardType.simple,
  isForTour,
}) => {
  const { triggerSearchDropdown, removeCompany } = useDataContext();

  if (isEmpty) {
    return (
      <div className="rounded-2xl bg-gray-50 p-5 flex flex-col">
        <button
          type="button"
          className="relative block w-full rounded-lg border-[1px] border-dashed border-gray-200 p-4 my-auto mx-auto text-center hover:border-gray-400 focus:outline-none focus:ring-0 select"
          style={{
            height: "91px",
          }}
          onClick={triggerSearchDropdown}
        >
          <div className="flex w-fit items-center justify-center mx-auto">
            <img
              className="w-6 h-6 rounded-lg"
              src="/redesign/portfolio_impact/plus_circle.svg"
            />
            <p className="ml-2 flex-1 text-sm font-bold leading-tight text-gray-600 text-start">
              Select {isSimpleCompany() ? "company" : "portfolio"} to compare
            </p>
          </div>
        </button>
      </div>
    );
  }

  const isNegativeChange = value < 0;

  if (type == PortfolioCardType.simple)
    return (
      <div className="rounded-2xl bg-gray-50 p-2 md:p-5  relative grid grid-cols-1 md:grid-cols-2">
        {closeable && (
          <img
            onClick={() => removeCompany(title)}
            src="/redesign/portfolio_impact/cancel.svg"
            className={`absolute right-1 top-1 cursor-pointer ${
              isForTour ? "close" : ""
            }`}
          />
        )}
        <div className="flex  flex-col col-span-1">
          <div className="inline-flex  space-x-2 items-center justify-start">
            <img
              className="w-4 h-4"
              src="/redesign/portfolio_impact/portfolio.svg"
            />
            <p className="text-sm font-bold leading-tight text-gray-600">
              {title}
            </p>
          </div>
          <div
            className={`w-10 h-1.5 ${
              isPrimary
                ? "bg-my-tailwind-light-blue-500"
                : "bg-my-tailwind-purple-500"
            } rounded-full mt-2`}
          />
          <p className="text-sm leading-tight text-gray-400 mt-2 md:mt-6">
            {isSimpleCompany() ? "Transparency score:" : "Companies covered:"}
          </p>
          <p className="text-sm leading-tight text-gray-500 font-semibold">
            {percentage?.toFixed(0)}%
          </p>
        </div>
        <div className="flex flex-col col-span-1">
          <dd className="flex items-center">
            <p
              className={classNames(
                !isNegativeChange
                  ? "text-my-tailwind-green-500"
                  : "text-red-500",
                "text-3xl font-semibold mt-3 md:mt-0"
              )}
            >
              ${formatNumber(value)}
            </p>
          </dd>
          <div className="mt-3 md:mt-5">
            <p className="text-sm leading-tight text-left text-gray-400">
              Real Impact in Financial Terms™
            </p>
            <p className="text-sm leading-tight text-left text-gray-400">
              (RIFT){" "}
            </p>
          </div>
        </div>
      </div>
    );

  if (type == PortfolioCardType.eu)
    return (
      <div className="rounded-2xl bg-gray-50 p-5  relative grid grid-cols-2 items-center">
        {closeable && (
          <img
            onClick={() => removeCompany(title)}
            src="/redesign/portfolio_impact/cancel.svg"
            className="absolute right-1 top-1 cursor-pointer"
          />
        )}
        <div className="flex flex-col col-span-1">
          <div className="inline-flex space-x-2 items-center justify-start">
            <img
              className="w-4 h-4"
              src="/redesign/portfolio_impact/portfolio.svg"
            />
            <p className="text-sm font-bold leading-tight text-gray-600">
              {title}
            </p>
          </div>
          <div
            className={`w-10 h-1.5 ${
              isPrimary
                ? "bg-my-tailwind-light-blue-500"
                : "bg-my-tailwind-purple-500"
            } rounded-full mt-2`}
          />
        </div>
        <div className="flex flex-col col-span-1">
          <div>
            <SemiCircleChartMemo key={title} progress={value} />
            <p className="text-2xl font-extrabold leading-7 text-center -mt-14 text-my-tailwind-green-500">
              {value && (value * 100)?.toFixed(2)}%
            </p>
          </div>
        </div>
      </div>
    );

  if (type == PortfolioCardType.sdg)
    return (
      <div className="rounded-2xl bg-gray-50 p-5  relative grid grid-cols-2 items-center">
        {closeable && (
          <img
            onClick={() => removeCompany(title)}
            src="/redesign/portfolio_impact/cancel.svg"
            className="absolute right-1 top-1 cursor-pointer"
          />
        )}
        <div className="flex flex-col col-span-1">
          <div className="inline-flex space-x-2 items-center justify-start">
            <img
              className="w-4 h-4"
              src="/redesign/portfolio_impact/portfolio.svg"
            />
            <p className="text-sm font-bold leading-tight text-gray-600">
              {title}
            </p>
          </div>
          <div
            className={`w-10 h-1.5 ${
              isPrimary
                ? "bg-my-tailwind-light-blue-500"
                : "bg-my-tailwind-purple-500"
            } rounded-full mt-2`}
          />
        </div>
        <div className="flex flex-col col-span-1">
          <p className="text-base leading-tight text-left mb-2 text-gray-400">
            Total SDG Alignment™
          </p>
          <dd className="flex items-center">
            <p
              className={classNames(
                !isNegativeChange
                  ? "text-my-tailwind-green-500"
                  : "text-red-500",
                "text-3xl font-semibold text-gray-900"
              )}
            >
              ${formatNumber(value)}
            </p>
          </dd>
        </div>
      </div>
    );

  return <></>;
};

export default PortfolioCard;
