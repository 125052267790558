import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";

const CustomPortfolioContext = createContext();
const DataActionsContext = createContext();

function getData() {
  const rowData = [
    {
      name: "Adidas AG",
      portfolio: null,
      securityId: "DE000A1EWWW0",
      status: "ok",
    },
    {
      name: "Adobe Inc.",
      portfolio: null,
      securityId: "US00724F1012",
      status: "ok",
    },
    {
      name: "Agilent Technologies, Inc.",
      portfolio: null,
      securityId: "US00846U1016",
      status: "error",
    },

    {
      name: "Stryker Corporation",
      portfolio: "S&P 500",
      securityId: "US00846U1017",
      status: "ok",
    },
    {
      name: "Sysmex Corporation",
      portfolio: "S&P 500",
      securityId: "US00846U1018",
      status: "ok",
    },
    {
      name: "USA",
      portfolio: "S&P 500",
      securityId: "US00846U1020",
      status: "ok",
    },
    {
      name: "USA",
      portfolio: "S&P 500",
      securityId: "US00846U3018",
      status: "error",
    },

    {
      name: "USA",
      portfolio: "Electrical Utilities Without Greenhouse Gas Emissions",
      securityId: "US00846U4238",
      status: "ok",
    },
    {
      name: "USA",
      portfolio: "Electrical Utilities Without Greenhouse Gas Emissions",
      securityId: "US00846U14214",
      status: "ok",
    },
  ];

  rowData.forEach(function (item, i) {
    item.position = ((i + 13) * 17 * 33) % 1000;
    //item.val2 = ((i + 23) * 17 * 33) % 1000;
  });
  return rowData;
}

export const CustomPortfolioProvider = ({ children }) => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [totalPosition, setTotalPosition] = useState(0);
  const [trigger, setTrigger] = useState();

  const handlePortfolioData = (newData) => {
    setPortfolioData(newData);
  };

  const addRow = (row) => {
    setPortfolioData([row, ...portfolioData]);
  };

  const updateRow = (oldId, update) => {
    const updatedData = portfolioData.map((item) =>
      item.securityId === oldId ? { ...item, ...update } : item
    );
    setPortfolioData(updatedData);
  };

  const deleteRow = (securityId, portfolio) => {
    if (securityId == null) {
      const filteredData = portfolioData.filter(
        (item) => item.portfolio !== portfolio
      );
      setPortfolioData(filteredData);
    } else {
      const filteredData = portfolioData.filter(
        (item) => item.securityId !== securityId
      );
      setPortfolioData(filteredData);
    }
  };

  // Calculate the total position once the rowData is set or updated
  useEffect(() => {
    const total = portfolioData.reduce(
      (sum, { position }) => sum + position,
      0
    );
    setTotalPosition(total);
  }, [portfolioData]);

  // Update rowData to include calculated weight percentages
  useEffect(() => {
    handleUpdateWeight();
  }, [totalPosition]);
  const handleUpdateWeight = useCallback(
    (params) => {
      const updatedRowData = portfolioData.map((item) => ({
        ...item,
        weight: (item.position / totalPosition) * 100, // Weight as a percentage of the total position
        status:
          item.position != 0 && item.status == "pending" ? "ok" : item.status,
      }));
      setPortfolioData(updatedRowData);
    },
    [portfolioData, totalPosition]
  );

  // Placeholder for any other actions you might need

  const value = {
    data: portfolioData,
    setPortfolioData,
    addRow,
    updateRow,
    deleteRow,
    handleUpdateWeight,
    handlePortfolioData,
    // add other actions here
  };

  return (
    <CustomPortfolioContext.Provider value={value}>
      <DataActionsContext.Provider value={{ setTrigger, trigger }}>
        {children}
      </DataActionsContext.Provider>
    </CustomPortfolioContext.Provider>
  );
};

export const useCustomPortfolio = () => useContext(CustomPortfolioContext);
export const useDataActions = () => useContext(DataActionsContext);
