import React, { useState, useEffect } from "react";
import FeatureSoon from "./FeatureSoon";
import WrapperCard from "../ui/redesign/WrapperCard";
import PrimaryButton from "../ui/redesign/PrimaryButton";
import { useDataContext } from "../../context/PortfolioContext";
import YearSelector from "./portfolio_impact/YearSelector";

const NoDataForYear = () => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    triggerSearchDropdown,
    setSelectedCompanies,
    setResponse,
    //setCompanyList
  } = useDataContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500); // Delay in milliseconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <div
      className={`transition-opacity duration-500 ${
        isVisible ? "opacity-1" : "opacity-0"
      }`}
    >
      <WrapperCard>
        <div className="mx-auto mt-4">
          <p className="text-gray-500 font-semibold text-center text-xl">
            Looks like there is no data for this year yet!
          </p>
          <p className="text-gray-500 font-normal text-center text-sm mt-4">
            Please select another year to view the data.
          </p>
          <div className="mb-8 mx-auto w-fit">
            <YearSelector />
          </div>
          <p className="text-gray-500 font-normal text-center text-sm mt-4">
            Or you can remove the current selected ones, and start the searching
            again from the beginning.
          </p>
          <PrimaryButton
            onClick={() => {
              setResponse(null);
              setSelectedCompanies([]);
              //setCompanies([]);
              triggerSearchDropdown();
            }}
            className={"mx-auto mt-4"}
            text={"Reset the search"}
          />
          <FeatureSoon />
        </div>
      </WrapperCard>
    </div>
  );
};

export default NoDataForYear;
