import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { set } from "react-hook-form";

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export const useOutsideClick = (ref, func) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  // Initialize GA if not on localhost
  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(process.env.REACT_APP_GA, {
        debug: process.env.REACT_APP_GA_DEBUG == "true" ? true : false,
      });
    }
    setInitialized(true);
  }, []);

  // send pageview event
  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export const useCenteredTree = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width } = containerElem.getBoundingClientRect();

      setTranslate({ x: width / 2, y: 20 });
    }
  }, []);
  return [translate, containerRef];
};

export function useOuterClick(callback) {
  const callbackRef = useRef(); // initialize mutable ref, which stores callback
  const innerRef = useRef(); // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      )
        callbackRef.current(e);
    }
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}

export const useLoadingAndError = (fetchData) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const toggleLoading = () => {
    setError(null);
    setIsLoading((prev) => !prev);
  };
  const toggleError = (error) => {
    setIsLoading(false);
    setError(error);
  };

  const handleFetchData = async () => {
    console.log("KRIS MEGHJIVVA");
    try {
      setIsLoading(true);
      setError(null);
      const data = await fetchData();
      setData(data);
    } catch (error) {
      toggleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    toggleLoading,
    toggleError,
    data,
    handleFetchData,
  };
};

export const useBeforeRouteChange = (action, requirementCheck) => {
  const history = useHistory();
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // Listen for changes to the current location.
    const unlisten = history.listen((newLocation, actionType) => {
      console.log(
        `Navigating from ${location.pathname} to ${newLocation.pathname}`
      );

      // Check if the action should be performed based on the requirementCheck function
      if (requirementCheck(location.pathname, newLocation.pathname)) {
        // Perform your action here
        action();
      }
    });

    // Cleanup the listener on component unmount
    return unlisten;
  }, [history, location, requirementCheck, action]);
};

export const useHubspotScript = (src) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = src;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src]);
};

export const trackPageView = (url) => {
  if (window._hsq) {
    window._hsq.push(["setPath", url]);
    window._hsq.push(["trackPageView"]);
  }
};

export const trackCustomEvent = (eventName, eventProperties = {}) => {
  if (window._hsq) {
    window._hsq.push([
      "trackEvent",
      {
        id: eventName,
        value: eventProperties,
      },
    ]);
  }
};
