import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CustomEvents from "highcharts-custom-events";
//import borderRadius from "highcharts-border-radius";
import HighchartsMore from "highcharts/highcharts-more";
import { useEffect, useState } from "react";
import ChartLegend from "../../ui/redesign/ChartLegend";
import { formatNumber } from "../../../services/utilities";

HighchartsMore(Highcharts);

CustomEvents(Highcharts);
//borderRadius(Highcharts);

function BoxPlotChart({
  id,
  data,
  categories,
  // title,
  // yTitle,
  // data,
  // portfolio1,
  // color,
  // toolTipCustomFormatter,
}) {
  Highcharts.AST.allowedAttributes.push("data-container");

  const options = {
    chart: {
      type: "boxplot",
      height: 300,
    },

    title: {
      text: "",
    },

    legend: {
      enabled: false,
    },

    xAxis: {
      categories: categories,
      // title: {
      //   text: 'Experiment No.'
      // }
    },

    yAxis: {
      title: {
        text: "grams of CO2/km",
      },
      plotLines: [
        {
          value: 932,
          color: "red",
          width: 1,
          label: {
            text: "Calculated mean: 932",
            align: "center",
            style: {
              color: "gray",
            },
          },
        },
      ],
    },

    series: [
      {
        name: "Observations",
        pointWidth: 50,

        data: data,
        tooltip: {
          headerFormat: "<em>Experiment No {point.key}</em><br/>",
        },
      },
      // }, {
      //   name: 'Outliers',
      //   color: Highcharts.getOptions().colors[0],
      //   type: 'scatter',
      //   data: [ // x, y positions where 0 is the first category
      //     [0, 644],
      //     [4, 718],
      //     [4, 951],
      //     [4, 969]
      //   ],
      //   marker: {
      //     fillColor: 'white',
      //     lineWidth: 1,
      //     lineColor: Highcharts.getOptions().colors[0]
      //   },
      //   tooltip: {
      //     pointFormat: 'Observation: {point.y}'
      //   }
      // }
    ],
  };

  return (
    <div className="themeid">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ id: id }}
      />
    </div>
  );
}

export default BoxPlotChart;
