import { Popover, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

export default function ToolTipRe({
  toolTipBase,
  children,
  onTop = false,
  offset,
  width,
  wFull,
  disabled,
  disableRelative,
  noButton,
  toolTipBaseClass
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      class={`group ${disableRelative ? "" : "relative"}   flex ${
        wFull ? "w-full" : "w-fit"
      } mx-auto items-center justify-center ${disabled ? "cursor-none" : ""}`}
    >
      {noButton ? (
        toolTipBase
      ) : (
        <button disabled={disabled} className={toolTipBaseClass}>
          {toolTipBase}
        </button>
      )}
      {!disabled && (
        <span
          style={{
            width: width,
          }}
          class={`absolute ${
            offset != null ? offset : onTop ? "bottom-10" : "top-10"
          } z-[200] scale-0 duration-200 ease-in-out p-4 bg-white shadow rounded-lg group-hover:scale-100`}
        >
          {children}
        </span>
      )}
    </div>
  );
}
