export const promethosMock = (portfolios) => {
  switch (portfolios) {
    case "Global Portfolio_MSCI ACWI":
      return {
        strategy:
          "The strategy utilizes a risk-controlled framework that seeks to provide long-term capital appreciation and excess returns relative to the MSCI ACWI Index.",
        total:
          "The Global Portfolio generally demonstrates a significantly higher impact compared to the MSCI ACWI. However, in 2020 and 2021, substantial recalls at Cardinal Health reduced the overall impact, bringing it below the levels observed in the benchmark.",
        environmental:
          "We observe a general downward trend in the environmental impact across both portfolios. This trend is driven by a substantial increase in Scope 3 emissions, which outweighs the decrease in Scope 1 and 2 emissions over the same period. In the Global Portfolio, Cardinal Health, Hartford Financial Services Group, and Compagnie de Saint-Gobain SA in particular, report significant and increasing Scope 3 emissions. While initially outperforming the benchmark, the Global Portfolio slightly underperformed since 2019.",
        employment:
          "The Global Portfolio consistently outperforms the MSCI ACWI in terms of its constituents' higher employee impact. This is primarily driven by a relatively higher fair wage impact, indicating that the companies in the Global Portfolio tend to pay higher wages and have fairer payout structures on average compared to the companies within the MSCI ACWI.",
        customer:
          "The customer impact tends to be higher in the Global Portfolio compared to the MSCI ACWI. Much of this is driven by a focus on products with higher customer utility, meaning products that exhibit lower price elasticity of demand. The drop in 2020 and 2021 is attributed to significant FDA Medical Class II recalls by Cardinal Health.",
      };
    case "International Portfolio_MSCI ACWI ex-U.S.":
      return {
        strategy:
          "The strategy utilizes a risk-controlled framework that seeks to provide long-term capital appreciation and excess returns relative to the MSCI ACWI ex-U.S. Index.",
        total:
          "The international portfolio generally generates a higher impact than the MSCI ACWI ex US, with a slight underperformance observed only in 2017.",
        environmental:
          "We observe a general downward trend in the environmental impact across both portfolios. This trend is driven by a substantial increase in Scope 3 emissions and a somewhat smaller increase in Scope 2 emissions, which outweighs the decrease in Scope 1 emissions over the same period. In the international portfolio, Acerinox SA and Lawson, Inc. report significant and increasing Scope 2 emissions, while Fortescue and Rexel SA report significant and increasing Scope 3 emissions. Although the international portfolio initially outperformed the benchmark, it has slightly underperformed since 2019.",
        employment:
          "The international portfolio generally outperformed the MSCI ACWI in terms of its constituents' higher employee impact until 2020, before experiencing a slight drop in 2021, which led to a minor underperformance relative to the benchmark. A key driver of the employee impact is the relatively higher fair wage impact, indicating that the companies in the Global Portfolio tend to pay higher wages and have fairer payout structures on average compared to the companies within the MSCI ACWI. The decline in impact in the international portfolio in 2021 and 2022 is attributed to a slight decrease in workforce diversity and a significant decrease in the equal opportunity metric, reflecting a reduction in the number of women in management positions relative to the number of women across all company functions.",
        customer:
          "The customer impact has shown a strong increase across all years, largely driven by a nearly doubling of the companies' revenues within the international portfolio.",
      };
    default:
      return {
        strategy:
          "The strategy utilizes a risk-controlled framework that seeks to provide long-term capital appreciation and excess returns relative to the MSCI ACWI ex-U.S. Index",
        total:
          "While the portfolio started with better performance than the benchmark in 2015, it became a laggard in 2019, largely due to a rise in Scope 2 emissions. Although there's a general downward trend in emissions across both portfolios, this is attributed to an increase in Scope 3 emissions over the years. This increase is likely due to more comprehensive reporting and may not reflect an actual underlying trend. Additionally, there was a drop in performance against the benchmark in terms of managing plastic and food waste.",
        environmental:
          "While the portfolio started with better performance than the benchmark in 2015, it became a laggard in 2019, largely due to a rise in Scope 2 emissions. Although there's a general downward trend in emissions across both portfolios, this is attributed to an increase in Scope 3 emissions over the years. This increase is likely due to more comprehensive reporting and may not reflect an actual underlying trend. Additionally, there was a drop in performance against the benchmark in terms of managing plastic and food waste.",
        employment:
          "While the portfolio started with better performance than the benchmark in 2015, it became a laggard in 2019, largely due to a rise in Scope 2 emissions. Although there's a general downward trend in emissions across both portfolios, this is attributed to an increase in Scope 3 emissions over the years. This increase is likely due to more comprehensive reporting and may not reflect an actual underlying trend. Additionally, there was a drop in performance against the benchmark in terms of managing plastic and food waste.",
        customer:
          "While the portfolio started with better performance than the benchmark in 2015, it became a laggard in 2019, largely due to a rise in Scope 2 emissions. Although there's a general downward trend in emissions across both portfolios, this is attributed to an increase in Scope 3 emissions over the years. This increase is likely due to more comprehensive reporting and may not reflect an actual underlying trend. Additionally, there was a drop in performance against the benchmark in terms of managing plastic and food waste.",
      };
  }
};
