import React, { useState } from "react";
import WrapperCard from "../../../components/ui/redesign/WrapperCard";
import FilterPanel from "./FilterPanel";
import { twMerge } from "tailwind-merge";

const CreatePortfolioContent = ({
  stepperData,
  handleSubSubStepPrev,
  currentStep,
  control,
  getValues,
  watch,
  setValue,
  index,
  subStepData,
  setSubStepData,
  setIsFlipped,
  isFlipped,
  handleEdit,
}) => {
  const checkMyId = () => {
    switch (index) {
      case 0:
        return "front";
      case 1:
        return "right";
      case 2:
        return "back";
      default:
        return "left";
    }
  };

  const stepContent = stepperData[index]; // Use index to determine which step content to show

  const { subStep, subSubStep } = subStepData[index] || {
    subStep: 0,
    subSubStep: 0,
  };

  const handleSubStepChange = (newSubStep) => {
    setSubStepData({
      ...subStepData,
      [index]: {
        ...subStepData[index],
        subStep: newSubStep,
        subSubStep: 0,
      },
    });
  };

  const handleSubSubStepChange = (newSubSubStep) => {
    setSubStepData({
      ...subStepData,
      [index]: {
        ...subStepData[index],
        subSubStep: newSubSubStep,
      },
    });
  };

  return (
    <div
      className="flex gap-x-10 w-[400px] h-full absolute p-2 bg-my-rift-primary-green-100"
      id={checkMyId()}
    >
      <WrapperCard
        wideBorder={false}
        className={
          "relative p-1 w-full h-full max-h-[615px] overflow-y-auto !rounded-none"
        }
      >
        <div
          className={`${
            stepContent.subSteps > 1 ? "justify-center" : ""
          } flex pb-4 gap-[0.35rem] relative overflow-x-auto items-center`}
        >
          {stepContent?.subSteps &&
            stepContent.subSteps.map((step, idx) => (
              <button
                key={idx}
                className={twMerge(
                  "py-1 px-3 shrink-0 !text-[0.875rem] text-my-tailwind-gray-600 text-sm border-my-rift-primary-green-500 border-[1px] bg-white",
                  idx === subStep && stepContent.subSteps.length > 1
                    ? "border-my-rift-primary-green-600 text-white bg-my-rift-primary-green-600"
                    : "shadow-md",
                  idx === 0 ? "rounded-l-md" : "",
                  idx === stepContent.subSteps.length - 1 ? "rounded-r-md" : ""
                )}
                onClick={() => handleSubStepChange(idx)}
              >
                {step.title}
              </button>
            ))}
        </div>
        <div className="flex pt-0 pb-2 w-full rounded-lg gap-1">
          {subStep !== null &&
            stepContent?.subSteps[subStep]?.subSteps.length > 1 &&
            stepContent?.subSteps[subStep]?.subSteps?.map((step, idx) => (
              <button
                key={idx}
                className={twMerge(
                  "py-1 px-2 !text-[0.875rem] w-full bg-white text-my-tailwind-gray-600 border-[1px] border-my-rift-primary-green-500 shadow-md",
                  idx === subSubStep &&
                    "border-b-2 text-white bg-my-rift-primary-green-600",
                  idx === 0 && "rounded-l-md",
                  idx === stepContent.subSteps[subStep].subSteps.length - 1 &&
                    "rounded-r-md"
                )}
                onClick={() => handleSubSubStepChange(idx)}
              >
                {step.title}
              </button>
            ))}
        </div>
        <FilterPanel
          form={stepContent?.subSteps[subStep]?.subSteps[subSubStep]?.form}
          handleBack={handleSubSubStepPrev}
          step={currentStep}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          setIsFlipped={setIsFlipped}
          isFlipped={isFlipped}
          handleEdit={handleEdit}
        />
      </WrapperCard>
    </div>
  );
};

export default CreatePortfolioContent;
