import React from "react";

const IWAI = ({ title, download }) => {
  function handleDownload() {
    const url = `${process.env.PUBLIC_URL}/docs/RGSImpact1.pdf`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "RGSImpact1.pdf");
    document.body.appendChild(link);
    link.click();
  }

  return (
    <a
      target="_blank"
      href="/docs/RGSImpact1.pdf"
      download="RGSImpact.pdf"
      className="w-fit"
    >
      <div className="inline-flex space-x-4 items-center justify-start p-4 bg-white shadow rounded-2xl w-fit">
        <img
          className="w-6 h-full"
          src="/redesign/portfolio_impact/iwai_doc.svg"
        />
        <p className="text-sm leading-tight text-gray-600">{title}</p>
        <img
          className="flex-1 h-6 rounded-lg cursor-pointer"
          src="/redesign/portfolio_impact/download.svg"
        />
      </div>
    </a>
  );
};

export default IWAI;
