import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CustomEvents from "highcharts-custom-events";
//import borderRadius from "highcharts-border-radius";
import { useEffect, useState } from "react";
import ChartLegend from "../../ui/redesign/ChartLegend";
import { formatNumber, imageNameTrim } from "../../../services/utilities";
import ToolTipRe from "../../ui/redesign/ToolTip";
import ScopeTooltipBody from "../portfolio_impact/ScopeTooltipBody";
import { categoryForDescription } from "../../../services/matchers";

CustomEvents(Highcharts);
//borderRadius(Highcharts);

const customColumnRadius = (Highcharts) => {
  (function (H) {
    H.wrap(H.seriesTypes.column.prototype, "translate", function (proceed) {
      const options = this.options;
      const topMargin = options.topMargin || 0;
      const bottomMargin = options.bottomMargin || 0;

      proceed.call(this);

      H.each(this.points, function (point) {
        if (options.customRadius) {
          const w = point.shapeArgs.width;
          const h = point.shapeArgs.height;
          const x = point.shapeArgs.x;
          const y = point.shapeArgs.y;

          let radiusTopLeft,
            radiusTopRight,
            radiusBottomRight,
            radiusBottomLeft;

          if (point.y > 0) {
            radiusTopLeft = H.relativeLength(options.customRadius, w);
            radiusTopRight = H.relativeLength(options.customRadius, w);
            radiusBottomLeft = 2;
            radiusBottomRight = 2;
          } else {
            radiusTopLeft = 2;
            radiusTopRight = 2;
            radiusBottomRight = H.relativeLength(options.customRadius, w);
            radiusBottomLeft = H.relativeLength(options.customRadius, w);
          }

          const maxR = Math.min(w, h) / 2;

          radiusTopLeft = radiusTopLeft > maxR ? maxR : radiusTopLeft;
          radiusTopRight = radiusTopRight > maxR ? maxR : radiusTopRight;
          radiusBottomRight =
            radiusBottomRight > maxR ? maxR : radiusBottomRight;
          radiusBottomLeft = radiusBottomLeft > maxR ? maxR : radiusBottomLeft;

          point.dlBox = point.shapeArgs;

          point.shapeType = "path";
          point.shapeArgs = {
            d: [
              "M",
              x + radiusTopLeft,
              y + topMargin,
              "L",
              x + w - radiusTopRight,
              y + topMargin,
              "C",
              x + w - radiusTopRight / 2,
              y,
              x + w,
              y + radiusTopRight / 2,
              x + w,
              y + radiusTopRight,
              "L",
              x + w,
              y + h - radiusBottomRight,
              "C",
              x + w,
              y + h - radiusBottomRight / 2,
              x + w - radiusBottomRight / 2,
              y + h,
              x + w - radiusBottomRight,
              y + h + bottomMargin,
              "L",
              x + radiusBottomLeft,
              y + h + bottomMargin,
              "C",
              x + radiusBottomLeft / 2,
              y + h,
              x,
              y + h - radiusBottomLeft / 2,
              x,
              y + h - radiusBottomLeft,
              "L",
              x,
              y + radiusTopLeft,
              "C",
              x,
              y + radiusTopLeft / 2,
              x + radiusTopLeft / 2,
              y,
              x + radiusTopLeft,
              y,
              "Z",
            ],
          };
        }
      });
    });
  })(Highcharts);
};

function DrillDownChart({
  id,
  title,
  yTitle,
  data1,
  data2,
  allData,
  portfolio1,
  portfolio2,
  categories,
}) {
  const allDrillDownLevels = allData;

  const [drilldownLevels, setdrilldownLevels] = useState([]);

  const tempAll = {
    data: [
      {
        name: portfolio1,
        data: data1,
        //color: "#0EA5E9",
      },
      {
        name: portfolio2,
        data: data2,
        //color: "#8B5CF6",
      },
    ],
    categories: categories,
    tooltip: [
      {
        title: "Tooltip title1",
        description: "Long content, explanation",
      },
      {
        title: "Tooltip title2",
        description: "Long content, explanation",
      },
      {
        title: "Tooltip title3",
        description: "Long content, explanation",
      },
      {
        title: "Tooltip title4",
        description: "Long content, explanation",
      },
    ],
    drilldown: [],
    /*   drilldown: [
      {
        data: [
          {
            name: "ESG portfolio",
            data: [4000, 30000, 40000],
            color: "#0EA5E9",
          },
          {
            name: "Benchmark portfolio",
            data: [3000, 12512, -8000],
            color: "#8B5CF6",
          },
        ],
        categories: ["Scope 1", "Scope 2", "Scope 3"],
        tooltip: [
          {
            title: "Tooltip title1",
            description: "Long content, explanation",
          },
          {
            title: "Tooltip title2",
            description: "Long content, explanation",
          },
          {
            title: "Tooltip title3",
            description: "Long content, explanation",
          },
        ],
        drilldown: [
          null,
          {
            data: [
              {
                name: "ESG portfolio",
                data: [9000, 25000, 9000, 3000, -1000, 2500],
                color: "#0EA5E9",
              },
              {
                name: "Benchmark portfolio",
                data: [-3000, 25410, 12000, 3122, -4100, 3111],
                color: "#8B5CF6",
              },
            ],
            categories: [
              "Scope 1",
              "Scope 2",
              "Scope 3",
              "Scope4",
              "Scope5",
              "Scope6",
            ],
            tooltip: [
              {
                title: "Tooltip title1",
                description: "Long content, explanation",
              },
              {
                title: "Tooltip title2",
                description: "Long content, explanation",
              },
              {
                title: "Tooltip title3",
                description: "Long content, explanation",
              },
              {
                title: "Tooltip title4",
                description: "Long content, explanation",
              },
              {
                title: "Tooltip title5",
                description: "Long content, explanation",
              },
              {
                title: "Tooltip title6",
                description: "Long content, explanation",
              },
            ],
            drilldown: [null, null, null, null, null, null],
          },
          null,
        ],
      },
      null,
      null,
    ], */
  };

  // Inside your useEffect or wherever you define/update your chart options
  useEffect(() => {
    const newOptions = {
      ...options(), // Assuming options() is your current way of generating options
      tooltip: {
        shared: true,
        useHTML: true,
        borderWidth: 0,
        padding: 8,
        shadow: false,
        backgroundColor: "transparent",
        formatter: function () {
          // console.log(this.points);
          const primary = this.points.find((p) => p.series.name === portfolio1);
          const secondary = this.points.find(
            (p) => p.series.name === portfolio2
          );
          //const secondary = this.points[1];

          return this.points.reduce(function (s, point) {
            return `     <div class="bg-white p-4 flex flex-col shadow-md rounded-lg">
          <div class="w-fit flex gap-x-2 mb-4 items-center">
            <img src="/redesign/general/${imageNameTrim(
              s.x
            )}.svg" class="w-5 h-5" />
            <div class="text-xs font-bold leading-none text-center pr-4 text-gray-600">
              ${s.x}
            </div>
          </div>
          <div class="flex items-center gap-x-1 w-fit">
            <div style="background-color: ${
              primary.color
            }" class="w-5 h-1.5  rounded-full" ></div>
            <div class="text-xs leading-none text-gray-600">
              ${portfolio1}:
            </div>
            <div class="text-gray-600 text-xs font-semibold">$ ${formatNumber(
              primary.y
            )}</div>
          </div>
          <div class="flex items-center gap-x-1 w-fit">
          <div style="background-color: ${
            secondary.color
          }" class="w-5 h-1.5  rounded-full" ></div>
          <div class="text-xs leading-none text-gray-600">
            ${portfolio2}:
          </div>
          <div class="text-gray-600 text-xs font-semibold">$ ${formatNumber(
            secondary.y
          )}</div>
        </div>
        </div>`;
          });
        },
      },
    };
    setChartOptions(newOptions);
  }, [portfolio1, portfolio2, data1, data2, categories]); // Add all dependencies here

  Highcharts.AST.allowedAttributes.push("data-container");

  const options = () => {
    return {
      chart: {
        styledMode: false,
        type: "column",
        marginLeft: 72,
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: "",
        },
        plotLines: [
          {
            color: "#FF0000",
            width: 1,
            value: 0,
            zIndex: 2,
            dashStyle: "ShortDash",
          },
        ],
        labels: {
          style: {
            color: "#D1D5DB;",
          },
          formatter: function () {
            // Use a custom formatter function to style the label for the 0 level differently
            if (this.value === 0) {
              return (
                '<span style="font-size: 14px; line-height: 20px;color: #9CA3AF;">' +
                this.value +
                "</span>"
              );
            } else {
              return (
                '<span style="font-size: 14px; line-height: 20px;color: #D1D5DB;">' +
                formatNumber(this.value) +
                "</span>"
              );
            }
          },
        },
        gridLineColor: "#E5E7EB",
      },
      xAxis: {
        gridLineWidth: 0,
        gridLineColor: "#00000000",
        lineColor: "#E5E7EB",
        text: "",
        categories: allData.categories,
        labels: {
          enabled: false,
        },
      },
      plotOptions: {
        column: {
          pointWidth: 26,
          // borderRadius: 5, // Set a default value for the border radius
          customRadius: 31,
          pointPadding: 0.005,
          grouping: true,
          borderWidth: 0,
          point: {
            events: {
              mouseOver: function (e) {
                var targetX = e.target.x,
                  from = targetX - 0.5,
                  to = targetX + 0.5;

                this.series.chart.xAxis[0].addPlotBand({
                  id: "plot-band-1",
                  from: from,
                  to: to,
                  zIndex: 1,
                  color: {
                    linearGradient: {
                      x1: 0,
                      x2: 1,
                      y1: 0,
                      y2: 1,
                    },
                    stops: [
                      [0, "rgb(235, 242, 255)"],
                      [1, "rgb(235, 242, 255)"],
                    ],
                  },
                });
              },
              mouseOut: function (e) {
                this.series.chart.xAxis[0].removePlotBand("plot-band-1");
              },
            },
          },
        },
      },
      legend: { enabled: false },
      tooltip: {
        shared: true,
        useHTML: true,
        borderWidth: 0,
        padding: 8,
        shadow: false,
        backgroundColor: "transparent",

        formatter: function () {
          // console.log(this.points);
          const primary = this.points.find((p) => p.series.name === portfolio1);
          const secondary = this.points.find(
            (p) => p.series.name === portfolio2
          );
          //const secondary = this.points[1];

          return this.points.reduce(function (s, point) {
            return `     <div class="bg-white p-4 flex flex-col shadow-md rounded-lg">
          <div class="w-fit flex gap-x-2 mb-4 items-center">
            <img src="/redesign/general/${imageNameTrim(
              s.x
            )}.svg" class="w-5 h-5" />
            <div class="text-xs font-bold leading-none text-center pr-4 text-gray-600">
              ${s.x}
            </div>
          </div>
          <div class="flex items-center gap-x-1 w-fit">
            <div style="background-color: ${
              primary.color
            }" class="w-5 h-1.5  rounded-full" ></div>
            <div class="text-xs leading-none text-gray-600">
              ${portfolio1}:
            </div>
            <div class="text-gray-600 text-xs font-semibold">$ ${formatNumber(
              primary.y
            )}</div>
          </div>
          <div class="flex items-center gap-x-1 w-fit">
          <div style="background-color: ${
            secondary.color
          }" class="w-5 h-1.5  rounded-full" ></div>
          <div class="text-xs leading-none text-gray-600">
            ${portfolio2}:
          </div>
          <div class="text-gray-600 text-xs font-semibold">$ ${formatNumber(
            secondary.y
          )}</div>
        </div>
        </div>`;
          });
        },
      },
      series: [
        {
          name: portfolio1,
          data: allData.data[0].data,
          color: "#0EA5E9",
          customxd: portfolio1,
        },
        {
          name: portfolio2,
          data: allData.data[1].data,
          color: "#8B5CF6",
          grouping: "group2",
          customxd: portfolio2,
        },
      ],
    };
  };

  const [chartOptions, setChartOptions] = useState(options);

  const [drilldownLevelNames, setdrilldownLevelNames] = useState(["Pillars"]);

  function addIndex(index, name) {
    setdrilldownLevels((prevIndexes) => [...prevIndexes, index]);
    setdrilldownLevelNames((prevIndexes) => [...prevIndexes, name]);
  }

  function removeLastIndex(number) {
    const idx = drilldownLevels[drilldownLevels.length - 1 - number];
    let tempDrills2 = JSON.parse(JSON.stringify(drilldownLevels));

    let tempDrills = tempDrills2.slice(0, -1 * number);
    setdrilldownLevels((prevIndexes) => prevIndexes.slice(0, -1 * number));
    if (drilldownLevelNames.length - number > 0) {
      setdrilldownLevelNames((prevIndexes) =>
        prevIndexes.slice(0, -1 * number)
      );
    }
    setChartOptions((prev) => {
      const temp = JSON.parse(JSON.stringify(prev));
      const deeperLevel = getNestedChartOptions(allDrillDownLevels, tempDrills);

      if (deeperLevel?.categories.length > 7) {
        temp.plotOptions.column.pointWidth = 8;
      } else {
        temp.plotOptions.column.pointWidth = 26;
      }

      temp.series = deeperLevel?.data;
      temp.xAxis.categories = deeperLevel?.categories;
      return temp;
    });
  }

  const handleDrillDown = (idx, name) => {
    if (idx == -1) {
      const last = drilldownLevels[drilldownLevels.length - 2];
      removeLastIndex();
      return;
    }
    addIndex(idx, name);
    setChartOptions((prev) => {
      const temp = JSON.parse(JSON.stringify(prev));
      const deeperLevel = getNestedChartOptions(allDrillDownLevels, [
        ...drilldownLevels,
        idx,
      ]);
      if (deeperLevel?.categories.length > 7) {
        temp.plotOptions.column.pointWidth = 8;
      } else {
        temp.plotOptions.column.pointWidth = 26;
      }

      temp.series = deeperLevel.data;
      temp.xAxis.categories = deeperLevel.categories;
      return temp;
    });
  };

  function getNestedChartOptions(obj, indices) {
    if (indices.length === 0) {
      return obj;
    }

    const index = indices[0];
    const restIndices = indices.slice(1);

    if (obj && obj.drilldown && obj.drilldown[index]) {
      return getNestedChartOptions(obj.drilldown[index], restIndices);
    }

    return null;
  }

  return (
    <div>
      <div className="my-4 w-full flex items-center justify-between">
        {drilldownLevels.length > 0 && (
          <button
            onClick={() => removeLastIndex(1)}
            className="inline-flex space-x-2 items-center justify-center py-2 px-3  bg-gray-100 shadow-my-sm border rounded border-gray-500"
          >
            <img
              className="w-4 h-full rounded-full"
              src="/redesign/general/backbutton.svg"
            />
            <p className="text-xs font-medium leading-none text-gray-600">
              Back
            </p>
          </button>
        )}
        <div className="w-fit inline-block md:flex gap-y-4 gap-x-2 items-center">
          {drilldownLevels.length > 0 &&
            drilldownLevelNames.map((item, idx) => {
              const isLast = !(idx < drilldownLevelNames.length - 1);
              return (
                <div className="w-fit mb-2 md:mb-0 flex items-center">
                  <p
                    onClick={() => {
                      if (isLast) return null;
                      removeLastIndex(drilldownLevelNames.length - idx - 1);
                    }}
                    className={`text-xs font-bold leading-none  ${
                      isLast
                        ? "text-my-tailwind-gray-600"
                        : "text-my-rift-primary-green-600 underline underline-offset-2 cursor-pointer"
                    } `}
                  >
                    {item}
                  </p>
                  {idx < drilldownLevelNames.length - 1 && (
                    <img
                      className="ml-2"
                      src="/redesign/general/Chevronright.svg"
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <HighchartsReact
        key={`${portfolio1}-${portfolio2}`}
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ id: id }}
      />
      <div className="w-full flex justify-around my-4 md:pl-[72px] details">
        {chartOptions.xAxis.categories.length < 7 &&
          chartOptions.xAxis.categories.map((item, idx) => {
            const columnDrillDown = getNestedChartOptions(allDrillDownLevels, [
              ...drilldownLevels,
              idx,
            ]);

            return (
              <ToolTipRe
                width={"300px"}
                offset={"bottom-20 left-16"}
                onTop={true}
                wFull={true}
                toolTipBase={
                  <div
                    onClick={() => {
                      if (columnDrillDown == null) return null;
                      handleDrillDown(idx, item);
                    }}
                    className={`flex w-full flex-col space-y-1.5 items-center justify-center p-2 bg-white hover:bg-my-tailwind-gray-100 ${
                      columnDrillDown != null
                        ? "border-[1px] border-my-tailwind-gray-400 cursor-pointer"
                        : ""
                    } rounded-lg`}
                  >
                    <div className="items-center justify-center w-6 h-6 p-0.5">
                      <img
                        className=""
                        src={`/redesign/general/${imageNameTrim(item)}.svg`}
                      />
                    </div>

                    <div className="w-full flex gap-x-2 items-center">
                      <span className="text-xs font-medium leading-none text-center text-gray-600 whitespace-normal">
                        {item}
                      </span>
                      {columnDrillDown != null && (
                        <img src="/redesign/general/Chevronright.svg" />
                      )}
                    </div>
                  </div>
                }
              >
                <ScopeTooltipBody
                  title={item}
                  icon={`/redesign/general/${imageNameTrim(item)}.svg`}
                  description={<>{categoryForDescription(item)}</>}
                  info={"You can drill down to get more granular data"}
                  drillDown={columnDrillDown}
                />
              </ToolTipRe>
            );
          })}
      </div>
      <div>
        <ChartLegend
          isVertical={false}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
        />
      </div>
    </div>
  );
}

export default DrillDownChart;
