import React from "react";

const ShortDescriptionInfo = ({ description }) => {
  return (
    <div className="inline-flex w-full gap-x-2 items-start justify-start p-4 bg-gray-50 rounded-lg">
      <img
        className="w-6 h-6 rounded-lg"
        src="/redesign/portfolio_impact/info_outline.svg"
      ></img>
      <p className="text-sm leading-normal text-gray-500">{description} </p>
    </div>
  );
};

export default ShortDescriptionInfo;
