import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { fetchPortfolioOverview } from "../../api/api";
import LoadingErrorWrapper from "../../api/LoadingErrorWrapper";
import YearSelector from "../../components/redesign/portfolio_impact/YearSelector";
import PrimaryButton from "../../components/ui/redesign/PrimaryButton";
import WrapperCard from "../../components/ui/redesign/WrapperCard";
import { useDataContext } from "../../context/PortfolioContext";
import PortfolioCardOverview from "../portfolio_information/PortfolioCardOverview";
import TabOverViewInformation from "../portfolio_information/TabOverViewInformation";
import { AllRoutes } from "../Routes";
import { API_ENUMS } from "../../api/api";
import html2pdf from "html2pdf.js";
import NoComparisonData from "../../components/redesign/NoComparisonData";
import Tabs from "../../components/redesign/portfolio_impact/Tabs";
import { useHistory } from "react-router-dom";
import NoDataForYear from "../../components/redesign/NoDataForYear";
import TabOverviewSelector from "../../components/redesign/portfolio_impact/TabOverviewSelector";

const pdfOptions = {
  margin: 0,
  filename: "document.pdf",
  image: { type: "jpeg", quality: 0.99 },
  html2canvas: { scale: 3 },
  jsPDF: { unit: "in", orientation: "landscape" },
  //width: 1920, // set width to the desired pixel value
};

const PortfolioInformation = () => {
  const {
    year,
    companyList,
    setAllYears,
    setCompanyList,
    setResponse,
    setCurrentType,
    handleClearData,
    response,
    currentType,
    selectedCompanies,
  } = useDataContext();

  const onExport = "PortfolioInformation";

  const generatePDF = () => {
    const content = document.getElementById(onExport);
    html2pdf().set(pdfOptions).from(content).toPdf().save("document.pdf");
  };

  const history = useHistory();
  if (!response || currentType != history.location.pathname)
    return (
      <div className="w-full" id={onExport}>
        <Tabs idx={1} />

        <div className="p-4 md:p-8 flex flex-col gap-y-8 bg-gray-50">
          <TabOverviewSelector
            //onExport={printRef.current}
            onExport={"Portfolio Information".toString().replaceAll(" ", "")}
            title={"Portfolio Information"}
          />
        </div>

        <NoComparisonData />
      </div>
    );
  if (response.currentYear != year) {
    return <NoDataForYear />;
  }

  const portfolio1 = {
    name: companyList[0]?.portfolioName,
    percentage: companyList[0]?.benchmarkCoverage * 100,
    customerValue:
      companyList[0]?.totalValueDrilldownByStakeholder?.customer?.[year],
    employeeValue:
      companyList[0]?.totalValueDrilldownByStakeholder?.employee?.[year],
    environmentValue:
      companyList[0]?.totalValueDrilldownByStakeholder?.environment?.[year],
  };
  const portfolio2 = {
    name: companyList[1]?.portfolioName,
    percentage: companyList[1]?.benchmarkCoverage * 100,
    customerValue:
      companyList[1]?.totalValueDrilldownByStakeholder?.customer?.[year],
    employeeValue:
      companyList[1]?.totalValueDrilldownByStakeholder?.employee?.[year],
    environmentValue:
      companyList[1]?.totalValueDrilldownByStakeholder?.environment?.[year],
  };

  return (
    <div className="w-full" id={onExport}>
      <Tabs idx={1} />

      <div className="w-full flex-col md:flex-row justify-between flex items-center mb-6 gap-y-4 p-4">
        <p className="text-lg font-bold leading-7 text-gray-600 w-full">
          {"Portfolio Information"}
        </p>
        <div className="flex items-center gap-x-2">
          {
            <PrimaryButton
              //onClick={generatePDF} TEMPORARY REMOVAL
              text={"Export data"}
              iconUrl={"/redesign/general/Download_data.svg"}
              className={"cursor-not-allowed"}
            />
          }
          <YearSelector defaultYear={"2020"} />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-6 w-full p-4">
        <div className="col-span-1">
          <WrapperCard>
            <PortfolioCardOverview
              title={portfolio1?.name}
              value={[
                portfolio1?.customerValue,
                portfolio1?.employeeValue,
                portfolio1?.environmentValue,
              ]}
              percentage={portfolio1.percentage}
              isPrimary={true}
              isEmpty={selectedCompanies.length == 0}
            />
          </WrapperCard>
        </div>
        <div className="col-span-1">
          <WrapperCard>
            <PortfolioCardOverview
              title={portfolio2?.name}
              value={[
                portfolio2?.customerValue,
                portfolio2?.employeeValue,
                portfolio2?.environmentValue,
              ]}
              percentage={portfolio2?.percentage}
              isPrimary={false}
              isEmpty={selectedCompanies.length < 2}
            />
          </WrapperCard>
        </div>
      </div>
      {selectedCompanies.length < 2 ? (
        <NoComparisonData />
      ) : (
        <TabOverViewInformation />
      )}
    </div>
  );
};

export default PortfolioInformation;
