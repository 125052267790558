import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { AllRoutes } from "../../pages/Routes";
import PrimaryButton from "../ui/redesign/PrimaryButton";
import ShowMoreChevron from "../ui/redesign/ShowMoreChevron";
import Logo from "./Logo";

export const navigation = [
  {
    name: "Dashboard",
    icon: "/redesign/nav/dashboard.svg",
    activeIcon: "/redesign/nav/dashboard-active.svg",
    href: AllRoutes.dashboard,
  },
  {
    name: "Public Equities - Portfolios",
    icon: "/redesign/nav/portfolio_impact.svg",
    activeIcon: "/redesign/nav/portfolio-impact-active.svg",
    href: AllRoutes.public_equity_portfolio,

    children: [
      {
        name: "Portfolio Impact",
        href: AllRoutes.portfolio_impact,
        icon: "/redesign/nav/portfolio_impact.svg",
        activeIcon: "/redesign/nav/portfolio-impact-active.svg",
        children: [
          {
            name: "Portfolio Overview",
            href: AllRoutes.portfolio_overview,
            icon: "/redesign/nav/portfolio_impact.svg",
            activeIcon: "/redesign/nav/portfolio-impact-active.svg",
          },
          {
            name: "Environmental Impact",
            href: AllRoutes.environmental_impact,
          },
          { name: "Employee Impact", href: AllRoutes.employees_impact },
          { name: "Customer Impact", href: AllRoutes.customer_impact },
          {
            name: "Portfolio Information",
            href: AllRoutes.portfolio_information,
          },
        ],
      },
      {
        name: "EU Taxonomy",
        href: AllRoutes.portfolio_eu_taxonomy,
        icon: "/redesign/nav/eu_taxonomy.svg",
        activeIcon: "/redesign/nav/eu-taxonomy-active.svg",
      },
      {
        name: "SDG Alignment",
        href: AllRoutes.portfolio_sdg_alignment,
        icon: "/redesign/nav/sdg.svg",
        activeIcon: "/redesign/nav/sdg-active.svg",
      },
      {
        name: "PSICS",
        href: AllRoutes.portfolio_psics,
        icon: "/redesign/nav/psics.svg",
        activeIcon: "/redesign/nav/psics-active.svg",
      },
      {
        name: "Raw data",
        href: AllRoutes.portfolio_raw_data,
        icon: "/redesign/nav/raw_data.svg",
        activeIcon: "/redesign/nav/raw-data-active.svg",
      },
    ],
  },
  {
    name: "Public Equities - Companies",
    icon: "/redesign/nav/new-company-impact.svg",
    activeIcon: "/redesign/nav/new-company-impact-active.svg",
    href: AllRoutes.company_impact,

    children: [
      {
        name: "Company Impact",
        href: AllRoutes.company_impact,
        icon: "/redesign/nav/new-company-impact.svg",
        activeIcon: "/redesign/nav/new-company-impact-active.svg",
        children: [
          { name: "Company Overview", href: AllRoutes.company_overview },
          {
            name: "Company Environmental Impact",
            href: AllRoutes.company_environmental_impact,
          },
          {
            name: "Company Employee Impact",
            href: AllRoutes.company_employees_impact,
          },
          {
            name: "Company Customer Impact",
            href: AllRoutes.company_customer_impact,
          },
        ],
      },
      {
        name: "EU Taxonomy",
        href: AllRoutes.company_eu_taxonomy,
        icon: "/redesign/nav/eu_taxonomy.svg",
        activeIcon: "/redesign/nav/eu-taxonomy-active.svg",
      },
      {
        name: "SDG Alignment",
        href: AllRoutes.company_sdg_alignment,
        icon: "/redesign/nav/sdg.svg",
        activeIcon: "/redesign/nav/sdg-active.svg",
      },
      {
        name: "PSICS",
        href: AllRoutes.company_psics,
        icon: "/redesign/nav/psics.svg",
        activeIcon: "/redesign/nav/psics-active.svg",
      },
      {
        name: "Raw data",
        href: AllRoutes.company_raw_data,
        icon: "/redesign/nav/raw_data.svg",
        activeIcon: "/redesign/nav/raw-data-active.svg",
      },
    ],
  },
  {
    name: "Fixed Income",
    icon: "/redesign/nav/new-income.svg",
    activeIcon: "/redesign/nav/new-income-active.svg",
    href: AllRoutes.fixed_income,

    children: [
      {
        name: "ABS climate analytics",
        href: AllRoutes.abs_climate,
        icon: "/redesign/nav/abs.svg",
        activeIcon: "/redesign/nav/abs-active.svg",
      },
      /* TEMPORARY REMOVAL
      {
        icon: "/img/icons/clo_clyma.svg",
        activeIcon: "/img/icons/clo_clyma.svg",
        name: "CLOs climate analytics",
        href: AllRoutes.clo_climate,
      },*/
    ],
  },
  {
    name: "Private Equity",
    icon: "/redesign/nav/new-wallet.svg",
    activeIcon: "/redesign/nav/new-wallet-active.svg",
    href: AllRoutes.private_equity,

    children: [
      {
        name: "Company impact",
        href: AllRoutes.private_equity,
        icon: "/redesign/nav/new-company-impact.svg",
        activeIcon: "/redesign/nav/new-company-impact-active.svg",
        children: [
          {
            name: "Company Overview",
            href: AllRoutes.private_company_overview,
          },
          {
            name: "Company Environmental Impact",
            href: AllRoutes.private_company_environmental_impact,
          },
          {
            name: "Company Employee Impact",
            href: AllRoutes.private_company_employees_impact,
          },
          {
            name: "Company Customer Impact",
            href: AllRoutes.private_company_customer_impact,
          },
        ],
      },
    ],
  },
  {
    name: "Report generation",
    icon: "/redesign/nav/info.svg",
    activeIcon: "/redesign/nav/info-active.svg",
    href: AllRoutes.reporting,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SidebarRe() {
  const history = useHistory();

  const isUrlActive = (url) => {
    if (url == "/") {
      return history.location.pathname == "/";
    }

    return history.location.pathname.includes(url);
  };

  return (
    <div className="flex flex-grow flex-col bg-white">
      <div className="flex flex-shrink-0 items-center">
        <Link to={AllRoutes.dashboard}>
          <Logo />{" "}
        </Link>
      </div>
      <div className="mt-5 flex flex-grow flex-col noscrollbar overflow-y-auto">
        <nav
          className="flex-1 space-y-2.5 bg-white nav-side"
          aria-label="Sidebar"
        >
          {navigation.map((item) =>
            !item.children ? (
              <div key={item.name}>
                <Link
                  to={item.href}
                  className={classNames(
                    isUrlActive(item.href)
                      ? "bg-gray-100 text-my-rift-primary-green-600"
                      : "bg-white text-gray-500 ",
                    "group flex w-full items-center rounded-xl py-2 pl-2 text-sm font-medium"
                  )}
                >
                  {isUrlActive(item.href) ? (
                    <img
                      src={item.activeIcon}
                      className={
                        "text-my-rift-primary-green-600 mr-3 h-6 w-6 flex-shrink-0"
                      }
                      aria-hidden="true"
                    />
                  ) : (
                    <img
                      src={item.icon}
                      className={
                        "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0"
                      }
                      aria-hidden="true"
                    />
                  )}

                  {item.name}
                </Link>
              </div>
            ) : (
              <Disclosure as="div" key={item.name} className="space-y-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        isUrlActive(item.href)
                          ? "bg-gray-100 text-my-rift-primary-green-600"
                          : "bg-white text-gray-500 hover:bg-gray-50",
                        "group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left text-sm font-medium focus:outline-none focus:ring-none",
                        item.name === "Public Equities - Portfolios"
                          ? "menu-item-1"
                          : item.name === "Public Equities - Companies"
                          ? "menu-item-4"
                          : ""
                      )}
                    >
                      {isUrlActive(item.href) ? (
                        <img
                          src={item.activeIcon}
                          className={
                            "text-my-rift-primary-green-600 mr-3 h-6 w-6 flex-shrink-0"
                          }
                          aria-hidden="true"
                        />
                      ) : (
                        <img
                          src={item.icon}
                          className={
                            "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0"
                          }
                          aria-hidden="true"
                        />
                      )}

                      <span className="flex-1 text-left">{item.name}</span>
                      <ShowMoreChevron open={open} />
                    </Disclosure.Button>
                    <Disclosure.Panel className="space-y-1">
                      <div className="pl-5">
                        <div className="border-l-[1px] border-dashed pl-1 flex flex-col space-y-4 border-my-tailwind-gray-200">
                          {item.children.map((subItem) =>
                            subItem.children ? (
                              <Disclosure
                                as="div"
                                key={subItem.name}
                                className="space-y-1"
                              >
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className={classNames(
                                        isUrlActive(subItem.href)
                                          ? "bg-gray-100 text-my-rift-primary-green-600"
                                          : "bg-white text-gray-500 hover:bg-gray-50",
                                        "group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left text-sm font-medium focus:outline-none focus:ring-none",
                                        subItem.name === "Portfolio Impact"
                                          ? "menu-item-2"
                                          : subItem.name === "Company Impact"
                                          ? "menu-item-5"
                                          : ""
                                      )}
                                    >
                                      <div className="w-full flex">
                                        {isUrlActive(subItem.href) ? (
                                          <img
                                            src={subItem.activeIcon}
                                            className={
                                              "text-my-rift-primary-green-600 mr-3 h-6 w-6 flex-shrink-0"
                                            }
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <img
                                            src={subItem.icon}
                                            className={
                                              "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0"
                                            }
                                            aria-hidden="true"
                                          />
                                        )}

                                        <span className="flex-1 text-left">
                                          {subItem.name}
                                        </span>

                                        <ShowMoreChevron open={open} />
                                      </div>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="space-y-1">
                                      <div className="pl-5">
                                        <div className="border-l-[1px] border-dashed flex flex-col space-y-4 border-my-tailwind-gray-200">
                                          {subItem.children.map((subItem2) => (
                                            <Disclosure.Button
                                              key={subItem2.name}
                                              className={classNames(
                                                isUrlActive(subItem2.href)
                                                  ? " font-semibold border-l-4 border-my-rift-primary-green-600 pl-1"
                                                  : " ",
                                                "group flex w-fit items-center py-1 ml-6 pr-2 text-sm font-medium text-gray-500 no-underline text-left",
                                                subItem2.name ===
                                                  "Portfolio Overview"
                                                  ? "menu-item-3"
                                                  : subItem2.name ===
                                                    "Company Overview"
                                                  ? "menu-item-6"
                                                  : ""
                                              )}
                                            >
                                              <div className="w-fit flex">
                                                <Link to={subItem2.href}>
                                                  {subItem2.name}
                                                </Link>
                                              </div>
                                            </Disclosure.Button>
                                          ))}
                                        </div>
                                      </div>
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            ) : (
                              <Disclosure.Button
                                key={subItem.name}
                                className={classNames(
                                  isUrlActive(subItem.href)
                                    ? " font-semibold border-l-4 border-my-rift-primary-green-600 pl-1"
                                    : " ",
                                  "group flex w-full items-center py-1 ml-3 pr-2 text-sm font-medium text-gray-500 no-underline text-left"
                                )}
                              >
                                {isUrlActive(subItem.href) ? (
                                  <img
                                    src={subItem.activeIcon}
                                    className={
                                      "text-my-rift-primary-green-600 mr-3 h-6 w-6 flex-shrink-0"
                                    }
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <img
                                    src={subItem.icon}
                                    className={
                                      "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0"
                                    }
                                    aria-hidden="true"
                                  />
                                )}

                                <Link to={subItem.href}>{subItem.name}</Link>
                              </Disclosure.Button>
                            )
                          )}
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )
          )}
        </nav>

        <div className=" mx-auto pt-8">
          <img
            src="/redesign/general/Upload.svg"
            className="w-fit -rotate-5 mx-auto mb-4 -z-1 overflow-hidden"
          />
          <PrimaryButton
            /* TEMPORARY REMOVAL
            onClick={() => history.push(AllRoutes.upload_portfolio)}
            */
            onClick={() => history.push(AllRoutes.contact_us)}
            text={"Upload your portfolio"}
          />
        </div>
      </div>
    </div>
  );
}
