import React from "react";

const CustomAbsCell = (props, id) => {
  const { node, value } = props;

  const { data } = props;
  const enabled = data.ticker.includes("VCL");
  if (!enabled) {
    if (id == "ticker") {
      return <div className="blur -mx-8 p-2">{data[id]}</div>;
    }
    return <div className="blur">{data[id]}</div>;
  } else {
    return <div>{data[id]}</div>;
  }
};

export default CustomAbsCell;
