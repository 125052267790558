import React, { createContext, useEffect, useState } from "react";
import { useContext } from "react";
import { allSearchData } from "../components/redesign/SearchDropdown";
import { set } from "react-hook-form";

export const DataContext = createContext();

const initialYear = 2022;

export function DataProvider({ children }) {
  const [year, setYear] = useState(2022);
  const [allYears, setAllYears] = useState([]);
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [companyList, setCompanyList] = useState();
  const [privateCompany, setPrivateCompany] = useState();
  const [response, setResponse] = useState();
  const [currentType, setCurrentType] = useState();
  const [allSearchData, setAllSearchData] = useState([]);

  const [triggerSearch, setTriggerSearch] = useState(0);

  const [selectedCompanies, setSelectedCompanies] = useState([
    "S&P 500",
    "DJIA",
  ]);

  const handleClearData = () => {
    setAllYears([]);
    setYear(initialYear);
    setCompanyList(null);
    setResponse(null);
  };

  const setNewYears = (years) => {
    setAllYears(years);
    setYear(initialYear);
  };

  const addCompany = (company, type) => {
    if (selectedCompanies.length >= 2) return;
    setSelectedCompanies((prevCompanies) => [...prevCompanies, company]);
    setCompanyList((prevCompanies) => [
      ...(prevCompanies ?? []),
      { portfolioName: company, name: company, type: type },
    ]);
  };

  const removeCompany = (company) => {
    if (isCompanyData()) {
      setSelectedCompanies([]);
      setCompanyList([]);
      return;
    }

    setSelectedCompanies((prevCompanies) =>
      prevCompanies?.filter((c) => c !== company)
    );
    setCompanyList((prevList) =>
      prevList?.filter(
        (c) => c?.portfolioName !== company && c?.name !== company
      )
    );
  };

  const triggerSearchDropdown = () => {
    setTriggerSearch((prev) => prev + 1);
  };

  const isCompanyData = () => {
    return currentType?.includes("company");
  };

  const value = {
    year: year ?? initialYear,
    initialYear: initialYear,
    setYear,
    allYears,
    setAllYears,
    setNewYears,
    companyList,
    setCompanyList,
    response,
    setResponse,
    privateCompany,
    setPrivateCompany,
    handleClearData,
    currentType,
    setCurrentType,
    addCompany,
    removeCompany,
    triggerSearchDropdown,
    triggerSearch,
    selectedCompanies,
    setSelectedCompanies,
    isCompanyData,
    setAllSearchData,
    allSearchData,
    isFullWidth,
    setIsFullWidth,
  };

  /*   useEffect(() => {
    if (!allSearchData || allSearchData.length == 0) return;
    setSelectedCompanies([allSearchData?.[0].id, allSearchData?.[1].id]);
  }, [allSearchData]); */

  /*   useEffect(() => {
    if (companyList?.length < 1) {
      setCompanyList(
        selectedCompanies.map((c) => ({ portfolioName: c, name: c }))
      );
    }
  }, [companyList]); */

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export function useDataContext() {
  return useContext(DataContext);
}
