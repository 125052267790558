import React from "react";
import { twMerge } from "tailwind-merge";

const ChartLegend = ({
  isVertical = true,
  portfolio1,
  portfolio2,
  className,
}) => {
  return (
    <div className={twMerge(className, "bg-white")}>
      <div
        className={
          isVertical
            ? "inline-flex flex-col space-y-2 items-start justify-start"
            : "flex flex-row w-full justify-evenly"
        }
      >
        <div className="inline-flex space-x-1 items-center justify-start">
          <div className="w-5 h-1.5 bg-my-tailwind-light-blue-500 rounded-full" />
          <p className="text-xs leading-none text-gray-600">{portfolio1}</p>
        </div>
        <div className="inline-flex space-x-1 items-center justify-start">
          <div className="w-5 h-1.5 bg-my-tailwind-purple-500 rounded-full" />
          <p className="text-xs leading-none text-gray-600">{portfolio2}</p>
        </div>
      </div>
    </div>
  );
};

export default ChartLegend;
