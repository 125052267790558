import React from "react";
import PremiumFeature from "../../components/redesign/PremiumSoon";

const MyPortfolio = () => {
  return (
    <div className="w-full h-[100vh]">
      <PremiumFeature />
    </div>
  );
};

export default MyPortfolio;
