import React from "react";
import { useDataContext } from "../../../context/PortfolioContext";
import PrimaryButton from "../../ui/redesign/PrimaryButton";
import WrapperCard from "../../ui/redesign/WrapperCard";
import PortfolioCard, { PortfolioCardType } from "./PortfolioCard";
import YearSelector from "./YearSelector";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import { isSimpleCompany } from "../../../services/utilities";
import ToggleSwitch from "../Switch";
import { twMerge } from "tailwind-merge";

const pdfOptions = {
  margin: 0,
  filename: "document.pdf",
  image: { type: "jpeg", quality: 0.99 } /*  */,
  html2canvas: { scale: 3 },
  jsPDF: { unit: "in", orientation: "landscape" },
  //width: 1920, // set width to the desired pixel value
};

const TabOverviewSelector = ({
  title,
  onExport,
  type = PortfolioCardType.simple,
  splitView = true,
  splitToggle = null,
}) => {
  const { year, companyList, selectedCompanies, response } = useDataContext();

  const generatePDF = () => {
    const content = document.getElementById(onExport);
    html2pdf().set(pdfOptions).from(content).toPdf().save("document.pdf");
  };

  const handlePrint = useReactToPrint({
    content: () => onExport,
  });

  if (response && response?.currentYear != year && companyList.length == 2)
    return (
      <div className="w-full flex-col md:flex-row justify-between flex items-center mb-6 gap-y-4">
        <p className="text-lg font-bold leading-7 text-gray-600 w-full">
          {title}
        </p>
        <div className="flex  items-center gap-x-2 flex-shrink-0 w-fit">
          {companyList?.length == 2 &&
            splitView != null &&
            splitToggle != null && (
              <ToggleSwitch
                title={"Compare with industry"}
                toggle={splitView}
                setToggle={splitToggle}
              />
            )}
          {onExport && (
            <PrimaryButton
              onClick={generatePDF}
              text={"Export data"}
              iconUrl={"/redesign/general/Download_data.svg"}
            />
          )}
          <YearSelector disabled={true} />
          {/*           TODO REMOVE FROM PSICS
           */}{" "}
        </div>
      </div>
    );

  return (
    <div>
      <div className="w-full flex-col md:flex-row justify-between flex items-center mb-6 gap-y-4 overview-header">
        <p className="text-lg font-bold leading-7 text-gray-600 w-full">
          {title}
        </p>
        <div className="flex  items-center gap-x-2 flex-shrink-0 w-fit">
          {companyList?.length == 2 &&
            splitView != null &&
            splitToggle != null && (
              <ToggleSwitch
                title={"Compare with industry"}
                toggle={splitView}
                setToggle={splitToggle}
              />
            )}
          {onExport && (
            <PrimaryButton
              //onClick={generatePDF} TEMPORARY REMOVAL
              text={"Export data"}
              iconUrl={"/redesign/general/Download_data.svg"}
              className={"cursor-not-allowed export"}
            />
          )}
          <YearSelector disabled={true} />
          {/*           TODO REMOVE FROM PSICS
           */}{" "}
        </div>
      </div>
      <div
        className={twMerge(
          "grid grid-cols-4 gap-x-6 cards",
          splitView == true && "grid-cols-4",
          splitView == false && "grid-cols-2"
        )}
      >
        <div className="col-span-2">
          <WrapperCard>
            <PortfolioCard
              title={companyList?.[0]?.name}
              value={companyList?.[0]?.value}
              percentage={companyList?.[0]?.percentage}
              isPrimary={true}
              isEmpty={selectedCompanies.length == 0}
              type={type}
            />
          </WrapperCard>
        </div>
        {splitView == true && (
          <div className="col-span-2">
            {selectedCompanies.length < 1 && isSimpleCompany() ? (
              <></>
            ) : (
              <WrapperCard>
                <PortfolioCard
                  title={companyList?.[1]?.name}
                  value={companyList?.[1]?.value}
                  percentage={companyList?.[1]?.percentage}
                  isPrimary={false}
                  closeable={!isSimpleCompany()}
                  isEmpty={companyList?.[1] == null}
                  type={type}
                  isForTour={true}
                />
              </WrapperCard>
            )}
          </div>
        )}
        {/*   <div className="col-span-1">
          <WrapperCard>
            <PortfolioCard isEmpty={true} />
          </WrapperCard>
        </div> */}
      </div>
    </div>
  );
};

export default TabOverviewSelector;
