import React, { Fragment } from "react";
import { classNames } from "../../../services/utilities";
import { Listbox } from "@headlessui/react";
import { Transition } from "notistack";

const years = [2023, 2022, 2021, 2020, 2019];

const YearForm = ({ selectedYear, setSelectedYear }) => {
  return (
    <div className="w-fit ml-auto pt-2 pr-2">
      <Listbox
        value={selectedYear}
        onChange={(y) => {
          setSelectedYear(y);
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900"></Listbox.Label>
            <div className="relative">
              <Listbox.Button className="relative w-full flex cursor-default  py-1.5 pl-3 gap-x-1 text-left text-gray-900 bg-white shadow rounded-md focus:outline-none sm:text-sm sm:leading-6">
                <span className="block ">{selectedYear}</span>
                <span className="pointer-events-none w-6 h-6 right-0 flex items-center p-1">
                  <img
                    src="/redesign/portfolio_impact/chevron.svg"
                    className="h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Listbox.Options className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {years.map((person) => (
                  <Listbox.Option
                    key={person}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "text-my-rift-primary-green-600"
                          : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3"
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block"
                          )}
                        >
                          {person}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default YearForm;
