// Ensure you have ag-Grid React and Tailwind CSS installed in your project
// npm install ag-grid-react ag-grid-community tailwindcss
// You might also need to install and set up react-autocomplete or similar for the autocomplete feature

import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // Using Alpine theme, change as needed
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
//import "ag-grid-enterprise";
import CellActionRenderer from "./components/CellActionRenderer";
import AddRowRenderer from "./components/AddRowRenderer";
import CustomGroupCellRenderer from "./components/CustomGroupCellRenderer";
import { useCustomPortfolio } from "../../context/CustomPortfolioContext";
import DoublingEditor from "./components/IndexSearchEditor";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";

import SimpleNameCellRenderer from "./components/SimpleNameCellRenderer";

import "ag-grid-enterprise";
import ToggleSwitch from "../../components/redesign/Switch";
import SecondaryButton from "../../components/ui/redesign/SeconaryButton";
import { set } from "react-hook-form";
import PrimaryButton from "../../components/ui/redesign/PrimaryButton";
import CompareTableCells from "./components/CompareTableCells";

// Mock function for autocomplete, replace with your autocomplete logic

const CreatePortfolio = ({ handleEnableNext }) => {
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "100%", marginTop: "-1.25rem" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "700px", width: "100%" }), []);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const gridRef = useRef();

  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    //set these css style
    //.ag-theme-quartz > div {
    //padding-bottom: 128px !important;
    // }
    //#portfolioTable > div.ag-theme-quartz
    setTimeout(() => {
      const div = document.querySelector(
        "#portfolioTable > div.ag-theme-quartz"
      );
      div.style.paddingBottom = "128px";
    }, 1000);
  }, []);

  //const [rowData, setRowData] = useState(getData());
  const {
    data: rowData,
    handleUpdateWeight,
    handlePortfolioData,
  } = useCustomPortfolio();
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (toggle) {
      const errorRows = rowData.filter((row) => row.status == "error");

      setLocalData(errorRows);
    } else {
      setLocalData([]);
    }
  }, [toggle]);

  const calculateWeightAggFunc = (params) => {
    console.log(params);
    let totalPosition = 0;
    params.values.forEach((value) => {
      totalPosition += value;
    });

    // Assuming you have a way to access the overall total position for the portfolio,
    // you might need to adjust this logic to calculate or retrieve the total position correctly.
    const overallTotalPosition = rowData.reduce(
      (acc, cur) => acc + cur.val1,
      0
    );

    console.log("overallTotalPosition", overallTotalPosition);

    return (totalPosition / overallTotalPosition) * 100; // Calculate weight as percentage
  };

  const columnDefs = useMemo(
    () => [
      { field: "securityId", hide: true },
      {
        field: "name",
        headerName: "Portfolio/Company Name",
        minWidth: 150,
        flex: 3,
        cellStyle: {
          textAlign: "start",
        },
        //editable: true,
        menuTabs: [],
        cellRenderer: (props) => SimpleNameCellRenderer(props, gridRef),
      },
      {
        field: "portfolio",
        headerName: "Security ID",
        hide: true,
        rowGroup: true,
        menuTabs: [],
      },
      {
        field: "position",
        headerName: "Position",
        aggFunc: "sum",
        editable: true,
        menuTabs: [],
      },
      {
        field: "weight",
        headerName: "Weight (%)",
        aggFunc: "sum",
        menuTabs: [],

        valueFormatter: (params) =>
          params?.value == null ? "" : params.value?.toFixed(2),
      },
      {
        headerName: "",
        cellRenderer: CellActionRenderer,
        minWidth: 125,
        cellRendererParams: {},
        menuTabs: [],
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 125,
      filter: false,
      sortable: true,
      resizable: false,
    }),
    []
  );

  const autoGroupColumnDef = useMemo(
    () => ({
      headerName: "ID",
      field: "securityId",
      menuTabs: [],
      minWidth: 150,
      checkboxSelection: true,
      editable: true,
      cellEditor: DoublingEditor,
      //enableGroupEdit: true,
      cellRenderer: CustomGroupCellRenderer,
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {
            component: AddRowRenderer,
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return { component: CustomGroupCellRenderer };
        }
      },
    }),
    []
  );

  const handleDeleteRowsWithErrors = () => {
    const updatedData = rowData.filter((row) => row.status !== "error");

    handlePortfolioData(updatedData);
    setLocalData([]);
  };

  //check how many rows are error status
  const errorRows = rowData.filter((row) => row.status == "error").length;
  const pendingRows = rowData.filter((row) => row.status == "pending").length;

  useEffect(() => {
    if (errorRows > 0 || pendingRows > 0) {
      if (handleEnableNext != null) handleEnableNext(false);
    } else {
      if (handleEnableNext != null) handleEnableNext(true);
    }
  }, [errorRows, pendingRows]);

  const onSelectionChanged = useCallback(() => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);

    // Allow only up to 2 rows to be selected
    console.log(selectedNodes);
    if (selectedData.length > 2) {
      // Deselect the earliest selected row
      selectedNodes.slice(2).forEach((node) => node.setSelected(false));
      //gridRef.current.api.showLoadingOverlay();

      // gridRef.current.api.deselectIndex(0, true); // This works!
    }

    setSelectedRowData(selectedData);
  }, []);

  //TODO HANDLE ONLY AUTHORIZED USERS CAN SEE THIS PACKAGE

  //TODO AFTER CREATE / UPLOAD PORTFOLIO REVALIDATE THE AVAILABLE-ASSETS REFETCH IN CONTEXT
  return (
    <div id="portfolioTable" style={containerStyle}>
      {errorRows > 0 && (
        <div className="bg-red-100 rounded-xl p-4 w-3/4 mx-auto my-4 flex justify-start items-center">
          <img src="/icon/alert_error.svg" alt="error" />
          <p className="text-red-800 text-sm ml-4">
            {errorRows} row contains error(s). You can edit this table by
            clicking on clicking on any cells.
          </p>
        </div>
      )}
      {errorRows > 0 && (
        <div className="w-full flex justify-between items-center">
          <ToggleSwitch
            toggle={toggle}
            setToggle={setToggle}
            title={"Show rows with error only"}
          />
          <div className="w-fit flex gap-x-3 items-center">
            {selectedRowData.length == 1 && (
              <CompareTableCells
                text={"Compare selected element with industry"}
              />
            )}
            <SecondaryButton
              iconUrl={"/icon/trash.svg"}
              onClick={handleDeleteRowsWithErrors}
              text={"Delete rows with error"}
            />
          </div>
        </div>
      )}
      <div style={gridStyle} className={"ag-theme-quartz mt-4"}>
        <AgGridReact
          ref={gridRef}
          groupAllowUnbalanced
          rowData={localData.length == 0 ? rowData : localData}
          rowHeight={52}
          onRowValueChanged={(e) => console.log(e)}
          onCellValueChanged={handleUpdateWeight}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          pinnedTopRowData={[{ id: -1 }]}
          suppressAggFuncInHeader={true}
          //suppressRowClickSelection={selectedRowData.length >= 2}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          onSelectionChanged={onSelectionChanged}
          groupDefaultExpanded={-1}
          groupAggFiltering={false}
          rowGroupPanelShow={"never"}
          suppressHeaderMenuButton={false}
          //enableGroupEdit={true}
          rowSelection="single"
          pagination={true}
          modules={[RowGroupingModule]}
          aggFuncs={{
            weight: calculateWeightAggFunc,
          }}
        />
      </div>
    </div>
  );
};

export default CreatePortfolio;
