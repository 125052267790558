import { createSlice } from '@reduxjs/toolkit';
import { roundNumber } from "../../services/formatter"

const companySlice = createSlice({
  name: 'company',
  initialState: {
    isLoading: false,
    renderCycle: 0,
    details: null,
    yearDetails: {},
    submitted: false,
    currency: "USD",
    sector: {},
    industry: {},
    subindustry: {},
    subindustryChangedCount: 0,
    publicCompanyChangedCount: 0,
    defaultYear: 2020,
    year: 2020,
    years: [],
    sliders: [],
    impactHistory: null,
    fields: {
      totalRevenue: {
        id: "TOTALREVENUE",
        name: "Total revenue",
        type: "revenue",
        value: null,
        unit: "USD"
      },
      revenueDetails: [],
      otherDetails: [],
      modifiers: []
    },
    input: {
        name: '',
        regionCodes: [],
        inputs: []
    },
    missingFields: {
      name: true,
      subindustry: true,
      regionCodes: true,
      totalRevenue: true,
      yearDetails: {}
    },
    futureRevenues: {},
    compareData: [],
    compareRecommendedCompanies: [],
    compareModalOpen: false,
    compareIsLoading: false,
    showGuide: false,
    guideDetails: {
      1: true,
      2: true,
      3: true,
      4: true,
      5: true
    },
    underEdit: false,
    excelIsLoading: false,
    publicCompanyError: false,

  },
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setRenderCycle(state) {
      state.renderCycle++
    },
    setMissingFields(state, action) {
      const { field, value, year } = action.payload;

      if (year) {
        state.missingFields[field][year] = value;
      } else {
        state.missingFields[field] = value;
      }
    },
    setYear(state, action) {
      state.year = action.payload;

      state.details.values.forEach((v) => {
        if (v.year === action.payload) {
          return state.yearDetails = v;
        }
      })

    },
    setSector(state, action) {
      state.sector = action.payload;
    },
    setIndustry(state, action) {
      state.industry = action.payload;
    },
    setSubindustry(state, action) {
      state.subindustry = action.payload;

      // Handle missing field state
      if (action.payload.id) {
        state.missingFields.subindustry = false;
        state.subindustryChangedCount++;
      } else {
        state.missingFields.subindustry = true;
      }
    },    
    setInput(state, action) {
      const { id, value, year, unit } = action.payload;

      // Set input
      if (!year) {
        state.input[id] = value;

        // Handle missing fields
        if (id === 'name' || id === 'regionCodes') {
          state.missingFields[id] = Boolean(!value?.length);
        }
      } else {
        state.input.inputs.forEach((i, index) => {
          if (i.year === year) {
            i.values.forEach((v, vIndex) => {
              if (v.id === id) {
                state.input.inputs[index].values[vIndex].value = value;
                state.input.inputs[index].values[vIndex].unit = unit;
              }
            })
          }
        })
      }

      state.underEdit = false;
    },
    setUnderEdit(state, action) {
      state.underEdit = action.payload;
    },
    setCompanyDetails(state, action) {
      state.details = action.payload?.details;
      state.yearDetails = action.payload?.yearDetails;
      state.years = action.payload?.years;
      state.year = action.payload?.year;
      state.sliders = action.payload?.sliders;
      state.impactHistory = action.payload?.impactHistory;
      state.submitted = true;
    },
    setCompareIsLoading(state, action) {
      state.compareIsLoading = action.payload;
    },
    setCompareData(state, action) {
      state.compareData = action.payload;
    },
    setCompareRecommendedCompanies(state, action) {
      state.compareRecommendedCompanies = action.payload;
    },
    addToCompareData(state, action) {
      state.compareData = [...state.compareData, action.payload];
    },
    setInputFields(state, action) {
      state.input.inputs = [];

      // Set revenue details
      let revenueDetails = [];

      if (action.payload.revenueDetails && action.payload.revenueDetails.length > 0) {
        action.payload.revenueDetails.forEach((r) => {
          revenueDetails.push({
            ...r, 
            value: null,
            type: "revenueDetail", 
            //unit: state.currency
            unit: "%"
          })
        })  
      }

      // Set other details
      let otherDetails = [];

      if (action.payload.otherDetails && action.payload.otherDetails.length > 0) { 
        action.payload.otherDetails.forEach((o) => {
          otherDetails.push({
            ...o, 
            value: null, 
            type: "otherDetail",
            unit: o.units[0].value
          })
        })
      }

      if (action.payload.modifiers && action.payload.modifiers.length > 0) {
        state.fields.modifiers = action.payload.modifiers;
      }

      state.missingFields.yearDetails[state.defaultYear] = true;

      // Set initial fields
      state.fields.revenueDetails = revenueDetails;
      state.fields.otherDetails = otherDetails;

      let inputs = [state.fields.totalRevenue, ...revenueDetails, ...otherDetails];

      // Set initial values from public company selection
      if (action.payload.values && action.payload.values.length > 0) {
        state.publicCompanyChangedCount++;
        // Get unique years in descending order
        let years = action.payload.values.map((v) => { return v.year })
            years = [...new Set(years)]
            years = years.sort(function(a, b){ return b - a });

        years.forEach((y, yIndex) => {
          let yearInputs = [];

          inputs.forEach((i, index) => {
            let value = null;

            action.payload.values.forEach((v) => { 
              if (i.id.toLowerCase() === v.id.toLowerCase() && v.year === y) {
                return value = String(roundNumber(v.value, 0))
              }
            })

            yearInputs.push({...i, value: value})
          });

          state.input.inputs[yIndex] = {
            year: y,
            values: yearInputs
          }

        });

      } else {
        state.input.inputs[0] = {
          year: state.defaultYear,
          values: inputs
        }
      }
    },
    addYear(state, action) {
      let minYear = state.input.inputs[0].year;

      state.input.inputs.forEach((i) => {
        if (i.year < minYear) {
          minYear = i.year
        }
      })

      const inputYears = state.input.inputs.length;
      const nextYear = state.defaultYear - inputYears;
      
      state.missingFields.yearDetails[nextYear] = true;

      state.input.inputs.push({
        year: nextYear,
        values: [state.fields.totalRevenue, ...state.fields.revenueDetails, ...state.fields.otherDetails]
      });
    },
    setModifier(state, action) {
      const { value, year, id, unit } = action.payload;

      state.details.values.forEach((v) => {
        if (v.year === year) {
          v.sliders.forEach((s) => {
            if (s.id === id) {
              s.projectedValue = Number(value);
              s.unit.value = unit;
            }
          })
        }
      })
    },
    setFutureRevenues(state, action) {
      state.futureRevenues[action.payload.year] = action.payload.value;
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setShowGuide(state, action) {
      state.showGuide = action.payload;

      // save preference to local storage
      localStorage.setItem('disableGuide', !action.payload);

      // If set back to visible -> show all
      if (action.payload) {
        state.guideDetails = {
          1: true,
          2: true,
          3: true,
          4: true,
          5: true
        }
      }
    },
    setGuideDetail(state, action) {
      state.guideDetails[action.payload.number] = action.payload.show;
    },
    setExcelIsLoading(state, action) {
      state.excelIsLoading = action.payload;
    },
    setOpenModal(state, action) {
      state[action.payload] = action.payload;
    },
    setCloseModal(state, action) {
      state.compareModalOpen = false;
    },
    setPublicCompanyError(state, action) {
      state.publicCompanyError = action.payload;
    }
  }
});

export const companyActions = companySlice.actions;

export default companySlice;