import { createSlice } from '@reduxjs/toolkit';

const industrySlice = createSlice({
  name: 'industry',
  initialState: {
    details: null,
    industries: [],
    sector: {},
    industry: {},
    subindustry: {},
    sectorAndIndustryDetails: null,
    sectorModalOpen: false,
    industryModalOpen: false,
    subindustryModalOpen: false,
    isLoading: false,
    subindustryError: false
  },
  reducers: {
    setDetails(state, action) {
      state.details = action.payload;
      state.isLoading = false;
    },
    setIndustries(state, action) {
      state.industries = action.payload;
    },
    setSector(state, action) {
      state.sector = action.payload;
    },
    setIndustry(state, action) {
      state.industry = action.payload;
    },
    setSubindustry(state, action) {
      state.subindustry = action.payload;
    },
    setSectorAndIndustryDetails(state, action) {
      state.sectorAndIndustryDetails = action.payload;
    },
    setOpenModal(state, action) {
      state[action.payload] = true;
    },
    setCloseModal(state, action) {
      state.sectorModalOpen = false;
      state.industryModalOpen = false;
      state.subindustryModalOpen = false;
      state.sectorModalDetails = null;
      state.industryModalDetails = null;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSubindustryError(state, action) {
      state.subindustryError = action.payload;
    }
  },
});

export const industryActions = industrySlice.actions;

export default industrySlice;