import React from "react";

import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { classNames } from "../../../services/utilities";

const mockcategories = [
  { icon: "/redesign/general/Rift", id: 0, name: "Total" },
  {
    icon: "/redesign/general/Environmental",
    id: 1,
    name: "Environmental",
  },
  { icon: "/redesign/general/Employee", id: 2, name: "Employee" },
  { icon: "/redesign/general/Customer", id: 3, name: "Customer" },
];

const CategorySelector = ({
  defaultSelected,
  categories = mockcategories,
  onChange,
}) => {
  const [selected, setSelected] = useState(categories[defaultSelected]);

  return (
    <Listbox
      value={selected}
      onChange={(value) => {
        setSelected(value);
        onChange(value);
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="block rounded-lg text-sm font-medium leading-6  text-gray-900"></Listbox.Label>
          <div
            className={`relative rounded-2xl  shadow-my-sm mt-2 ${
              open
                ? "border-my-tailwind-gray-500 bg-my-tailwind-gray-200 border-2"
                : "border-2 border-transparent bg-my-tailwind-gray-100"
            }`}
          >
            <Listbox.Button
              className={`relative w-full flex items-center cursor-default rounded-2xl  py-1.5 pl-3 gap-x-1 text-left text-gray-900  focus:outline-none sm:text-sm sm:leading-6`}
            >
              <img className="w-4 h-4" src={`${selected.icon}.svg`} />
              <span className="block ">{selected.name}</span>
              <span className="pointer-events-none w-6 h-6 right-0 flex items-center p-1">
                <img
                  src="/redesign/portfolio_impact/chevron.svg"
                  className="h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute -ml-80 w-80 z-10 mt-1 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {categories.map((cat) => (
                  <Listbox.Option
                    key={cat.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-my-tailwind-gray-50" : "",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={cat}
                  >
                    {({ selected, active }) => (
                      <div className="flex items-center gap-x-2">
                        <img className="w-4 h-4" src={`${cat.icon}.svg`} />

                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block"
                          )}
                        >
                          {cat.name}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default CategorySelector;
