import React, { useState } from "react";
import { sendContactUs } from "../api/api";
import { enqueueSnackbar } from "notistack";
import PrimaryButton from "../components/ui/redesign/PrimaryButton";
import WrapperCard from "../components/ui/redesign/WrapperCard";
import Swal from "sweetalert2";

const ContactUs = () => {
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e);

    const form = e.target;
    const formData = new FormData(form);
    const name = formData.get("fullname");
    const email = formData.get("email");
    const message = formData.get("message");

    if (!name || !email || !message) {
      enqueueSnackbar("Please fill out every field on the form.", {
        variant: "warning",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
      return;
    }

    try {
      setIsSending(true);
      const response = await sendContactUs(name, email, message);

      if (response.status === 200 || response.status === 201) {
        setIsSending(false);
        form.reset();
        enqueueSnackbar(
          "Your message has been sent. We will contact you soon.",
          {
            variant: "success",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          }
        );
      } else {
        setIsSending(false);
        Swal.fire({
          title: "Your message was not sent.",
          text: "The credentials have not been saved due to an error, please contact us via the email address below:",
          footer: "customer-service@rgsciences.com",
          confirmButtonText: "OK",
          focusCancel: true,
          customClass: {
            confirmButton: "px-8 py-2",
          },
        });
      }
    } catch (error) {
      setIsSending(false);
      Swal.fire({
        title: "Your message was not sent.",
        text: "The credentials have not been saved due to an error, please contact us via the email address below:",
        footer: "customer-service@rgsciences.com",
        confirmButtonText: "OK",
        focusCancel: true,
        customClass: {
          confirmButton: "px-8 py-2",
        },
      });
    }
  };

  return (
    <div className="m-8 p-1.5">
      <WrapperCard>
        <div className="w-full grid grid-cols-2 gap-x-16 py-8">
          <div className="flex flex-col gap-y-8 px-8">
            <div>
              <p className="text-3xl font-bold leading-9 text-gray-600 mb-4">
                Let’s talk
              </p>
              <p className="text-base font-medium leading-normal text-gray-600">
                To request a quote or want to meet up for a coffee. Contact us
                directly or fill out the form and we will get back to you
                promptly.
              </p>
            </div>
            <form className="flex flex-col gap-y-8" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-y-1">
                <label class="text-sm font-bold leading-tight text-gray-600">
                  Your name
                </label>
                <input
                  className="text-base leading-normal px-3 py-2 bg-white shadow-my-sm border rounded-xl border-gray-300 focus:outline-none focus:ring-0 focus:border-gray-400 focus:border-2"
                  id="fullname"
                  name="fullname"
                />
              </div>
              <div className="flex flex-col gap-y-1">
                <label class="text-sm font-bold leading-tight text-gray-600">
                  Email
                </label>
                <input
                  className="text-base leading-normal px-3 py-2 bg-white shadow-my-sm border rounded-xl border-gray-300 focus:outline-none focus:ring-0 focus:border-gray-400 focus:border-2"
                  id="email"
                  name="email"
                />
              </div>
              <div className="flex flex-col gap-y-1">
                <label
                  class="text-sm font-bold leading-tight text-gray-600"
                  for="message"
                >
                  Your Message
                </label>
                <textarea
                  cols="40"
                  rows="5"
                  className="text-base leading-normal px-3 py-2 bg-white shadow-my-sm border rounded-md border-gray-300 focus:outline-none focus:ring-0 focus:border-gray-400 focus:border-2 "
                  id="message"
                  name="message"
                />
              </div>
              <button type="submit">
                <PrimaryButton
                  text={isSending ? "Sending..." : "Send message"}
                  className={isSending ? "cursor-progress" : "cursor-pointer"}
                  disabled={isSending}
                />
              </button>
            </form>
          </div>
          <div className="flex flex-col gap-y-8 px-8 relative">
            <img src="/redesign/general/ContactUs.svg" className="mx-auto" />
            <div className="mx-auto absolute bottom-0 left-0 right-0 flex items-center justify-center gap-x-6">
              <a
                style={{
                  textDecoration: "underline",
                }}
                href="https://www.linkedin.com/company/richmond-global-sciences/"
                target="_blank"
              >
                <img src="/redesign/general/Linkedin.svg" />
              </a>
              <a
                style={{
                  textDecoration: "underline",
                }}
                href="https://twitter.com/RGSciences"
                target="_blank"
              >
                <img src="/redesign/general/Twitter.svg" />
              </a>
            </div>
          </div>
        </div>
      </WrapperCard>
    </div>
  );
};

export default ContactUs;
