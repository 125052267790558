import React, { useState } from "react";
import WrapperCard from "../../ui/redesign/WrapperCard";
import PrimaryButton from "../../ui/redesign/PrimaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { AllRoutes } from "../../../pages/Routes";
import { enqueueSnackbar } from "notistack";
import LoadingWrapper from "../../../api/LoadingWrapper";
import { useLoadingAndError } from "../../../services/hooks";
import { signUp } from "../../../api/api";
import Logo from "../Logo";

const SignUpPage = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const { toggleLoading, isLoading } = useLoadingAndError();

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const isValidPassword = (password) =>
    /^(?=.*\d)(?=.*[,.+?!@#$%^&*_-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
      password
    ) && password.length >= 8;

  const handleSignUp = async () => {
    // Validate email

    if (!isValidEmail(email)) {
      enqueueSnackbar("Please enter a valid email address", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
      return;
    }

    // Validate password
    if (!isValidPassword(password)) {
      enqueueSnackbar(
        "Password must be at least 8 characters long and include numbers, uppercase, lowercase, and special characters",
        {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        }
      );
      return;
    }

    // Validate password confirmation
    if (password !== passwordConfirmation) {
      enqueueSnackbar("Passwords do not match", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
      return;
    }

    toggleLoading();
    try {
      await signUp(email, password);
      enqueueSnackbar("Successfully created your account. Please sign in!", {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
      toggleLoading();
      history.push(AllRoutes.signIn);
    } catch (error) {
      toggleLoading();
      enqueueSnackbar(
        "An error occurred while creating your account. Please try again",
        {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        }
      );
    }
    // If all validations pass

    // Proceed with the sign-up process
  };

  const showPasswordIcon = (show) => {
    let url = show ? "eye_on" : "eye_off";
    return (
      <img
        src={`/icon/${url}.svg`}
        onClick={handleTogglePassword}
        alt="password icon"
        className="absolute cursor-pointer right-3 top-2.5 h-6 fill-slate-500"
      />
    );
  };
  return (
    <div className="w-full h-full m-auto flex justify-center items-center">
      <WrapperCard className="px-8 py-16 flex flex-col w-[600px]">
        <Logo className={"mx-auto"} />
        <p className="text-2xl leading-8 font-semibold text-gray-500 mt-8 mb-4 text-center">
          Welcome to our data platform
        </p>
        <p className="text-lg leading-7 text-gray-500 text-center mb-10">
          Please setup an account to continue!
        </p>
        <div className="relative mb-4">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email"
            className="shadow-sm w-full text-gray-600 pl-10 border outline-none focus:ring-1 focus:ring-gray-400 border-gray-300 rounded-xl px-3 py-2 placeholder-gray-500 placeholder-sm"
          />
          <img
            src="/icon/email.svg"
            alt="email icon"
            className="absolute left-3 top-2.5 h-6 fill-slate-500"
          />
        </div>
        <div className="relative mb-4">
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="shadow-sm w-full text-gray-600 pl-10 border outline-none focus:ring-1 focus:ring-gray-400 border-gray-300 rounded-xl px-3 py-2 placeholder-gray-500 placeholder-sm"
            type={showPassword ? "text" : "password"}
          />
          <img
            src="/icon/password.svg"
            alt="password icon"
            className="absolute left-3 top-2.5 h-6 fill-slate-500"
          />
          {showPasswordIcon(showPassword)}
        </div>
        <div className="relative mb-8">
          <input
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            placeholder="Password confirmation"
            type={showPassword ? "text" : "password"}
            className="shadow-sm w-full text-gray-600 pl-10 border outline-none focus:ring-1 focus:ring-gray-400 border-gray-300 rounded-xl px-3 py-2 placeholder-gray-500 placeholder-sm"
          />
          <img
            src="/icon/password.svg"
            alt="password icon"
            className="absolute left-3 cursor-pointer top-2.5 h-6 fill-slate-500"
          />
          {showPasswordIcon(showPassword)}
        </div>
        <LoadingWrapper isLoading={isLoading}>
          <PrimaryButton full={true} onClick={handleSignUp} text={"Sign up"} />
        </LoadingWrapper>
        <span className="mt-6 text-base leading-sm text-my-tailwind-gray-500 text-center">
          Already have an account?{" "}
          <span
            onClick={() => {
              history.push(AllRoutes.signIn);
            }}
            className="font-semibold cursor-pointer text-my-rift-primary-green-600"
          >
            Sign In
          </span>
        </span>
      </WrapperCard>
    </div>
  );
};

export default SignUpPage;
