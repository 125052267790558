import React, { useEffect } from "react";
import { Fragment, useState, useRef } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { useDataContext } from "../../context/PortfolioContext";
import { useOuterClick, useOutsideClick } from "../../services/hooks";
import { useHistory } from "react-router-dom";
import { AllRoutes } from "../../pages/Routes";
import { isSimpleCompany } from "../../services/utilities";
import { useTourContext } from "../../context/TourContext";

const filters = ["Everything", "Portfolios", "My Portfolios", "Company"];
const filtersType = ["everything", "portfolio", "myportfolio", "company"];

const SearchDropdown = () => {
  const history = useHistory();
  const { status } = useTourContext();

  const {
    triggerSearch,
    allSearchData,
    addCompany,
    selectedCompanies,
    setCompanies,
    setSelectedCompanies,
    setCurrentType,
  } = useDataContext();
  const bigRef = useOuterClick((ev) => {
    //handleInputBlur();

    if (isPopoverOpen == true && status !== "running") {
      setIsPopoverOpen2((_) => false);
      setIsPopoverOpen((_) => false);
    }
  });
  const innerRef = useOuterClick((ev) => {
    //handleInputBlur();
    //setIsPopoverOpen((_) => false);
  });

  const [selectedFilter, setSelectedFilter] = useState(
    isSimpleCompany() ? 3 : 1
  );
  const [inputValue, setInputValue] = useState("");

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverOpen2, setIsPopoverOpen2] = useState(false);

  const redirectCompanyToPortfolioRoute = () => {
    const url = history.location.pathname;
    switch (url) {
      case AllRoutes.portfolio_impact:
      case AllRoutes.private_company_impact:
        return AllRoutes.company_impact;
      case AllRoutes.portfolio_information:
        return AllRoutes.company_information;
      case AllRoutes.portfolio_overview:
      case AllRoutes.private_company_overview:
        return AllRoutes.company_overview;
      case AllRoutes.customer_impact:
      case AllRoutes.private_company_customer_impact:
        return AllRoutes.company_customer_impact;
      case AllRoutes.employees_impact:
      case AllRoutes.private_company_employees_impact:
        return AllRoutes.company_employees_impact;
      case AllRoutes.environmental_impact:
      case AllRoutes.private_company_environmental_impact:
        return AllRoutes.company_environmental_impact;
      case AllRoutes.portfolio_psics:
        return AllRoutes.company_psics;
      case AllRoutes.portfolio_eu_taxonomy:
        return AllRoutes.company_eu_taxonomy;

      default:
        break;
    }
  };
  const redirectPortfolioToCompanyRoute = () => {
    const url = history.location.pathname;
    switch (url) {
      case AllRoutes.company_impact:
        return AllRoutes.portfolio_impact;
      case AllRoutes.company_information:
        return AllRoutes.portfolio_information;
      case AllRoutes.company_overview:
        return AllRoutes.portfolio_overview;
      case AllRoutes.company_customer_impact:
        return AllRoutes.customer_impact;
      case AllRoutes.company_employees_impact:
        return AllRoutes.employees_impact;
      case AllRoutes.company_environmental_impact:
        return AllRoutes.environmental_impact;
      case AllRoutes.company_psics:
        return AllRoutes.portfolio_psics;

      case AllRoutes.company_eu_taxonomy:
        return AllRoutes.portfolio_eu_taxonomy;

      default:
        break;
    }
  };

  //TODO CUSTOM OWN PORTFOLIO COMPARISON NEED TO BE ADJUSTED TO THIS LOGIC
  const companySelectedForComparison = (item) => {
    if (
      item.type == "company" &&
      (history.location.pathname.includes(AllRoutes.public_equity_portfolio) ||
        history.location.pathname.includes(AllRoutes.private_equity))
    ) {
      history.push(redirectCompanyToPortfolioRoute());
      setCurrentType("company");
    } else if (
      item.type != "company" &&
      history.location.pathname.includes(AllRoutes.public_equity_company)
    ) {
      history.push(redirectPortfolioToCompanyRoute());

      setCurrentType("portfolio");
    }
    addCompany(item.id, item.type);
  };

  useEffect(() => {
    if (triggerSearch == 0) return;

    //innerRef.current.focus();

    setSelectedFilter(isSimpleCompany() ? 3 : 1);
    setIsPopoverOpen(true);
    setIsPopoverOpen2(true);
  }, [triggerSearch]);

  const handleInputFocus = () => {
    setIsPopoverOpen((_) => true);
  };

  const handleInputBlur = () => {
    // Wait for the popover to close before resetting the state
    setTimeout(() => {
      //console.log("false kene legyen");
      setIsPopoverOpen((_) => false);
    }, 100);
  };
  const toggleFilter = (idx) => {
    setSelectedFilter((_) => idx);
  };

  const highlightMatches = (text) => {
    // Split the text into parts that match and don't match the input value
    if (!inputValue) {
      return text;
    }

    const parts = [];
    let startIndex = 0;

    while (startIndex < text.length) {
      const matchIndex = text
        .toLowerCase()
        .indexOf(inputValue.toLowerCase(), startIndex);

      if (matchIndex === -1) {
        parts.push(text.substring(startIndex));
        break;
      }

      parts.push(text.substring(startIndex, matchIndex));
      parts.push(
        <span
          key={startIndex}
          className="bg-my-rift-primary-green-600 bg-opacity-30  py-1"
        >
          {text.substring(matchIndex, matchIndex + inputValue.length)}
        </span>
      );
      startIndex = matchIndex + inputValue.length;
    }

    return parts;
  };

  const isMatchLongEnough = (text) => {
    if (inputValue.length <= 3) return true;
    const matchIndex = text.toLowerCase().indexOf(inputValue.toLowerCase());
    if (matchIndex !== -1) {
      const matchText = text.substring(
        matchIndex,
        matchIndex + inputValue.length
      );
      return matchText.length > 3;
    }
    return false;
  };
  const isInTheCategory = (category) => {
    if (selectedFilter == 0) return true;

    return category == filtersType[selectedFilter];
  };

  const disableElement = (item) => {
    return (
      selectedCompanies.includes(item.id) ||
      selectedCompanies.length == 2 ||
      (selectedCompanies.length == 1 && item.type == "company") ||
      (history.location.pathname.includes("company-impact") &&
        selectedCompanies.length == 1)
    );
  };

  const filterDynamic = history.location.pathname.includes("/reporting")
    ? filters.slice(0, 3)
    : filters;

  return (
    <div ref={bigRef}>
      <Popover className="relative w-full">
        <>
          <input
            id="search"
            name="search"
            className="text-base font-bold leading-normal w-full bg-gray-50 py-2 pl-10 pr-3 text-gray-900 placeholder:text-gray-4 00 sm:text-sm sm:leading-6 focus:outline-none focus:ring-1 focus:ring-gray-600"
            placeholder="Search for portfolio, company..."
            autoComplete="off"
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            style={{ borderRadius: "14px" }}
            onFocus={handleInputFocus}
            //onBlur={handleInputBlur}
            ref={innerRef}
          />
        </>

        <Transition
          as={Fragment}
          show={isPopoverOpen || isPopoverOpen2}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            static
            //style={{ top: inputRef.current?.offsetHeight }}
            className="absolute -left-8 top-full z-50 mt-1 w-screen max-w-lg mx-8 max-h-96 overflow-y-scroll overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5"
          >
            <div className="">
              <div className="pl-3 pr-3 w-full flex gap-x-8 py-4 border-b-[1px] items-center justify-evenly border-dashed border-gray-600 category">
                {filterDynamic.map((f, idx) => {
                  return (
                    <button
                      key={f}
                      onClick={() => toggleFilter(idx)}
                      className="w-fit flex gap-x-2 flex-none"
                    >
                      <img
                        src={`/redesign/general/radio_${
                          selectedFilter == idx ? "on" : "off"
                        }.svg`}
                      />
                      <p className="text-sm font-semibold leading-tight text-gray-600">
                        {f}
                      </p>
                    </button>
                  );
                })}
              </div>
              <div className="w-full h-4"></div>
              {allSearchData
                ?.filter((p) => isMatchLongEnough(p.id))
                .filter((p) => isInTheCategory(p.type))
                .map((item) => (
                  <div
                    onClick={() => {
                      if (disableElement(item)) return;

                      companySelectedForComparison(item);
                    }}
                    key={item.id}
                    className={`group relative flex gap-x-6  p-2 pl-6 text-sm leading-6 portfolio  ${
                      disableElement(item) ? "opacity-40" : "hover:bg-gray-100"
                    }`}
                  >
                    <div className="flex-auto w-full justify-between flex items-center">
                      <div className="w-full justify-between flex gap-x-1 items-center">
                        <div className="flex w-fit items-center gap-x-1">
                          <img
                            className="w-4 h-4"
                            src="/redesign/portfolio_impact/portfolio.svg"
                          />
                          <p className="text-xs font-bold leading-none text-gray-600">
                            {highlightMatches(item.id)}
                          </p>
                        </div>
                        {/*   {selectedCompanies.length == 1 &&
                          item.type == "company" && (
                            <p className="text-xs font-bold leading-none text-my-rift-primary-green-600 mr-2">
                              You cannot compare portfolio with company
                            </p>
                          )} */}
                      </div>
                      <img
                        className="w-5 h-5 rounded-lg mr-4"
                        src="/redesign/portfolio_impact/plus_circle.svg"
                      />
                    </div>
                  </div>
                ))}
              <div className="w-full h-4"></div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default SearchDropdown;
