import React from "react";
import WrapperCard from "../../components/ui/redesign/WrapperCard";
import BoxPlotChart from "../../components/redesign/charts/BoxPlotChart";
import SimpleColumnChart from "../../components/redesign/charts/SimpleColumnChart";

const mockData = [
  [760, 801, 848, 895, 965],
  [733, 853, 939, 980, 1080],
  [714, 762, 817, 870, 918],
  [724, 802, 806, 871, 950],
  [834, 836, 864, 882, 910],
];

const mockEuTaxData = [76.5, 34.6, 54.6, 34.6, 88.5];
const AbsChartView = ({ selectedRows }) => {
  const categories = selectedRows.map((d) => d.ticker);
  const boxPlotData = selectedRows.map((d, idx) => d.boxPlot || mockData[idx]);
  const euTaxData = selectedRows.map(
    (d, idx) => d.euTaxonomy || mockEuTaxData[idx]
  );

  return (
    <div>
      <h1 className={"ml-8 mt-8 text-lg font-bold leading-7 text-gray-600"}>
        GHG Intensity by Issuer Trust
      </h1>
      <h2 className={"font-sans mx-8 my-2 italic"}>
        Vehicle emission intensity
      </h2>

      <WrapperCard wideBorder={true} className={"mx-8"}>
        <BoxPlotChart id={"abs1"} data={boxPlotData} categories={categories} />
      </WrapperCard>
      <h1 className={"ml-8 mt-8 text-lg font-bold leading-7 text-gray-600"}>
        EU taxonomy aligment
      </h1>
      <h2 className={"font-sans mx-8 my-2 italic"}>
        % of vehicles aligned to the EU Green Taxonomy
      </h2>
      <WrapperCard wideBorder={true} className={"mx-8"}>
        <SimpleColumnChart
          id={"abs998958"}
          portfolio1={"EU taxonomy alignment ratio"}
          data={{
            data: [euTaxData],
            categories: categories,
          }}
          currencyType="%"
          color={"#0EA5E9"}
          height={"200"}
          showXLabel={true}
        />
      </WrapperCard>
    </div>
  );
};

export default AbsChartView;
