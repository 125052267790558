import React, { useCallback, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, required
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme CSS
import "ag-grid-community/styles/ag-theme-quartz.css";

import { total39, total41 } from "../AbsPortfolio";
import CustomAbsCell from "./CustomAbsCell";
import ToggleSwitch from "../../components/redesign/Switch";

const AbsTable = ({ selectedRows, handleSelectedRows }) => {
  // Column definitions, adjusted to match your data structure
  const gridRef = React.useRef(null);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Ticker",
        field: "ticker",
        sortable: true,
        cellRenderer: (p) => CustomAbsCell(p, "ticker"),

        flex: 1,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        menuTabs: [],
        cellStyle: {
          textAlign: "start",
        },
      },
      {
        headerName: "Name of Issuing Trust",
        field: "name",
        cellRenderer: (p) => CustomAbsCell(p, "name"),

        sortable: true,
        minWidth: 230,
        flex: 1,
        menuTabs: [],
        cellStyle: {
          textAlign: "start",
        },
      },
      {
        headerName: "Total Vehicles",

        field: "total_emission_intensity",
        cellRenderer: (p) => CustomAbsCell(p, "total_emission_intensity"),

        sortable: true,
        flex: 1,
        menuTabs: [],
        cellStyle: {
          textAlign: "start",
        },
      },
      {
        headerName: "Avg Tailpipe emissions",

        field: "avg_tailpipe_emissions",
        cellRenderer: (p) => CustomAbsCell(p, "avg_tailpipe_emissions"),

        sortable: true,
        flex: 1,
        menuTabs: [],
        cellStyle: {
          textAlign: "start",
        },
      },
    ],
    []
  );

  // Using your provided data
  const rowData = useMemo(
    () => [
      {
        ticker: "VCL-41",
        name: "Volkswagen Leasing GmbH",
        total_emission_intensity: total41.totalCount, // Assuming `total41.totalCount` resolves to a value
        avg_tailpipe_emissions: total41.averageEwltp, // Assuming `total41.averageEwltp` resolves to a value
        boxPlot: [
          0, // Min
          81.18, // Lower Quartile
          113.97, // Median (Approximated with Mean)
          146.74, // Upper Quartile
          308.01, // Max
        ],
        euTaxonomy: 18.77,
      },
      {
        ticker: "VCL-39",
        name: "Volkswagen Leasing GmbH",
        total_emission_intensity: total39.totalCount, // Assuming `total39.totalCount` resolves to a value
        avg_tailpipe_emissions: total39.averageEwltp, // Assuming `total39.averageEwltp` resolves to a value
        boxPlot: [
          0, // Min
          81.2, // Lower Quartile
          113.98, // Median (Approximated with Mean)
          146.76, // Upper Quartile
          308.01, // Max
        ],
        euTaxonomy: 18.7,
      },
      // Additional test data as provided
      {
        ticker: "test1",
        name: "Name Of Issue",

        total_emission_intensity: 100,
        avg_tailpipe_emissions: 100,
      },
      {
        ticker: "test2",
        name: "Name Of Issue",

        total_emission_intensity: 200,
        avg_tailpipe_emissions: 100,
      },
      {
        ticker: "test3",
        name: "Name Of Issue",

        total_emission_intensity: 300,
        avg_tailpipe_emissions: 100,
      },
      {
        ticker: "test4",
        name: "Name Of Issue",

        total_emission_intensity: 400,
        avg_tailpipe_emissions: 100,
      },
      {
        ticker: "test5",
        name: "Name Of Issue",

        total_emission_intensity: 500,
        avg_tailpipe_emissions: 100,
      },
    ],
    []
  );

  // Function to print out selected rows
  /*   const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedDataTemp = selectedNodes.map((node) => node.data);
    setSelectedData(selectedDataTemp);
    console.log("Selected Rows:", selectedNodes);
  }; */

  const [inputValue, setInputValue] = useState("");
  const [onlySelected, setOnlySelected] = useState(false);
  const [toggle, setToggle] = useState(false);

  const onSelectionChanged = useCallback((event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    handleSelectedRows(selectedData);
    //setSelectedData(selectedData);
    // Note: Removed setSelectedData to avoid component state update
  }, []);

  const handleShowOnlySelected = () => {
    setOnlySelected((prev) => !prev);
    //filter out the datarows to only show the selected ones
    if (gridRef.current) {
      gridRef.current.api.setQuickFilter(onlySelected ? "" : "selected");
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (gridRef.current) {
      gridRef.current.api.setQuickFilter(value);
    }
  };

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  return (
    <div style={containerStyle}>
      <input
        id="search"
        name="search"
        className="text-base mb-4 mt-1 font-bold leading-normal block w-full bg-gray-50 py-2 pl-10 pr-3 text-gray-900 placeholder:text-gray-4 00 sm:text-sm sm:leading-6 focus:outline-none focus:ring-1 focus:ring-gray-600"
        placeholder="Search for ABS"
        autoComplete="off"
        value={inputValue}
        onChange={handleInputChange}
        style={{ borderRadius: "14px" }}
        //onBlur={handleInputBlur}
      />
      {selectedRows.length > 0 && (
        <div className="w-full flex jsutify-between items-center mb-2">
          <ToggleSwitch
            toggle={toggle}
            setToggle={setToggle}
            title={"Show selected rows only"}
          />
          <p className="font-semibold ml-auto my-1 text-my-rift-primary-green-400">
            {selectedRows.length} selected
          </p>
        </div>
      )}
      <div style={gridStyle} className="ag-theme-quartz">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          doesExternalFilterPass={(node) => {
            if (toggle) return node.isSelected();
          }}
          isExternalFilterPresent={() => {
            if (toggle) return true;
          }}
          defaultColDef={{
            wrapHeaderText: true,
            autoHeaderHeight: true,
          }}
          rowData={rowData} // Use the provided data as row data
          rowSelection="multiple"
          onSelectionChanged={onSelectionChanged}
          domLayout="autoHeight"
          suppressRowClickSelection={true}
        />
      </div>
    </div>
  );
};

export default AbsTable;
