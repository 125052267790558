import React from "react";

const LoadingWrapper = ({ isLoading, error, children }) => {
  return (
    <div className="w-full mx-auto">
      {isLoading && (
        <div class="animate-spin rounded-full mx-auto h-8 w-8 border-t-2 border-b-2 border-my-rift-primary-green-400"></div>
      )}
      {error && (
        <div>
          <p className="text-red-800 font-semibold leading-10 my-4">{error}</p>
          {children}
        </div>
      )}
      {!isLoading && !error && children}
    </div>
  );
};

export default LoadingWrapper;
