import React from "react";

const Logo = ({ className }) => {
  return (
    <div className={`flex gap-x-3 items-center w-fit ${className}`}>
      <img src="/img/rgs_just_logo.svg" alt="RGS" className="h-8 w-auto" />
      <img src="/img/rgs_just_rift.svg" alt="RGS" className="h-8 w-auto" />
    </div>
  );
};

export default Logo;
