import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchPSICS } from "../api/api";
import LoadingErrorWrapper from "../api/LoadingErrorWrapper";

import TabOverviewSelector from "../components/redesign/portfolio_impact/TabOverviewSelector";
import PsicsTabGeographics from "../components/redesign/psics/PsicsTabGeographic";
import PsicsTabProducts from "../components/redesign/psics/PsicsTabProducts";
import NoComparisonData from "../components/redesign/NoComparisonData";

import TopTab from "../components/redesign/psics/TopTab";
import { useDataContext } from "../context/PortfolioContext";
import { AllRoutes } from "./Routes";

const PSICS = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { selectedCompanies } = useDataContext();

  const tabs = [{ name: "Products & Services (PSICS)" }, { name: "Geography" }];

  const handleTabChange = (tab) => {
    setActiveTab((prev) => tab);
  };

  const {
    year,
    companyList,
    setAllYears,
    setCompanyList,
    setResponse,
    setCurrentType,
    handleClearData,
    isCompanyData,
    setSelectedCompanies,
  } = useDataContext();

  const { isLoading, error, data } = useQuery(
    ["psics", selectedCompanies, year],
    () => {
      return fetchPSICS(selectedCompanies, year);
    }
  );

  useEffect(() => {
    /*   console.log("torolem");
    handleClearData(); */

    if (data) {
      setCompanyList(
        data.details.map((c) => ({
          name: c.portfolioName,
          percentage: c.benchmarkCoverage * 100,
          value: c.totalValue[year],
        }))
      );
      setAllYears(
        Object.keys(data?.details[0].totalValue)
          .map((i, idx) => ({
            id: idx,
            name: `${i}`,
          }))
          .reverse()
      );
      setResponse(data);
      setCurrentType(AllRoutes.portfolio_psics);
    } else {
      if (isCompanyData()) {
        setSelectedCompanies([]);
        setCompanyList([]);
      }
    }
  }, [data, year]);

  if (isLoading || error)
    return <LoadingErrorWrapper isLoading={isLoading} error={error} />;

  return (
    <div className="w-full flex flex-col gap-y-8" id="psics">
      {/*       <TopTab tabs={tabs} active={activeTab} onChange={handleTabChange} />
       */}{" "}
      <div className="px-8 mt-8 flex flex-col gap-y-8">
        <TabOverviewSelector title={tabs[activeTab].name} onExport={"psics"} />
        {selectedCompanies.length < 2 ? (
          <NoComparisonData />
        ) : (
          <>
            {activeTab == 0 && <PsicsTabProducts />}
            {activeTab == 1 && <PsicsTabGeographics />}
          </>
        )}
      </div>
    </div>
  );
};

export default PSICS;
