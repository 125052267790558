import React from "react";
import ShortDescriptionInfo from "./ShortDescriptionInfo";

const ScopeTooltipBody = ({
  icon,
  title,
  description,
  info,
  drillDown = true,
}) => {
  return (
    <div className="flex flex-col gap-y-4 p-1">
      <div className="flex items-center gap-x-2">
        <img src={icon} />
        <p className="text-xs font-bold leading-none text-center text-gray-600">
          {title}
        </p>
      </div>
      <p className="text-xs font-medium leading-none text-gray-600">
        {description}
      </p>
      {drillDown && <ShortDescriptionInfo description={info} />}
    </div>
  );
};

export default ScopeTooltipBody;
