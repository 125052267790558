import React from "react";
import { twMerge } from "tailwind-merge";

const ImpactTableCompare = ({ data, portfolio1, portfolio2 }) => {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  function getCategoryByMetric(metricName) {
    const metricCategories = {
      "GHG Emissions": "Environment",
      "Operational Water Consumed": "Environment",
      "Plastic Waste": "Environment",
      "Food Waste": "Environment",
      "Customer Utility": "Customer",
      "Data Breaches": "Customer",
      Recalls: "Customer",
      Wages: "Employment",
      Diversity: "Employment",
      "Equal Opportunity": "Employment",
      "FDA Medical Device Recalls": "Customer",
      "Appliance Recalls": "Customer",
      "Machinery Recalls": "Customer",
      "Scope 1 Emissions": "Environment",
      "Scope 2 Emissions": "Environment",
      "Scope 3 Emissions": "Environment",
      "Purchased goods and services": "Environment",
      "Capital goods": "Environment",
      "Fuel-and-energy-related activities": "Environment",
      "Upstream transportation and distribution": "Environment",
      "Waste generated in operations": "Environment",
      "Business travel": "Environment",
      "Employee commuting": "Environment",
      "Upstream leased assets": "Environment",
      "Downstream transportation and distribution": "Environment",
      "Processing of sold products": "Environment",
      "Use of sold products": "Environment",
      "End of life treatment of sold products": "Environment",
      "Downstream leased assets": "Environment",
      Franchises: "Environment",
      Investments: "Environment",
    };

    // Return the category or 'Unknown' if the metric is not in the dictionary
    return metricCategories[metricName] || "Environment";
  }

  // Example usage:

  // Example usage:

  const scope3 = data.findIndex((item) => item.name === "Scope 3 Emissions");

  const namesToExclude = [
    "Upstream Scope 3 GHG Emissions",
    "Environmental Operational Impact",
    "Diversity per Employee",
    "Equal Opportunity per Employee",
    "Wages per Employee",
    "Social Operational Impact",
    "Downstream Scope 3 GHG Emissions",
    "Environmental Product Impact",
    "Social Product Impact",
    "Total Social Impact",
    "Total Operational RIFT Impact",
    "Total Product RIFT Impact",
    "Purchased goods and services",
    "Capital goods",
    "Fuel-and-energy-related activities",
    "Upstream transportation and distribution",
    "Waste generated in operations",
    "Business travel",
    "Employee commuting",
    "Upstream leased assets",
    "Downstream transportation and distribution",
    "Processing of sold products",
    "Use of sold products",
    "End of life treatment of sold products",
    "Downstream leased assets",
    "Franchises",
    "Investments",
    "Total RIFT Impact",
  ];

  const filteredAndCategorizedData = data
    .filter((item) => !namesToExclude.includes(item.name))
    .map((item) => ({
      ...item,
      category: getCategoryByMetric(item.name),
    }))
    .sort((a, b) => {
      if (a.category < b.category) return -1;
      if (a.category > b.category) return 1;
      return 0;
    });

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-my-rift-primary-green-500 text-sm">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left  font-bold text-white uppercase tracking-wider"
            >
              Category
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left  font-bold text-white uppercase tracking-wider"
            >
              Impact*
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center  font-bold text-white uppercase tracking-wider"
            >
              {portfolio1}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center  font-bold text-white uppercase tracking-wider"
            >
              {portfolio2}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center font-bold text-white uppercase tracking-wider"
            >
              % Difference (C1 vs C2)
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredAndCategorizedData.map((item, index) => (
            <tr key={index}>
              <td className="px-6 py-1 whitespace-nowrap text-base text-left text-gray-600">
                {item.category}
              </td>
              <td
                className={twMerge(
                  "px-6 py-1 whitespace-nowrap text-base text-gray-600",
                  index > scope3 && "pl-10"
                )}
              >
                {item.name}
              </td>
              <td className="px-6 py-1 whitespace-nowrap text-base text-right text-gray-600">
                {USDollar.format(item.value1)}
              </td>
              <td className="px-6 py-1 whitespace-nowrap text-base text-right text-gray-600">
                {USDollar.format(item.value2)}
              </td>
              <td
                className={`px-6 py-1 whitespace-nowrap text-right text-base ${
                  item.percentageDifference >= 0
                    ? "text-green-600"
                    : "text-red-600"
                }`}
              >
                {!isNaN(item.percentageDifference) &&
                  `${item.percentageDifference} %`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ImpactTableCompare;
