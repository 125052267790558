import React, { createContext, useContext, useEffect, useState } from "react";
import {
  authEmail,
  authToken,
  authPermissions,
  fetchAllSearchData,
  signOutApi,
} from "../api/api";
import { useDataContext } from "./PortfolioContext";
import { enqueueSnackbar } from "notistack";

// Define the context
const UserContext = createContext();

// Define the provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { setAllSearchData } = useDataContext();

  const clearUserData = () => {
    localStorage.removeItem(authEmail);
    localStorage.removeItem(authToken);
    localStorage.removeItem("expiryDate");
  };

  useEffect(() => {
    // Read user email and expiry date from local storage
    const email = localStorage.getItem(authEmail);
    const expiryDate = localStorage.getItem("expiryDate");
    const permissions = localStorage.getItem(authPermissions);

    const now = new Date();

    // Check if the token has expired
    if (expiryDate && new Date(expiryDate) > now) {
      if (email != null) {
        setUser({ email: email, permissions: permissions });
      }
    } else {
      // Token has expired or does not exist
      clearUserData();
      setUser(null);
      if (expiryDate != null)
        enqueueSnackbar("You have been logged out, please sign in again", {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
    }
  }, []);

  // Function to simulate signing in
  const signIn = async (userData) => {
    // Calculate expiry date (24 hours from now)
    //const expiryDate = new Date(new Date().getTime() + 10 * 1000);
    const expiryDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

    // Store email and expiry date in local storage
    localStorage.setItem(authEmail, userData.email);
    localStorage.setItem("expiryDate", expiryDate.toISOString());

    setUser(userData);
    window.location.reload();

    const allData = await fetchAllSearchData();
    setAllSearchData(allData);
  };

  // Function to sign out the user
  const signOut = async () => {
    // Clear user from context
    clearUserData();
    setUser(null);
    signOutApi();
    window.location.reload();
    const allData = await fetchAllSearchData();
    //console.log(allData);
    setAllSearchData(allData);
  };

  const isAdmin = user?.permissions?.split(",").includes("internal");

  // The value that will be given to the context consumers
  const value = {
    user,
    signIn,
    signOut,
    isAdmin,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

// Custom hook to use the user context
export const useUser = () => useContext(UserContext);
