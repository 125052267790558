import React from "react";
import { twMerge } from "tailwind-merge";

const PrimaryButton = ({
  text,
  onClick,
  disabled,
  iconUrl,
  iconRight,
  full,
  className,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={twMerge(
        `flex gap-x-2 items-center justify-center rounded-md  bg-my-rift-primary-green-500 py-2.5 px-4 text-sm font-semibold text-white shadow-sm hover:bg-opacity-90 ${
          full ? "w-full" : "w-fit"
        } ${disabled ? "bg-my-rift-primary-green-500/50" : "cursor-pointer"}`,
        className
      )}
    >
      {iconUrl && iconRight != true && (
        <img className="flex-shrink-0" src={iconUrl} />
      )}
      <span className="whitespace-nowrap">{text}</span>
      {iconUrl && iconRight == true && (
        <img className="flex-shrink-0" src={iconUrl} />
      )}
    </button>
  );
};

export default PrimaryButton;
