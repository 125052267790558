import React, { useCallback, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import ToolTipRe from "../../../components/ui/redesign/ToolTip";

const CustomGroupCellRenderer = (props) => {
  const { node, value } = props;
  const [expanded, setExpanded] = useState(node.expanded);

  useEffect(() => {
    const expandListener = (event) => setExpanded(event.node.expanded);

    node.addEventListener("expandedChanged", expandListener);

    return () => {
      node.removeEventListener("expandedChanged", expandListener);
    };
  }, []);

  const onClick = useCallback(() => node.setExpanded(!node.expanded), [node]);

  const { data } = props;
  const error = data?.status === "error";

  return (
    <ToolTipRe
      onTop={true}
      disableRelative={true}
      disabled={error == false}
      noButton={true}
      toolTipBase={
        <div
          style={{
            paddingLeft: `${node.level * 15}px`,
            width: "200px",
          }}
          className={twMerge(
            "flex gap-x-2 pl-2 cursor-pointer",
            error && "bg-red-100"
          )}
          onClick={onClick}
        >
          {node.group && (
            <img
              alt="group-icon"
              src={`/icon/${!expanded ? "group_closed" : "group_open"}.svg`}
            />
          )}
          {!node.group && (
            <p className={node.level != 0 ? "pr-3" : "px-3"}>
              {node.rowIndex + 1}
            </p>
          )}
          {value}
          {error && (
            <img
              className="ml-auto pr-1"
              alt="error"
              src="/icon/row_error.svg"
            />
          )}
        </div>
      }
    >
      {error && (
        <p className="text-sm font-medium leading-normal text-gray-600">
          We can’t find this company. <br />
          Please edit or manually delete.
        </p>
      )}
    </ToolTipRe>
  );
};

export default CustomGroupCellRenderer;
