import React, { useState } from "react";
import FAQAccordion from "../../../components/redesign/psics/FAQAccordion";
import IWAI from "../../../components/redesign/portfolio_impact/IWAI";
import RevenueTable from "../../../components/redesign/psics/RevenueTable";
import PSICSComparison from "../../../components/redesign/psics/PSICSComparison";
import ColumnChart from "../../../components/redesign/charts/ColumnChart";
import SimpleColumnChart from "../../../components/redesign/charts/SimpleColumnChart";
import WrapperCard from "../../../components/ui/redesign/WrapperCard";
import HorizontalBarChart from "../charts/HorizontalBarChart";
import TopTab from "./TopTab";
import { useDataContext } from "../../../context/PortfolioContext";
import { AllRoutes } from "../../../pages/Routes";
import { useHistory } from "react-router-dom";
import { isSimpleCompany } from "../../../services/utilities";
import ChartTypeSelector from "../charts/components/ChartTypeSelector";
import SimplePieChart from "../charts/SimplePieChart";

const PsicsTabGeographics = ({ idx }) => {
  const { year, response: overResponse, currentType } = useDataContext();
  //const [showChartType1, setShowChartType1] = useState(0);

  const [activeTab1, setActiveTab1] = useState(0);
  //const [activeTab2, setActiveTab2] = useState(0);
  const history = useHistory();

  const cType = isSimpleCompany()
    ? AllRoutes.company_psics
    : AllRoutes.portfolio_psics;
  if (!overResponse || currentType != cType) return <></>;

  if (isSimpleCompany()) return <></>;

  const response = overResponse.overview;
  const mapTab = [
    "geographicalDrilldownByCountries",
    "geographicalDrilldownByIncomeRegions",
  ];

  const chartData1 = Object.keys(response?.[mapTab[activeTab1]]?.[year]?.[idx]);
  const chartTitle1 = Object.values(
    response?.[mapTab[activeTab1]]?.[year]?.[idx]
  ).map((p) => p * 100);

  /*   const chartData2 = Object.keys(response?.[mapTab[activeTab2]]?.[year]?.[1]);
  const chartTitle2 = Object.values(
    response?.[mapTab[activeTab2]]?.[year]?.[1]
  ).map((p) => p * 100);
 */
  const categoryChartData1 = chartData1.map((title, index) => ({
    name: title,
    data: [chartTitle1[index]],
  }));

  const pieChartData1 = categoryChartData1.map((item) => ({
    name: item.name,
    y: item.data?.[0], // Assuming each item only has one data point
  }));

  /*   const categoryChartData2 = chartData2.map((title, index) => ({
    name: title,
    data: [chartTitle2[index]],
  }));

  const pieChartData2 = categoryChartData2.map((item) => ({
    name: item.name,
    y: item.data?.[0], // Assuming each item only has one data point
  })); */

  const tabs = [
    { icon: "/redesign/general/EnvImpactPlace.svg", name: "Countries" },
    { icon: "/redesign/general/Wages.svg", name: "Income Regions" },
  ];

  const handleTabChange = (tab) => {
    setActiveTab1((prev) => tab);
  };

  return (
    <div>
      <div className="w-full flex gap-x-8 justify-between items-center">
        <WrapperCard className="w-full rounded-2xl border-[2px] border-my-tailwind-gray-100 pl-6 pt-2 pb-6">
          <TopTab
            tabs={tabs}
            active={activeTab1}
            onChange={(val) => handleTabChange(val, 1)}
          />
          <div
            className={`pt-4 ${
              activeTab1 == 0
                ? "max-h-[350px] overflow-y-hidden"
                : "max-h-[350px] overflow-y-scroll"
            }   supervisortable-dark pr-4 -mt-4`}
          >
            {activeTab1 == 1 && (
              <SimplePieChart
                id={"simple2"}
                data={pieChartData1}
                color={"bg-my-tailwind-light-blue-500"}
                showLegend={true}
                unit={"%"}
                leftUnit={false}
              />
            )}
            {activeTab1 == 0 && (
              <SimplePieChart
                id={"simple1"}
                data={pieChartData1}
                color={"#0EA5E9"}
                showLegend={true}
                unit={"%"}
                leftUnit={false}
              />
            )}
          </div>
          {
            <div className="mx-auto w-fit pr-6 -mt-12 h-20">
              {activeTab1 == 1 && (
                <WrapperCard>
                  <div
                    onClick={() => {
                      if (activeTab1 == 1) {
                        window.open(
                          "https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html",
                          "_blank"
                        );
                      }
                    }}
                    className="w-fit flex items-center justify-center gap-x-4 p-3"
                  >
                    <img src="/redesign/general/income_region.svg" />
                    <p className="text-sm leading-tight text-my-tailwind-gray-600">
                      Income regions are based on the classification of the{" "}
                      <span className="text-my-rift-primary-green-600 underline font-bold cursor-pointer underline-offset-4">
                        <a
                          style={{
                            textDecoration: "underline",
                          }}
                          href="https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          World Bank
                        </a>
                      </span>
                    </p>
                    <a
                      style={{}}
                      href="https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/redesign/general/income_region_arrow.svg" />
                    </a>
                  </div>
                </WrapperCard>
              )}
            </div>
          }
        </WrapperCard>
      </div>
    </div>
  );
};

export default PsicsTabGeographics;
