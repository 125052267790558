import React from "react";
import { useCustomPortfolio } from "../../../context/CustomPortfolioContext";

const CellActionRenderer = (props) => {
  const { deleteRow } = useCustomPortfolio();
  if (props.data?.id == -1) return <></>;

  const status = props.data?.status;

  const group = props.node.group;
  const groupStatus = props.node?.allLeafChildren || [];
  const groupStatusOk = groupStatus.every((item) => item.data.status == "ok");
  const groupError = groupStatus.some((item) => item.data.status == "error");

  return (
    <div className="flex ml-auto">
      {(status == "ok" || (group && groupStatusOk)) && (
        <img alt="ok" src="/icon/action_ok.svg" />
      )}
      {status == "pending" && (
        <img alt="pending" src="/icon/action_pending.svg" />
      )}
      {(status == "error" || (group && groupError)) && (
        <img alt="error" src="/icon/action_error.svg" />
      )}
      <img
        onClick={() => deleteRow(props.data?.securityId, props.node.key)}
        className="cursor-pointer"
        alt="delete"
        src="/icon/action_delete.svg"
      />
    </div>
  );
};

export default CellActionRenderer;
