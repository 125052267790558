import React from "react";
import PremiumFeature from "../components/redesign/PremiumSoon";
import NoDataForYear from "../components/redesign/NoDataForYear";

const LoadingErrorWrapper = ({ isLoading, error, mini }) => {
  //TODO IF THE PREMIUM SUBSCRIPTION PLANS ARE ADDED (UPGRADE_PLANS IN THE NAVBAR MODIFY THE STATUS CODE HANDLING SO IT DOESN'T SHOW THE ERROR IF ITS A PREMIUM FEATURE SCREEN
  if (isLoading && mini) {
    return (
      <div class="p-8 animate-spin mx-auto rounded-full h-32 w-32 border-t-2 border-b-2 border-my-rift-primary-green-400"></div>
    );
  }

  if (isLoading) {
    return (
      <div class="fixed top-0 right-0 h-screen w-screen z-[200] flex justify-center items-center">
        <div class="md:ml-64 animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-my-rift-primary-green-400"></div>
      </div>
    );
  }

  if (error != null && error.message.includes("404")) {
    return (
      <div className="m-auto w-full max-w-2xl">
        <NoDataForYear />
      </div>
    );
  }

  if (error != null && error.message.includes("401")) {
    return (
      <div className="m-auto">
        <PremiumFeature />
      </div>
    );
  }

  //TODO CUSTOM ERROR SCREEN
  if (error) {
    return <div>Error! {error.message}</div>;
  }
};

export default LoadingErrorWrapper;
