import React from "react";
import HoverButton from "../../ui/redesign/HoverButton";
import ModalRe from "../../ui/redesign/Modal";
import ShowMore from "../../ui/redesign/ShowMore";
import ToolTipRe from "../../ui/redesign/ToolTip";
import ShortDescriptionInfo from "../portfolio_impact/ShortDescriptionInfo";
import ProductsServicesDetail from "./ProductsServicesDetail";
import { imageNameTrim, isSimpleCompany } from "../../../services/utilities";
import { useHistory } from "react-router-dom";
import { AllRoutes } from "../../../pages/Routes";

const ProductsServices = ({ title, portfolio1, portfolio2, data1, data2 }) => {
  const portfolios = [
    {
      name: portfolio1,
      top3: data1,
    },
    {
      name: portfolio2,
      top3: data2,
    },
  ];

  const history = useHistory();

  return (
    <div className="">
      <div className="w-full flex items-center justify-between mb-6 ">
        <div className="flex items-center gap-x-2">
          <img src="/redesign/nav/psics.svg" />
          <p className=" text-sm font-bold leading-tight text-gray-600">
            {title}
          </p>
        </div>

        <ShowMore
          onClick={() =>
            history.push(
              isSimpleCompany()
                ? AllRoutes.company_psics
                : AllRoutes.portfolio_psics
            )
          }
        />
      </div>
      {portfolios.map((portfolio, idx) => (
        <div className=" mt-8 flow-root sm:mx-0">
          <div>
            <div className="inline-flex space-x-2 items-center justify-start">
              <img
                className="w-4 h-4"
                src="/redesign/portfolio_impact/portfolio.svg"
              />
              <p className="text-sm font-bold leading-tight text-gray-600">
                {portfolio.name}
              </p>
            </div>
            <div
              className={`w-10 h-1.5 ${
                idx == 0
                  ? "bg-my-tailwind-light-blue-500"
                  : "bg-my-tailwind-purple-500"
              } rounded-full mb-4`}
            />
          </div>
          <table className="min-w-full topthree">
            <thead>
              <tr className="px-2.5 py-1 ">
                <th
                  scope="col"
                  className="py-1 pl-2.5 pr-2.5 text-left text-xs leading-none text-my-tailwind-gray-600 font-normal bg-my-tailwind-gray-50 rounded-tl-lg rounded-bl-lg"
                >
                  Revenue Category - Top3
                </th>
                <th
                  scope="col"
                  className=" py-1 px-2.5 text-center text-xs leading-none text-my-tailwind-gray-600 font-normal sm:table-cell bg-my-tailwind-gray-50"
                >
                  Category Ratio
                </th>
                <th
                  scope="col"
                  className=" py-1 px-2.5 text-center text-xs leading-none text-my-tailwind-gray-600 font-normal sm:table-cell bg-my-tailwind-gray-50 rounded-tr-lg rounded-br-lg"
                >
                  Impacts
                </th>
              </tr>
            </thead>
            <tbody>
              {portfolio.top3.slice(0, 3).map((project) => {
                return (
                  <tr key={project.id} className="px-2.5">
                    <td className="py-2.5 px-2.5 text-sm">
                      <ModalRe
                        title={project.name}
                        content={
                          <ProductsServicesDetail
                            data={project.impacts}
                            subtitle={project.productCategoryDescription}
                          />
                        }
                      >
                        <div className="text-xs font-semibold leading-none text-my-tailwind-gray-600 hover:text-my-rift-primary-green-600 group flex items-center gap-x-1.5 cursor-pointer text-start">
                          {project.name}
                          <span className="group-hover:visible invisible">
                            <img src="/redesign/general/arrow_expand.svg" />
                          </span>
                        </div>
                      </ModalRe>
                    </td>
                    <td className=" py-2.5 px-3 text-center text-xs font-semibold leading-none text-my-tailwind-gray-600 sm:table-cell">
                      {project.percentage.toFixed()}%
                    </td>
                    <td className=" py-2.5 px-3 text-center text-xs font-semibold leading-none text-my-tailwind-gray-600 sm:table-cell">
                      <ToolTipRe
                        offset={"bottom-7 -left-20"}
                        toolTipBase={
                          <HoverButton
                            outHover={
                              <img src="/redesign/general/more_circle.svg" />
                            }
                            onHover={
                              <img src="/redesign/general/more_circle_active.svg" />
                            }
                          />
                        }
                      >
                        {/*TEMPORARY REMOVAL*/}
                        {/*{project.relevantImpactPillars?.map((imp) => (
                          <div class="flex mt-3 gap-x-2 items-center justify-start w-44">
                            <img
                              className="flex-shrink-0 w-6 h-6"
                              src={`/redesign/general/${imageNameTrim(
                                imp
                              )}.svg`}
                            />
                            <div className="flex-shrink-0 max-w-[140px]">
                              <p class="text-xs text-left font-semibold leading-none text-gray-600">
                                {imp}
                              </p>
                            </div>
                          </div>
                        ))}*/}
                        <p className="w-36 text-center font-bold">
                          Upgrade plan to view mapped impacts
                        </p>
                      </ToolTipRe>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ))}
      <div className="mt-4">
        <ShortDescriptionInfo
          description={
            "The RGS Products and Services Classification System (PSICS) provides a structured taxonomy classifying revenues into products and services which are then mapped to impact metrics."
          }
        />
      </div>
    </div>
  );
};

export default ProductsServices;
