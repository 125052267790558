import PrimaryButton from "../components/ui/redesign/PrimaryButton";
import { useTourContext } from "../context/TourContext";

const Opening = () => {
  const { tour } = useTourContext();

  return (
    <div className="relative w-full min-h-max">
      <div className="absolute w-full h-full bg-wave bg-no-repeat bg-cover bg-top opacity-20 z-0" />
      <div className="absolute z-1 w-full h-full flex flex-col items-center justify-center gap-6">
        <img
          src="/img/rift_logo.png"
          alt="Rift Logo"
          className="w-[35%] h-auto"
        />
        <h1 className="text-[1.75rem] font-bold text-[#3b4551]">
          Welcome to our Guided Tour!
        </h1>
        <div className="flex justify-center w-[50%]">
          <PrimaryButton text={"Start a Tour"} onClick={() => tour.start()} />
        </div>
      </div>
    </div>
  );
};

export default Opening;
