import { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { AllRoutes } from "../../pages/Routes";
import { NavLink as Link } from "react-router-dom";
import ShowMoreChevron from "../ui/redesign/ShowMoreChevron";
import { useHistory } from "react-router-dom";
import SearchDropdown from "./SearchDropdown";
import { useUser } from "../../context/UserContext";
import PrimaryButton from "../ui/redesign/PrimaryButton";
import MobileMenu from "./MobileMenu";
import SidebarRe from "./Sidebar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [
  {
    name: "Contact Us",

    href: AllRoutes.contact_us,
  },
  {
    name: "Upgrade Plan",

    href: AllRoutes.upgrade,
  },
  {
    name: "Guided Tour",

    href: AllRoutes.opening,
  },
];

export default function HeaderRe() {
  const history = useHistory();
  const { user, signOut } = useUser();

  const currentPath = history.location.pathname;
  const showSearch = currentPath !== AllRoutes.create_portfolio;

  //TODO FIX NAVBAR IF NEEDED FOR SPECIAL SITES LIKE CREATE PORTFOLIO

  return (
    <Disclosure as="nav" className="bg-white shadow w-full border-1">
      {({ open }) => (
        <>
          <div className="sm:px-4 lg:px-8 shadow-my-sm">
            <div className="flex h-20 justify-between">
              <div className="flex items-center justify-center  px-2 lg:px-0 w-full max-w-lg">
                {showSearch && (
                  <div className="w-full">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative bg-gray-50  w-[90%] md:w-full border-b-[2px] border-gray-200 rounded-2xl">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 rounded-2xl">
                        <img
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                          src="/redesign/nav/search.svg"
                        />
                      </div>
                      <SearchDropdown />
                    </div>
                  </div>
                )}
              </div>
              <div className="hidden lg:flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      className={`inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium ${
                        history.location.pathname == item.href
                          ? "text-my-rift-primary-green-600"
                          : "text-gray-500 hover:text-gray-700 hover:border-gray-300"
                      }   `}
                      to={item.href}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  key={history.location.pathname}
                  className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-my-rift-primary-green-500"
                >
                  <span className="sr-only">Open main menu</span>
                  <img src="/icon/hamburger.svg" className="w-8 h-8" />
                </Disclosure.Button>
              </div>
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                <span className="sr-only">View notifications</span>
                <div className="inline-flex items-start justify-start p-1 bg-white border rounded border-gray-100">
                  <div className="flex items-center justify-center flex-1 h-full p-0.5">
                    <img
                      src="/redesign/nav/bell.svg"
                      className="h-6 w-6"
                      aria-hidden="true"
                    />{" "}
                  </div>
                </div>

                {/* Profile dropdown */}
                {user && (
                  <Menu as="div" className="relative ml-4 flex-shrink-0 ">
                    {({ open }) => (
                      <>
                        <div className="flex items-center">
                          <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-none items-center ">
                            <span className="sr-only">Open user menu</span>
                            <div class="inline-flex items-center uppercase justify-center w-8 h-8 text-xl text-white bg-my-rift-primary-green-600 rounded-full">
                              {user.email[0]}
                            </div>
                            <ShowMoreChevron open={!open} />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-50 mt-2 w-60 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {user && (
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "bg-gray-200 block px-2 py-2 text-sm text-gray-700 text-ellipsis"
                                    )}
                                  >
                                    {user.email}
                                  </div>
                                )}
                              </Menu.Item>
                            )}
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-2 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Settings
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={signOut}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block w-full px-4 py-2 text-sm text-my-tailwind-red-400"
                                  )}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                    {/*     <div className="bg-gray-400 inline-flex">
                      <MobileMenu />
                    </div>{" "} */}
                  </Menu>
                )}
                {user == null && (
                  <div className="relative ml-4 flex-shrink-0">
                    <PrimaryButton
                      text="Sign In"
                      onClick={() => history.push(AllRoutes.signIn)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 pt-2 pb-3 px-4">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
              <SidebarRe key={"mobile"} />
            </div>
            <div className="border-t border-gray-200 pt-4 pb-3">
              {user && (
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <div class="inline-flex items-center uppercase justify-center w-8 h-8 text-xl text-white bg-my-rift-primary-green-600 rounded-full">
                      {user.email[0]}
                    </div>
                  </div>
                  <div className="ml-3">
                    <div className="text-sm font-medium text-gray-500">
                      {user.email}
                    </div>
                  </div>

                  <div className="inline-flex items-start justify-start p-1 bg-white border rounded border-gray-100">
                    <div className="flex items-center justify-center flex-1 h-full p-0.5">
                      <img
                        src="/redesign/nav/bell.svg"
                        className="h-6 w-6"
                        aria-hidden="true"
                      />{" "}
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Settings
                </Disclosure.Button>
                {user != null ? (
                  <button
                    onClick={signOut}
                    className={classNames(
                      "block w-full px-4 py-2 text-sm text-my-tailwind-red-400"
                    )}
                  >
                    Sign out
                  </button>
                ) : (
                  <div className="relative ml-4 mr-4 flex-shrink-0">
                    <PrimaryButton
                      full={true}
                      text="Sign In"
                      onClick={() => history.push(AllRoutes.signIn)}
                    />
                  </div>
                )}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
