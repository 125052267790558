export const roundNumber = (num, digit = 2) => {   
    const rounded = +(Math.round(num + `e+${digit}`)  + `e-${digit}`);

    if (isNaN(rounded)) {
        return 0;
    } else {
        return rounded;
    }
}

export const financialFormatter = (num) => {
    if (typeof num === 'number') {
        return new Intl.NumberFormat('en-US').format(Number(num))
    } else {
        return 0;
    } 
}

export const formatId = (id) => {
    return id?.replace(/\s/g, "_")?.toLowerCase()
}