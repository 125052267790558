import WrapperCard from "../../../components/ui/redesign/WrapperCard";
import { Fragment } from "react";
import {
  STEP1_COUNTRIES,
  STEP1_EXCLUSIONS_BUSINESS_INVOLVMENTS,
  STEP1_INDUSTRIES,
  STEP1_REGIONS,
  STEP1_SECTORS,
  STEP4_TEMP,
  STEPSDG,
} from "../form/FormPredefValues";
import CreatePortfolio from "../CreatePortfolio";
import { FULL_STEP } from "../CreatePortfolioPage";

const getLabel = (values, options) => {
  const selectedValues = values
    ? Object.keys(values).filter((key) => values[key])
    : [];
  const numberOfSelected = selectedValues.length;

  if (numberOfSelected === 0) return "None";
  if (numberOfSelected === 1) {
    const selectedValue = selectedValues[0];
    return options.find((option) => option.value === selectedValue).label;
  }
  return `Multiple (${numberOfSelected})`;
};

const getCustomLabel = (values) => {
  const selectedValues = values || [];
  if (selectedValues.length === 0) return "None";
  if (selectedValues.length === 1)
    return selectedValues[0].id || selectedValues[0].value;
  return `Multiple (${selectedValues.length})`;
};

const getLabelByKey = (values) => {
  const selectedValues = values
    ? Object.keys(values).filter((key) => values[key])
    : [];
  if (selectedValues.length === 0) return "None";
  if (selectedValues.length === 1)
    return selectedValues[0]
      .replace(/_/g, " ")
      .replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
      );
  return `Multiple (${selectedValues.length})`;
};

const getPercentLabel = (values) => (values ? `${values}%` : "None");

const SearchSummary = ({ getValues, handleEdit, isFlipped, aboveStep }) => {
  const data = [
    {
      label: "Theme",
      value: getLabel(getValues("temp4"), STEP4_TEMP.options),
      editRoute: [0, 0, 0],
    },
    {
      label: "Custom theme",
      value: getCustomLabel(getValues("custom_theme")),
      editRoute: [0, 0, 0],
    },
    {
      label: "EU Taxonomy",
      value: getPercentLabel(getValues("slider_6")),
      editRoute: [1, 0, 0],
    },
    {
      label: "SDG",
      value: getLabel(getValues("sdg"), STEPSDG.options),
      editRoute: [1, 0, 1],
    },
    {
      label: "Region",
      value: getLabel(getValues("regions"), STEP1_REGIONS.options),
      editRoute: [2, 0, 0],
    },
    {
      label: "Country",
      value: getLabel(getValues("countries"), STEP1_COUNTRIES.options),
      editRoute: [2, 0, 1],
    },
    {
      label: "Sector",
      value: getLabel(getValues("temp"), STEP1_SECTORS.options),
      editRoute: [2, 1, 0],
    },
    {
      label: "Industry",
      value: getLabel(getValues("temp1"), STEP1_INDUSTRIES.options),
      editRoute: [2, 1, 1],
    },
    {
      label: "Company",
      value: getCustomLabel(getValues("customidx")),
      editRoute: [2, 1, 2],
    },
    {
      label: "Market cap",
      value: getLabelByKey(getValues("marketcap")),
      editRoute: [2, 2, 0],
    },
    {
      label: "Exclusion",
      value: getLabel(
        getValues("business_involvements"),
        STEP1_EXCLUSIONS_BUSINESS_INVOLVMENTS.options
      ),
      editRoute: [2, 3, 0],
    },
    {
      label: "Custom exclusion",
      value: getCustomLabel(getValues("custom_exclusion")),
      editRoute: [2, 3, 0],
    },
  ];

  return (
    <WrapperCard
      id="search-summary"
      className={`${
        isFlipped ? "[transform:rotateX(180deg)]" : "transform:rotateX(0deg)"
      } h-[615px] w-full rounded-none mt-0 mx-auto p-4 my-4 flex items-center justify-center flex-col shadow-lg transition-all duration-1000 ease-in-out flip-card-inner`}
    >
      <div className="grid grid-cols-12 w-full flip-card-front">
        <div className="font-semibold text-left col-span-5 bg-my-rift-primary-green-200 py-1 rounded-l-lg pl-3">
          Criteria
        </div>
        <div className="font-semibold text-left col-span-5 bg-my-rift-primary-green-200 py-1">
          Selection
        </div>
        <div className="font-semibold text-center col-span-2 bg-my-rift-primary-green-200 py-1 rounded-r-lg">
          Edit
        </div>
        {data.map((item, index) => (
          <Fragment key={index}>
            <div className="py-2 font-semibold col-span-5 pl-3">
              {!item.value.includes("Multiple")
                ? item.label
                : item.label.slice(-1) === "y"
                ? item.label.slice(0, -1) + "ies"
                : item.label + "s"}
              :
            </div>

            <div className="py-2 col-span-5">{item.value}</div>
            <button
              onClick={() => handleEdit(...item.editRoute)}
              className="text-my-rift-primary-green-600 hover:underline py-2 text-center col-span-2"
            >
              Edit
            </button>
          </Fragment>
        ))}
      </div>
      <div className="flip-card-back overflow-hidden">
        {aboveStep === FULL_STEP && <CreatePortfolio />}
      </div>
    </WrapperCard>
  );
};

export default SearchSummary;
