import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { API_ENUMS } from "../../api/api";

import ColumnChart from "../../components/redesign/charts/ColumnChart";
import DrillDownChart from "../../components/redesign/charts/DrillDownColumnChart";
import HistoryLineChart from "../../components/redesign/charts/HistoryLineChart";
import HorizontalBarChart from "../../components/redesign/charts/HorizontalBarChart";
import ProductsServices from "../../components/redesign/charts/ProductsServices";
import SDGAlignmentChart from "../../components/redesign/charts/SDGAlignmentChart";
import EUTaxonomyCard from "../../components/redesign/portfolio_impact/EUTaxonomyCard";
import ImpactStakeHoldersAndHistory from "../../components/redesign/portfolio_impact/ImpactStakeHolderAndHistory";
import PortfolioInformationAndPsics from "../../components/redesign/portfolio_impact/PortfolioInformationAndPsics";
import WrapperCard from "../../components/ui/redesign/WrapperCard";
import { useDataContext } from "../../context/PortfolioContext";
import { isSimpleCompany } from "../../services/utilities";
import { AllRoutes } from "../Routes";
import NoDataForYear from "../../components/redesign/NoDataForYear";

const PortfolioOverview = () => {
  const [selected, setSelected] = useState(0);
  const {
    response: rawResponse,
    year,
    currentType,
    companyList,
  } = useDataContext();
  const history = useHistory();

  if (
    !rawResponse ||
    currentType != history.location.pathname ||
    companyList.length != 2
  )
    return <></>;

  if (rawResponse.currentYear != year) {
    return <NoDataForYear />;
  }
  let response = isSimpleCompany() ? rawResponse.data : rawResponse["details"];

  const portfolio1 = response?.[0].portfolioName;
  const portfolio2 = response?.[1].portfolioName;

  const impactCategories = ["Environmental", "Employee", "Customer"];
  const impactValueByCategory1 = [
    response?.[0].totalValueDrilldownByStakeholder?.environment?.[year],
    response?.[0].totalValueDrilldownByStakeholder?.employee?.[year],
    response?.[0].totalValueDrilldownByStakeholder?.customer?.[year],
  ];
  const impactValueByCategory2 = [
    response?.[1].totalValueDrilldownByStakeholder?.environment?.[year],
    response?.[1].totalValueDrilldownByStakeholder?.employee?.[year],
    response?.[1].totalValueDrilldownByStakeholder?.customer?.[year],
  ];

  const drillAllData = {
    data: [{ data: impactValueByCategory1 }, { data: impactValueByCategory2 }],
    categories: impactCategories,
  };

  const categoryChartData = {
    data: [impactValueByCategory1, impactValueByCategory2],
    categories: impactCategories,
  };

  const historyYears = Object.keys(response?.[0].totalValue);
  const historyTotalValue1 = Object.values(response?.[0].totalValue);
  const historyTotalValue2 = Object.values(response?.[1].totalValue);
  const historyEnvironmentValue1 = Object.values(
    response?.[0].totalValueDrilldownByStakeholder?.environment
  );
  const historyEnvironmentValue2 = Object.values(
    response?.[1].totalValueDrilldownByStakeholder?.environment
  );
  const historyEmployeeValue1 = Object.values(
    response?.[0].totalValueDrilldownByStakeholder?.employee
  );
  const historyEmployeeValue2 = Object.values(
    response?.[1].totalValueDrilldownByStakeholder?.employee
  );
  const historyCustomerValue1 = Object.values(
    response?.[0].totalValueDrilldownByStakeholder?.customer
  );
  const historyCustomerValue2 = Object.values(
    response?.[1].totalValueDrilldownByStakeholder?.customer
  );

  const historyAllData = [
    [historyTotalValue1, historyTotalValue2],
    [historyEnvironmentValue1, historyEnvironmentValue2],
    [historyEmployeeValue1, historyEmployeeValue2],
    [historyCustomerValue1, historyCustomerValue2],
  ];

  const euTaxonomy1 = response?.[0].EUTaxonomy?.[year] * 100;
  const euTaxonomy2 = response?.[1].EUTaxonomy?.[year] * 100;

  const totalSdg1 =
    response?.[0].ImpactResultSDGAlignment?.totalSDGImpactByYear?.[year];
  const totalSdg2 =
    response?.[1].ImpactResultSDGAlignment?.totalSDGImpactByYear?.[year];

  const sdgValues1 = Object.values(
    response?.[0].ImpactResultSDGAlignment?.SDGCategoryLevelImpactByYear
  ).map((val) => {
    if (val != null) return val?.[year];
  });
  const sdgValues2 = Object.values(
    response?.[1].ImpactResultSDGAlignment?.SDGCategoryLevelImpactByYear
  ).map((val) => {
    if (val != null) return val?.[year];
  });

  const revenueCategories1 = response?.[0].benchmarkRevenueCategories?.[
    year
  ].map((i) => ({
    name: i.productCategoryName,
    percentage: i.productCategoryRevenueShare * 100,
    impacts: i.companyList.map((c) => ({
      name: c["Company Name"],
      revenue: c["Category Revenue"],
      ratio: c["Product Category Revenue Shares"],
    })),
    relevantImpactPillars: i.relevantImpactPillars,
    productCategoryDescription: i.productCategoryDescription,
  }));
  const revenueCategories2 = response?.[1].benchmarkRevenueCategories?.[
    year
  ].map((i) => ({
    name: i.productCategoryName,
    percentage: i.productCategoryRevenueShare * 100,
    impacts: i.companyList.map((c) => ({
      name: c["Company Name"],
      revenue: c["Category Revenue"],
      ratio: c["Product Category Revenue Shares"],
    })),
    relevantImpactPillars: i.relevantImpactPillars,
    productCategoryDescription: i.productCategoryDescription,
  }));

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="col-span-1">
        <ImpactStakeHoldersAndHistory title={"Impacts by Stakeholders"} />
      </div>
      <div className="col-span-1">
        <ImpactStakeHoldersAndHistory title={"Impacts History"} />
      </div>
      <WrapperCard wideBorder={true}>
        {/*  <ColumnChart
          data={categoryChartData}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
        /> */}
        <DrillDownChart
          categories={impactCategories}
          data1={impactValueByCategory1}
          data2={impactValueByCategory2}
          allData={drillAllData}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
        />
      </WrapperCard>
      <WrapperCard wideBorder={true}>
        <HistoryLineChart
          historyYears={historyYears}
          data={historyAllData[selected]}
          selected={selected}
          setSelected={setSelected}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
        />
      </WrapperCard>
      <WrapperCard wideBorder={true}>
        <ProductsServices
          title={"Products & Services"}
          data1={revenueCategories1}
          data2={revenueCategories2}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
        />
      </WrapperCard>

      <EUTaxonomyCard
        portfolio1={portfolio1}
        portfolio2={portfolio2}
        value1={euTaxonomy1}
        value2={euTaxonomy2}
        viewMore={() => history.push(AllRoutes.company_eu_taxonomy)}
      />
      <WrapperCard wideBorder={true}>
        <SDGAlignmentChart
          title={"SDG Alignment"}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
          total1={totalSdg1}
          total2={totalSdg2}
          data1={sdgValues1}
          data2={sdgValues2}
          viewMore={() => history.push(AllRoutes.company_sdg_alignment)}
        />
      </WrapperCard>
    </div>
  );
};

export default PortfolioOverview;
