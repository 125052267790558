import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { AllRoutes } from "../../../pages/Routes";
import { formatNumber, isSimpleCompany } from "../../../services/utilities";
import ShowMore from "../../ui/redesign/ShowMore";
import ToolTipRe from "../../ui/redesign/ToolTip";

import ShortDescriptionInfo from "../portfolio_impact/ShortDescriptionInfo";

export const sdg_color = [
  "#E5243B",
  "#DDA83A",
  "#4C9F38",
  "#26BDE2",
  "#FD6925",
  "#FF3A21",
  "#C5192D",
  "#FCC30B",
  "#A21942",
  "#DD1367",
  "#FD9D24",
  "#BF8B2E",
  "#3F7E44",
  "#0A97D9",
  "#56C02B",
  "#00689D",
  "#19486A",
];

export const sdg_names = [
  "No Poverty",
  "Zero Hunger",
  "Good Health and Well-being",
  "Quality Education",
  "Gender Equality",
  "Clean Water and Sanitation",
  "Affordable and Clean Energy",
  "Decent Work and Economic Growth",
  "Industry, Innovation and Infrastructure",
  "Reduced Inequality",
  "Sustainable Cities and Communities",
  "Responsible Consumption and Production",
  "Climate Action",
  "Life Below Water",
  "Life on Land",
  "Peace, Justice and Strong Institutions",
  "Partnerships for the Goals",
];
export const sdg_descriptions = [
  "End poverty in all its forms everywhere",
  "End hunger, achieve food security and improved nutrition, and promote sustainable agriculture",
  "Ensure healthy lives and promote well-being for all at all ages",
  "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all",
  "Achieve gender equality and empower all women and girls",
  "Ensure availability and sustainable management of water and sanitation for all",
  "Ensure access to affordable, reliable, sustainable, and modern energy for all",
  "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all",
  "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation",
  "Make cities and human settlements inclusive, safe, resilient and sustainable",
  "Reduce inequality within and among countries",
  "Ensure sustainable consumption and production patterns",
  "Take urgent action to combat climate change and its impacts",
  "Conserve and sustainably use the oceans, seas, and marine resources for sustainable development",
  "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss",
  "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable, and inclusive institutions at all levels",
  "Strengthen the means of implementation and revitalize the global partnership for sustainable development",
];

const SDGAlignmentChart = ({
  title,
  portfolio1,
  portfolio2,
  data1,
  data2,
  total1,
  total2,
  viewMore
}) => {
  const history = useHistory();

  let SDGNumbers1 = [];
  let SDGNumbers2 = [];

  for (let i = 0; i < 17; i++) {
    const item1 = {
      price: data1[i],
      name: sdg_names[i],
      description: sdg_descriptions[i],
      value: data1[i] < 0 ? -1 : data1[i] > 0 ? 1 : 0,
      id: i,
    };
    const item2 = {
      price: data2[i],
      name: sdg_names[i],
      description: sdg_descriptions[i],
      value: data2[i] < 0 ? -1 : data2[i] > 0 ? 1 : 0,
      id: i,
    };
    SDGNumbers1.push(item1);
    SDGNumbers2.push(item2);
  }

  const colorByValue = (val) => {
    switch (val) {
      case 0:
        return "bg-my-tailwind-gray-400";
      case -1:
        return "bg-my-tailwind-red-500";
      case 1:
        return "bg-my-tailwind-green-500";
    }
  };

  return (
    <div>
      <div className="w-full flex items-center justify-between mb-6 ">
        <div className="flex items-center gap-x-2">
          <img src="/redesign/nav/sdg.svg" />
          <p className=" text-sm font-bold leading-tight text-gray-600">
            {title}
          </p>
        </div>

        <ShowMore onClick={viewMore} />
      </div>
      {/* TODO IF WE WANT TO LET WRAP      grid grid-cols-2 gap-x-6 w-full
       */}{" "}
      <div className="flex justify-evenly w-full flex-col md:flex-row gap-y-8">
        {[1, 2].map((item, idx) => {
          const sdgNumbers = idx == 0 ? SDGNumbers1 : SDGNumbers2;
          return (
            <div className="col-span-1 flex flex-col justify-center gap-y-6 px-8 md:max-w-[240px]">
              <div className="inline-flex space-x-2 items-center justify-start">
                <img
                  className="w-4 h-4"
                  src="/redesign/portfolio_impact/portfolio.svg"
                />
                <p className="text-sm font-bold leading-tight text-gray-600 break-all">
                  {idx == 0 ? portfolio1 : portfolio2}
                </p>
                <div
                  className={`w-5 h-1.5  ${
                    idx == 0
                      ? "bg-my-tailwind-light-blue-500"
                      : "bg-my-tailwind-purple-500"
                  } rounded-full`}
                />
              </div>
              <div className="flex flex-wrap justify-center items-center gap-4">
                {sdgNumbers.map((item) => {
                  const isNegative = item.price < 0;
                  return (
                    <ToolTipRe
                      key={item.id}
                      onTop={true}
                      disabled={item.value == 0}
                      toolTipBase={
                        <div
                          className={`w-8 h-8 flex justify-center items-center rounded-lg ${colorByValue(
                            item.value
                          )} ${item.value == 0 ? "" : "hover:bg-[#DDA83A]"} `}
                        >
                          <img
                            className="w-6 h-6"
                            src={`/redesign/sdg/Layer_1-${item.id}.svg`}
                          />
                        </div>
                      }
                    >
                      <div className="flex flex-col w-64 items-start">
                        <div className="flex items-center gap-x-2 mb-4">
                          <div
                            style={{
                              backgroundColor: sdg_color[item.id],
                            }}
                            className={`w-8 h-8 flex justify-center items-center rounded-lg `}
                          >
                            <img
                              className="w-6 h-6"
                              src={`/redesign/sdg/Layer_1-${item.id}.svg`}
                            />
                          </div>
                          <p className="text-xs font-bold leading-none text-gray-600">
                            {item.name}
                          </p>
                        </div>
                        <p className="mb-1 text-xs leading-none text-gray-500">
                          {"SDG Alignment"}
                        </p>
                        <p
                          className={`text-3xl font-bold leading-9 text-center ${
                            isNegative
                              ? "text-my-tailwind-red-500"
                              : "text-my-tailwind-green-500"
                          } `}
                        >
                          $ {formatNumber(item.price)}
                        </p>
                      </div>
                    </ToolTipRe>
                  );
                })}
              </div>
              <div>
                <p className="text-sm leading-tight text-center text-my-tailwind-gray-500 mb-1">
                  {isSimpleCompany()
                    ? "Total SDG Alignment per $ of revenue"
                    : "Total SDG Alignment"}
                </p>
                <p
                  className={`text-3xl font-bold leading-9 text-center ${
                    (idx == 0 ? total1 : total2) < 0
                      ? "text-my-tailwind-red-500"
                      : "text-my-tailwind-green-500"
                  } text-my-tailwind-green-500`}
                >
                  ${formatNumber(idx == 0 ? total1 : total2)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-4">
        <ShortDescriptionInfo
          description={
            <p>
              See how the revenue of these portfolios are aligned or misaligned
              with the{" "}
              <a
                style={{
                  textDecoration: "underline",
                }}
                href="https://sdgs.un.org/"
                target="_blank"
                rel="noreferrer"
              >
                United Nations Sustainable Development Goals
              </a>
              . Our model estimates the alignment based on the products and
              services these companies produce.
            </p>
          }
        />
      </div>
    </div>
  );
};

export default SDGAlignmentChart;
