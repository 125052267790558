import React, { useState, useEffect, useContext } from "react";
import { Dialog } from "@headlessui/react";
import { useQuery } from "react-query";

import { useDataContext } from "../../context/PortfolioContext";
import {
  API_ENUMS,
  fetchCompanyImpactCategory,
  fetchCompanyOverview,
} from "../../api/api";
import PortfolioOverview from "./PortfolioOverview";
import EnvironmentalImpact from "./EnvironmentalImpact";
import EmployeesImpact from "./EmployeesImpact";
import CustomerImpact from "./CustomerImpact";
import PortfolioInformation from "./PortfolioInformation";
import IWAI from "../../components/redesign/portfolio_impact/IWAI";
import TabOverviewSelector from "../../components/redesign/portfolio_impact/TabOverviewSelector";
import Tabs from "../../components/redesign/portfolio_impact/Tabs";
import { AllRoutes } from "../Routes";
import LoadingErrorWrapper from "../../api/LoadingErrorWrapper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function CompanyImpactDialog({ isOpen, onClose, company }) {
  const [currentTab, setCurrentTab] = useState(API_ENUMS.overview);
  const [currentTabIdx, setCurrentTabIdx] = useState(0);

  const history = useHistory();

  const {
    year,
    initialYear,
    companyList,
    setAllYears,
    setCompanyList,
    setResponse,
    setCurrentType,
    handleClearData,
    setSelectedCompanies,
    isCompanyData,
  } = useDataContext();
  const fetchImpactData = (tab) => {
    return tab === API_ENUMS.overview
      ? fetchCompanyOverview([company], year)
      : fetchCompanyImpactCategory(tab, [company], year);
  };

  const { data, isLoading, error } = useQuery(
    ["companyImpact", currentTab, company, year],
    () => fetchImpactData(currentTab, year),
    { enabled: isOpen }
  );

  useEffect(() => {
    if (data) {
      //setResponse(data);
      setCurrentType(history.location.pathname);
    }
  }, [data, currentTab, setResponse, setCurrentType]);

  const switchHandleTab = (tab) => {
    switch (tab) {
      case AllRoutes.company_overview:
      case AllRoutes.private_company_overview:
        return API_ENUMS.overview;
      case AllRoutes.company_environmental_impact:
      case AllRoutes.private_company_environmental_impact:
        return API_ENUMS.environment;
      case AllRoutes.company_employees_impact:
      case AllRoutes.private_company_employees_impact:
        return API_ENUMS.employee;
      case AllRoutes.company_customer_impact:
      case AllRoutes.private_company_customer_impact:
        return API_ENUMS.customer;
      case AllRoutes.company_information:
        return API_ENUMS.information;
      default:
        break;
    }
  };

  const handleTabChange = (tab, idx) => {
    setCurrentTab(switchHandleTab(tab));
    setCurrentTabIdx((_) => idx);
  };

  const getPageComponent = () => {
    switch (currentTab) {
      case API_ENUMS.overview:
        return <PortfolioOverview />;
      case API_ENUMS.environment:
        return <EnvironmentalImpact />;
      case API_ENUMS.employee:
        return <EmployeesImpact />;
      case API_ENUMS.customer:
        return <CustomerImpact />;
      case API_ENUMS.information:
        return <PortfolioInformation />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (data) {
      if (currentTab == API_ENUMS.overview) {
        setCompanyList(
          data.map((c) => ({
            name: c.portfolioName,
            percentage: c.transparencyScore * 100,
            value: c.totalValue[year],
          }))
        );
        setAllYears(
          Object.keys(data?.[0].totalValue)
            .map((i, idx) => ({
              id: idx,
              name: `${i}`,
            }))
            .reverse()
        );
      } else if (currentTab == API_ENUMS.information) {
        setCompanyList(data.details);
        setAllYears(
          Object.keys(
            data?.details[0].totalValueDrilldownByStakeholder.customer
          )
            .map((i, idx) => ({
              id: idx,
              name: `${i}`,
            }))
            .reverse()
        );
      } else {
        setCompanyList(
          data.portfolioComparison.map((c) => ({
            name: c.portfolioName,
            percentage: c.transparencyScore * 100,
            value:
              c.portfolioImpactResults.totalValueDrilldownByStakeholder[
                currentTab
              ][year],
          }))
        );
        setAllYears(
          Object.keys(
            data.portfolioComparison?.[0].portfolioImpactResults.totalValue
          )
            .map((i, idx) => ({
              id: idx,
              name: `${i}`,
            }))
            .reverse()
        );
      }
      //console.log(privateCompany, selectedCompanies, "SETREPONSE", data);
      setResponse(data);
      //console.log("setresp");
      //setCurrentType(history.location.pathname);
      /* TODO ONLY SAVE THE YEAR setResponse(data.map((c)=>({
        
        EUTaxonomy: c.EUTaxonomy?.[year],

      }))); */
    } else {
    }
  }, [data, year]);

  const titleByRoute = () => {
    switch (currentTab) {
      case API_ENUMS.overview:
        return "Company overview";
      case API_ENUMS.environment:
        return "Environmental impact";
      case API_ENUMS.employee:
        return "Employee impact";
      case API_ENUMS.customer:
        return "Customer impact";
      case API_ENUMS.information:
        return <PortfolioInformation />;
      default:
        return null;
    }
  };

  if (isLoading || error)
    return (
      <LoadingErrorWrapper mini={true} isLoading={isLoading} error={error} />
    );

  return (
    <div className="bg-white p-6 rounded-lg max-w-6xl mx-auto">
      <div
        onClick={onClose}
        className="absolute top-0 right-0 p-4 cursor-pointer"
      >
        <img src="/redesign/portfolio_impact/cancel.svg" />
      </div>
      <Tabs
        idx={2}
        currentTab={currentTab}
        onChangeTab={handleTabChange}
        currentTabIdx={currentTabIdx}
      />
      <p className="text-lg font-bold leading-7 text-gray-600 w-full">
        {titleByRoute()}
      </p>
      {/*       <TabOverviewSelector title={titleByRoute()} />
       */}{" "}
      {isLoading ? (
        <LoadingErrorWrapper mini={true} isLoading={isLoading} />
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        getPageComponent()
      )}
      {/*    <div>
        szia
        {PageComponent}
      </div> */}
      <IWAI title="We are aligned with impact-weighted accounting principles. Check out ..." />
    </div>
  );
}

export default CompanyImpactDialog;
