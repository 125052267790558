import React from "react";
import ChartLegend from "../../ui/redesign/ChartLegend";
import ShowMore from "../../ui/redesign/ShowMore";
import WrapperCard from "../../ui/redesign/WrapperCard";
import SemiCircleChart from "../charts/SemiCircleChart";
import { useHistory } from "react-router-dom";
import { AllRoutes } from "../../../pages/Routes";
import ShortDescriptionInfo from "./ShortDescriptionInfo";
import { isSimpleCompany } from "../../../services/utilities";

const EUTaxonomyCard = ({ portfolio1, portfolio2, value1, value2, viewMore }) => {
  const history = useHistory();

  return (
    <WrapperCard wideBorder={true} className={"eutaxanomy"}>
      <div className="flex flex-col gap-y-6 bg-white">
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <img src="/redesign/nav/info.svg" />
            <p className=" text-sm font-bold leading-tight text-gray-600">
              EU Taxonomy
            </p>
          </div>
          <ShowMore onClick={viewMore} />
        </div>
        <div className="inline-flex w-full items-center justify-evenly">
          <div>
            <SemiCircleChart progress={value1 / 100} />
            <p className="text-2xl font-extrabold leading-7 text-center -mt-14 text-my-tailwind-green-500">
              {value1.toFixed(2)}%
            </p>
            <div className="inline-flex mt-6 mx-auto w-full space-x-1 items-center justify-center">
              <div className="w-5 h-1.5 bg-my-tailwind-light-blue-500 rounded-full" />
              <p className="text-xs leading-none text-gray-600">{portfolio1}</p>
            </div>
          </div>
          <div>
            <SemiCircleChart progress={value2 / 100} />
            <p className="text-2xl font-extrabold leading-7 text-center -mt-14 text-my-tailwind-green-500">
              {value2.toFixed(2)}%
            </p>
            <div className="inline-flex mt-6 mx-auto w-full space-x-1 items-center justify-center">
              <div className="w-5 h-1.5 bg-my-tailwind-purple-500 rounded-full" />
              <p className="text-xs leading-none text-gray-600">{portfolio2}</p>
            </div>
          </div>
        </div>

        <div className="inline-flex gap-x-2 items-start justify-start p-4 bg-gray-50 rounded-lg">
          <img
            className="w-6 h-6 rounded-lg"
            src="/redesign/portfolio_impact/info_outline.svg"
          ></img>
          <p className="text-sm leading-normal text-gray-500">
            {isSimpleCompany()
              ? `The 
percentage of revenues from EU sustainable economic activities. A low 
percentage means that the portfolio's or company's revenues are mostly from 
activities that are not defined to be relevant to sustainability.`
              : `The EU taxonomy for sustainable activities is a list of economic
            activities with performance criteria for their contribution to six
            environmental objectives.`}
          </p>
        </div>
      </div>
    </WrapperCard>
  );
};

export default EUTaxonomyCard;
