import React, { useEffect, useState } from "react";

function FileDropZone({ register, setValue, getValues, fieldName }) {
  const [file, setFile] = useState("");
  const [isUploading, setIsUploading] = useState([]);

  useEffect(() => {
    const file = getValues(fieldName);
    setFile(file);
    setValue(fieldName, file);

    if (file) console.log(file.name);
  }, []);

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleUpload = (files) => {
    try {
      setIsUploading(true);
      setFile(files[0]);
      setValue(fieldName, files[0]);
      console.log(files[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  };

  //use these functions on drag events
  const [isDragActive, setIsDragActive] = useState(false);

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);
    const files = Array.from(e.dataTransfer.files);
    handleUpload(files);
  };

  return (
    <div>
      {!file && (
        <div
          className={`flex w-full flex-col justify-center items-center h-48 border-2 border-dashed rounded-lg p-5
      ${
        isDragActive
          ? " bg-my-rift-primary-green-100"
          : "border-my-rift-primary-green-600 bg-gray-50"
      } hover:bg-my-rift-primary-green-100 cursor-pointer`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          onClick={() => document.getElementById("file-upload").click()}
        >
          <input
            //{...register("report")}
            name="report"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleUpload(e.target.files)}
            id="file-upload"
          />
          <div>
            {isDragActive && (
              <p className="text-center text-gray-500 mt-4">
                Leave your file here
              </p>
            )}
            {!isDragActive && (
              <div>
                <div className="z-10">
                  <img
                    src="/icon/upload_illustration.svg"
                    alt="placeholder"
                    className="mx-auto"
                  />
                </div>
                <p className="text-center text-gray-500 mt-4">
                  <span className="text-my-rift-primary-green-500 font-semibold">
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {/*       {isUploading && <div>Uploading...</div>}
       */}{" "}
      {file && (
        <div className="mt-4 flex items-center justify-between bg-gray-100 px-4 py-1 rounded-xl">
          <div className="items-center flex gap-x-2">
            <img
              src="/icon/file_upload.svg"
              className="bg-white p-2 rounded-lg"
            />
            <div className="flex flex-col gap-y-1">
              <p className="text-gray-700 font-semibold">{file.name}</p>
              <p className="text-gray-500">{file.size} KB</p>
            </div>
          </div>
          <button
            onClick={handleRemoveFile}
            className="text-my-rift-primary-green-500 font-semibold"
          >
            X
          </button>
        </div>
      )}
    </div>
  );
}

export default FileDropZone;
