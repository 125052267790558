import React from "react";
import { isSimpleCompany } from "../../../services/utilities";
import ToolTipRe from "../../ui/redesign/ToolTip";
import { useLocation } from "react-router-dom";

const ImpactStakeHoldersAndHistory = ({ title, tooltip, isDollar }) => {
  const location = useLocation();
  const isEmployee = location.pathname.includes("company-employees");

  return (
    <div>
      <p className="text-lg font-bold leading-7 text-gray-600 mb-0.5">
        {title}
      </p>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <p className="text-xs leading-none text-gray-600">
            {isDollar
              ? "Monetized impact per Employee"
              : isEmployee
              ? "Monetized impact per $ of revenue"
              : isSimpleCompany()
              ? "Monetized impact per $ of revenue"
              : " Monetized impact in absolute $ terms"}
          </p>
          <ToolTipRe
            width={"450px"}
            onTop={false}
            toolTipBase={
              <img src="/redesign/portfolio_impact/info_outline.svg" />
            }
          >
            <p className="text-sm font-medium leading-normal text-gray-600">
              RGS measures and values the impacts that companies have on society
              and the environment using US Dollars as units. For example, every
              additional ton of carbon emitted has an impact on society that can
              be priced and quantified using social costs.
            </p>
          </ToolTipRe>
        </div>
      </div>
    </div>
  );
};

export default ImpactStakeHoldersAndHistory;
