import React from "react";
import { useCustomPortfolio } from "../../../context/CustomPortfolioContext";

export default function AddRowRenderer(props) {
  const { addRow } = useCustomPortfolio();

  const emptyRow = {
    id: 0,
    securityId: "-",
    position: 0,
    status: "pending",
  };

  return (
    <span
      style={{
        height: "52px",
        marginLeft: "32px",
      }}
      onClick={() => addRow(emptyRow)}
      className="flex gap-x-3 items-center text-my-tailwind-gray-900 font-semibold cursor-pointer"
    >
      <img src="/icon/add_row.svg" alt="add" />
      <p>Add new row</p>
    </span>
  );
}
