import React, { useCallback, useState } from "react";
import FeatureSoon from "../components/redesign/FeatureSoon";
import TopTab from "../components/redesign/psics/TopTab";
import WrapperCard from "../components/ui/redesign/WrapperCard";
import { useDataContext } from "../context/PortfolioContext";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import PrimaryButton from "../components/ui/redesign/PrimaryButton";
import { useHistory } from "react-router-dom";
import { AllRoutes } from "./Routes";
import ContactUs from "./ContactUs";

const filtersType = ["everything", "portfolio", "myportfolio", "company"];

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { selectedCompanies, allSearchData } = useDataContext();

  const tabs = [
    { icon: "/redesign/portfolio_impact/portfolio.svg", name: "My Portfolios" },
    { icon: "/redesign/general/indices.svg", name: "Indices" },
    { icon: "/redesign/general/Officebuilding.svg", name: "Companies" },
    { icon: "/redesign/general/Clipboard.svg", name: "Saved Comparisons" },
  ];
  const handleTabChange = (tab) => {
    setActiveTab((prev) => tab);
  };

  const groupData = useCallback(() => {
    const portfolio = [];
    const company = [];
    const myportfolio = [];
    const indices = [];

    allSearchData.forEach((item) => {
      switch (item.type) {
        case "portfolio":
          portfolio.push(item);
          break;
        case "company":
          company.push(item);
          break;
        case "myportfolio":
          myportfolio.push(item);
          break;
        case "indices":
          indices.push(item);
          break;
        default:
          break;
      }
    });

    const result = [portfolio, indices, company, myportfolio];
    return result;
  }, [allSearchData]);

  const history = useHistory();
  if (!allSearchData) return <></>;

  const result = groupData();
  return (
    <div className="w-full m-8">
      <div className="w-full flex items-center gap-x-6 mb-8">
        <WrapperCard className="p-[3px]">
          <PrimaryButton
            iconUrl={"/redesign/general/upload-flow.svg"}
            text="Upload your portfolio"
            onClick={() => history.push(AllRoutes.contact_us)}
          />
        </WrapperCard>
        <WrapperCard className="p-[3px]">
          <PrimaryButton
            iconUrl={"/redesign/general/build-flow.svg"}
            text="Build your portfolio"
            // TEMPORARY REMOVAL
            //onClick={() => history.push(AllRoutes.create_portfolio)}
            onClick={() => history.push(AllRoutes.contact_us)}
          />
        </WrapperCard>
      </div>
      <WrapperCard wideBorder={true}>
        <TopTab tabs={tabs} active={activeTab} onChange={handleTabChange} />
        <div className="w-full flex flex-col gap-y-2.5 mt-6">
          {activeTab == 1 || activeTab == 3 ? (
            <ContactUs />
          ) : (
            result[activeTab].map((item, idx) => (
              <div
                key={item.id}
                className="shadow-my-sm border-[6px] border-white bg-my-tailwind-gray-50 p-4 rounded-2xl flex w-full justify-between items-center"
              >
                <div className="flex w-fit gap-x-2">
                  <img className="w-6 h-6" src={tabs[activeTab].icon} />
                  <div>
                    <p className="text-sm font-bold leading-tight text-gray-600">
                      {item.id}
                    </p>
                    <p className="text-base leading-tight text-left mb-2 text-gray-400 mt-1">
                      Modified: 27/05/2024
                    </p>
                  </div>
                </div>

                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center rounded-md   text-base font-medium  hover:text-opacity-100 focus:outline-none `}
                      >
                        <img
                          src="/redesign/general/more-vert.svg"
                          className="cursor-pointer"
                        />
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 mt-3 w-32 max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative flex flex-col bg-white">
                              <div className="flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                Edit
                              </div>
                              <div className="flex text-red-400 items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                Delete
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            ))
          )}
        </div>
      </WrapperCard>
    </div>
  );
};

export default Dashboard;
