import React, { useState } from "react";
import WrapperCard from "../../components/ui/redesign/WrapperCard";
import ColumnChart from "../../components/redesign/charts/ColumnChart";
import HistoryLineChart from "../../components/redesign/charts/HistoryLineChart";
import ImpactStakeHoldersAndHistory from "../../components/redesign/portfolio_impact/ImpactStakeHolderAndHistory";
import LeadersLaggards from "../environmental_impact/LeadersLaggards";
import RawDataSection from "../customer_impact/RawDataSection";
import { useDataContext } from "../../context/PortfolioContext";
import { AllRoutes } from "../Routes";
import { API_ENUMS } from "../../api/api";
import DrillDownChart from "../../components/redesign/charts/DrillDownColumnChart";
import { isSimpleCompany } from "../../services/utilities";
import { useHistory } from "react-router-dom";
import NoDataForYear from "../../components/redesign/NoDataForYear";

const EmployeesImpact = () => {
  const { response, year, companyList, currentType } = useDataContext();
  const [selected, setSelected] = useState(2);
  const [isDollar, setIsDollar] = useState(false);

  const history = useHistory();
  if (
    !response ||
    currentType != history.location.pathname ||
    companyList.length != 2
  )
    return <></>;

  if (response.currentYear != year) {
    return <NoDataForYear />;
  }

  const portfolio1 = companyList?.[0].name;
  const portfolio2 = companyList?.[1].name;

  const historyYears = Object.keys(
    response?.portfolioComparison?.[0].portfolioImpactResults.totalValue
  );
  const historyTotalValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults.totalValue
  );
  const historyTotalValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults.totalValue
  );
  const historyEnvironmentValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.environment
  );
  const historyEnvironmentValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.environment
  );
  const historyEmployeeValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.employee
  );
  const historyEmployeeValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.employee
  );
  const historyEmployeeDollarValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.employmentPerDollarOfRevenue
  );
  const historyEmployeeDollarValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.employmentPerDollarOfRevenue
  );
  const historyCustomerValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.customer
  );
  const historyCustomerValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.customer
  );

  const historyAllData = [
    [historyTotalValue1, historyTotalValue2],
    [historyEnvironmentValue1, historyEnvironmentValue2],
    [historyEmployeeValue1, historyEmployeeValue2],
    [historyCustomerValue1, historyCustomerValue2],
    [historyEmployeeDollarValue1, historyEmployeeDollarValue2],
  ];

  const envAllCategory = Object.keys(
    response?.portfolioComparison?.[0]?.portfolioImpactResults
      .stakeholderSpecificData.pillarLevelImpactValues[year]
  );
  const envCategoryData1 = Object.values(
    response?.portfolioComparison?.[0]?.portfolioImpactResults
      .stakeholderSpecificData.pillarLevelImpactValues[year]
  );
  const envCategoryData2 = Object.values(
    response?.portfolioComparison?.[1]?.portfolioImpactResults
      .stakeholderSpecificData.pillarLevelImpactValues[year]
  );

  const envAllDrill =
    response?.portfolioComparison?.[0]?.portfolioImpactPillarDrilldown[year];

  const categoryChartData = {
    data: [envCategoryData1, envCategoryData2],
    categories: envAllCategory,
  };

  let laggards1 =
    !isSimpleCompany() &&
    Object.entries(
      response?.portfolioComparison?.[0]?.portfolioImpactResults
        .stakeholderSpecificData.laggardCompanies?.[year]
    ).map(([name, value]) => ({ name, value }));
  let leaders1 =
    !isSimpleCompany() &&
    Object.entries(
      response?.portfolioComparison?.[0]?.portfolioImpactResults
        .stakeholderSpecificData.leaderCompanies?.[year]
    ).map(([name, value]) => ({ name, value }));
  const laggards2 = Object.entries(
    response?.portfolioComparison?.[1]?.portfolioImpactResults
      .stakeholderSpecificData.laggardCompanies?.[year]
  ).map(([name, value]) => ({ name, value }));
  const leaders2 = Object.entries(
    response?.portfolioComparison?.[1]?.portfolioImpactResults
      .stakeholderSpecificData.leaderCompanies?.[year]
  ).map(([name, value]) => ({ name, value }));

  laggards1 = laggards1 || laggards2;
  leaders1 = leaders1 || leaders2;

  return (
    <div className="grid grid-cols-2 gap-8">
      <div className="col-span-1">
        <ImpactStakeHoldersAndHistory title={"Impacts by Stakeholders"} />
      </div>
      <div className="col-span-1">
        <ImpactStakeHoldersAndHistory
          title={"Impacts History"}
          isDollar={isDollar}
        />
      </div>
      <WrapperCard wideBorder={true}>
        <DrillDownChart
          categories={envAllCategory}
          data1={envCategoryData1}
          data2={envCategoryData2}
          allData={envAllDrill}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
        />
        {/*   <ColumnChart
          data={categoryChartData}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
        />{" "} */}
      </WrapperCard>
      <WrapperCard wideBorder={true}>
        <HistoryLineChart
          historyYears={historyYears}
          data={historyAllData[selected]}
          selected={selected}
          setSelected={setSelected}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
          isCompanyView={true}
          isDollar={isDollar}
          setIsDollar={setIsDollar}
        />
      </WrapperCard>
      <WrapperCard wideBorder={true}>
        <LeadersLaggards
          title={"Leaders in Employee Impact"}
          isPositive={true}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
          data1={leaders1}
          data2={leaders2}
        />
      </WrapperCard>
      <WrapperCard wideBorder={true}>
        <LeadersLaggards
          title={"Laggards in Employee Impact"}
          isPositive={false}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
          data1={laggards1}
          data2={laggards2}
        />
      </WrapperCard>
      <div className="col-span-2">
        <WrapperCard wideBorder={true}>
          <RawDataSection
            title={"Underlying Raw data"}
            compare1={"ESG Portfolio"}
            compare2={"Benchmark portfolio"}
          />
        </WrapperCard>
      </div>
    </div>
  );
};

export default EmployeesImpact;
