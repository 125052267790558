import React from "react";
import ShowMore from "../../components/ui/redesign/ShowMore";

const RawDataSection = ({ title, compare1, compare2 }) => {
  const datas = [
    {
      name: "GHG Emissions",
      value: "30.5",
      unit: "tonnes of CO2",
      investmentGoal:
        "you reduce emmissions worth the average yearly emmissions of ",
      goalInHouseHold: 30,
    },
    {
      name: "Water consumed from high water stress regions",
      value: "-33000.3",
      unit: "m2",
      investmentGoal:
        "you create additional water consumption in high water stress regions worth the average yearly water consumption of ",
      goalInHouseHold: 30,
    },
    {
      name: "Plastic packaging",
      value: "-145.6",
      unit: "tonnes",
      investmentGoal:
        "you create additional plastic packaging worth the yearly average of",
      goalInHouseHold: 30,
    },
    {
      name: "Food waste",
      value: "145.5",
      unit: "tonnes",
      investmentGoal:
        "you create additional food waste worth the yearly average of ",
      goalInHouseHold: 30,
    },
  ];

  return (
    <div>
      <div className="w-full flex items-center justify-between mb-6 ">
        <div className="flex items-center gap-x-2">
          <img src="/redesign/nav/raw_data.svg" />
          <p className=" text-sm font-bold leading-tight text-gray-600">
            {title}
          </p>
        </div>

        <ShowMore />
      </div>
      <div className="w-full grid grid-cols-1 gap-y-4 md:flex md:justify-between blur-[5px]">
        {datas.map((data) => {
          const isNegative = data.value < 0;

          return (
            <div className="flex flex-col w-full mx-0 md:mx-4">
              <div className="bg-my-tailwind-gray-50 py-4 flex flex-col items-center justify-center h-fit w-full rounded-2xl px-3">
                <img src="/redesign/nav/info.svg" />
                <p className="text-sm leading-4 text-center font-medium text-my-tailwind-gray-600 mt-2 mb-8 ">
                  {data.name}
                  {data.unit == null ? "%" : ""}
                </p>
                <p
                  className={`${
                    isNegative
                      ? "text-my-tailwind-red-500"
                      : "text-my-tailwind-green-500"
                  }  text-2xl leading-8 font-bold mb-1 tracking-wider`}
                >
                  {Math.abs(data.value)}
                </p>
                <p className="text-sm text-center leading-4 font-medium text-my-tailwind-gray-600">
                  {data.unit}
                </p>
              </div>
              <div className="my-6 flex flex-col gap-y-2 mx-auto items-center px-4">
                <img
                  className="h-14"
                  src={`/redesign/raw/${
                    isNegative ? "raw_emoji_negative" : "raw_emoji_positive"
                  }.svg`}
                />
                <p className="text-sm leading-4 font-medium text-my-tailwind-gray-600">
                  By investing in
                </p>
                <div className="flex gap-x-1 items-center">
                  <div
                    className={`flex shrink-0 rounded-full w-2 h-2 bg-my-tailwind-light-blue-500`}
                  ></div>
                  <p className="text-sm leading-5 font-bold text-my-tailwind-gray-600">
                    {compare1}
                  </p>
                </div>
                <p className="text-sm leading-4 font-medium text-my-tailwind-gray-600">
                  instead of{" "}
                </p>
                <div className="flex gap-x-1 items-center">
                  <div
                    className={`flex shrink-0 rounded-full w-2 h-2 bg-my-tailwind-purple-500`}
                  ></div>
                  <p className="text-sm leading-5 font-bold text-my-tailwind-gray-600">
                    {compare2}
                  </p>
                </div>
                <p className="text-sm leading-4 text-center font-medium text-my-tailwind-gray-600">
                  {data.investmentGoal}
                </p>
              </div>
              <div className="w-full flex justify-center items-center gap-x-2.5">
                <img src="/redesign/raw/HouseHold.svg" />
                <p className="text-lg leading-7 font-semibold text-my-tailwind-gray-600">
                  X
                </p>
                <p className="text-2xl leading-8 font-semibold text-my-tailwind-gray-600">
                  {data.goalInHouseHold}
                </p>
              </div>
              <p className="text-sm leading-4 font-medium text-center mx-auto text-my-tailwind-gray-600 mt-2">
                UK Household
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RawDataSection;
