import { createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    items: [],
    isLoading: false,
  },
  reducers: {
    setItems(state, action) {
      state.items = action.payload;
      state.isLoading = false;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const themeActions = themeSlice.actions;

export default themeSlice;