import React from "react";

const ChartTypeSelector = ({ list, showActive, handleClick }) => {
  return (
    <div className="-mt-2 flex items-center gap-x-2">
      {list.map((item, index) => (
        <p
          key={index}
          className={`text-base font-semibold leading-none text-gray-600 cursor-pointer ${
            showActive == index ? "text-my-rift-primary-green-400" : ""
          }`}
          onClick={() => handleClick(index)}
        >
          {item}
        </p>
      ))}
    </div>
  );
};

export default ChartTypeSelector;
