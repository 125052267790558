import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { twMerge } from "tailwind-merge";
import { formatNumber } from "../../../services/utilities";

/* const mockData = {
  2015: {
    Urbanization: 1.0,
    "Transportation Innovation": 1.0,
    "Sustainable Agriculture": 0.98,
    "Transition Material Exposure": 0.95,
    "Resource Efficiency": 0.87,
    "Human Mobility": 0.81,
    "Medical Technologies": 0.76,
    "Transition Indirect Exposure": 0.75,
    "Advanced Therapies": 0.73,
    "Automation/Robotics": 0.72,
    "Financial Inclusion": 0.6,
    "Climate Transition": 0.36,
    "Material Science Innovations": 0.32,
    "Datafication/Digitization": 0.3,
    "Carbon Reduction and Removal": 0.25,
    "Mental Wellbeing": 0.24,
    "Transition Solutions": 0.23,
    "Leisure/Extended Reality": 0.16,
    "Artificial Intelligence/Machine Learning": 0.06,
    "Renewable/Alternative Energy": 0.02,
  },
  2016: {
    "Carbon Reduction and Removal": 0.96,
    "Human Mobility": 0.93,
    "Transition Material Exposure": 0.91,
    "Sustainable Agriculture": 0.86,
    "Artificial Intelligence/Machine Learning": 0.85,
    "Leisure/Extended Reality": 0.82,
    "Material Science Innovations": 0.75,
    "Mental Wellbeing": 0.72,
    "Climate Transition": 0.65,
    "Resource Efficiency": 0.57,
    "Advanced Therapies": 0.45,
    Urbanization: 0.44,
    "Transition Indirect Exposure": 0.4,
    "Transportation Innovation": 0.33,
    "Financial Inclusion": 0.25,
    "Automation/Robotics": 0.13,
    "Renewable/Alternative Energy": 0.05,
    "Medical Technologies": 0.04,
    "Datafication/Digitization": 0.03,
    "Transition Solutions": 0.01,
  },
  2017: {
    "Automation/Robotics": 0.99,
    "Mental Wellbeing": 0.98,
    "Transportation Innovation": 0.98,
    Urbanization: 0.95,
    "Resource Efficiency": 0.91,
    "Leisure/Extended Reality": 0.75,
    "Renewable/Alternative Energy": 0.73,
    "Transition Material Exposure": 0.73,
    "Advanced Therapies": 0.7,
    "Transition Indirect Exposure": 0.68,
    "Financial Inclusion": 0.65,
    "Material Science Innovations": 0.64,
    "Medical Technologies": 0.63,
    "Carbon Reduction and Removal": 0.51,
    "Datafication/Digitization": 0.44,
    "Human Mobility": 0.42,
    "Climate Transition": 0.35,
    "Sustainable Agriculture": 0.34,
    "Transition Solutions": 0.26,
    "Artificial Intelligence/Machine Learning": 0.2,
  },
  2018: {
    "Leisure/Extended Reality": 0.98,
    "Human Mobility": 0.9,
    Urbanization: 0.9,
    "Transition Indirect Exposure": 0.89,
    "Advanced Therapies": 0.85,
    "Renewable/Alternative Energy": 0.82,
    "Medical Technologies": 0.77,
    "Transition Solutions": 0.74,
    "Sustainable Agriculture": 0.73,
    "Transportation Innovation": 0.7,
    "Automation/Robotics": 0.67,
    "Transition Material Exposure": 0.65,
    "Mental Wellbeing": 0.63,
    "Financial Inclusion": 0.58,
    "Material Science Innovations": 0.47,
    "Artificial Intelligence/Machine Learning": 0.47,
    "Datafication/Digitization": 0.38,
    "Climate Transition": 0.33,
    "Resource Efficiency": 0.16,
    "Carbon Reduction and Removal": 0.07,
  },
  2019: {
    "Financial Inclusion": 0.94,
    "Sustainable Agriculture": 0.91,
    "Renewable/Alternative Energy": 0.89,
    "Transition Indirect Exposure": 0.8,
    "Climate Transition": 0.79,
    "Transportation Innovation": 0.77,
    "Mental Wellbeing": 0.71,
    "Resource Efficiency": 0.71,
    "Material Science Innovations": 0.63,
    "Carbon Reduction and Removal": 0.54,
    "Artificial Intelligence/Machine Learning": 0.52,
    "Transition Solutions": 0.47,
    "Automation/Robotics": 0.36,
    "Leisure/Extended Reality": 0.36,
    Urbanization: 0.34,
    "Transition Material Exposure": 0.31,
    "Datafication/Digitization": 0.18,
    "Advanced Therapies": 0.16,
    "Human Mobility": 0.13,
    "Medical Technologies": 0.1,
  },
  2020: {
    "Carbon Reduction and Removal": 1.0,
    "Datafication/Digitization": 0.98,
    "Resource Efficiency": 0.98,
    "Automation/Robotics": 0.87,
    "Mental Wellbeing": 0.86,
    "Renewable/Alternative Energy": 0.84,
    "Human Mobility": 0.82,
    "Climate Transition": 0.79,
    "Leisure/Extended Reality": 0.74,
    "Transition Material Exposure": 0.59,
    "Advanced Therapies": 0.43,
    "Artificial Intelligence/Machine Learning": 0.35,
    "Transition Indirect Exposure": 0.35,
    "Material Science Innovations": 0.3,
    Urbanization: 0.21,
    "Transition Solutions": 0.21,
    "Sustainable Agriculture": 0.15,
    "Financial Inclusion": 0.09,
    "Transportation Innovation": 0.07,
    "Medical Technologies": 0.0,
  },
  2021: {
    "Transition Solutions": 1.0,
    "Automation/Robotics": 0.92,
    "Resource Efficiency": 0.8,
    "Transition Material Exposure": 0.64,
    "Transition Indirect Exposure": 0.59,
    "Renewable/Alternative Energy": 0.49,
    "Medical Technologies": 0.48,
    "Material Science Innovations": 0.47,
    "Financial Inclusion": 0.47,
    "Sustainable Agriculture": 0.43,
    "Climate Transition": 0.34,
    Urbanization: 0.24,
    "Artificial Intelligence/Machine Learning": 0.23,
    "Carbon Reduction and Removal": 0.23,
    "Transportation Innovation": 0.14,
    "Leisure/Extended Reality": 0.11,
    "Datafication/Digitization": 0.05,
    "Mental Wellbeing": 0.04,
    "Human Mobility": 0.03,
    "Advanced Therapies": 0.0,
  },
  2022: {
    "Material Science Innovations": 0.98,
    "Automation/Robotics": 0.95,
    "Resource Efficiency": 0.91,
    "Transition Indirect Exposure": 0.82,
    "Transition Material Exposure": 0.81,
    "Carbon Reduction and Removal": 0.8,
    "Sustainable Agriculture": 0.79,
    "Renewable/Alternative Energy": 0.79,
    "Human Mobility": 0.69,
    "Medical Technologies": 0.6,
    "Advanced Therapies": 0.48,
    "Mental Wellbeing": 0.41,
    "Transportation Innovation": 0.41,
    "Datafication/Digitization": 0.28,
    "Leisure/Extended Reality": 0.27,
    "Climate Transition": 0.16,
    "Transition Solutions": 0.12,
    Urbanization: 0.06,
    "Artificial Intelligence/Machine Learning": 0.05,
    "Financial Inclusion": 0.05,
  },
}; */

const CategoryOtherAreaChart = ({ topCategories, data }) => {
  const categories = [...topCategories, "OTHER"]; // Combine top categories with "OTHER"
  const series = categories.map((categoryName) => ({
    name: categoryName,
    data: Object.keys(data).map((year) => data[year][categoryName] || 0),
  }));

  const chartOptions = {
    chart: {
      type: "area",
    },
    title: {
      text: "", // "Top Product Categories Revenue vs Others",
    },
    xAxis: {
      categories: Object.keys(data),
    },
    yAxis: {
      title: {
        text: "", //"Revenue",
      },
    },
    tooltip: {
      shared: false,
      split: false,

      formatter: function () {
        // Here you can call formatNumber and return the formatted string
        const formattedNumber = formatNumber(this.y); // this.y refers to the tooltip's point value
        return `<div><span style="font-size: 10px; margin-left: 4px;">${this.x}</span><br/><span style="color:${this.series.color}">${this.series.name}</span>: <b>$${formattedNumber}</b><br/></div>`;
      },
    },
    legend: {
      layout: "vertical",
    },
    plotOptions: {
      area: {
        stacking: "normal",
        lineColor: "#666666",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: "#666666",
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: series,
  };

  return (
    <div className="themeid pt-10">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      <div
        className="justify-center max-h-64 overflow-y-scroll"
        style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}
      ></div>
    </div>
  );
};

export default CategoryOtherAreaChart;

// Sample data, replace this with your actual data prop
