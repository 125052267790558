import React, { useEffect, useState } from "react";
import MultiStepForm from "./private/MultiStepForm";
import Stepper from "../../components/redesign/Stepper";
import WrapperCard from "../../components/ui/redesign/WrapperCard";

const PrivateCompany = () => {
  const [activeStep, setActiveStep] = useState(0);
  const nextStep = () => setActiveStep((prevStep) => prevStep + 1);
  const prevStep = () => setActiveStep((prevStep) => prevStep - 1);
  const steps = [
    { stepNumber: "01", title: "Basic" },
    { stepNumber: "02", title: "Advanced" },
    { stepNumber: "03", title: "Calculation" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <WrapperCard className="w-full m-4 h-fit">
      <div className="w-2/3 mx-auto py-4">
        <Stepper
          steps={steps}
          activeStep={activeStep}
          changeStep={(idx) => setActiveStep(idx)}
        />
      </div>
      <div className="w-3/5 mx-auto">
        <MultiStepForm
          currentStep={activeStep}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      </div>
    </WrapperCard>
  );
};

export default PrivateCompany;
