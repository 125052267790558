export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function formatNumber(num, fixed = 1) {
  if (Math.abs(num) >= 1000000000)
    return (num / 1000000000).toFixed(fixed) + "B";

  if (Math.abs(num) >= 1000000) {
    num = (num / 1000000).toFixed(fixed) + "M";
  } else if (Math.abs(num) >= 1000) {
    num = (num / 1000).toFixed(fixed) + "K";
  } else if (Math.abs(num) < 10) {
    num = num?.toFixed(2);
  } else if (Math.abs(num) < 1000) {
    num = num?.toFixed(fixed);
  }
  return num;
}
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function formatMoney(number) {
  return formatter.format(number);
}

export function imageNameTrim(name) {
  if (name == null) return name;

  if (name?.includes("Recalled") || name?.includes("Recalls")) return "Recalls";
  if (name?.includes("Wages")) return "Wages";
  if (name?.includes("Diversity")) return "Diversity";
  if (name?.includes("Equal Opportunity")) return "EqualOpportunity";
  if (
    name?.includes("Scope") ||
    name?.includes("Emissions") ||
    name?.includes("Category ") ||
    [
      "Purchased goods and services",
      "Capital goods",
      "Fuel-and-energy-related activities",
      "Upstream transportation and distribution",
      "Waste generated in operations",
      "Business travel",
      "Employee commuting",
      "Upstream leased assets",
      "Downstream transportation and distribution",
      "Processing of sold products",
      "Use of sold products",
      "End of life treatment of sold products",
      "Downstream leased assets",
      "Franchises",
      "Investments",
    ].includes(name)
  )
    return "EnvImpactPlace";
  return name.replace(/\s/g, "");
}

export function highlightWords(text, substrings) {
  const regex = new RegExp(`(${substrings.join("|")})`, "gi");
  return text.replace(regex, "<strong>$1</strong>");
}

export function hrefWords(text, substrings, href) {
  const regex = new RegExp(`(${substrings.join("|")})`, "gi");
  return text.replace(regex, (match, p1, offset) => {
    const index = substrings.indexOf(p1);
    return `<a className="hrefgreen" href='${href[index]}'>${p1}</a>`;
  });
}

export function isSimpleCompany() {
  const url = window.location.href;

  return (
    url.includes("company") ||
    url.includes("create-portfolio") ||
    url.includes("upload-portfolio")
  ); //TODO ONLY FOR CREATEPORTFOLIO
}

export function formatNumberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
