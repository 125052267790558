import React, { useState } from "react";
import FAQAccordion from "../../../components/redesign/psics/FAQAccordion";
import IWAI from "../../../components/redesign/portfolio_impact/IWAI";
import RevenueTable from "../../../components/redesign/psics/RevenueTable";
import PSICSComparison from "../../../components/redesign/psics/PSICSComparison";
import ColumnChart from "../../../components/redesign/charts/ColumnChart";
import SimpleColumnChart from "../../../components/redesign/charts/SimpleColumnChart";
import WrapperCard from "../../../components/ui/redesign/WrapperCard";
import { useDataContext } from "../../../context/PortfolioContext";
import { AllRoutes } from "../../../pages/Routes";
import ToolTipRe from "../../ui/redesign/ToolTip";
import { isSimpleCompany } from "../../../services/utilities";
import SimplePieChart from "../charts/SimplePieChart";
import ChartTypeSelector from "../charts/components/ChartTypeSelector";
import ImpactThemeCoverageChart from "../charts/ImpactThemeChart";
import PsicsTabGeographics from "./PsicsTabGeographic";
import ThemeTable from "./ThemeTable";
import ImpactThemeLineChart from "../charts/ImpactThemeLineChart";
import CategoryOtherAreaChart from "../charts/CategoryOtherAreaChart";
import { twMerge } from "tailwind-merge";
import NoDataForYear from "../NoDataForYear";

const PsicsTabProducts = ({ splitView = true }) => {
  const {
    year,
    response: overResponse,
    currentType,
    isCompanyData,
  } = useDataContext();

  const [showChartType1, setShowChartType1] = useState(0);
  const [showChartType2, setShowChartType2] = useState(0);

  const cType = isSimpleCompany()
    ? AllRoutes.company_psics
    : AllRoutes.portfolio_psics;
  if (!overResponse || currentType != cType) return <></>;

  if (overResponse.currentYear != year) {
    return <NoDataForYear />;
  }

  const numberOf = isCompanyData() ? 5 : 10;

  const response = isSimpleCompany() ? overResponse.data : overResponse.details;

  const portfolio1 = response?.[0].portfolioName;
  const portfolio2 = response?.[1].portfolioName;

  const benchMarkByType = isSimpleCompany()
    ? "benchmarkRevenueCategories"
    : "benchmarkRevenueCategoriesWithOtherCategories";

  const portfolio1Categories1 = response?.[0].psics.benchmarkRevenueCategories[
    year
  ]
    .slice(0, numberOf)
    .map((item) => item.productCategoryName);
  const portfolio2Categories2 = response?.[1].psics.benchmarkRevenueCategories[
    year
  ]
    .slice(0, numberOf)
    .map((item) => item.productCategoryName);
  const portfolio1Categories1Pie = response?.[0].psics[benchMarkByType][
    year
  ].map((item) => item.productCategoryName);
  const portfolio2Categories2Pie = response?.[1].psics[benchMarkByType][
    year
  ].map((item) => item.productCategoryName);

  const portfolio1Data1 = response?.[0].psics.benchmarkRevenueCategories[year]
    .slice(0, numberOf)
    .map((item) => item.productCategoryRevenue);
  const portfolio2Data2 = response?.[1].psics.benchmarkRevenueCategories[year]
    .slice(0, numberOf)
    .map((item) => item.productCategoryRevenue);
  const portfolio1Data1Pie = response?.[0].psics[benchMarkByType][year].map(
    (item) => item.productCategoryRevenue
  );
  const portfolio2Data2Pie = response?.[1].psics[benchMarkByType][year].map(
    (item) => item.productCategoryRevenue
  );

  const chartData1 = {
    data: [portfolio1Data1],
    categories: portfolio1Categories1,
  };
  const chartData2 = {
    data: [portfolio2Data2],
    categories: portfolio2Categories2,
  };
  const chartData1Pie = {
    data: [portfolio1Data1Pie],
    categories: portfolio1Categories1Pie,
  };
  const chartData2Pie = {
    data: [portfolio2Data2Pie],
    categories: portfolio2Categories2Pie,
  };
  const pieChartData1 = chartData1Pie.data[0].map((value, index) => ({
    name: chartData1Pie.categories[index],
    y: value,
    // color: topColors[index],
  }));

  const pieChartData2 = chartData2Pie.data[0].map((value, index) => ({
    name: chartData2Pie.categories[index],
    y: value,
    // color: topColors[index],
  }));

  const pieChartDataTable1 = pieChartData1.map((obj, idx) => {
    return {
      name: obj.name,
      value: obj.y,
    };
  }, {});
  const pieChartDataTable2 = pieChartData2.map((obj, idx) => {
    return {
      name: obj.name,
      value: obj.y,
    };
  }, {});

  let impactTheme1;
  let impactTheme2;
  let impactTheme1Table;
  let impactTheme2Table;
  if (!isSimpleCompany()) {
    impactTheme1 = response?.[0].psics.impactThemeCoverages;
    impactTheme2 = response?.[1].psics.impactThemeCoverages;
    impactTheme1Table = Object.keys(impactTheme1?.[year]).map((key) => {
      return {
        name: key,
        value: impactTheme1?.[year][key] * 100,
      };
    });
    impactTheme2Table = Object.keys(impactTheme2?.[year]).map((key) => {
      return {
        name: key,
        value: impactTheme2?.[year][key] * 100,
      };
    });
  }

  const rawData1 = response?.[0].psics.benchmarkRevenueCategories;
  const rawData2 = response?.[1].psics.benchmarkRevenueCategories;
  /*   const top3TransformOther = Object.keys(rawData1).reduce((acc, year) => {
    const categories = rawData1[year]
      .sort((a, b) => b.productCategoryRevenue - a.productCategoryRevenue)
      .slice(0, 3); // Top 3 categories

    const otherCategoriesSum = rawData1[year]
      .slice(3) // All except top 3
      .reduce(
        (sum, { productCategoryRevenue }) => sum + productCategoryRevenue,
        0
      );

    // Add "OTHER" category if there are more than 3 categories
    if (rawData1[year].length > 3) {
      categories.push({
        productCategoryName: "OTHER",
        productCategoryRevenue: otherCategoriesSum,
      });
    }

    acc[year] = categories.reduce(
      (obj, { productCategoryName, productCategoryRevenue }) => {
        obj[productCategoryName] = productCategoryRevenue;
        return obj;
      },
      {}
    );

    return acc;
  }, {});
 */
  const topCategories1 = rawData1[year]
    .sort((a, b) => b.productCategoryRevenue - a.productCategoryRevenue)
    .slice(0, 3)
    .map((category) => category.productCategoryName);

  const transformedData1 = Object.keys(rawData1).reduce((acc, year) => {
    // Initialize revenue accumulator for top categories and others
    const yearData = { OTHER: 0 };
    topCategories1.forEach((category) => (yearData[category] = 0));

    rawData1[year].forEach(
      ({ productCategoryName, productCategoryRevenue }) => {
        if (topCategories1.includes(productCategoryName)) {
          yearData[productCategoryName] += productCategoryRevenue;
        } else {
          yearData["OTHER"] += productCategoryRevenue;
        }
      }
    );

    acc[year] = yearData;
    return acc;
  }, {});
  const topCategories2 = rawData2[year]
    .sort((a, b) => b.productCategoryRevenue - a.productCategoryRevenue)
    .slice(0, 3)
    .map((category) => category.productCategoryName);

  const transformedData2 = Object.keys(rawData2).reduce((acc, year) => {
    // Initialize revenue accumulator for top categories and others
    const yearData = { OTHER: 0 };
    topCategories2.forEach((category) => (yearData[category] = 0));

    rawData2[year].forEach(
      ({ productCategoryName, productCategoryRevenue }) => {
        if (topCategories2.includes(productCategoryName)) {
          yearData[productCategoryName] += productCategoryRevenue;
        } else {
          yearData["OTHER"] += productCategoryRevenue;
        }
      }
    );

    acc[year] = yearData;
    return acc;
  }, {});

  const revenue1 = response[0].psics.benchmarkRevenueCategories[year].map(
    (r) => ({
      category: r.productCategoryName,
      revenue: r.productCategoryRevenue,
      percentage: r.productCategoryRevenueShare * 100,
      companyList: r.companyList,
      relevantImpactPillars: r.relevantImpactPillars,
      productCategoryDescription: r.productCategoryDescription,
    })
  );

  const revenue2 = response[1].psics.benchmarkRevenueCategories[year].map(
    (r) => ({
      category: r.productCategoryName,
      revenue: r.productCategoryRevenue,
      percentage: r.productCategoryRevenueShare * 100,
      companyList: r.companyList,
      relevantImpactPillars: r.relevantImpactPillars,
      productCategoryDescription: r.productCategoryDescription,
    })
  );

  return (
    <div>
      <div
        className={twMerge(
          "grid grid-cols-2 gap-x-8",
          splitView == false && "grid-cols-1"
        )}
      >
        <div className="col-span-1 flex flex-col gap-y-8">
          <div>
            <div className="flex w-fit items-center gap-x-2 gap-y-4 mb-2">
              <p className="text-xs leading-none text-gray-600">
                Revenues ($) from top categories in {year}
              </p>

              {/*   <ToolTipRe
                onTop={false}
                toolTipBase={
                  <img src="/redesign/portfolio_impact/info_outline.svg" />
                }
              >
                <p>tooltipbody</p>
              </ToolTipRe> */}
            </div>
            <WrapperCard
              wideBorder={true}
              topRightChild={
                <ChartTypeSelector
                  list={["Pie", "Column"]}
                  showActive={showChartType1}
                  handleClick={setShowChartType1}
                />
              }
            >
              {showChartType1 == 1 && (
                <SimpleColumnChart
                  id={"simple1"}
                  portfolio1={portfolio1}
                  data={chartData1Pie}
                  color={"#0EA5E9"}
                  narrow={true}
                />
              )}
              {showChartType1 == 0 && (
                <SimplePieChart
                  id={"simple1"}
                  portfolio1={portfolio1}
                  data={pieChartData1}
                  color={"#0EA5E9"}
                  showLegend={false}
                  showLabel={true}
                  marginLeft={0}
                />
              )}
            </WrapperCard>
          </div>
          <div>
            {!isSimpleCompany() && (
              <div className="">
                <p className="text-xs leading-none text-gray-600 mt-4 mb-3">
                  Revenue breakdown by geographies and income regions
                </p>
                <PsicsTabGeographics idx={0} />
              </div>
            )}
          </div>
          <div>
            <p className="text-xs leading-none text-gray-600 my-4 ml-2">
              Historical revenues ($) from top 3 products & services
            </p>
            <WrapperCard>
              {/*                 <ImpactThemeCoverageChart data={impactTheme2} />
               */}{" "}
              <CategoryOtherAreaChart
                data={transformedData1}
                topCategories={topCategories1}
              />
            </WrapperCard>
          </div>
          <p className="text-xs leading-none text-gray-600 -mb-5">
            Full list of products & services in {year}
          </p>
          <PSICSComparison allData={revenue1} color={"#0EA5E9"} hack={0} />
          {impactTheme1 && (
            <div>
              <p className=" mb-2 text-xs leading-none text-gray-600">
                Historical revenues ($) by impact themes{" "}
              </p>
              <WrapperCard>
                {impactTheme1 && <ImpactThemeLineChart data={impactTheme1} />}{" "}
                {impactTheme1Table && (
                  <ThemeTable
                    data={impactTheme1Table}
                    isPercentage={true}
                    keyTitle={"Impact theme"}
                    valueTitle={"Aligned revenues"}
                  />
                )}
              </WrapperCard>
            </div>
          )}{" "}
        </div>
        {splitView == true && (
          <div className="col-span-1 flex flex-col gap-y-8">
            <div>
              <div className="flex w-fit items-center gap-x-2 gap-y-4 mb-2">
                <p className="text-xs leading-none text-gray-600">
                  Revenues ($) from top categories in {year}
                </p>

                {/*   <ToolTipRe
                onTop={false}
                toolTipBase={
                  <img src="/redesign/portfolio_impact/info_outline.svg" />
                }
              >
                <p>tooltipbody</p>
              </ToolTipRe> */}
              </div>
              <WrapperCard
                wideBorder={true}
                topRightChild={
                  <ChartTypeSelector
                    list={["Pie", "Column"]}
                    showActive={showChartType2}
                    handleClick={setShowChartType2}
                  />
                }
              >
                {showChartType2 == 1 && (
                  <SimpleColumnChart
                    id={"simple1"}
                    portfolio1={portfolio2}
                    data={chartData2Pie}
                    color={"#0EA5E9"}
                    narrow={true}
                  />
                )}
                {showChartType2 == 0 && (
                  <SimplePieChart
                    id={"simple1"}
                    portfolio1={portfolio2}
                    data={pieChartData2}
                    color={"#0EA5E9"}
                    showLegend={false}
                    showLabel={true}
                    marginLeft={0}
                  />
                )}
              </WrapperCard>
            </div>
            <div>
              {!isSimpleCompany() && (
                <div className="">
                  <p className="text-xs leading-none text-gray-600 mt-4 mb-3">
                    Revenue breakdown by geographies and income regions
                  </p>
                  <PsicsTabGeographics idx={1} />
                </div>
              )}
            </div>
            <div>
              <p className="text-xs leading-none text-gray-600 my-4 ml-2">
                Historical revenues ($) from top 3 products & services
              </p>
              <WrapperCard>
                {/*                 <ImpactThemeCoverageChart data={impactTheme2} />
                 */}{" "}
                <CategoryOtherAreaChart
                  data={transformedData2}
                  topCategories={topCategories2}
                />
              </WrapperCard>
            </div>
            <p className="text-xs leading-none text-gray-600 -mb-5">
              Full list of products & services in {year}
            </p>
            <PSICSComparison allData={revenue2} color={"#8B5CF6"} hack={1} />
            {impactTheme2 && (
              <div>
                <p className="text-xs leading-none text-gray-600 mb-2">
                  Historical revenues ($) by impact themes{" "}
                </p>

                <WrapperCard>
                  {impactTheme2 && <ImpactThemeLineChart data={impactTheme2} />}{" "}
                  {impactTheme2Table && (
                    <ThemeTable
                      data={impactTheme2Table}
                      isPercentage={true}
                      keyTitle={"Impact theme"}
                      valueTitle={"Aligned revenues"}
                    />
                  )}
                </WrapperCard>
              </div>
            )}{" "}
          </div>
        )}
      </div>

      <div className="mt-8 flex flex-col gap-y-8">
        {/*         <IWAI title={"Placeholder downloadable document card "} />
         */}{" "}
        <div className="pb-8">
          <FAQAccordion />
        </div>
      </div>
    </div>
  );
};

export default PsicsTabProducts;
