import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchCompanySDG, fetchPortfolioSDG } from "../api/api";
import LoadingErrorWrapper from "../api/LoadingErrorWrapper";
import {
  sdg_color,
  sdg_descriptions,
  sdg_names,
} from "../components/redesign/charts/SDGAlignmentChart";
import NoComparisonData from "../components/redesign/NoComparisonData";
import { PortfolioCardType } from "../components/redesign/portfolio_impact/PortfolioCard";
import TabOverviewSelector from "../components/redesign/portfolio_impact/TabOverviewSelector";
import PremiumFeature from "../components/redesign/PremiumSoon";
import ToolTipRe from "../components/ui/redesign/ToolTip";
import WrapperCard from "../components/ui/redesign/WrapperCard";
import { useDataContext } from "../context/PortfolioContext";
import { formatNumber } from "../services/utilities";
import { AllRoutes } from "./Routes";

const CompanySDGAlignment = () => {
  const [selectedItem, setSelectedItem] = useState(0);

  const {
    year,
    companyList,
    setAllYears,
    selectedCompanies,
    currentType,
    setCompanyList,
    setResponse,
    response,
    setCurrentType,
    handleClearData,
    isCompanyData,
    setSelectedCompanies,
  } = useDataContext();

  const { isLoading, error, data } = useQuery(
    ["company_sdg", selectedCompanies, year],
    () => {
      return fetchCompanySDG(selectedCompanies, year);
    }
  );

  useEffect(() => {
    if (data) {
      setCompanyList(
        Object.keys(data?.ImpactResultSDGAlignment ?? {}).map((key) => ({
          name: key,
          value: data?.ImpactResultSDGAlignment[key].totalSDGImpactByYear[year],
        }))
      );
      let years = Object.values(data?.CompanyLevelSDGTotalValues ?? {}).reduce(
        (acc, curr) => {
          Object.keys(curr).forEach((key) => {
            if (key !== "name" && !acc.includes(key)) {
              acc.push(key);
            }
          });
          return acc;
        },
        []
      );
      years = years.reverse();
      setAllYears(years);
      setResponse(data);
      setCurrentType(AllRoutes.company_sdg_alignment);
    } else {
      if (!isCompanyData()) {
        setSelectedCompanies([]);
        setCompanyList([]);
      }
    }
  }, [data, year]);

  if (isLoading || error)
    return <LoadingErrorWrapper isLoading={isLoading} error={error} />;

  const name1 = companyList?.[0]?.name;
  const name2 = companyList?.[1]?.name;
  let SDGNumbers1 = [];
  let SDGNumbers2 = [];

  let data1 =
    response &&
    Object.values(
      response?.ImpactResultSDGAlignment?.[name1]
        ?.SDGCategoryLevelImpactByYear ?? {}
    ).map((i) => i[year]);
  let data2 =
    response &&
    Object.values(
      response?.ImpactResultSDGAlignment?.[name2]
        ?.SDGCategoryLevelImpactByYear ?? {}
    ).map((i) => i[year]);

  for (let i = 0; i < 17; i++) {
    const item1 = {
      price: data1?.[i],
      name: sdg_names[i],
      description: sdg_descriptions[i],
      value: data1?.[i] < 0 ? -1 : data1?.[i] > 0 ? 1 : 0,
      id: i,
    };
    const item2 = {
      price: data2?.[i],
      name: sdg_names[i],
      description: sdg_descriptions[i],
      value: data2?.[i] < 0 ? -1 : data2?.[i] > 0 ? 1 : 0,
      id: i,
    };
    SDGNumbers1.push(item1);
    SDGNumbers2.push(item2);
  }

  function getValueByPath(obj, path) {
    // Split the path into an array of keys
    const keys = path.split(".");
    // Reduce the keys array to navigate through the object
    return keys.reduce((acc, key) => {
      return acc ? acc[key] : undefined;
    }, obj);
  }

  const selectedTableValue = (name) =>
    selectedItem != null
      ? `CompanyLevelSelectedSDGValues.${name}.${year}.${selectedItem}`
      : `CompanyLevelSDGTotalValues.${name}.${year}`;

  let companies1 =
    response &&
    Object.entries(
      getValueByPath(response, selectedTableValue(name1)) ?? {}
    ).map(([name, value]) => ({ name, value }));
  let companies2 =
    response &&
    Object.entries(
      getValueByPath(response, selectedTableValue(name2)) ?? {}
    ).map(([name, value]) => ({ name, value }));

  const colorByValue = (val) => {
    switch (val) {
      case 0:
        return "bg-my-tailwind-gray-400";
      case -1:
        return "bg-my-tailwind-red-500";
      case 1:
        return "bg-my-tailwind-green-500";
    }
  };

  //if (!response || currentType != AllRoutes.company_eu_taxonomy) return <></>
  return (
    <div className="w-full flex flex-col gap-y-8" id="company_sdg">
      <div className="px-8 flex flex-col gap-y-8 mt-4">
        <TabOverviewSelector
          title={"SDG Alignment"}
          onExport={"company_sdg"}
          type={PortfolioCardType.sdg}
        />
        {selectedCompanies.length != 1 ? (
          <NoComparisonData />
        ) : (
          <div className="w-full grid grid-cols-2 gap-x-8">
            {[1, 2].map((item, idx) => {
              const sdgNumbers = idx == 0 ? SDGNumbers1 : SDGNumbers2;
              const companies = idx == 0 ? companies1 : companies2;

              return (
                <WrapperCard wideBorder={true} className="col-span-1">
                  <div className="w-full flex flex-col gap-y-6">
                    <div className="flex flex-wrap justify-center items-center gap-4 mx-8">
                      {sdgNumbers.map((item) => {
                        const isNegative = item.price < 0;
                        return (
                          <ToolTipRe
                            key={item.id}
                            onTop={true}
                            disabled={item.value == 0}
                            toolTipBase={
                              <div
                                onClick={() => setSelectedItem(item.id)}
                                className={` ${
                                  selectedItem == item.id
                                    ? "border-blue-500 p-0.5 border-[1px] rounded-xl bg-blue-50"
                                    : ""
                                } cursor-pointer`}
                              >
                                <div
                                  className={`w-8 h-8 flex justify-center items-center rounded-xl ${colorByValue(
                                    item.value
                                  )} ${
                                    item.value == 0 ? "" : "hover:bg-[#DDA83A]"
                                  }`}
                                >
                                  <img
                                    className="w-6 h-6"
                                    src={`/redesign/sdg/Layer_1-${item.id}.svg`}
                                  />
                                </div>
                              </div>
                            }
                          >
                            <div className="flex flex-col w-64 items-start">
                              <div className="flex items-center gap-x-2 mb-4">
                                <div
                                  style={{
                                    backgroundColor: sdg_color[selectedItem],
                                  }}
                                  className={`w-8 h-8 flex justify-center items-center rounded-xl`}
                                >
                                  <img
                                    className="w-6 h-6"
                                    src={`/redesign/sdg/Layer_1-${item.id}.svg`}
                                  />
                                </div>
                                <p className="text-xs font-bold leading-none text-gray-600">
                                  {item.name}
                                </p>
                              </div>
                              <p className="mb-1 text-xs leading-none text-gray-500">
                                {"SDG Alignment"}
                              </p>
                              <p
                                className={`text-3xl font-bold leading-9 text-center ${
                                  isNegative
                                    ? "text-my-tailwind-red-500"
                                    : "text-my-tailwind-green-500"
                                } `}
                              >
                                $ {formatNumber(item.price)}
                              </p>
                            </div>
                          </ToolTipRe>
                        );
                      })}
                    </div>
                    <div className="border-b-[2px] border-dashed w-full h-1 border-my-tailwind-gray-200" />
                    <div className="flex gap-x-4 w-fit items-center">
                      <div
                        style={{
                          backgroundColor: sdg_color[selectedItem],
                        }}
                        className={`w-12 h-12 flex justify-center items-center rounded-xl `}
                      >
                        <img
                          className="w-10 h-10"
                          src={`/redesign/sdg/Layer_1-${selectedItem}.svg`}
                        />
                      </div>{" "}
                      <div>
                        <p className="text-base font-bold leading-none text-gray-600">
                          {selectedItem + 1} - {sdgNumbers[selectedItem].name}
                        </p>{" "}
                        <p
                          className={`text-base font-bold leading-none ${
                            sdgNumbers[selectedItem].price < 0
                              ? "text-my-tailwind-red-500"
                              : "text-my-tailwind-green-500"
                          }
                                `}
                        >
                          {formatNumber(sdgNumbers[selectedItem].price)}
                        </p>{" "}
                      </div>
                    </div>
                    <div className="">
                      <div className="flow-root ">
                        <div className="w-full ">
                          <div className="inline-block py-2 align-middle w-full">
                            <div className="grid grid-cols-4">
                              <div className="col-span-3 cursor-pointer flex items-center rounded-tl-lg px-2 py-3 border-b-[1px] border-gray-200 bg-gray-50">
                                <p className="text-xs font-semibold leading-tight pr-2 text-gray-500">
                                  Companies
                                </p>
                              </div>

                              <div className="col-span-1 flex items-center justify-center  border-b-[1px] rounded-tr-lg px-2 py-3 border-gray-200 bg-gray-50">
                                <p className="text-xs font-semibold leading-tight px-2 text-gray-500">
                                  Impact
                                </p>
                              </div>
                            </div>
                            <div
                              className="h-[400px] grid grid-cols-8 overflow-y-scroll supervisortable"
                              style={{
                                height: "400px",
                              }}
                            >
                              <div className="w-full col-span-8">
                                {companies?.map((c, idx) => (
                                  <div
                                    className={`${
                                      idx % 2 == 0
                                        ? ""
                                        : "bg-my-tailwind-gray-50"
                                    } w-full grid-flow-col grid grid-cols-4 col-span-8`}
                                  >
                                    <div
                                      className={`col-span-3 px-2 my-auto ${
                                        idx % 2 == 0
                                          ? ""
                                          : "bg-my-tailwind-gray-50"
                                      }`}
                                    >
                                      <div
                                        className={`text-xs text-left font-normal leading-tight text-my-tailwind-gray-600  group flex items-center gap-x-1.5 cursor-pointer ${
                                          idx > 10 ? "" : ""
                                        }`}
                                      >
                                        {c.name}
                                      </div>
                                    </div>

                                    <div
                                      className={`col-span-1 flex justify-end my-auto relative ${
                                        idx % 2 == 0
                                          ? ""
                                          : "bg-my-tailwind-gray-50"
                                      }`}
                                    >
                                      <p
                                        className={`text-xs font-normal leading-tight ${
                                          c.value < 0
                                            ? "text-my-tailwind-red-500"
                                            : "text-my-tailwind-green-500"
                                        }  text-gray-500 px-2 py-4 text-center ${
                                          idx > 10 ? "" : ""
                                        }`}
                                      >
                                        $ {formatNumber(c.value)}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                                {/*   <div className="relative mt-4">
                                  <div className="top-0 z-10 mx-auto bg-gray-50">
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </WrapperCard>
              );
            })}
          </div>
        )}

        {/*  {selectedCompanies.length < 2 ? (
            <NoComparisonData />
          ) : (
            <>
              {activeTab == 0 && <PsicsTabProducts />}
              {activeTab == 1 && <PsicsTabGeographics />}
            </>
          )} */}
      </div>
    </div>
  );
};

export default CompanySDGAlignment;
