import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import { useGridCellEditor } from "ag-grid-react";
import ToolTipRe from "../../../components/ui/redesign/ToolTip";
import WrapperCard from "../../../components/ui/redesign/WrapperCard";
import { useDataContext } from "../../../context/PortfolioContext";
import { useCustomPortfolio } from "../../../context/CustomPortfolioContext";

const AutocompleteCellEditor = memo(
  ({ value, onValueChange, api, rowIndex, colDef, data }) => {
    const [inputValue, setInputValue] = useState(value || "");
    const refInput = useRef(null);
    const [suggestions, setSuggestions] = useState([]); // Mock suggestions, replace with your data
    const [showSuggestions, setShowSuggestions] = useState(false);
    const { allSearchData } = useDataContext();
    const { updateRow } = useCustomPortfolio();

    useEffect(() => {
      if (refInput.current) {
        refInput.current.focus();
      }
    }, []);

    // Example autocomplete logic, replace with actual logic
    const updateSuggestions = useCallback((input) => {
      if (!input) {
        setSuggestions([]);
        return;
      }
      // Mock suggestions based on input, replace with actual suggestion logic

      setSuggestions(
        allSearchData.filter(
          (s) => s.id.toLowerCase().includes(input.toLowerCase()) //TODO NOT ONLY NAME BUT ISIN NUMBER AS WELL
        )
      );
      setShowSuggestions(true);
    }, []);

    const finishEditing = useCallback(
      (selectedValue) => {
        onValueChange(selectedValue);
        api.stopEditing();
      },
      [api, onValueChange]
    );

    /*   useEffect(() => {
      updateSuggestions(inputValue);
    }, [inputValue, updateSuggestions]); */

    return (
      <div
        style={{
          height: "200px",
        }}
        className="w-full absolute left-1 top-0"
        //onBlur={() => setShowSuggestions(false)}
      >
        <input
          className="w-full outline-none ring-0 p-1"
          ref={refInput}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            updateSuggestions(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && suggestions.length > 0) {
              finishEditing(suggestions[0]); // For simplicity, selecting the first suggestion
            }
          }}
        />{" "}
        {showSuggestions && (
          <div className="fixed h-64 w-100 z-50 overflow-y-scroll">
            <WrapperCard className="rounded-xl shadow-md p-1">
              {suggestions.map((suggestion, index) => (
                <div
                  onClick={() => {
                    setInputValue(suggestion.id);
                    setShowSuggestions(false);
                    updateRow(data.securityId, {
                      securityId: "TODOID",
                      position: 0,
                      status: "pending",
                      name: suggestion.id,
                    });
                    //setSuggestions([]);
                  }}
                  className="flex gap-x-2 cursor-pointer overflow-x-scroll"
                >
                  <p>komolyid</p>-
                  <p className="font-semibold text-ellipsis">{suggestion.id}</p>
                </div>
              ))}
              {suggestions.length === 0 && (
                <div className="mx-auto flex flex-col justify-center items-center p-2">
                  <img src="/icon/filter_icon.svg" alt="no matches" />
                  <p className="text-sm text-my-tailwind-gray-600 font-semibold">
                    No matches
                  </p>
                  <p className="text-sm text-my-tailwind-gray-600 mt-1">
                    Couldn’t find any company match.
                  </p>
                </div>
              )}
            </WrapperCard>
          </div>
        )}
      </div>
    );
  }
);

export default AutocompleteCellEditor;
