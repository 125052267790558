import React, { useEffect, useRef } from "react";
import IWAI from "../components/redesign/portfolio_impact/IWAI";
import TabOverviewSelector from "../components/redesign/portfolio_impact/TabOverviewSelector";
import Tabs from "../components/redesign/portfolio_impact/Tabs";
import ToolTipRe from "../components/ui/redesign/ToolTip";
import { useHistory } from "react-router-dom";
import { AllRoutes } from "./Routes";

import PortfolioOverview from "./company_impact/PortfolioOverview";
import EnvironmentalImpact from "./company_impact/EnvironmentalImpact";
import CustomerImpact from "./company_impact/CustomerImpact";
import PortfolioInformation from "./company_impact/PortfolioInformation";
import EmployeesImpact from "./company_impact/EmployeesImpact";

import { useDataContext } from "../context/PortfolioContext";
import { useQuery } from "react-query";
import {
  fetchPortfolioOverview,
  fetchImpactCategory,
  API_ENUMS,
  fetchCompanyOverview,
  fetchCompanyImpactCategory,
} from "../api/api";
import LoadingErrorWrapper from "../api/LoadingErrorWrapper";
import FeatureSoon from "../components/redesign/FeatureSoon";
import NoComparisonData from "../components/redesign/NoComparisonData";
import {
  privateCompanyOverview,
  privateCompanyResponseByType,
} from "./company_impact/private/PrivateCompanyData";
import PrivateCompany from "./company_impact/PrivateCompany";
import { useBeforeRouteChange } from "../services/hooks";
import PremiumFeature from "../components/redesign/PremiumSoon";
import Shepherd from "shepherd.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const CompanyImpact = () => {
  const history = useHistory();
  const location = useLocation();

  const printRef = useRef();
  const { selectedCompanies, privateCompany, setPrivateCompany } =
    useDataContext();

  const {
    year,
    initialYear,
    companyList,
    setAllYears,
    setCompanyList,
    setResponse,
    setCurrentType,
    handleClearData,
    setSelectedCompanies,
    isCompanyData,
  } = useDataContext();
  const isPrivateCompany = () => {
    return history.location.pathname.includes("private");
  };

  useBeforeRouteChange(
    () => {
      setSelectedCompanies([]);
      setCompanyList([]);
      setResponse(null);
      setPrivateCompany(null);
    },
    (oldPath, newPath) => {
      if (oldPath.includes("private") && newPath.includes("public"))
        return true;
      if (oldPath.includes("public") && newPath.includes("private"))
        return true;
    }
  );

  const impactKeyByRoute = () => {
    switch (history.location.pathname) {
      case AllRoutes.company_overview:
      case AllRoutes.private_company_overview:
        return API_ENUMS.overview;
      case AllRoutes.company_environmental_impact:
      case AllRoutes.private_company_environmental_impact:
        return API_ENUMS.environment;
      case AllRoutes.company_employees_impact:
      case AllRoutes.private_company_employees_impact:
        return API_ENUMS.employee;
      case AllRoutes.company_customer_impact:
      case AllRoutes.private_company_customer_impact:
        return API_ENUMS.customer;
      case AllRoutes.company_information:
        return API_ENUMS.information;
      default:
        break;
    }
  };

  const { isLoading, error, data } = useQuery(
    [impactKeyByRoute(), selectedCompanies, privateCompany, year],
    () => {
      const key = impactKeyByRoute();
      if (privateCompany) return privateCompanyResponseByType(key); //TODO API CALL
      if (isPrivateCompany() && !privateCompany) return null;
      return key == API_ENUMS.overview
        ? fetchCompanyOverview(selectedCompanies, year)
        : fetchCompanyImpactCategory(key, selectedCompanies, year);
    }
  );
  /*   const { isLoading, error, data } = useQuery(
    "PROBAAA",
    fetchPortfolioOverview
  ); */

  useEffect(() => {
    if (data) {
      if (
        history.location.pathname == AllRoutes.company_overview ||
        history.location.pathname == AllRoutes.private_company_overview
      ) {
        setCompanyList(
          data.data.map((c) => ({
            name: c.portfolioName,
            percentage: c.transparencyScore * 100,
            value: c.totalValue[year],
          }))
        );
        setAllYears(
          Object.keys(data?.data?.[0].totalValue)
            .map((i, idx) => ({
              id: idx,
              name: `${i}`,
            }))
            .reverse()
        );
      } else if (history.location.pathname == AllRoutes.company_information) {
        setCompanyList(data.data.details);
        setAllYears(
          Object.keys(
            data?.data?.details[0]?.totalValueDrilldownByStakeholder.customer
          )
            .map((i, idx) => ({
              id: idx,
              name: `${i}`,
            }))
            .reverse()
        );
      } else {
        setCompanyList(
          data.portfolioComparison.map((c) => ({
            name: c.portfolioName,
            percentage: c.transparencyScore * 100,
            value:
              c.portfolioImpactResults?.totalValueDrilldownByStakeholder[
                impactKeyByRoute()
              ][year],
          }))
        );
        setAllYears(
          Object.keys(
            data.portfolioComparison?.[0].portfolioImpactResults.totalValue
          )
            .map((i, idx) => ({
              id: idx,
              name: `${i}`,
            }))
            .reverse()
        );
      }
      setResponse(data);
      //console.log("setresp");
      setCurrentType(history.location.pathname);
      /* TODO ONLY SAVE THE YEAR setResponse(data.map((c)=>({
        
        EUTaxonomy: c.EUTaxonomy?.[year],

      }))); */
    } else {
      if (!isCompanyData() || (isPrivateCompany() && !privateCompany)) {
        setSelectedCompanies([]);
        setCompanyList([]);
      }
    }
  }, [data, year]);

  useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const shouldTourContinue = urlParams.get("stc");

    if (shouldTourContinue === "t" && !isLoading) {
      console.log(Shepherd.activeTour);
      if (Shepherd.activeTour.getCurrentStep().id === "step23") {
        Shepherd.activeTour.next();
      } else if (Shepherd.activeTour.getCurrentStep().id === "step26") {
        Shepherd.activeTour.next();
      }
    }
  }, [location, isLoading]);

  if (isLoading || error)
    return <LoadingErrorWrapper isLoading={isLoading} error={error} />;

  if (history.location.pathname == AllRoutes.company_information)
    return <PortfolioInformation />;

  const pageByRoute = () => {
    switch (history.location.pathname) {
      case AllRoutes.company_overview:
      case AllRoutes.private_company_overview:
        return <PortfolioOverview />;
      case AllRoutes.company_environmental_impact:
      case AllRoutes.private_company_environmental_impact:
        return <EnvironmentalImpact />;
      case AllRoutes.company_employees_impact:
      case AllRoutes.private_company_employees_impact:
        return <EmployeesImpact />;
      case AllRoutes.company_customer_impact:
      case AllRoutes.private_company_customer_impact:
        return <CustomerImpact />;
      case AllRoutes.company_information:
        return <PortfolioInformation />;

      default:
        break;
    }
  };
  const titleByRoute = () => {
    switch (history.location.pathname) {
      case AllRoutes.company_overview:
      case AllRoutes.private_company_overview:
        return "Company Overview";
      case AllRoutes.company_environmental_impact:
      case AllRoutes.private_company_environmental_impact:
        return "Impact on environment";
      case AllRoutes.company_employees_impact:
      case AllRoutes.private_company_employees_impact:
        return "Impact on employees";
      case AllRoutes.company_customer_impact:
      case AllRoutes.private_company_customer_impact:
        return "Impact on customers";
      case AllRoutes.company_information:
        return "Company Information";

      default:
        break;
    }
  };

  if (isPrivateCompany() && selectedCompanies.length == 0)
    return <PrivateCompany />;
  else if (isPrivateCompany() && selectedCompanies.length != 0)
    return (
      <div className="w-full">
        <PremiumFeature />
      </div>
    );

  return (
    <div
      className="w-full bg-white"
      ref={printRef}
      id={titleByRoute().toString().replaceAll(" ", "")}
    >
      <Tabs idx={isPrivateCompany() ? 4 : 2} />
      <div className="p-8 flex flex-col gap-y-8 bg-gray-50">
        <TabOverviewSelector
          //onExport={printRef.current}
          onExport={titleByRoute().toString().replaceAll(" ", "")}
          title={titleByRoute()}
        />
        <ToolTipRe />

        {selectedCompanies.length != 1 ? <NoComparisonData /> : pageByRoute()}
        {/*<IWAI
          title={
            "We are aligned with impact-weighted accounting principles. Check out ..."
          }
        /> TEMPORARY REMOVE*/}
      </div>
    </div>
  );
};

export default CompanyImpact;
