import Shepherd from "shepherd.js";
import { createContext, useContext, useEffect, useState } from "react";
import { AllRoutes } from "../pages/Routes";
import { useDataContext } from "./PortfolioContext";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import "../styles/shepherd.css";

export const TourContext = createContext();

export function TourProvider({ children }) {
  const { selectedCompanies, setSelectedCompanies } = useDataContext();
  const [status, setStatus] = useState("idle");
  const [tourRoute, setTourRoute] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    exitOnEsc: true,
    defaultStepOptions: {
      scrollTo: {
        behavior: "smooth",
        block: "center",
      },
    },
  });

  function dismissTour() {
    if (!localStorage.getItem("shepherd-tour")) {
      localStorage.setItem("shepherd-tour", "yes");
    }
  }

  useEffect(() => {
    const updateTourRoute = (newRoute) => {
      setTourRoute(newRoute);

      if (
        newRoute.length === 3 &&
        !newRoute[0].includes("overview") &&
        !newRoute[2].includes("overview") &&
        newRoute[0] === newRoute[2]
      ) {
        Shepherd.activeTour?.cancel();
      } else if (
        newRoute.length === 3 &&
        newRoute[1].includes("overview") &&
        newRoute[0] === newRoute[2]
      ) {
        Shepherd.activeTour?.cancel();
      }
    };

    if (tourRoute.length === 0) {
      updateTourRoute([location.pathname]);
    } else if (tourRoute[tourRoute.length - 1] !== location.pathname) {
      if (tourRoute.length <= 2) {
        updateTourRoute([...tourRoute, location.pathname]);
      } else {
        updateTourRoute([...tourRoute.slice(1), location.pathname]);
      }
    }
  }, [location.pathname]);

  const clearURL = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("stc");
    history.replace(url.pathname + url.search);
  };

  tour.on("cancel", () => {
    clearURL();
    dismissTour();
  });
  tour.on("complete", () => {
    clearURL();
    dismissTour();
  });

  useEffect(() => {
    if (!localStorage.getItem("shepherd-tour")) {
      tour.start();
    }
  }, []);

  tour.addStep({
    id: "step0",
    text: "Welcome to the RIFT Portal Tour! In the next few steps, we will guide you through the main features of the website.",
    canClickTarget: false,
    scrollTo: false,
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step1",
    text: "With this menu, you can navigate between the different pages on the website.",
    canClickTarget: false,
    modalOverlayOpeningPadding: 5,
    attachTo: {
      element: ".nav-side",
      on: "right-start",
    },
    scrollTo: false,
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step2",
    text: "You can reach the Portfolio Overview page by clicking here.",
    canClickTarget: false,
    attachTo: {
      element: ".menu-item-3",
      on: "bottom",
    },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const isOpen = document
          .querySelector(".menu-item-1")
          .getAttribute("aria-expanded");

        if (isOpen === "true") {
          findMenuWithClose();
        } else {
          findMenu();
        }

        async function findMenuWithClose() {
          await document.querySelector(".menu-item-1").click();
          findMenu();
        }

        async function findMenu() {
          await document.querySelector(".menu-item-1")?.click();
          await document.querySelector(".menu-item-2")?.click();
          resolve();
        }
      });
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: function () {
          tour.hide();
          history.push(`${AllRoutes.portfolio_overview}?stc=t`);
        },
      },
    ],
  });
  tour.addStep({
    id: "step3",
    text: "This is the Portfolio Overview page, where you can compare different portfolios.",
    canClickTarget: false,
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        if (selectedCompanies.length < 2) {
          setSelectedCompanies(["S&P 500", "DJIA"]);
          resolve();
        } else {
          resolve();
        }
      });
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step4",
    text: "You can navigate between the different tabs to get more information regarding your selected portfolios.",
    canClickTarget: false,
    attachTo: {
      element: ".nav-tab",
      on: "bottom",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step5",
    text: "Here you can see which page you are on, which year you are viewing, and options to export the data.",
    canClickTarget: false,
    modalOverlayOpeningPadding: 5,
    attachTo: {
      element: ".overview-header",
      on: "bottom",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step6",
    text: "Click here to download the comparison data in PDF format...",
    canClickTarget: false,
    modalOverlayOpeningPadding: 5,
    attachTo: {
      element: ".export",
      on: "bottom-start",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step7",
    text: "or select a different year in order to see the relevant data.",
    canClickTarget: false,
    modalOverlayOpeningPadding: 5,
    attachTo: {
      element: ".year",
      on: "bottom-start",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step8",
    text: "Here you can see the main information regarding your selected portfolios.",
    canClickTarget: false,
    attachTo: {
      element: ".cards",
      on: "bottom",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step9",
    text: "By clicking on this icon you can remove the selected portfolio.",
    canClickTarget: false,
    attachTo: {
      element: ".close",
      on: "left",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step10",
    text: "Now the comparison is not complete, you can select a new portfolio by clicking here.",
    canClickTarget: false,
    attachTo: {
      element: ".select",
      on: "bottom",
    },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        tour.hide();
        document.querySelector(".close").click();
        setStatus("running");
        setTimeout(() => {
          resolve();
        }, 200);
      });
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step11",
    text: "You can select a category with the radio buttons at the top of this panel.",
    canClickTarget: false,
    attachTo: {
      element: ".category",
      on: "bottom",
    },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        document.querySelector(".select").click();
        setTimeout(() => {
          resolve();
        }, 200);
      });
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step12",
    text: "From your selected category, you can choose a portfolio.",
    canClickTarget: false,
    attachTo: {
      element: ".portfolio",
      on: "bottom",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: function () {
          Shepherd.activeTour.hide();
          setStatus("idle");
          return new Promise(function (resolve) {
            document.querySelector(".portfolio").click();
            resolve();
          });
        },
      },
    ],
  });
  tour.addStep({
    id: "step13",
    text: "After you have selected a new portfolio, you can learn more about it in the comparison section below.",
    canClickTarget: false,
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step14",
    text: "You can see the impact by stakeholders of the selected portfolios here...",
    canClickTarget: false,
    attachTo: {
      element: ".stakeholders",
      on: "right",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step15",
    text: "and get more detailed information by clicking on one of these icons.",
    canClickTarget: false,
    scrollTo: false,
    attachTo: {
      element: ".details",
      on: "right",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step16",
    text: "On this card you can see which products and services are the most impactful out of your selected portfolios...",
    canClickTarget: false,
    attachTo: {
      element: ".products",
      on: "right",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step17",
    text: "and in these tables you can check the top 3.",
    canClickTarget: false,
    scrollTo: false,
    attachTo: {
      element: ".topthree",
      on: "right",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step18",
    text: "You can see the impact of your selected portfolios by the EU Taxonomy here.",
    canClickTarget: false,
    attachTo: {
      element: ".eutaxanomy",
      on: "right-start",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step19",
    text: "On this card you can learn more about the Sustainable Development Goals of your selected portfolios.",
    canClickTarget: false,
    attachTo: {
      element: ".sdgcard",
      on: "left-start",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step20",
    text: "This card shows the geographical distribution of your selected portfolios.",
    canClickTarget: false,
    attachTo: {
      element: ".geographic",
      on: "left-start",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step21",
    text: "On this graph you can see the impact history of your selected portfolios...",
    canClickTarget: false,
    attachTo: {
      element: ".history",
      on: "left-start",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step22",
    text: "and get filtered information by selecting from the dropdown menu.",
    canClickTarget: false,
    scrollTo: false,
    attachTo: {
      element: ".history-details",
      on: "bottom-start",
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step23",
    text: "If you want to learn more about a specific company, you can reach out to the Company Overview page by clicking here.",
    canClickTarget: false,
    attachTo: {
      element: ".menu-item-6",
      on: "bottom",
    },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        document.querySelector(".menu-item-1").click();

        const isOpen = document
          .querySelector(".menu-item-4")
          .getAttribute("aria-expanded");

        if (isOpen === "true") {
          findMenuWithClose();
        } else {
          findMenu();
        }

        async function findMenuWithClose() {
          await document.querySelector(".menu-item-4").click();
          findMenu();
        }

        async function findMenu() {
          await document.querySelector(".menu-item-4")?.click();
          await document.querySelector(".menu-item-5")?.click();
          resolve();
        }
      });
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: function () {
          tour.hide();
          clearURL();
          history.push(`${AllRoutes.company_overview}?stc=t`);
        },
      },
    ],
  });
  tour.addStep({
    id: "step24",
    text: "This is the Company Overview page, where you can compare a selected company with the relevant Industry Average.",
    canClickTarget: false,
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step25",
    text: "Now the comparison is empty, you can select a new company by clicking here.",
    canClickTarget: false,
    attachTo: {
      element: ".select",
      on: "bottom",
    },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(() => {
          resolve();
        }, 200);
      });
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step26",
    text: "You can select a new company, like a portfolio previously, by clicking on its name.",
    canClickTarget: false,
    attachTo: {
      element: ".portfolio",
      on: "bottom",
    },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        document.querySelector(".select").click();
        setTimeout(() => {
          resolve();
        }, 200);
      });
    },
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: function () {
          Shepherd.activeTour.hide();
          setStatus("idle");
          return new Promise(function (resolve) {
            document.querySelector(".portfolio").click();
            resolve();
          });
        },
      },
    ],
  });
  tour.addStep({
    id: "step27",
    text: "After you have selected a new company, you can see the comparison a few seconds later.",
    canClickTarget: false,
    buttons: [
      {
        text: "Finish Tour",
        action: tour.cancel,
        secondary: true,
      },
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    id: "step28",
    text: "Thank you for taking the tour, and have a great time with our website!",
    canClickTarget: false,
    buttons: [
      {
        text: "Finish Tour",
        action: tour.complete,
      },
    ],
  });

  const value = { tour, status, setStatus };

  return <TourContext.Provider value={value}>{children}</TourContext.Provider>;
}

export function useTourContext() {
  return useContext(TourContext);
}
