import { startEditingCell } from "ag-grid-react";
import React from "react";
import { twMerge } from "tailwind-merge";
import ToolTipRe from "../../../components/ui/redesign/ToolTip";

const SimpleNameCellRenderer = (props, ref) => {
  const { data, startEditingCell } = props;
  const error = data?.status === "error";
  return (
    <ToolTipRe
      onTop={true}
      disableRelative={true}
      disabled={error == false}
      noButton={true}
      wFull={true}
      offset={"left-0 bottom-10"}
      toolTipBase={
        <div
          style={{
            height: "51px",
            lineHeight: "51px",
          }}
          onDoubleClick={() => {
            console.log("double click", props);
            ref.current.api.tabToPreviousCell();
            ref.current.api.startEditingCell({
              rowIndex: props.rowIndex,
              colKey: "ag-Grid-AutoColumn",
            });
            //ref.current.api.tabToNextCell();
            /*  ref.current.api.setFocusedCell(0, "securityId");
          ref.current.api.startEditingCell({
            rowIndex: props.rowIndex,
            colKey: "securityId",
            // set to 'top', 'bottom' or undefined
          }); */
          }}
          className={twMerge(
            "flex items-center gap-x-2 pl-2 w-full cursor-pointer",
            error && "bg-red-100"
          )}
        >
          {data?.name}
          {error && (
            <img
              className="ml-auto pr-1 w-7 h-7"
              alt="error"
              src="/icon/row_error.svg"
            />
          )}
        </div>
      }
    >
      {error && (
        <p className="text-sm font-medium leading-normal text-gray-600">
          We can’t find this company. <br />
          Please edit or manually delete.
        </p>
      )}
    </ToolTipRe>
  );
};

export default SimpleNameCellRenderer;
