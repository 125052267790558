import React from "react";
import { useHistory } from "react-router-dom";
import { useDataContext } from "../../../context/PortfolioContext";
import { navigation } from "../Sidebar";
import { useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ idx, currentTab, onChangeTab, currentTabIdx }) {
  const tabs = navigation[idx].children[0].children;
  const history = useHistory();
  const { handleClearData } = useDataContext();

  const isUrlActive = (url, idx) => {
    return idx === currentTabIdx || history.location.pathname.includes(url);
  };

  const handleTabChange = (tab, idx) => {
    if (onChangeTab) {
      onChangeTab(tab.href, idx);
      handleClearData();
    } else {
      history.push(tab.href);
      handleClearData();
    }
  };

  //auto scroll in toptabs to the active tab
  useEffect(() => {
    const activeTab = document.querySelector(
      ".border-my-rift-primary-green-600"
    );
    if (activeTab) {
      const toptabs = document.getElementById("toptabs");
      toptabs.scrollLeft = activeTab.offsetLeft - toptabs.offsetWidth / 2;
    }
  }, [history.location.pathname]);

  return (
    <div className="w-full flex justify-center mx-auto bg-white sticky top-0 z-20">
      <div
        id="toptabs"
        className="block w-full overflow-x-auto md:overflow-hidden px-4 md:px-0 noscrollbar"
      >
        <div className="border-b border-gray-200">
          <nav
            className="flex justify-evenly -mb-px w-full gap-x-4 md:gap-x-0 nav-tab"
            aria-label="Tabs"
          >
            {tabs.map((tab, idx) => {
              const isCurrent = isUrlActive(tab.href, idx);
              return (
                <button
                  key={tab.name}
                  onClick={() => handleTabChange(tab, idx)}
                  className={classNames(
                    isCurrent
                      ? "border-my-rift-primary-green-600 text-my-tailwind-gray-600 font-semibold"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium text-my-tailwind-gray-600"
                  )}
                  aria-current={isCurrent ? "page" : undefined}
                >
                  {tab.name}
                </button>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
}
