import React, { useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Disclosure } from "@headlessui/react";
import formData from "./FormData"; // Ensure this path is correct
import BasicStep from "./BasicStep";
import InputField from "./InputField"; // Path to your InputField.js file
import PrimaryButton from "../../../components/ui/redesign/PrimaryButton";
import { useDataContext } from "../../../context/PortfolioContext";

const FormStep = ({
  step,
  year,
  onInputChange,
  formDataValues,
  prevStep,
  nextStep,
  register,
  control,
  watch,
  formState,
  setValue,
  getValues,
  validateCurrentStep,
}) => {
  const { category, fields } = formData[step];
  const {
    fields: fieldsArray,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "totalRevenue",
  });

  function validate(evt) {
    var theEvent = evt || window.event;
    var key;
    // Handle paste
    if (theEvent.type === "paste") {
      // eslint-disable-next-line no-restricted-globals
      key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 uppercase">
        {step == 0 ? category : step == 1 ? "Advanced" : ""}
      </h2>
      {step === 0 && (
        <div>
          {fields.map((field, index) => (
            <InputField
              year={year}
              key={index}
              field={field}
              register={register}
              validate={validate}
              onInputChange={onInputChange}
              value={formDataValues[field.name]}
              setValue={setValue}
              getValues={getValues}
              control={control}
              Controller={Controller}
              //
              fieldsArray={fieldsArray}
              append={append}
              remove={remove}
            />
          ))}{" "}
          <BasicStep
            key={year}
            year={year}
            watch={watch}
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
        </div>
      )}
      {step === 1 && (
        <div className="flex flex-col">
          {formData.slice(1).map((category, index) => (
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="w-full mb-8 mt-4 flex justify-between items-center py-2 px-4 border border-gray-300 rounded-md">
                    <span className="text-sm font-medium text-gray-600">
                      {category.category}
                    </span>
                    <img
                      alt="chevron"
                      src="/redesign/portfolio_impact/chevron.svg"
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-3.5 w-3.5  right-2 top-6 `}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="-mt-8  pt-4 pb-2 text-sm text-gray-500 bg-gray-100 px-1">
                    {category.fields.map((field, index) => (
                      <InputField
                        key={index}
                        field={field}
                        register={register}
                        validate={validate}
                        onInputChange={onInputChange}
                        value={formDataValues[field.name]}
                        setValue={setValue}
                        getValues={getValues}
                        control={control} // Pass 'control' to the InputField component
                        Controller={Controller} // Pass 'Controller' to the InputField component
                      />
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </div>
      )}
      {/*       {step === 2 && (
        <div>
          <h1>Confirmation</h1>
          {getValues().map((value, index) => (
            <p key={index}>{value}</p>
          ))}
        </div>
      )} */}
      <div className="w-full flex items-center justify-between mt-4">
        <div>
          {step > 0 && (
            <button type="button" onClick={prevStep}>
              <PrimaryButton text={"Previous"} />
            </button>
          )}
        </div>
        {step < 1 ? (
          <button type="submit">
            <PrimaryButton onClick={() => {}} text={"Next"} />
          </button>
        ) : (
          <button type="submit">
            {" "}
            <PrimaryButton
              onClick={() => {
                /*  setPrivateCompany("xd");
                //setCompanyList([{ name: "xd" }]);
                addCompany("xd", "company");
                setCurrentType("company"); */
              }}
              text={"Confirm"}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default FormStep;
