import React, { useState } from "react";
import { Controller } from "react-hook-form";
import ToolTipRe from "../../../components/ui/redesign/ToolTip";

function CheckboxPanel({
  control,
  name,
  search,
  options,
  title,
  description,
  getValues,
  setValue,
  watch,
}) {
  const [filter, setFilter] = useState("");

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(filter.toLowerCase())
  );

  const handleReset = () => {
    options.forEach((option) => {
      setValue(`${name}.${option.value}`, false);
    });
    setFilter("");
  };

  //get selected count

  let formValue = watch(name);
  const selectedCount = formValue
    ? Object.values(formValue).filter(Boolean).length
    : 0;

  //toggle select all
  const handleSelectAll = () => {
    const allSelected = selectedCount === filteredOptions.length;
    filteredOptions.forEach((option) => {
      setValue(`${name}.${option.value}`, !allSelected);
    });
  };

  return (
    <div className="flex flex-col">
      <div className="w-full flex justify-between items-center">
        {title && <p className="font-bold text-gray-600 text-base">{title}</p>}
        {description && (
          <div className="flex w-fit relative">
            <ToolTipRe
              onTop={false}
              width={200}
              toolTipBase={
                <img src="/redesign/portfolio_impact/info_outline.svg" />
              }
            >
              <p className="text-sm font-medium leading-normal text-gray-600">
                {description}
              </p>
            </ToolTipRe>
          </div>
        )}
      </div>{" "}
      {(search == true || options.length > 10) && (
        <input
          type="text"
          placeholder={"Type here..."}
          className="text-base leading-normal w-full py-1 pl-4 pr-3 rounded-lg text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none ring-1 ring-my-rift-primary-green-600 border-none mb-2 mt-2"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      )}
      <div className="w-full justify-between flex">
        <p
          onClick={handleSelectAll}
          className="cursor-pointer text-my-rift-primary-green-500 font-semibold"
        >
          Select all
        </p>
        {selectedCount != 0 ? (
          <span>Selected: {selectedCount}</span>
        ) : (
          <div></div>
        )}
        <button className="text-red-400" onClick={handleReset}>
          Reset
        </button>
      </div>
      {/*       SELECT ALL BUTTON
       */}
      <div className="flex flex-col gap-y-1 p-3 ">
        {filteredOptions.map((option) => (
          <Controller
            key={option.value}
            name={`${name}.${option.value}`}
            control={control}
            render={({ field }) => (
              <div className="checkbox-tick flex gap-3 items-center">
                <input
                  type="checkbox"
                  id={option.value}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
                <label className="check-box" for={option.value} />
                <p className="font-normal text-gray-900">{option.label}</p>
              </div>
            )}
          />
        ))}
      </div>
    </div>
  );
}

export default CheckboxPanel;
