import React from "react";
import ShowMore from "../../components/ui/redesign/ShowMore";
import { imageNameTrim } from "../../services/utilities";

const RawDataSection = ({ title, compare1, compare2 }) => {
  const datas = [
    {
      name: "Fair Wages",
      value: 90,
      fact: "companies employees are paid above the local living wage, that is ",
      difference: 26,
    },
    {
      name: "Diversity",
      value: -30,
      fact: "companies employees are women, that is ",
      difference: -12,
    },
    {
      name: "Equal Opportunity",
      value: -30,
      fact: "companies employees are women, and the % of women in management is 20%, leading to an opportunity gap of 10% points. The opportunity gap of",
      difference: -10,
    },
  ];

  return (
    <div>
      <div className="w-full flex items-center justify-between mb-6 ">
        <div className="flex items-center gap-x-2">
          <img src="/redesign/nav/raw_data.svg" />
          <p className=" text-sm font-bold leading-tight text-gray-600">
            {title}
          </p>
        </div>

        <ShowMore />
      </div>
      <div className="w-full grid grid-cols-1 gap-y-4 md:flex md:justify-between blur-[5px]">
        {datas.map((data) => {
          const isNegative = data.value < 0;

          return (
            <div className="flex flex-col w-full mx-0 md:mx-4">
              <div className="bg-my-tailwind-gray-50 py-4 flex flex-col items-center justify-center h-fit w-full rounded-2xl px-3">
                <img
                  src={`/redesign/general/${imageNameTrim(data.name)}.svg`}
                />
                <p className="text-sm leading-4 text-center font-medium text-my-tailwind-gray-600 mt-2 mb-8 ">
                  {data.name}
                </p>
                <p
                  className={`${
                    isNegative
                      ? "text-my-tailwind-red-500"
                      : "text-my-tailwind-green-500"
                  }  text-2xl leading-8 font-bold mb-1 tracking-wider`}
                >
                  {Math.abs(data.value)}%
                </p>
              </div>
              <div className="my-6 flex flex-col gap-y-2 mx-auto items-center px-4">
                <p className="text-sm leading-4 font-medium text-my-tailwind-gray-600">
                  of
                </p>
                <div className="flex gap-x-1 items-center">
                  <div
                    className={`flex shrink-0 rounded-full w-2 h-2 bg-my-tailwind-light-blue-500`}
                  ></div>
                  <p className="text-sm leading-5 font-bold text-my-tailwind-gray-600">
                    {compare1}
                  </p>
                </div>
                <p className="text-sm leading-4 font-medium text-center text-my-tailwind-gray-600">
                  {data.fact}
                </p>
                <p
                  className={`${
                    isNegative
                      ? "text-my-tailwind-red-500"
                      : "text-my-tailwind-green-500"
                  }  text-2xl leading-8 font-bold mb-1 tracking-wider`}
                >
                  {Math.abs(data.difference)}%
                </p>
                <p className="text-sm leading-4 font-medium text-my-tailwind-gray-600">
                  points {isNegative ? "less" : "more"} than in the{" "}
                </p>
                <div className="flex gap-x-1 items-center">
                  <div
                    className={`flex shrink-0 rounded-full w-2 h-2 bg-my-tailwind-purple-500`}
                  ></div>
                  <p className="text-sm leading-5 font-bold text-my-tailwind-gray-600">
                    {compare2}
                  </p>
                </div>
                <p className="text-sm leading-4 text-center font-medium text-my-tailwind-gray-600">
                  {data.investmentGoal}
                </p>
              </div>
              <div className="w-full flex justify-center items-center gap-x-2.5">
                <img
                  src={`/redesign/general/${imageNameTrim(data.name)}I.svg`}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RawDataSection;
