const formData = [
  {
    category: "BASIC",
    fields: [
      {
        required: true,
        title: "Company name",
        description: "Enter the name of the company.",
        unit: "",
        type: "text",
        name: "companyName",
      },

      /*       {
        required: true,
        title: "Company financial year",
        description: "Enter the year you want to analyze.",
        unit: "year",
        name: "companyFinancialYear",
        type: "dropdown",
        options: [
          { label: "2023 year", value: "2023" },
          { label: "2022 year", value: "2022" },
          // More options as needed
        ],
      }, */
      {
        required: true,
        title: "Main sales region",
        description: "Where does the main revenue come from?",
        unit: "revenue",
        name: "revenue",
        type: "dropdown",
        options: [
          { label: "Global", value: "global" },
          { label: "Europe", value: "europe" },
          { label: "North America", value: "northAmerica" },
          { label: "East Asia & Pacific", value: "eastAsiaPacific" },
          { label: "Central Asia", value: "centralAsia" },
          { label: "South Asia", value: "southAsia" },
          { label: "Sub-Saharan Africa", value: "subSaharanAfrica" },
          {
            label: "Latin America & Caribbean",
            value: "latinAmericaCaribbean",
          },
          {
            label: "Middle East & North Africa",
            value: "middleEastNorthAfrica",
          },
          // More options as needed
        ],
      },
      {
        required: true,
        title: "Company main currency",
        description: "Select which currency you want to calculate in.",
        unit: "",
        name: "currency",
        type: "dropdown",
        options: [
          { label: "USD", value: "usd" },
          { label: "EUR", value: "eur" },
          { label: "GBP", value: "gbp" },
          { label: "CNY", value: "cny" },
          // More options as needed
        ],
      },
      {
        required: true,
        title: "Revenues by Business Segment",

        name: "totalRevenue",
        array: [
          {
            name: "revenue",
            type: "number",
            unit: "currency",
            description: "Revenue",
          },
          {
            name: "segment",
            type: "text",
            unit: "",
            description: "Describe what type of segment the income comes from",
          },
        ],
      },
      {
        required: false,
        title: "Company website URL",
        description: "Enter the website of the company.",
        unit: "Website, or LinkedIn",
        type: "text",
        name: "companyWebsiteUrl",
      },
    ],
  },
  {
    category: "EMPLOYMENT",
    fields: [
      {
        title: "Median Wage",
        description: "Enter the median wage within the organization.",
        unit: "currency",
        type: "number",
        name: "medianWage",
      },
      {
        title: "Mean Wage",
        description: "Enter the mean wage within the organization.",
        unit: "currency",
        type: "number",
        name: "meanWage",
      },
      {
        required: false,
        title: "Percentage Women in Management",
        description: "Enter the percentage of women in management positions.",
        unit: "%",
        type: "number",
        name: "percentageWomenManagement",
      },
      {
        required: false,
        title: "Percentage Women in the Company",
        description:
          "Enter the overall percentage of women within the company.",
        unit: "%",
        type: "number",
        name: "percentageWomenCompany",
      },
      {
        title: "Total Number of Employees",
        description: "Enter the total number of employees in the company.",
        unit: "number",
        type: "number",
        name: "totalEmployees",
      },
      {
        required: false,
        title: "Salaries, wages, and benefits",
        description:
          "Enter the total amount spent on salaries, wages, and benefits.",
        unit: "currency",
        type: "number",
        name: "salariesWagesBenefits",
      },
    ],
  },
  {
    category: "CUSTOMER",
    fields: [
      {
        title: "Consumers Affected by Data Breach",
        description: "Enter the number of consumers affected by data breaches.",
        unit: "number of people",
        type: "number",
        name: "consumersAffectedDataBreach",
      },
      {
        required: false,
        title: "Recalled FDA Class One Medical Device Units",
        description:
          "Enter the number of Class One medical device units recalled by the FDA.",
        unit: "number of recalled units",
        type: "number",
        name: "recalledFdaClassOne",
      },
      {
        required: false,
        title: "Recalled FDA Class Two Medical Device Units",
        description:
          "Enter the number of Class Two medical device units recalled by the FDA.",
        unit: "number of recalled units",
        type: "number",
        name: "recalledFdaClassTwo",
      },
      {
        required: false,
        title: "Recalled FDA Class Three Medical Device Units",
        description:
          "Enter the number of Class Three medical device units recalled by the FDA.",
        unit: "number of recalled units",
        type: "number",
        name: "recalledFdaClassThree",
      },
      {
        required: false,
        title: "Recalled NHTSA Units",
        description:
          "Enter the number of units recalled as reported by the National Highway Traffic Safety Administration.",
        unit: "number of recalled units",
        type: "number",
        name: "recalledNhtsaUnits",
      },
      {
        required: false,
        title: "Recalled CPSC Appliance & Machinery Units",
        description:
          "Enter the number of appliance and machinery units recalled as reported by the Consumer Product Safety Commission.",
        unit: "number of recalled units",
        type: "number",
        name: "recalledCpscUnits",
      },
    ],
  },

  {
    category: "ENVIRONMENT",
    fields: [
      {
        title: "Food Waste in Tonnes",
        description:
          "Enter the total food waste produced by the company in metric tonnes.",
        unit: "metric tons",
        type: "number",
        name: "foodWasteTonnes",
      },
      {
        title: "Plastic Packaging in Tonnes",
        description:
          "Enter the total plastic packaging used by the company in metric tonnes.",
        unit: "metric tons",
        type: "number",
        name: "plasticPackagingTonnes",
      },
      {
        required: false,
        title: "Scope 1 Emissions in Tonnes",
        description:
          "Enter the total Scope 1 greenhouse gas emissions in metric tonnes.",
        unit: "metric tons",
        type: "number",
        name: "scope1EmissionsTonnes",
      },
      {
        required: false,
        title: "Scope 2 Emissions in Tonnes",
        description:
          "Enter the total Scope 2 greenhouse gas emissions in metric tonnes.",
        unit: "metric tons",
        type: "number",
        name: "scope2EmissionsTonnes",
      },
      {
        required: false,
        title: "Scope 3 Emissions in Tonnes",
        description:
          "Enter the total Scope 3 greenhouse gas emissions in metric tonnes.",
        unit: "metric tons",
        type: "number",
        name: "scope3EmissionsTonnes",
      },
      {
        required: false,
        title: "Scope 3 Upstream Emissions in Tonnes",
        description:
          "Enter the total Scope 3 upstream greenhouse gas emissions in metric tonnes.",
        unit: "metric tons",
        type: "number",
        name: "scope3UpstreamEmissionsTonnes",
      },
      {
        required: false,
        title: "Scope 3 Downstream Emissions in Tonnes",
        description:
          "Enter the total Scope 3 downstream greenhouse gas emissions in metric tonnes.",
        unit: "metric tons",
        type: "number",
        name: "scope3DownstreamEmissionsTonnes",
      },
      {
        required: false,
        title: "Scope 3 Emissions by Type in Tonnes",
        description:
          "Specify and enter the Scope 3 emissions by type, in metric tonnes.",
        unit: "metric tons",
        type: "number",
        name: "scope3EmissionsTypeTonnes",
      },
      {
        required: false,
        title: "Water discharged",
        description:
          "Enter the total volume of water discharged by the company.",
        unit: "cubic meters or metric tons",
        type: "number",
        name: "waterDischarged",
      },
      {
        required: false,
        title: "Total Water Withdrawn",
        description:
          "Enter the total volume of water withdrawn by the company.",
        unit: "cubic meters or metric tons",
        type: "number",
        name: "totalWaterWithdrawn",
      },
      {
        required: false,
        title: "Hazardous Waste Produced",
        description:
          "Enter the total volume of hazardous waste produced by the company.",
        unit: "metric tons",
        type: "number",
        name: "hazardousWasteProduced",
      },
    ],
  },
];

export default formData;
