import React from "react";
import ChartLegend from "../../ui/redesign/ChartLegend";
import ShowMore from "../../ui/redesign/ShowMore";
import ShortDescriptionInfo from "../portfolio_impact/ShortDescriptionInfo";

function sortDataByAverage(data) {
  // Sort the array by the average of the data values
  data.sort((a, b) => {
    const aAvg = a.data.reduce((acc, val) => acc + val, 0) / a.data.length;
    const bAvg = b.data.reduce((acc, val) => acc + val, 0) / b.data.length;
    return bAvg - aAvg;
  });
  return data;
}

const HorizontalBarChart = ({
  title,
  data,
  portfolio1,
  portfolio2,
  color,
  info,
  viewMore,
}) => {
  const sortedData = sortDataByAverage(data);

  return (
    <div>
      {title && (
        <div className="w-full flex items-center justify-between mb-6">
          <div className="flex items-center gap-x-2">
            <img src="/redesign/nav/info.svg" />
            <p className=" text-sm font-bold leading-tight text-gray-600">
              {title}
            </p>
          </div>
          <ShowMore onClick={viewMore} />
        </div>
      )}
      <div className="flex flex-col gap-y-3 mb-4 max-h-[600px] overflow-y-auto">
        {sortedData.map((item) => {
          return (
            <div className="flex flex-col gap-y-[6px]">
              <p className="text-xs font-semibold leading-none text-gray-600">
                {item.name}
              </p>
              {item.data.map((dat, idx) => (
                <div className="w-full flex items-center gap-x-2">
                  <div className="w-full h-2.5 bg-my-tailwind-gray-50 rounded-lg">
                    <div
                      style={{
                        width: `${dat ?? 0}%`,
                      }}
                      className={`h-2.5 ${
                        color != null
                          ? color
                          : idx == 0
                          ? "bg-my-tailwind-light-blue-500"
                          : "bg-my-tailwind-purple-500"
                      }   rounded-lg`}
                    ></div>
                  </div>
                  <p className="w-fit text-xs font-semibold leading-none text-gray-500">
                    {dat.toFixed(1)}%
                  </p>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      {portfolio1 && portfolio2 && (
        <ChartLegend
          isVertical={false}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
        />
      )}
      <div className="mt-4">
        {info && <ShortDescriptionInfo description={info} />}
      </div>
    </div>
  );
};

export default HorizontalBarChart;
