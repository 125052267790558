export const rowData = [
  {
    name: "Adidas AG",
    portfolio: null,
    securityId: "DE000A1EWWW0",
    status: "ok",
    position: 100,
  },
  {
    name: "Adobe Inc.",
    portfolio: null,
    securityId: "US00724F1012",
    status: "ok",
    position: 150,
  },
  {
    name: "Agilent Technologies, Inc.",
    portfolio: null,
    securityId: "US00846U1016",
    status: "error",
    position: 200,
  },

  {
    name: "Stryker Corporation",
    portfolio: "S&P 500",
    securityId: "US00846U1017",
    status: "ok",
    position: 122,
  },
  {
    name: "Sysmex Corporation",
    portfolio: "S&P 500",
    securityId: "US00846U1018",
    status: "ok",
    position: 300,
  },
  {
    name: "USA",
    portfolio: "S&P 500",
    securityId: "US00846U1020",
    status: "ok",
    position: 350,
  },
  {
    name: "USA",
    portfolio: "S&P 500",
    securityId: "US00846U3018",
    status: "error",
    position: 210,
  },

  {
    name: "USA",
    portfolio: "Electrical Utilities Without Greenhouse Gas Emissions",
    securityId: "US00846U4238",
    status: "ok",
    position: 50,
  },
  {
    name: "USA",
    portfolio: "Electrical Utilities Without Greenhouse Gas Emissions",
    securityId: "US00846U14214",
    status: "ok",
    position: 75,
  },
];
