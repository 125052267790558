import { generalActions } from "./slice";
import axios from "axios";

export const initSession = () => {
    return async (dispatch) => { 
        try {
            const session = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/session`, {}, {
              withCredentials: true,
              credentials: 'include'
            });
      
            dispatch(
              generalActions.setSessionId({
                token: session.data.token,
              })
            );

            dispatch(
              generalActions.setEmail({
                email: session.data.email,
              })
            );
      
          } catch (error) {
            dispatch( handleError(error, "initSession") );
          }
    }
}

export const fetchIndustries = () => {
    return async (dispatch) => {
        try {
            const industries = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/industries`, {
              withCredentials: true,
              credentials: 'include'
            });
      
            dispatch(generalActions.setIndustries(industries.data));

            let companies = [];

            industries.data.forEach((i) => {
              if (i?.publicCompanies?.length > 0 && i.rgsCode === i.referenceRgsCode) {
                i.publicCompanies.forEach((c) => {
                  companies.push({
                    ...c,
                    rgsCode: i.rgsCode,
                    reference: i.referenceRgsCode,
                    subindustry: i.subindustry,
                    industryHierarchy: {
                      ...i
                    }
                  }) 
                })
              }
            })

            dispatch( generalActions.setPublicCompanies(companies));
      
          } catch (error) {
            dispatch( handleError(error, "fetchIndustries") );
          }
    }
}

export const fetchProductGroups = () => {
  return async (dispatch) => {
      try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/product-groups`, {
            withCredentials: true,
            credentials: 'include'
          });

          dispatch(generalActions.setProductGroups(response.data));

      } catch (error) {
        dispatch( handleError(error, "fetchProductGroups") );
      }
  }
}

export const handleError = (err, source) => {
  return async (dispatch) => {
    // Global error log
    console.log({
      status: err?.response?.status,
      url: err?.config?.url,
      method: err?.config?.method,
      req: err?.config?.data,
      res: err?.response?.data,
      timestamp: new Date(),
      err: err,
      source: source
    })

    // handle 401 unauthorized -> redirect to login url
    if (err?.response?.status === 401) {
      if (err.response?.data?.redirect_url) {
        // Create return url
        let returnUrl = window.location.protocol + "//" + window.location.host + "?auth=1";
            returnUrl = encodeURIComponent(returnUrl)

        if (window.location?.search.includes("auth=1")) {
          alert("Error occured during login!")
        } else {
          // base auth url
          let authUrl = `${err.response.data.redirect_url}?return_url=${returnUrl}`;

          window.location.href = authUrl
        }
      }
    } else {
      alert(`Error calling: ${err?.config?.url}. Check the console for details.`);
    }
  }
}