import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const DoughnutChart = ({ data, title }) => {
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 250,
      height: 250,
    },
    title: {
      text: title, // Example title
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        size: "100px",
        innerSize: "60px", // Ensures minimum size for the doughnut
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Brands",
        colorByPoint: true,
        data: data,
      },
    ],
  };

  return (
    <div className="themeid">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default DoughnutChart;
