import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { classNames } from "../../../services/utilities";
import { useDataContext } from "../../../context/PortfolioContext";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const YearSelector = ({ disabled }) => {
  const { response, setYear, year } = useDataContext(); //TODO REMOVED ALL YEARS IF NOT DYNAMICALLY WANT
  const location = useLocation();

  const isOnPSICS = location.pathname.includes("psics");

  const allYears = [
    { id: 1, name: "2022" },
    { id: 2, name: "2021" },
    { id: 3, name: "2020" },
    { id: 4, name: "2019" },
    { id: 5, name: "2018" },
    { id: 6, name: "2017" },
    { id: 7, name: "2016" },
    { id: 8, name: "2015" },
  ];
  const [selected, setSelected] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (allYears.length > 0) {
      setSelected(allYears.find((y) => y.name === year) || allYears[0]);
    }
  }, [response, allYears, year]);

  if (allYears.length <= 0) return <></>;

  /*   const disabledPath =
    history.location.pathname.includes("portfolio-overview") ||
    history.location.pathname.includes("portfolio-impact") ||
    history.location.pathname.includes("company-impact")
      ? false
      : disabled; */
  const disabledPath = false;

  const filteredYears = isOnPSICS
    ? allYears.filter((year) => year.name !== "2015")
    : allYears;

  return (
    <Listbox
      disabled={disabledPath}
      value={selected}
      onChange={(y) => {
        setSelected(y);
        setYear(y.name);
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900"></Listbox.Label>
          <div className="relative">
            <Listbox.Button className="relative w-full flex cursor-default  py-1.5 pl-3 gap-x-1 text-left text-gray-900 bg-white shadow rounded-md focus:outline-none sm:text-sm sm:leading-6 year">
              <span className="block ">{selected?.name}</span>
              <span className="pointer-events-none w-6 h-6 right-0 flex items-center p-1">
                <img
                  src="/redesign/portfolio_impact/chevron.svg"
                  className="h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredYears.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "text-my-rift-primary-green-600"
                          : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block"
                          )}
                        >
                          {person.name}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default YearSelector;
