import { useEffect, useState } from "react";
import {
  classNames,
  formatNumber,
  imageNameTrim,
} from "../../../services/utilities";
import HoverButton from "../../ui/redesign/HoverButton";
import ToolTipRe from "../../ui/redesign/ToolTip";
import ModalRe from "../../ui/redesign/Modal";
import RevenueDetails from "./RevenueDetails";
import SimpleColumnChart from "../charts/SimpleColumnChart";
import PremiumFeature from "../PremiumSoon";

export default function RevenueTable({ allData, color, hack }) {
  const [sortColumn, setSortColumn] = useState("revenue");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortedData, setSortedData] = useState(allData);

  const toggleSortOrder = (category) => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  useEffect(() => {
    if (sortColumn == null) return;
    let sortedNewTemp = [];
    sortColumn == "category"
      ? (sortedNewTemp = [...allData].sort((a, b) => {
          if (sortOrder === "asc") {
            return a[sortColumn]
              .toString()
              .localeCompare(b[sortColumn].toString());
          } else {
            return b[sortColumn]
              .toString()
              .localeCompare(a[sortColumn].toString());
          }
        }))
      : (sortedNewTemp = [...allData].sort((a, b) => {
          if (sortOrder === "asc") {
            return a[sortColumn] < b[sortColumn] ? -1 : 1;
          } else {
            return a[sortColumn] > b[sortColumn] ? -1 : 1;
          }
        }));
    setSortedData(sortedNewTemp);
  }, [sortColumn, sortOrder]);

  return (
    <div className="">
      <div className="flow-root ">
        <div className="w-full ">
          <div className="inline-block py-2 align-middle w-full">
            <div className="grid grid-cols-8">
              <div
                onClick={() => {
                  setSortColumn("category");
                  toggleSortOrder("category");
                }}
                className="col-span-3 cursor-pointer flex items-center rounded-tl-lg px-2 py-3 border-b-[1px] border-gray-200 bg-gray-50"
              >
                <p className="text-xs font-semibold leading-tight pr-2 text-gray-500">
                  PSICS Revenue Category
                </p>
                {
                  <img
                    className={`${
                      sortColumn == "category" ? "opacity-1" : "opacity-0"
                    } ${sortOrder == "asc" ? "-rotate-180" : ""}`}
                    src="/redesign/general/SortArrow.svg"
                  />
                }
              </div>
              <div
                onClick={() => {
                  setSortColumn("revenue");
                  toggleSortOrder("revenue");
                }}
                className="col-span-2 cursor-pointer flex items-center justify-center  px-2 py-3 border-b-[1px] border-gray-200 bg-gray-50"
              >
                <p className="text-xs font-semibold text-center leading-tight pr-2 text-gray-500">
                  Category Revenue
                </p>
                {
                  <img
                    className={`${
                      sortColumn == "revenue" ? "opacity-1" : "opacity-0"
                    } ${sortOrder == "asc" ? "-rotate-180" : ""}`}
                    src="/redesign/general/SortArrow.svg"
                  />
                }
              </div>
              <div
                onClick={() => {
                  setSortColumn("percentage");
                  toggleSortOrder("percentage");
                }}
                className="col-span-2 cursor-pointer flex items-center justify-center  px-2 py-3 border-b-[1px] border-gray-200 bg-gray-50"
              >
                <p className="text-xs font-semibold text-center leading-tight pr-2 text-gray-500">
                  Percentage of Portfolio Revenues
                </p>
                {
                  <img
                    className={`${
                      sortColumn == "percentage" ? "opacity-1" : "opacity-0"
                    } ${sortOrder == "asc" ? "-rotate-180" : ""}`}
                    src="/redesign/general/SortArrow.svg"
                  />
                }
              </div>
              <div className="col-span-1 flex items-center justify-center  border-b-[1px] rounded-tr-lg px-2 py-3 border-gray-200 bg-gray-50">
                <p className="text-xs font-semibold leading-tight px-2 text-gray-500">
                  Product Impacts
                </p>
              </div>
            </div>
            <div
              className="h-[400px] grid grid-cols-8 overflow-y-scroll supervisortable"
              style={{
                height: "400px",
              }}
            >
              <div className="w-full col-span-8">
                {sortedData.map((person, idx) => {
                  return (
                    <div
                      className={`${
                        idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                      } w-full grid-flow-col grid grid-cols-8 col-span-8`}
                    >
                      <div
                        className={`col-span-3 px-2 my-auto ${
                          idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                        }`}
                      >
                        <ModalRe
                          // disabled={idx > 10}
                          title={person.category}
                          maxWidth={"60%"}
                          content={
                            <RevenueDetails
                              data={person.companyList}
                              color={color}
                              subtitle={person.productCategoryDescription}
                            />
                          }
                        >
                          <div
                            className={`text-xs text-left font-normal leading-tight text-my-tailwind-gray-600 hover:text-my-rift-primary-green-600 group flex items-center gap-x-1.5 cursor-pointer ${
                              idx > 10 ? "" : ""
                            }`}
                          >
                            {person.category}
                            <span className="group-hover:visible invisible">
                              <img src="/redesign/general/arrow_expand.svg" />
                            </span>
                          </div>
                        </ModalRe>
                      </div>
                      <div
                        className={`col-span-2 my-auto ${
                          idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                        }`}
                      >
                        <p
                          className={`text-xs font-normal leading-tight  text-gray-500 px-2 py-4 text-center ${
                            idx > 10 ? "" : ""
                          }`}
                        >
                          $ {formatNumber(person.revenue)}
                        </p>
                      </div>
                      <div
                        className={`col-span-2 my-auto ${
                          idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                        }`}
                      >
                        <p
                          className={`text-xs font-normal leading-tight  text-gray-500 px-2 py-4 text-center ${
                            idx > 10 ? "" : ""
                          }`}
                        >
                          {person.percentage.toFixed(2)}%
                        </p>
                      </div>
                      <div
                        className={`col-span-1 flex justify-end my-auto relative ${
                          idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                        }`}
                      >
                        <p
                          className={`text-xs font-normal leading-tight  text-gray-500 px-2 py-4 ${
                            idx > 10 ? "" : ""
                          }`}
                        >
                          <ToolTipRe
                            offset={`right-12 ${idx < 3 ? "top-6" : "-top-12"}`}
                            disableRelative={true}
                            // disabled={idx > 15}
                            toolTipBase={
                              <HoverButton
                                outHover={
                                  <img src="/redesign/general/more_circle.svg" />
                                }
                                onHover={
                                  idx <= 15 ? (
                                    <img src="/redesign/general/more_circle_active.svg" />
                                  ) : (
                                    <img src="/redesign/general/more_circle.svg" />
                                  )
                                }
                              />
                            }
                          >
                            {/*
                            TEMPORARY REMOVAL
                            {(idx == 0
                              ? hack == 0
                                ? [
                                    "Downstream Scope 3 Carbon Emissions",
                                    "Customer Utility",
                                  ]
                                : ["Data Breaches", "Customer Utility"]
                              : person.relevantImpactPillars
                            ).map((imp) => (
                              <div class="flex mt-3 gap-x-2 items-center justify-start w-44">
                                {
                                  <img
                                    className="flex-shrink-0 w-6 h-6"
                                    src={`/redesign/general/${imageNameTrim(
                                      imp
                                    )}.svg`}
                                  />
                                }
                                <div className="flex-shrink-0 max-w-[140px]">
                                  <p class="text-xs text-left font-semibold leading-none text-gray-600">
                                    {imp}
                                  </p>
                                </div>
                              </div>
                            ))}
                            */}
                            <p className="w-36 text-center font-bold">
                              Upgrade plan to view mapped impacts
                            </p>
                          </ToolTipRe>
                        </p>
                      </div>
                    </div>
                  );
                })}
                {/*   <div className="relative mt-4">
                  <div className="top-0 z-10 mx-auto bg-gray-50">
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
