import { createSlice } from "@reduxjs/toolkit";

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState: {
    isLoading: false,
    isSuccess: false,
  },
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsSuccess(state, action) {
      state.isSuccess = action.payload;
    },
  },
});

export const portfolioActions = portfolioSlice.actions;

export default portfolioSlice;
