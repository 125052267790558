import React from "react";

const TopTab = ({ tabs, onChange, active }) => {
  return (
    <div className="w-full flex items-center justify-between bg-white border-b-[1px] border-my-tailwind-gray-200 overflow-x-auto flex-nowrap break-keep gap-x-4 md:gap-x-0">
      {tabs.map((item, idx) => (
        <div
          key={item.name}
          onClick={() => onChange(idx)}
          className={`w-fit flex gap-x-2 ${
            tabs.length > 10 ? "flex-shrink-0" : ""
          } justify-center flex-nowrap break-keep items-center pt-3 pb-2.5 mx-4 cursor-pointer ${
            idx == active
              ? "border-b-[1px] border-my-rift-primary-green-600"
              : ""
          }`}
        >
          {item.icon && <img className="w-6 h-6" src={item.icon} />}
          <p
            className={`text-sm ${
              idx == active ? "font-bold" : ""
            } leading-tight text-center break-keep flex-nowrap  text-gray-600`}
          >
            {item.name}
          </p>
        </div>
      ))}
      {/*       selector input dropdown for mobile
       */}
    </div>
  );
};

export default TopTab;
