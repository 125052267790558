import React from "react";

import ReactDOM from "react-dom";
import { useForm, Controller } from "react-hook-form";
import Slider from "react-input-slider";
import ToolTipRe from "../../../components/ui/redesign/ToolTip";
const SliderPanel = ({
  control,
  name,
  title,
  description,
  unit,
  min,
  step,
  max,
  setValue,
}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="w-full flex justify-between items-center">
        {title && <p className="font-bold text-gray-600 text-base">{title}</p>}
        {description && (
          <div className="flex w-fit justify-end">
            <ToolTipRe
              onTop={false}
              width={200}
              offset={"right-0 top-10"}
              toolTipBase={
                <img src="/redesign/portfolio_impact/info_outline.svg" />
              }
            >
              <p className="text-sm font-medium leading-normal text-gray-600">
                {description}
              </p>
            </ToolTipRe>
          </div>
        )}
      </div>{" "}
      <Controller
        control={control}
        name={name}
        defaultValue={null}
        //defaultValue={max / 2}
        render={({ field: { value, onChange } }) => (
          <div id="customslider" className="shadow-inner w-full pt-2 px-1">
            <SliderComponent
              axis={"x"}
              xmax={max}
              xmin={min}
              xstep={step}
              onChange={(e) => {
                onChange(e);
                setValue(name, [e]);
              }}
              value={value}
            />
            <p className="font-medium text-gray-600 mb-4">
              Value: {value} {unit}
            </p>
          </div>
        )}
      />
    </div>
  );
};

function SliderComponent({ axis, xmax, xmin, xstep, onChange, value }) {
  return (
    <div>
      <Slider
        axis={axis}
        x={value}
        xmax={xmax}
        xmin={xmin}
        xstep={xstep}
        onChange={({ x }) => onChange(x)}
      />
    </div>
  );
}

export default SliderPanel;
