import { useState, useEffect } from "react";
import ArcProgress from "react-arc-progress";
import React from "react";

const greenActive = "#10B981";
const greenInactive = "#D1FAE5  ";
const redActive = "#DC2626";
const redInactive = "#FEE2E2";

function SemiCircleChart({ flip, progress, value }) {
  const size = "140";
  const fillColor = "#10B981";
  const emptyColor = "#D1FAE5";

  return (
    <div className={flip ? "flip_V" : "flex justify-center "}>
      <ArcProgress
        progress={!progress ? 0 : 1 * progress}
        size={size}
        fillColor={!progress ? emptyColor : fillColor}
        emptyColor={emptyColor}
        thickness={22}
        fillThickness={22}
        arcStart={180}
        arcEnd={360}
        observer={(current) => {
          const { percentage, currentText } = current;
        }}
        animationEnd={({ progress, text }) => {}}
      />
    </div>
  );
}

export default React.memo(function SemiCircleChartMemo({
  flip,
  progress,
  value,
}) {
  const [delay, setDelay] = useState(0);
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowComponent(true);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay]);

  if (!showComponent || progress === undefined) {
    return null;
  }

  return <SemiCircleChart flip={flip} progress={progress} value={value} />;
});
