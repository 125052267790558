function ToggleSwitch({ toggle, setToggle, title }) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="checkbox-rocker">
        <label className="switch">
          <input
            type="checkbox"
            onChange={(e) => setToggle(e.target.checked)}
            checked={toggle}
          />
          <div className="slider">
            <div className="circle">
              <img
                src={"/redesign/general/checkmark.svg"}
                alt="Checkmark Icon"
                className="checkmark"
              />
            </div>
          </div>
        </label>
      </div>
      <p className="text-[0.75rem] -mt-1">{title}</p>
    </div>
  );
}

export default ToggleSwitch;
