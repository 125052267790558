import React from "react";
import { twMerge } from "tailwind-merge";

const Stepper = ({ steps, activeStep, changeStep, className }) => {
  return (
    <div className={twMerge(className, "flex w-full")}>
      {steps.map((step, index) => (
        <React.Fragment key={step.stepNumber}>
          {/* Step circle and title */}
          <div
            onClick={() => changeStep(index)}
            className={twMerge("flex items-center cursor-pointer w-fit")}
          >
            <div
              className={twMerge(
                `w-8 h-8 rounded-full flex items-center justify-center ${
                  index <= activeStep
                    ? "text-my-rift-primary-green-600 border-my-rift-primary-green-600"
                    : "text-gray-500 border-gray-300"
                } border-2`,
                index < activeStep && "bg-my-rift-primary-green-600"
              )}
            >
              {index < activeStep ? (
                <img
                  src="/icon/step_done.svg"
                  alt="check"
                  className="w-4 h-4"
                />
              ) : (
                step.stepNumber
              )}
            </div>
            <div
              className={`ml-2 ${
                index <= activeStep
                  ? "text-my-rift-primary-green-600"
                  : "text-gray-500"
              }`}
            >
              {step.title}
            </div>
          </div>
          {/* Divider */}
          {index < steps.length - 1 && (
            <div className="flex-auto min-w-[16px] mx-4 border-t-2 border-gray-300"></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Stepper;
