import React, { useEffect } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { Listbox } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import formData from "./FormData";
import FormattedInput from "./FormattedInput";
import NumberFormat from "react-number-format";

const currencies = [
  { id: "usd", name: "USD" },
  { id: "eur", name: "EUR" },
  { id: "gbp", name: "GBP" },
  { id: "cny", name: "CNY" },
  // Add more currencies as needed
];

const InputField = ({
  year,
  field,
  control,
  register,
  validate,
  setValue,
  getValues,
  append,
  remove,
  fieldsArray,
}) => {
  const [selectedCurrency, setSelectedCurrency] = React.useState(currencies[0]);

  useEffect(() => {
    if (field.unit != "currency") return;

    let value = getValues(field.name);
    if (!value) return;
    if (value.includes(" ")) {
      let currency = value.split(" ")[1];
      setSelectedCurrency(currencies.find((c) => c.name === currency));
    }
  }, []);

  useEffect(() => {
    if (field.unit != "currency") return;
    let value = getValues(field.name);
    if (!value) return;
    setValue(field.name, `${value.split(" ")[0]} ${selectedCurrency.name}`);
  }, [selectedCurrency]);

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: "#d1d5db", // Tailwind CSS gray-300
      boxShadow: "none",
      "&:hover": {
        borderColor: "#6B7280", // Tailwind CSS gray-400 for hover state
      },
      borderRadius: "0.375rem", // Tailwind CSS rounded-md
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#71d37b" : "white", // Tailwind CSS green-500 when selected
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: state.isSelected ? "#71d37b" : "#F3F4F6", // Tailwind CSS gray-100 for hover state on non-selected options
        color: state.isSelected ? "white" : "black",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "#9CA3AF" : "#4B5563", // Tailwind CSS gray-500
    }),
  };

  // For dropdown fields
  if (field.type === "dropdown") {
    return (
      <div className="mb-4">
        <label
          htmlFor={field.name}
          className="block text-sm font-medium text-gray-700"
        >
          {field.title}{" "}
          {field.required ? (
            <span className="text-red-500">*</span>
          ) : (
            <span>(Optional)</span>
          )}
        </label>
        <Controller
          name={field.name}
          control={control}
          defaultValue={null}
          //make it required if the field is required
          rules={{ required: field.required }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Select
              inputRef={ref}
              key={`${year}-${field.name}`}
              classNames={{
                control: () => "border border-gray-300 rounded-md",
              }}
              isSearchable={false}
              styles={customSelectStyles} // Apply custom styles
              options={field.options}
              value={field.options.find((option) => option.value === value)}
              onChange={(val) => onChange(val.value)}
              onBlur={onBlur}
              id={field.name}
              instanceId={field.name} // Unique ID for each select
            />
          )}
        />
        <p className="mt-2 text-sm text-gray-500">{field.description}</p>
      </div>
    );
  }

  if (field.array) {
    return (
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          {field.title}{" "}
          {field.required && <span className="text-red-500">*</span>}
        </label>
        <p className="mt-2 text-sm text-gray-500">{field.description}</p>
        <div className="flex flex-col gap-y-4">
          {fieldsArray.map((item, index) => (
            <div className="flex gap-x-4 items-top" key={item.id}>
              {field.array.map((subField, idx) => {
                const isFormattedInput = subField.type === "number"; // Adjust this condition as needed

                if (isFormattedInput) {
                  return (
                    <div className={idx == 1 && "w-full"} key={subField.name}>
                      <Controller
                        key={subField.name}
                        name={`${field.name}[${index}].${subField.name}`}
                        control={control}
                        render={({ field }) => (
                          <NumberFormat
                            {...field}
                            thousandSeparator={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              field.onChange(value); // Update the form value
                            }}
                            className={twMerge(
                              "focus:ring-my-rift-primary-green-500 focus:border-my-rift-primary-green-500 block w-full pl-4 sm:text-sm border-gray-300 rounded-md",
                              idx == 1 && "w-full"
                            )}
                          />
                        )}
                      />
                      <label className="text-sm text-gray-500">
                        {subField.description}
                      </label>
                    </div>
                  );
                }

                return (
                  <div className={idx == 1 && "w-full"} key={subField.name}>
                    <input
                      {...register(`${field.name}[${index}].${subField.name}`, {
                        required: true,
                      })}
                      type={"text"}
                      className={twMerge(
                        "focus:ring-my-rift-primary-green-500 focus:border-my-rift-primary-green-500 block w-full pl-7 sm:text-sm border-gray-300 rounded-md",
                        idx == 1 && "w-full"
                      )}
                    />
                    <label className="text-sm text-gray-500">
                      {subField.description}
                    </label>
                  </div>
                );
              })}

              {index != 0 && (
                <img
                  onClick={() => remove(index)}
                  src="/redesign/portfolio_impact/cancel.svg"
                  className="relative -mt-4  w-fit cursor-pointer"
                />
              )}
            </div>
          ))}
        </div>
        <button
          className="w-full text-gray-900 bg-my-rift-primary-green-400/70 hover:bg-my-rift-primary-green-400 mt-4 mb-4 rounded-xl px-4 py-1"
          type="button"
          onClick={() => append({})}
        >
          +
        </button>
      </div>
    );
  }

  if (field.unit === "currency") {
    return (
      <div className="mb-4">
        <label
          htmlFor={field.name}
          className="block text-sm font-medium text-gray-700"
        >
          {field.title}{" "}
          {field.required ? (
            <span className="text-red-500">*</span>
          ) : (
            <span>(Optional)</span>
          )}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <Controller
            name={field.name}
            control={control}
            rules={{ required: field.required }}
            render={({ field: { onChange, onBlur, value, ref, name } }) => (
              <input
                ref={ref}
                value={
                  value != null && value.includes(" ")
                    ? value?.split(" ")[0]
                    : ""
                } // Remove currency code from display value
                onChange={(e) =>
                  onChange(`${e.target.value} ${selectedCurrency.name}`)
                }
                onBlur={onBlur}
                name={name}
                type={field.type === "number" ? "text" : "text"} // Keep as text but validate for numbers if needed
                onKeyPress={field.type === "number" ? validate : undefined}
                className="focus:ring-my-rift-primary-green-500 focus:border-my-rift-primary-green-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                id={field.name}

                // You might need a custom onChange to handle concatenating the selected currency with the value
              />
            )}
          />

          <Listbox value={selectedCurrency} onChange={setSelectedCurrency}>
            {({ open }) => (
              <>
                <div className="absolute inset-y-0 right-0 flex items-center">
                  <Listbox.Button className="flex items-center text-gray-500 bg-transparent gap-x-1 p-2">
                    {selectedCurrency.name}
                    <img
                      src="/redesign/portfolio_impact/chevron.svg"
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-3.5 w-3.5 `}
                    />
                  </Listbox.Button>
                </div>
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-fit right-0 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {currencies.map((currency) => (
                    <Listbox.Option
                      key={currency.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 px-6  ${
                          active
                            ? "bg-my-rift-primary-green-100 text-my-rift-primary-green-900"
                            : "text-gray-500"
                        }`
                      }
                      value={currency}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {currency.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-my-rift-primary-green-600">
                              {/* Icon to show for selected currency, could be a check or similar */}
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </Listbox>
        </div>
        <p className="mt-2 text-sm text-gray-500">{field.description}</p>
      </div>
    );
  }

  // For basic input fields
  return (
    <div className="mb-4">
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700"
      >
        {field.title}{" "}
        {field.required ? (
          <span className="text-red-500">*</span>
        ) : (
          <span>(Optional)</span>
        )}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          {...register(field.name, { required: field.required })}
          type={field.type === "number" ? "text" : "text"} // Keep as text but validate for numbers if needed
          onKeyPress={field.type === "number" ? validate : undefined}
          className="focus:ring-my-rift-primary-green-500 focus:border-my-rift-primary-green-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          id={field.name}
          // Placeholder or the default value could be controlled here
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{field.unit}</span>
        </div>
      </div>
      <p className="mt-2 text-sm text-gray-500">{field.description}</p>
    </div>
  );
};

export default InputField;
