import React, { useState } from "react";

const HoverButton = ({ onHover, outHover }) => {
  const [hovered, setHovered] = useState();
  return (
    <div
      className="w-fit h-fit"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered && onHover}
      {!hovered && outHover}
    </div>
  );
};

export default HoverButton;
