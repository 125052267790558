import React, { useEffect, useState } from "react";
import WrapperCard from "../../components/ui/redesign/WrapperCard";
import PrimaryButton from "../../components/ui/redesign/PrimaryButton";
import Stepper from "../../components/redesign/Stepper";
import FileDropZone from "../company_impact/private/FileDropZone";
import { useForm } from "react-hook-form";
import PortfolioTemplateDownload from "./PortfolioTemplateDownload";
import CreatePortfolio from "../create_portfolio/CreatePortfolio";
import SecondaryButton from "../../components/ui/redesign/SeconaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AllRoutes } from "../Routes";
import { useLoadingAndError } from "../../services/hooks";
import { uploadPortfolioSave, uploadPortfolioUploadFile } from "../../api/api";
import { useCustomPortfolio } from "../../context/CustomPortfolioContext";
import LoadingErrorWrapper from "../../api/LoadingErrorWrapper";
import { enqueueSnackbar } from "notistack";

const UploadPortfolio = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [enableNext, setenableNext] = useState(false);
  const history = useHistory();
  const [portfolioName, setPortfolioName] = useState("New portfolio");
  const { register, setValue, watch, getValues } = useForm();

  const { setPortfolioData, data: portfolioData } = useCustomPortfolio();
  const {
    isLoading: isLoading1,
    error: error1,
    data: data1,
    handleFetchData: handleFetchData1,
  } = useLoadingAndError(() => uploadPortfolioUploadFile(getValues("file")));
  const {
    isLoading: isLoading2,
    error: error2,
    data: data2,
    handleFetchData: handleFetchData2,
  } = useLoadingAndError(() =>
    uploadPortfolioSave(portfolioName, portfolioData)
  );

  const theFile = watch("file");

  useEffect(() => {
    if (theFile) {
      handleEnableNext(true);
    } else {
      handleEnableNext(false);
    }
  }, [theFile]);

  useEffect(() => {
    if (data1 && !isLoading1) {
      //TODO SAME THING FOR THE CREATE PORTFOLIO FLOW AS WELL
      setPortfolioData(data1);
      setActiveStep(activeStep + 1);
    }
    if (error1) {
      enqueueSnackbar("Something went wrong, try again!", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
    }
  }, [data1, isLoading1, error1]);
  useEffect(() => {
    if (data2 && !isLoading2) {
      setActiveStep(activeStep + 1);
    }
    if (error2) {
      enqueueSnackbar("Something went wrong, try again!", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
    }
  }, [data2, isLoading2, error2]);

  const handleEnableNext = (value) => {
    setenableNext(value);
  };

  const handleNextStep = () => {
    if (activeStep == 0) {
      handleFetchData1();
    }
    if (activeStep == 1) {
      setActiveStep(activeStep + 1);
    }
    if (activeStep == 2) {
      handleFetchData2();
    }
  };

  const steps = [
    { stepNumber: "01", title: "Upload" },
    { stepNumber: "02", title: "Map / Edit" },
    { stepNumber: "03", title: "Save" },
  ];

  //TODO HANDLE ONLY AUTHORIZED USERS CAN SEE THIS PACKAGE
  //TODO CHECK PERMISSION CALL FOR API

  if (isLoading1 || isLoading2)
    return <LoadingErrorWrapper isLoading={isLoading1 || isLoading2} />;

  return (
    <div className="w-full h-fit m-auto flex justify-center items-center  bg-gray-50 mt-4 mb-36">
      <WrapperCard className={"w-full h-fit mx-8 p-8"}>
        <div className="w-2/3 mx-auto  pt-0 mb-4 ">
          <Stepper
            steps={steps}
            activeStep={activeStep}
            changeStep={(idx) => setActiveStep(idx)}
          />
        </div>
        {activeStep == 0 && (
          <div className="flex flex-col w-full h-full m-0">
            <div className="flex flex-col">
              <p className="text-lg text-gray-600 font-semibold mb-2 text-center">
                Assess the impact of your portfolios
              </p>
              <p className="text-sm text-gray-500 text-center leading-5 w-1/3 mx-auto">
                Measure the impact of your portfolio by uploading a file or{" "}
                <span className="font-bold text-my-rift-primary-green-500">
                  skip{" "}
                </span>
                this step and fill out the portfolio details manually.
              </p>
              <div className="w-2/3 my-8 mx-auto">
                <FileDropZone
                  fieldName={"file"}
                  getValues={getValues}
                  register={register}
                  setValue={setValue}
                />
              </div>
            </div>
            <div className="mb-0">
              <PortfolioTemplateDownload />
            </div>
          </div>
        )}
        {activeStep == 1 && (
          <div className="flex flex-col">
            <p className="text-lg text-gray-600 font-semibold mb-2 text-center">
              Check column values
            </p>
            <p className="text-sm text-gray-500 text-center leading-5 mx-auto">
              Our system checks the values of each row and flags any issues
              found that can be easily edited manually.
            </p>
            <CreatePortfolio handleEnableNext={handleEnableNext} />
          </div>
        )}
        {activeStep == 2 && (
          <div className="flex flex-col">
            <p className="text-lg text-gray-600 font-semibold mb-2 text-center">
              Name and save your portfolio{" "}
            </p>
            <WrapperCard className={"w-fit mx-auto"}>
              <div className="bg-gray-100 rounded-xl py-4 w-fit mx-auto flex gap-x-2 items-center px-4">
                <img
                  src="/redesign/portfolio_impact/portfolio.svg"
                  className="w-5 h-5"
                />
                <input
                  className="text-base rounded-xl leading-normal block w-64 bg-white border-[1.5px] border-gray-200 px-2 py-2 pr-3 text-gray-600 placeholder:text-gray-4 00 sm:text-sm sm:leading-6 focus:outline-none focus:ring-1 focus:ring-gray-600"
                  value={portfolioName}
                  onChange={(e) => setPortfolioName(e.target.value)}
                />
              </div>
            </WrapperCard>
          </div>
        )}
        {activeStep == 3 && (
          <div className="flex flex-col gap-y-3">
            <img
              alt="Success saved portfolio illustration"
              src="/redesign/illustration/save_portfolio.svg"
              className="w-64 mx-auto my-8"
            />
            <PrimaryButton
              className="mx-auto"
              full={false}
              onClick={() =>
                history.push(AllRoutes.my_portfolio.replace(":id", 1))
              }
              text={"Analyze portfolio"}
            />
            <SecondaryButton
              className="mx-auto"
              full={false}
              onClick={() => history.push(AllRoutes.dashboard)}
              text={"Back to my dashboard"}
            />
          </div>
        )}
      </WrapperCard>

      {activeStep < 3 && (
        <div
          style={{
            width: "calc(100% - 264px + 8px)", //calc 100% - 264px
          }}
          className="fixed bottom-0 py-4 px-8 right-0 w-full  bg-white rounded-t-xl shadow-md flex  gap-x-8 justify-end"
        >
          {activeStep != 0 && (
            <SecondaryButton
              //disabled={watch("file") ? false : true}
              onClick={() => setActiveStep(activeStep - 1)}
              text={"Previous step"}
            />
          )}
          <PrimaryButton
            disabled={!enableNext}
            //disabled={!enableNext}
            onClick={handleNextStep}
            text={activeStep == 2 ? "Save portfolio" : "Next"}
          />
        </div>
      )}
    </div>
  );
};

export default UploadPortfolio;
