import React, { useState } from "react";
import { Controller } from "react-hook-form";

const CustomInputField = ({
  name,
  title,
  placeholder,
  control,
  getValues,
  setValue,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (inputValue) {
      const currentSelections = getValues(name) || [];
      const newSelections = [...currentSelections, { value: inputValue }];

      setSelectedItems(newSelections);
      setValue(name, newSelections);
      setInputValue("");
    }
  };

  const handleRemoveValue = (index) => {
    const currentSelections = getValues(name) || [];
    const newSelections = currentSelections.filter((_, i) => i !== index);

    setSelectedItems(newSelections);
    setValue(name, newSelections);
  };

  return (
    <div className="relative mb-4">
      {title && (
        <p className="font-bold text-gray-600 text-base mb-1">{title}</p>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className="flex gap-2">
            <input
              {...field}
              placeholder={placeholder ? placeholder : "Type here..."}
              className="text-base leading-normal w-full py-1 pl-4 pr-3 rounded-lg text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none ring-1 ring-my-rift-primary-green-600 mt-1"
              autoComplete="off"
              value={inputValue}
              onChange={handleInputChange}
            />
            <button
              className="self-end bg-my-rift-primary-green-600 rounded-lg py-1 px-3 text-white"
              onClick={handleSubmit}
            >
              Add
            </button>
          </div>
        )}
      />
      <div className="flex flex-col w-full gap-3 mt-4">
        {getValues(name)?.map((item, index) => (
          <div
            key={index}
            className="bg-gray-100 rounded py-1 px-2 flex justify-between items-center"
          >
            {item.value}
            <button
              onClick={() => handleRemoveValue(index)}
              className="text-red-500 hover:text-red-700"
            >
              <img src="/redesign/portfolio_impact/cancel.svg" alt="" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomInputField;
