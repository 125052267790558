import React from "react";
import ShowMore from "../../components/ui/redesign/ShowMore";
import { formatNumber, isSimpleCompany } from "../../services/utilities";
import ToolTipRe from "../../components/ui/redesign/ToolTip";

const LeadersLaggards = ({
  title,
  isPositive,
  portfolio1,
  portfolio2,
  data1,
  data2,
}) => {
  const portfolios = [
    {
      name: isSimpleCompany() ? portfolio2 : portfolio1,
      companies: data1.slice(0, 3),
    },
    {
      name: portfolio2,
      companies: data2.slice(0, 3),
    },
  ];

  const portfoliosWrap = portfolios.slice(0, isSimpleCompany() ? 1 : 2);

  return (
    <div>
      <div className="w-full flex flex-col gap-y-2 justify-between mb-6 ">
        <div className="flex items-center gap-x-2">
          <img src="/redesign/nav/psics.svg" />
          <p className="text-sm font-bold leading-tight text-gray-600">
            {title}
          </p>
        </div>
        <span className="flex gap-x-2 w-fit items-center">
          <p className="text-xs text-gray-600">Monetized impact per Employee</p>
          <ToolTipRe
            width={"450px"}
            onTop={false}
            toolTipBase={
              <img src="/redesign/portfolio_impact/info_outline.svg" />
            }
          >
            <p className="text-sm font-medium leading-normal text-gray-600">
              RGS measures and values the impacts that companies have on society
              and the environment using US Dollars as units. For example, every
              additional ton of carbon emitted has an impact on society that can
              be priced and quantified using social costs.
            </p>
          </ToolTipRe>
        </span>
      </div>
      <div className="flex w-full gap-x-6">
        {portfoliosWrap.map((portfolio, idx) => (
          <div className="w-full flex flex-col gap-y-4 bg-my-tailwind-gray-50 p-2 rounded-2xl">
            <div className="inline-flex space-x-2 items-center justify-start p-2.5 mx-auto">
              <img
                className="w-4 h-4"
                src="/redesign/portfolio_impact/portfolio.svg"
              />
              <p className="text-sm font-bold leading-tight text-gray-600">
                {portfolio.name}
              </p>
              {!isSimpleCompany() && (
                <div
                  className={`w-10 h-1.5 ${
                    idx == 0
                      ? "bg-my-tailwind-light-blue-500"
                      : "bg-my-tailwind-purple-500"
                  } rounded-full`}
                />
              )}
            </div>
            {portfolio.companies.map((c) => (
              <div className="mb-2">
                <div className="w-full flex justify-between items-center">
                  <p className="text-sm leading-4 font-medium text-my-tailwind-gray-600">
                    {c.name}
                  </p>
                  <p
                    className={`text-sm leading-5 font-semibold ${
                      c.value >= 0
                        ? "text-my-tailwind-green-500"
                        : "text-my-tailwind-red-500"
                    }`}
                  >
                    {formatNumber(c.value, 2)}
                  </p>
                </div>
                {/*      <div className="flex w-full justify-center items-center relative py-2">
                  <div
                    className={`w-16 h-2.5 ${
                      isPositive
                        ? "bg-my-tailwind-green-100"
                        : "bg-my-tailwind-red-100"
                    } rounded-lg`}
                  >
                    <div
                      style={{
                        width: `${c.percentage}%`,
                      }}
                      className={`w-16 h-2.5 ${
                        isPositive
                          ? "bg-my-tailwind-green-500"
                          : "bg-my-tailwind-red-500"
                      } rounded-lg`}
                    ></div>
                  </div>
                  <p
                    className={`absolute right-0 text-sm leading-5 font-semibold ${
                      isPositive
                        ? "text-my-tailwind-green-500"
                        : "text-my-tailwind-red-500"
                    }`}
                  >
                    ${formatNumber(c.value.toFixed(0))}
                  </p>
                </div> */}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeadersLaggards;
