import { Pie } from "react-chartjs-2";

import "chart.js/auto";
import Chart from "chart.js/auto";

import { useEffect, useState } from "react";
import { formatNumber } from "../../../services/utilities";

function SimplePieChartReport({
  id,
  title,
  data,
  color, // This might be ignored unless you adjust the color settings for the dataset
  toolTipCustomFormatter,
  showLegend,
  unit,
  leftUnit = true,
  showLabel,
  marginLeft,
  width,
  height,
}) {
  // Transform data to the format required by Chart.js
  const chartData = {
    labels: data.map((item) => item.name),
    datasets: [
      {
        label: "Values",
        labels: {
          render: "label",
          fontColor: "#000",
          position: "outside",
        },
        data: data.map((item) => item.y),
        /*     backgroundColor: color || [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],*/
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: showLegend,
        maxWidth: 300,
        fullSize: false,
        position: "right",

        labels: {
          //show only first 10 labels
          generateLabels: (chart) => {
            const labels = chart.data.labels.slice(0, 10).map((l, i) => {
              return {
                datasetIndex: i,
                text: l,
                fillStyle: chart.data.datasets[0]?.backgroundColor[i],
                strokeStyle: chart.data.datasets[0]?.backgroundColor[i],
                //strokeStyle: chart.data.datasets[i].backgroundColor,
                hidden: chart.getDatasetMeta(i).hidden,
              };
            });
            //add last element as "Others"
            if (chart.data.labels.length > 10) {
              labels.push({
                text: "... Rest",
              }); // Add a separator
              const lengthOfLabels = chart.data.labels.length;
              labels.push({
                datasetIndex: 10,
                text: "Others",
                fillStyle:
                  chart.data.datasets[0]?.backgroundColor[lengthOfLabels - 1],
                strokeStyle:
                  chart.data.datasets[0]?.backgroundColor[lengthOfLabels - 1],
                hidden: chart.getDatasetMeta(10).hidden,
              });
            }

            return labels;
          },

          boxWidth: 20,
          useBorderRadius: true,

          borderRadius: "10px",
          color: "black", // Adjust according to your theme
          // This more specific font property overrides the global property
          font: {
            size: 10,
          },
        },
      },

      labels: {
        render: "label",
        fontColor: "#000",
        position: "outside",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            const item = data[tooltipItem.dataIndex];
            const value = formatNumber(item.y);
            const label = `${item.name}: ${
              leftUnit ? unit || "$" : ""
            }${value} ${!leftUnit ? unit || "$" : ""}`;
            return toolTipCustomFormatter
              ? toolTipCustomFormatter(tooltipItem.dataIndex)
              : label;
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        width: width || "100%",
        height: height || "325px",
        marginLeft: marginLeft,
      }}
    >
      <Pie data={chartData} options={options} id={id} />
    </div>
  );
}

export default SimplePieChartReport;
