import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { twMerge } from "tailwind-merge";

const ImpactThemeLineChart = ({ data }) => {
  const mockData = data;
  // Initial key to display
  const sortedKeys = Object.keys(mockData[Object.keys(mockData)[0]]).sort();
  const initialKey = sortedKeys[0];

  const [activeKey, setActiveKey] = useState(initialKey); // Now it's just one key

  // Function to transform the provided data into a format suitable for Highcharts
  const transformDataForHighcharts = () => {
    if (!activeKey) return [];
    return [
      {
        name: activeKey,
        data: Object.keys(mockData).map(
          (year) => mockData[year][activeKey] * 100 || 0
        ),
      },
    ];
  };

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "line", // Changed to line chart
      height: 200,
    },
    title: {
      useHTML: false,
      text: "",
      align: "left",
    },
    xAxis: {
      categories: Object.keys(mockData),
    },
    yAxis: {
      labels: {
        format: "{value}%",
      },
      title: {
        enabled: false,
      },
    },
    tooltip: {
      borderColor: "gray",
      borderRadius: 16,
      backgroundColor: "white",
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.1f}%</b><br/>',
      split: false,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: transformDataForHighcharts(),
  });

  // Update chart options whenever active key changes
  useEffect(() => {
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      series: transformDataForHighcharts(),
    }));
  }, [activeKey, mockData]);

  // Toggle key function updated to support single selection
  const toggleKey = (key) => {
    setActiveKey(key);
  };

  return (
    <div className="themeid">
      <p className="text-xs text-my-tailwind-gray-600 my-2 text-center">
        Impact theme of {activeKey} ({Object.keys(mockData)[0]} -
        {Object.keys(mockData)[Object.keys(mockData).length - 1]})
      </p>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      <div
        className="justify-center mb-8 max-h-32 overflow-y-scroll"
        style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}
      >
        {sortedKeys.map((key) => (
          <button
            key={key}
            onClick={() => toggleKey(key)}
            className={twMerge(
              "bg-white text-xs rounded-xl text-my-tailwind-gray-600 px-2 py-2 m-1 border border-my-tailwind-gray-400",
              activeKey === key &&
                "border border-my-rift-primary-green-400 text-my-rift-primary-green-400"
            )}
          >
            {key}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ImpactThemeLineChart;
