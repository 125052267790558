import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CustomEvents from "highcharts-custom-events";
import borderRadius from "highcharts-border-radius";
import { useEffect, useState } from "react";
import ChartLegend from "../../ui/redesign/ChartLegend";
import { formatNumber } from "../../../services/utilities";
import CategorySelector from "./CategorySelector";
import ToggleSwitch from "../Switch";

CustomEvents(Highcharts);
borderRadius(Highcharts);

function HistoryLineChart({
  id,
  data,
  historyYears,
  portfolio1,
  portfolio2,
  hideSelector,
  height,
  isCompanyView,
  selected,
  setSelected,
  isDollar,
  setIsDollar,
}) {
  const [changeCounter, setChangeCounter] = useState(0);

  useEffect(() => {
    if (isDollar && selected === 2) {
      setSelected(4);
    } else if (!isDollar && selected === 4) {
      setSelected(2);
    }
  }, [isDollar]);

  useEffect(() => {
    if (isCompanyView && selected === 2 && isDollar) {
      setIsDollar(false);
    }
  }, [selected]);

  const tickMax = Math.max(data[0]) / 10;

  Highcharts.AST.allowedAttributes.push("data-container");

  const options = {
    chart: {
      styledMode: false,
      height: height,
    },
    title: {
      text: "",
    },
    xAxis: {
      crosshair: {
        // color: "red",
        width: 1,
        color: "#9CA3AF",
      },
      tickmarkPlacement: true,
      categories: [...historyYears],
      accessibility: {
        description: "Months of the year",
      },
      gridLineWidth: 1,
      gridLineColor: "#E5E7EB",
      lineColor: "#E5E7EB",
      labels: {
        useHTML: true, // enable HTML rendering
        formatter: function () {
          // format the labels with an icon
          if (hideSelector) return `<p class="text-[10px]">${this.value}</p>`;

          return `
          <div class="flex flex-col space-y-1.5 items-center justify-center p-2 bg-white shadow rounded-lg">
      
          <p class="text-xs font-medium leading-none text-center text-gray-600">${this.value}</p>
      </div>
          
          `;
        },
        style: {
          textAlign: "center", // center the label text
        },
      },
    },
    yAxis: {
      allowDecimals: true,
      tickInterval: tickMax,
      /*   min: -20000,
      max: 60000, */
      title: { text: "" },
      labels: {
        style: {
          color: "#D1D5DB;",
        },
        formatter: function () {
          // Use a custom formatter function to style the label for the 0 level differently
          if (this.value === 0) {
            return (
              '<span style="font-size: 14px; line-height: 20px;color: #9CA3AF;">' +
              this.value +
              "</span>"
            );
          } else {
            return (
              '<span style="font-size: 14px; line-height: 20px;color: #D1D5DB;">' +
              formatNumber(this.value) +
              "</span>"
            );
          }
        },
      },
      plotLines: [
        {
          color: "#FF0000",
          width: 1,
          value: 0,
          zIndex: 2,
          dashStyle: "ShortDash",
        },
      ],
      gridLineColor: "#E5E7EB",
    },
    legend: { enabled: false },

    tooltip: {
      shared: true,
      useHTML: true,
      borderWidth: 0,
      padding: 8,
      shadow: false,
      backgroundColor: "transparent",

      formatter: function () {
        // console.log(this.points);
        const primary = this.points[0];
        const secondary = this.points[1];

        return `     <div class="bg-white p-4 flex flex-col w-64 shadow-md rounded-lg">
          <div class="w-fit flex gap-x-2 mb-4 items-center">
            <div class="text-xs font-bold leading-none text-center text-gray-600">
              ${primary.x}
            </div>
          </div>
          <div class="flex items-center gap-x-1 w-fit">
            <div style="background-color: ${
              primary.color
            }" class="w-5 h-1.5  rounded-full" ></div>
            <div class="text-xs leading-none text-gray-600">
              ${primary.series.name}:
            </div>
            <div class="text-gray-600 text-xs font-semibold">$ ${formatNumber(
              primary.y
            )}</div>
          </div>
          <div class="flex items-center gap-x-1 w-fit">
          <div style="background-color: ${
            secondary.color
          }" class="w-5 h-1.5  rounded-full" ></div>
          <div class="text-xs leading-none text-gray-600">
            ${secondary.series.name}:
          </div>
          <div class="text-gray-600 text-xs font-semibold">$ ${formatNumber(
            secondary.y
          )}</div>
        </div>
        </div>`;
      },
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        //pointStart: 2015,
      },
    },

    series: [
      {
        name: portfolio1,
        data: data[0],
        color: "#0EA5E9",

        marker: {
          symbol: "circle",

          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      {
        name: portfolio2,
        data: data[1],
        color: "#8B5CF6",

        marker: {
          symbol: "circle",

          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    ],
  };

  const isEmployee = selected === 2 || selected === 4;

  return (
    <div className="bg-white">
      {!hideSelector && (
        <div className="ml-auto w-fit bg-white mb-4 history-details">
          <CategorySelector
            defaultSelected={selected}
            onChange={(val) => {
              setSelected(val.id);
              setChangeCounter(changeCounter + 1);
            }}
          />
        </div>
      )}
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ id: id }}
      />
      <div className="pt-5 flex items-center justify-center">
        <ChartLegend
          isVertical={false}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
          className={"w-4/5"}
        />
        {isEmployee && isCompanyView && (
          <div className="flex flex-col ml-10 items-center justify-center w-1/5">
            <ToggleSwitch
              toggle={isDollar}
              setToggle={setIsDollar}
              title={"Dollar / Employee"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default HistoryLineChart;
