import React, { useEffect, useRef, memo, useMemo } from "react";
import { set, useForm } from "react-hook-form";
import CheckboxPanel from "../form/CheckboxPanel";
import { STEP1_COUNTRIES } from "../form/FormPredefValues";
import SwitchPanel from "../form/SwitchPanel";
import { useDataContext } from "../../../context/PortfolioContext";
import {
  useCustomPortfolio,
  useDataActions,
} from "../../../context/CustomPortfolioContext";
import CustomCompanySearch from "../form/CustomCompanySearch";
import SliderPanel from "../form/SliderPanel";
import PrimaryButton from "../../../components/ui/redesign/PrimaryButton";
import { FULL_STEP } from "../CreatePortfolioPage";
import SecondaryButton from "../../../components/ui/redesign/SeconaryButton";
import CustomInputField from "../form/CustomInputField";
import { twMerge } from "tailwind-merge";
import Swal from "sweetalert2";

const FilterPanel = ({
  form,
  handleBack,
  step,
  control,
  getValues,
  watch,
  setValue,
  className,
  isFlipped,
  setIsFlipped,
  handleEdit,
}) => {
  const { setTrigger, trigger } = useDataActions();

  const handleSearch = () => {
    setIsFlipped(true);
    handleEdit(4, 0, 0);
  };

  const handleSave = () => {
    Swal.fire({
      title: "Your portfolio has been saved",
      text: "You can view and edit your portfolio in My Portfolios.",
      icon: "success",
      confirmButtonText: "OK",
      focusCancel: true,
      customClass: {
        confirmButton: "px-8 py-2",
      },
    });
  };

  useEffect(() => {
    if (isFlipped && step < 4) setIsFlipped(false);
  }, [step]);

  //detect if anything in the form changed, debounce 1 second and then call api with the new values

  const formValues = watch();

  // Use a ref to store the debounce timeout ID
  const debounceTimer = useRef();

  // Function to call your API (stub)
  const fetchPreviewCount = () => {
    //if the current form values is the same as the previous value, do not call the api
    if (JSON.stringify(formValues) === JSON.stringify(trigger)) {
      return;
    }

    if (!trigger || JSON.stringify(formValues) != JSON.stringify(trigger)) {
      //TODO CALL THE API WITH THE FILTERS BUT FORMVALUES NEED TO BE TRANSFORMED, MAPPED
      //TODO SAVE THIS PREVIEW DATA TO THE CONTEXT
      //console.log("Calling API for preview count...");
      //console.log("API call with new values");
      setTrigger((prev) => JSON.stringify(formValues));
    }
    // Your API call logic here
  };

  useEffect(() => {
    // Clear existing timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new debounce timer
    debounceTimer.current = setTimeout(() => {
      fetchPreviewCount();
    }, 100); // Adjust delay as necessary

    // Cleanup function to clear the timer on component unmount or before re-render
    return () => clearTimeout(debounceTimer.current);
  }, [formValues]); // Depend

  /*   const panels = useMemo(
    () =>
      form.map((item, index) => {
        if (item.type === "switch") {
          return (
            <SwitchPanel
              key={index}
              control={control}
              watch={watch}
              setValue={setValue}
              search={true}
              getValues={getValues}
              name={item.name}
              options={item.options}
              title={item.title}
            />
          );
        }

        return (
          <CheckboxPanel
            key={index}
            control={control}
            watch={watch}
            setValue={setValue}
            search={true}
            getValues={getValues}
            name={item.name}
            options={item.options}
            title={item.title}
          />
        );
      }),
    [control, watch, setValue, getValues, form]
  ); */
  return (
    <div className={className}>
      {form?.map((item, index) => {
        if (item.type === "switch") {
          return (
            <SwitchPanel
              key={index}
              control={control}
              watch={watch}
              setValue={setValue}
              search={true}
              getValues={getValues}
              name={item.name}
              options={item.options}
              title={item.title}
              description={item.description}
            />
          );
        }

        if (item.type == "slider") {
          return (
            <SliderPanel
              key={index}
              control={control}
              watch={watch}
              setValue={setValue}
              search={true}
              getValues={getValues}
              name={item.name}
              options={item.options}
              min={item.min}
              max={item.max}
              unit={item.unit}
              step={item.step}
              title={item.title}
              description={item.description}
            />
          );
        }

        if (item.type === "customidx") {
          return (
            <CustomCompanySearch
              setValue={setValue}
              getValues={getValues}
              control={control}
              key={index}
              name={item.name}
            />
          );
        }

        if (item.type === "input") {
          return (
            <CustomInputField
              key={item.name}
              control={control}
              setValue={setValue}
              getValues={getValues}
              name={item.name}
              title={item.title}
              placeholder={item.placeholder}
            />
          );
        }

        if (item.type == "checkbox")
          return (
            <CheckboxPanel
              key={`${item.name}_${index}`}
              control={control}
              watch={watch}
              setValue={setValue}
              search={item.search}
              getValues={getValues}
              name={item.name}
              options={item.options}
              title={item.title}
              description={item.description}
            />
          );

        if (item.type == "review")
          return (
            <div className="mt-10">
              {step != FULL_STEP && (
                <p className="text-base text-gray-500 text-center">
                  Please review your filters on the right
                </p>
              )}
              {step == FULL_STEP ? (
                <p className="text-sm text-gray-600 text-center mb-4">
                  Press <span className="font-bold">Back</span> for modify your
                  filters
                </p>
              ) : (
                <p className="text-sm text-gray-600 text-center mb-4">
                  Press <span className="font-bold">Search</span> for the
                  results
                </p>
              )}
              <div className="flex gap-2 mt-10">
                {step <= FULL_STEP && (
                  <SecondaryButton
                    full={true}
                    text={"Back"}
                    className={
                      "text-[1rem] !font-normal py-[0.3rem] border-my-rift-primary-green-600"
                    }
                    onClick={handleBack}
                  />
                )}
                {step != FULL_STEP && (
                  <PrimaryButton
                    full={true}
                    text={"Search"}
                    className={"text-[1rem] !font-normal py-[0.3rem]"}
                    onClick={handleSearch}
                  />
                )}
                {step == FULL_STEP && (
                  <PrimaryButton
                    full={true}
                    text={"Save Portfolio"}
                    className={"text-[1rem] !font-normal py-[0.3rem]"}
                    onClick={handleSave}
                  />
                )}
              </div>
            </div>
          );
      })}
    </div>
  );
};

export default FilterPanel;
