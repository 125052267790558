import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import HorizontalBarChart from "../../components/redesign/charts/HorizontalBarChart";
import PremiumFeature from "../../components/redesign/PremiumSoon";
import TopTab from "../../components/redesign/psics/TopTab";
import WrapperCard from "../../components/ui/redesign/WrapperCard";
import { useDataContext } from "../../context/PortfolioContext";
import { AllRoutes } from "../Routes";

const TabOverViewInformation = () => {
  const { year, companyList, response, currentType } = useDataContext();

  const [activeTab, setActiveTab] = useState(0);
  const history = useHistory();

  const tabs = [
    { icon: "/redesign/general/EnvImpactPlace.svg", name: "Countries" },
    { icon: "/redesign/general/Wages.svg", name: "Income Regions" },
    { icon: "/redesign/general/Clipboard.svg", name: "Products & Services" },
    { icon: "/redesign/general/Officebuilding.svg", name: "Companies" },
  ];

  const handleTabChange = (tab) => {
    setActiveTab((prev) => tab);
  };

  const tabByData = [
    "geographicBreakdown",
    "geographicalDrilldownByIncomeRegions",
    "benchmarkRevenueCategories",
    "companyList",
  ];

  const chartByTab = (idx, tab) => {
    const base = response.details[idx][tabByData[tab]][year];

    switch (tab) {
      case 0:
        return Object.keys(base).map((key) => {
          return {
            name: key,
            data: [base[key] * 100],
          };
        });
      case 1:
        return Object.keys(base).map((key) => {
          return {
            name: key,
            data: [base[key] * 100],
          };
        });
      case 2:
        return base.map((item) => ({
          name: item.productCategoryName,
          data: [item.productCategoryRevenueShare * 100],
        }));
      case 3:
        return base.map((obj) => {
          const name = Object.keys(obj)[0];
          const percentage = obj[name] * 100;
          return { name, percentage };
        });
    }
  };

  const categoryChartData1 = chartByTab(0, activeTab);
  const categoryChartData2 = chartByTab(1, activeTab);

  const data1 = chartByTab(0, activeTab);
  const data2 = chartByTab(1, activeTab);

  return (
    <div className="w-full px-4">
      <WrapperCard wideBorder={true}>
        <TopTab tabs={tabs} active={activeTab} onChange={handleTabChange} />
        {activeTab != 3 && (
          <div className="w-full flex-col md:flex-row flex gap-x-8 justify-between items-center mt-6">
            <div className="w-full rounded-2xl border-[2px] border-my-tailwind-gray-100 pl-6 pb-6 pt-2 ">
              <div className="pt-4 max-h-[200px] overflow-y-scroll supervisortable-dark pr-4">
                <HorizontalBarChart
                  data={categoryChartData1}
                  color={"bg-my-tailwind-light-blue-500"}
                />
              </div>
            </div>
            <div className="w-full rounded-2xl border-[2px] border-my-tailwind-gray-100 pl-6 pb-6 pt-2">
              <div className="pt-4 max-h-[200px] overflow-y-scroll supervisortable-dark pr-4">
                <HorizontalBarChart
                  data={categoryChartData2}
                  color={"bg-my-tailwind-purple-500"}
                />
              </div>
            </div>
          </div>
        )}
        {(activeTab == 1 || activeTab == 2) && (
          <div className="shadow-my-sm rounded-2xl w-fit my-6 mx-auto">
            <WrapperCard>
              <div
                className={`${
                  activeTab == 2 || activeTab == 1 ? "cursor-pointer" : ""
                } w-fit flex items-center justify-center gap-x-4 p-3 mx-auto`}
                onClick={() => {
                  if (activeTab == 1 || activeTab == 2) {
                    activeTab == 2
                      ? history.push(AllRoutes.portfolio_psics)
                      : window.open(
                          "https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html",
                          "_blank" // <- This is what makes it open in a new window.
                        );
                  }
                }}
              >
                <img
                  src={`/redesign/general/${
                    activeTab == 1 ? "income_region" : "Computer"
                  }.svg`}
                />
                <p className="text-sm leading-tight text-my-tailwind-gray-600">
                  {activeTab == 1
                    ? "Income regions are based on the classification of the "
                    : "Check out Our Products & Services (PSICS) data feed"}
                  {activeTab == 1 && (
                    <span className="text-my-rift-primary-green-600 underline font-bold cursor-pointer underline-offset-4">
                      <a
                        style={{
                          textDecoration: "underline",
                        }}
                        href="https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        World Bank
                      </a>
                    </span>
                  )}
                </p>
                {activeTab == 1 ? (
                  <a
                    style={{}}
                    href={
                      "https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/redesign/general/income_region_arrow.svg" />
                  </a>
                ) : (
                  <img
                    className="cursor-pointer"
                    onClick={() => history.push(AllRoutes.portfolio_psics)}
                    src="/redesign/general/income_region_arrow.svg"
                  />
                )}
              </div>
            </WrapperCard>
          </div>
        )}
        {activeTab == 3 && (
          <div className="w-full flex-col md:flex-row gap-y-6 flex gap-x-8 justify-between items-center mt-6">
            <div className="w-full h-[400px] overflow-y-scroll supervisortable border-[1px] border-gray-200 rounded-2xl">
              <div className="">
                {data1?.map((c, idx) => (
                  <div
                    className={`${
                      idx % 2 == 1 ? "" : "bg-my-tailwind-gray-50"
                    } w-full flex items-center justify-between px-2 py-3 ${
                      idx > 5 ? "" : ""
                    } `}
                  >
                    <p className="text-sm leading-tight text-gray-500">
                      {c.name}
                    </p>
                    <p className="text-xs font-semibold leading-none text-right text-gray-500">
                      {c.percentage.toFixed(1)}%
                    </p>
                  </div>
                ))}
              </div>
              {/*  <div className="relative mt-4">
                <div className="top-0 z-10 mx-auto bg-gray-50">
                </div>
              </div> */}
            </div>

            <div
              className="h-[400px] overflow-y-scroll supervisortable w-full border-[1px] border-gray-200 rounded-2xl"
              style={{
                height: "400px",
              }}
            >
              <div className="">
                {data2?.map((c, idx) => (
                  <div
                    className={`${
                      idx % 2 == 1 ? "" : "bg-my-tailwind-gray-50"
                    } w-full flex items-center justify-between px-2 py-3 ${
                      idx > 5 ? "" : ""
                    }`}
                  >
                    <p className="text-sm leading-tight text-gray-500">
                      {c.name}
                    </p>
                    <p className="text-xs font-semibold leading-none text-right text-gray-500">
                      {c.percentage.toFixed(1)}%
                    </p>
                  </div>
                ))}
                <div className="relative mt-4">
                  <div className="top-0 z-10 mx-auto bg-gray-50"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </WrapperCard>
    </div>
  );
};

export default TabOverViewInformation;

{
  /* 
  import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import HorizontalBarChart from "../../components/redesign/charts/HorizontalBarChart";
import PremiumFeature from "../../components/redesign/PremiumSoon";
import TopTab from "../../components/redesign/psics/TopTab";
import WrapperCard from "../../components/ui/redesign/WrapperCard";
import { useDataContext } from "../../context/PortfolioContext";
import { AllRoutes } from "../Routes";

const TabOverViewInformation = () => {
  const { year, companyList, response, currentType } = useDataContext();

  const [activeTab, setActiveTab] = useState(0);
  const history = useHistory();

  const tabs = [
    { icon: "/redesign/general/EnvImpactPlace.svg", name: "Countries" },
    { icon: "/redesign/general/Wages.svg", name: "Income Regions" },
    { icon: "/redesign/general/Clipboard.svg", name: "Products & Services" },
    { icon: "/redesign/general/Officebuilding.svg", name: "Companies" },
  ];

  const handleTabChange = (tab) => {
    setActiveTab((prev) => tab);
  };

  const tabByData = [
    "geographicBreakdown",
    "geographicalDrilldownByIncomeRegions",
    "benchmarkRevenueCategories",
    "companyList",
  ];

  const chartByTab = (idx, tab) => {
    const base = response.details[idx][tabByData[tab]][year];

    switch (tab) {
      case 0:
        return Object.keys(base).map((key) => {
          return {
            name: key,
            data: [base[key] * 100],
          };
        });
      case 1:
        return Object.keys(base).map((key) => {
          return {
            name: key,
            data: [base[key] * 100],
          };
        });
      case 2:
        return base.map((item) => ({
          name: item.productCategoryName,
          data: [item.productCategoryRevenueShare * 100],
        }));
      case 3:
        return base.map((obj) => {
          const name = Object.keys(obj)[0];
          const percentage = obj[name] * 100;
          return { name, percentage };
        });
    }
  };

  const categoryChartData1 = chartByTab(0, activeTab);
  const categoryChartData2 = chartByTab(1, activeTab);

  const data1 = chartByTab(0, activeTab);
  const data2 = chartByTab(1, activeTab);

  return (
    <div className="w-full px-4">
      <WrapperCard wideBorder={true}>
        <TopTab tabs={tabs} active={activeTab} onChange={handleTabChange} />
        {activeTab != 3 && (
          <div className="w-full flex gap-x-8 justify-between items-center mt-6">
            <div className="w-full rounded-2xl border-[2px] border-my-tailwind-gray-100 pl-6 pb-6 pt-2 ">
              <div className="pt-4 max-h-[200px] overflow-y-scroll supervisortable-dark pr-4">
                <HorizontalBarChart
                  data={categoryChartData1}
                  color={"bg-my-tailwind-light-blue-500"}
                />
              </div>
            </div>
            <div className="w-full rounded-2xl border-[2px] border-my-tailwind-gray-100 pl-6 pb-6 pt-2">
              <div className="pt-4 max-h-[200px] overflow-y-scroll supervisortable-dark pr-4">
                <HorizontalBarChart
                  data={categoryChartData2}
                  color={"bg-my-tailwind-purple-500"}
                />
              </div>
            </div>
          </div>
        )}
        {(activeTab == 1 || activeTab == 2) && (
          <div className="shadow-my-sm rounded-2xl w-fit my-6 mx-auto">
            <WrapperCard>
              <div
                className={`${
                  activeTab == 2 || activeTab == 1 ? "cursor-pointer" : ""
                } w-fit flex items-center justify-center gap-x-4 p-3 mx-auto`}
                onClick={() => {
                  if (activeTab == 1 || activeTab == 2) {
                    activeTab == 2
                      ? history.push(AllRoutes.psics)
                      : window.open(
                          "https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html",
                          "_blank" // <- This is what makes it open in a new window.
                        );
                  }
                }}
              >
                <img
                  src={`/redesign/general/${
                    activeTab == 1 ? "income_region" : "Computer"
                  }.svg`}
                />
                <p className="text-sm leading-tight text-my-tailwind-gray-600">
                  {activeTab == 1
                    ? "Income regions are based on the classification of the "
                    : "Check out Our Products & Services (PSICS) data feed"}
                  {activeTab == 1 && (
                    <span className="text-my-rift-primary-green-600 underline font-bold cursor-pointer underline-offset-4">
                      <a
                        style={{
                          textDecoration: "underline",
                        }}
                        href="https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html"
                        target="_blank"
                      >
                        World Bank
                      </a>
                    </span>
                  )}
                </p>
                {activeTab == 1 ? (
                  <a
                    style={{}}
                    href={
                      "https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html"
                    }
                    target="_blank"
                  >
                    <img src="/redesign/general/income_region_arrow.svg" />
                  </a>
                ) : (
                  <img
                    className="cursor-pointer"
                    onClick={() => history.push(AllRoutes.psics)}
                    src="/redesign/general/income_region_arrow.svg"
                  />
                )}
              </div>
            </WrapperCard>
          </div>
        )}
        {activeTab == 3 && (
          <div className="w-full flex gap-x-8 justify-between items-center mt-6">
            <div className="w-full h-[400px] overflow-y-scroll supervisortable border-[1px] border-gray-200 rounded-2xl">
              <div className="">
                {data1.slice(0, 5).map((c, idx) => (
                  <div
                    className={`${
                      idx % 2 == 1 ? "" : "bg-my-tailwind-gray-50"
                    } w-full flex items-center justify-between px-2 py-3 ${
                      idx > 5 ? "blur-sm" : ""
                    } `}
                  >
                    <p className="text-sm leading-tight text-gray-500">
                      {c.name}
                    </p>
                    <p className="text-xs font-semibold leading-none text-right text-gray-500">
                      {c.percentage.toFixed(1)}%
                    </p>
                  </div>
                ))}
              </div>
              <div className="relative">
                <div className="sticky top-0 z-10 mx-auto px-16 py-4 my-4">
                  <PremiumFeature />
                </div>
                {data1.slice(6, data1.length - 1).map((c, idx) => (
                  <div
                    className={`${
                      idx % 2 == 1 ? "" : "bg-my-tailwind-gray-50"
                    } w-full flex items-center justify-between px-2 py-3 blur-sm  `}
                  >
                    <p className="text-sm leading-tight text-gray-500">
                      {c.name}
                    </p>
                    <p className="text-xs font-semibold leading-none text-right text-gray-500">
                      {c.percentage.toFixed(1)}%
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="h-[400px] overflow-y-scroll supervisortable w-full border-[1px] border-gray-200 rounded-2xl"
              style={{
                height: "400px",
              }}
            >
              {data2.map((c, idx) => (
                <div
                  className={`${
                    idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                  } w-full flex items-center justify-between px-2 py-3 ${
                    idx > 5 ? "blur-sm" : ""
                  }`}
                >
                  <p className="text-sm leading-tight text-gray-500">
                    {c.name}
                  </p>
                  <p className="text-xs font-semibold leading-none text-right text-gray-500">
                    {c.percentage.toFixed(1)}%
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </WrapperCard>
    </div>
  );
};

export default TabOverViewInformation;

{
  /* <div className="relative">
<div className="sticky top-0 z-10 mx-auto px-16 py-16">
  <PremiumFeature />
</div>
{data1.slice(6, data1.length - 1).map((c, idx) => (
  <div
    className={`${
      idx % 2 == 1 ? "" : "bg-my-tailwind-gray-50"
    } w-full flex items-center justify-between px-2 py-3 blur-sm  `}
  >
    <p className="text-sm leading-tight text-gray-500">
      {c.name}
    </p>
    <p className="text-xs font-semibold leading-none text-right text-gray-500">
      {c.percentage.toFixed(1)}%
    </p>
  </div>
))}
</div> 
}
*/
}
