import React from "react";
import { formatNumber } from "../../../services/utilities";
import { useDataContext } from "../../../context/PortfolioContext";

const ThemeTable = ({
  height,
  data,
  keyTitle,
  valueTitle,
  isPercentage,
  isReport,
}) => {
  //map data key is name, value is value map object to array
  const sortedData = data.sort((a, b) => b.value - a.value);

  const { year } = useDataContext();

  return (
    <div>
      <div className="">
        {/*  <p className="font-semibold mt-4 text-xl text-my-tailwind-gray-500 text-center">
          Exposure to impact themes of year {year}
        </p> */}
        <div className="flow-root ">
          <div className="w-full ">
            <div className="w-full inline-block pt-0 pb-0 align-middle ">
              <div className="grid grid-cols-4">
                <div className="col-span-2 cursor-pointer flex items-center justify-center rounded-t-2xl  px-2 py-3 border-b-[1px] border-gray-200 bg-gray-50">
                  <p
                    className={`${
                      isReport ? "text-sm" : "text-xs"
                    } font-semibold text-center leading-tight pr-2 text-gray-500`}
                  >
                    {keyTitle}
                  </p>
                </div>
                <div className="col-span-2 cursor-pointer flex items-center justify-center  px-2 py-3 border-b-[1px] border-gray-200 bg-gray-50">
                  <p
                    className={`${
                      isReport ? "text-sm" : "text-xs"
                    } font-semibold text-center leading-tight pr-2 text-gray-500`}
                  >
                    {valueTitle}
                  </p>
                </div>
              </div>
              <div
                className={`${
                  isReport ? "min-h-max" : "h-[300px]"
                } grid grid-cols-8 overflow-y-scroll supervisortable`}
                style={{
                  height: height || "300px",
                }}
              >
                <div className="w-full col-span-8">
                  {sortedData.map((category, idx) => {
                    return (
                      <div
                        className={`${
                          idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                        } w-full grid-flow-col grid grid-cols-8 col-span-8`}
                      >
                        <div
                          className={`col-span-4 my-auto ${
                            idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                          }`}
                        >
                          <p
                            className={`${
                              isReport ? "text-sm" : "text-xs"
                            } font-normal leading-tight  text-gray-500 px-2  text-center ${
                              height == "100%" ? "pt-1 pb-1.5" : "py-4"
                            }`}
                          >
                            {category.name}
                          </p>
                        </div>
                        <div
                          className={`col-span-4 my-auto ${
                            idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                          }`}
                        >
                          <p
                            className={`${
                              isReport ? "text-sm" : "text-xs"
                            } font-normal leading-tight  text-gray-500 px-2  text-center ${
                              height == "100%" ? "pt-1 pb-[6px]" : "py-4"
                            }`}
                          >
                            {isPercentage == true
                              ? `${category.value.toFixed(2)} %`
                              : `$ ${formatNumber(category.value)}`}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeTable;
