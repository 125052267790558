export const categoryForDescription = (key) => {
  switch (key) {
    case "Plastic Packaging in Tonnes":
      return "Total weight of product plastic packaging monetized using the social cost of plastic. This impact measures the adverse environmental impacts caused by plastic packaging in landfills and oceans.";
    case "Food Waste in Tonnes":
      return "This impact captures the total weight of food waste generated and is monetized using the social cost of food waste. This impact measures  emissions, water usage, soil erosion and biodiversity losses caused by the production of food that is wasted.";
    case "Total Water Withdrawn in Cubic Meter":
      return "This impact is generated from 'water withdrawn' less 'wated discharged', and is monetized by the social cost of water which is adjusted for local water scarcity.";
    case "Recalls":
      return "The recalls impact captures  the number of faulty products monetized using the social cost of recalls specific to the sub-industry. (e.g., the social cost of FDA Class One Recalls in Health care)";
    case "Consumers Affected by Data Breach":
      return "Number of users affected by data breaches, i.e. unauthorized third-party access to personal indentifiable information, monetized using the Social Cost of Data Breach.";
    case "Wages":
      return "This impact is the real utility of the wages paid, using the living and satiation wages, and adjusted for geographic location.";
    case "Diversity":
      return "The diversity impact identifies the number of women that should be employed at the firm for equal parity between workforce demograpgics and population demographics, monetized using the company specific utility of the average wage. ";
    case "Equal Opportunity":
      return "Equal opportunity captures the number of females in management relative to non-management roles, and it is monetized using the gap in utility of average wage between the two groups.";
    case "Customer Utility":
      return "This impact captures the benefit that a company brings to the customer and is monetized using consumer surplus.";
    case "GHG Emissions":
      return "GHG emissions is the sum of Scope 1, 2 and 3 GHG emissions, and monetized using the social cost of carbon.";
    case "Scope 1 Emissions in Tonnes":
      return "Scope 1 emissions are direct GHG emissions that occur from sources that are controlled or owned by an organization (e.g., emissions associated with fuel combustion in boilers, furnaces, vehicles).";
    case "Scope 2 Emissions in Tonnes":
      return "Scope 2 emissions are indirect GHG emissions associated with the purchase of electricity, steam, heat, or cooling.";
    case "Scope 3 Emissions in Tonnes":
      return "Scope 3 emissions include all other indirect emissions that occur in the upstream and downstream activities of an organisation.";
    case "Operational GHG Emissions":
      return "GHG emissions is the sum of Scope 1, 2 and 3 GHG emissions, and monetized using the social cost of carbon.";
    case "Upstream Scope 3 GHG Emissions":
      return "GHG emissions is the sum of Scope 1, 2 and 3 GHG emissions, and monetized using the social cost of carbon.";
  }
};
