import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchCompanyEuTaxonomy, fetchPSICS } from "../api/api";
import LoadingErrorWrapper from "../api/LoadingErrorWrapper";

import TabOverviewSelector from "../components/redesign/portfolio_impact/TabOverviewSelector";
import PsicsTabGeographics from "../components/redesign/psics/PsicsTabGeographic";
import PsicsTabProducts from "../components/redesign/psics/PsicsTabProducts";
import NoComparisonData from "../components/redesign/NoComparisonData";

import TopTab from "../components/redesign/psics/TopTab";
import { useDataContext } from "../context/PortfolioContext";
import { AllRoutes } from "./Routes";
import { PortfolioCardType } from "../components/redesign/portfolio_impact/PortfolioCard";
import WrapperCard from "../components/ui/redesign/WrapperCard";
import { formatNumber } from "../services/utilities";
import CompanyTab from "../components/redesign/eutaxonomy/CompanyTab";
import EUSusTab from "../components/redesign/eutaxonomy/EUSusTab";

const CompanyEUTaxonomy = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { selectedCompanies } = useDataContext();

  const tabs = [
    { icon: "/redesign/general/Officebuilding.svg", name: "Companies" },
    { icon: "/redesign/general/eusus.svg", name: "EU Sustainable activity" },
  ];
  const handleTabChange = (tab) => {
    setActiveTab((prev) => tab);
  };

  const {
    year,
    companyList,
    setAllYears,
    currentType,
    setCompanyList,
    setResponse,
    response,
    setCurrentType,
    handleClearData,
    isCompanyData,
    setSelectedCompanies,
  } = useDataContext();

  const { isLoading, error, data } = useQuery(
    ["company_eu", selectedCompanies, year],
    () => {
      return fetchCompanyEuTaxonomy(selectedCompanies, year);
    }
  );

  useEffect(() => {
    if (data) {
      setCompanyList(
        Object.keys(data?.EUTaxonomy).map((key) => ({
          name: key,
          value: data?.EUTaxonomy[key][year],
        }))
      );
      let years = Object.values(data.EUTaxonomy).reduce((acc, curr) => {
        Object.keys(curr).forEach((key) => {
          if (key !== "name" && !acc.includes(key)) {
            acc.push(key);
          }
        });
        return acc;
      }, []);
      years = years.reverse();
      setAllYears(years);
      setResponse(data);
      setCurrentType(AllRoutes.company_eu_taxonomy);
    } else {
      if (!isCompanyData()) {
        setSelectedCompanies([]);
        setCompanyList([]);
      }
    }
  }, [data, year]);

  if (isLoading || error)
    return <LoadingErrorWrapper isLoading={isLoading} error={error} />;

  return (
    <div className="w-full flex flex-col gap-y-8" id="company_eu">
      <div className="px-8 flex flex-col gap-y-8 mt-4">
        <TabOverviewSelector
          title={"EU Taxonomy"}
          onExport={"company_eu"}
          type={PortfolioCardType.eu}
        />
        {selectedCompanies.length != 1 ? (
          <NoComparisonData />
        ) : !response || currentType != AllRoutes.company_eu_taxonomy ? (
          <></>
        ) : (
          <WrapperCard wideBorder={true}>
            <TopTab tabs={tabs} active={activeTab} onChange={handleTabChange} />
            <div className="w-full grid grid-cols-2 gap-x-8 mt-6">
              {activeTab == 0 && <CompanyTab />}
              {activeTab == 1 && <EUSusTab />}
            </div>
          </WrapperCard>
        )}
      </div>
    </div>
  );
};

export default CompanyEUTaxonomy;
