import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";

const BasicStep = ({ register, control, year, setValue, getValues, watch }) => {
  const [file, setFile] = useState(null);

  const report = watch("report");

  useEffect(() => {
    const file = getValues("report");
    setFile(file);
    setValue("report", file);

    if (file) console.log(file.name, year);
  }, [report, year]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setValue("report", event.target.files[0]); // you get all the files object here
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFile(event.dataTransfer.files[0]);
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  return (
    <div>
      <p className="text-base font-bold mb-1 text-gray-700">
        Upload financial report of company{" "}
        <span className="font-normal">(Optional)</span>
      </p>
      <input
        //{...register("report")}
        name="report"
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="file-upload"
      />
      {!file && (
        <label htmlFor="file-upload" className="cursor-pointer">
          <div
            className="p-16 border-dashed border-my-rift-primary-green-500 border-2 rounded-xl flex-col hover:bg-my-rift-primary-green-50 bg-gray-50 flex items-center justify-center"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <img
              src="/icon/upload_illustration.svg"
              alt="placeholder"
              className="mx-auto"
            />
            <p className="text-center text-gray-500 mt-4">
              <span className="text-my-rift-primary-green-500 font-semibold">
                Click to upload
              </span>{" "}
              or drag and drop
            </p>
          </div>
        </label>
      )}
      {file && (
        <div className="mt-4 flex items-center justify-between bg-gray-100 px-4 py-1 rounded-xl">
          <div className="items-center flex gap-x-2">
            <img
              src="/icon/file_upload.svg"
              className="bg-white p-1 rounded-lg"
            />
            <div className="flex flex-col gap-y-1">
              <p className="text-gray-700 font-semibold">{file.name}</p>
              <p className="text-gray-500">{file.size} KB</p>
            </div>
          </div>
          <button
            onClick={handleRemoveFile}
            className="text-my-rift-primary-green-500 font-semibold"
          >
            X
          </button>
        </div>
      )}
    </div>
  );
};

export default BasicStep;
