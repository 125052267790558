import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CustomEvents from "highcharts-custom-events";
import { useEffect, useState } from "react";
import { formatNumber } from "../../../services/utilities";
import { topColors } from "./colors";

CustomEvents(Highcharts);
/* const colors = Highcharts.getOptions().colors.map((c, i) =>
  // Start out with a darkened base color (negative brighten), and end
  // up with a much brighter color
  Highcharts.color(Highcharts.getOptions().colors[1])
    .brighten((i - 3) / 7)
    .get()
); */

function SimplePieChart({
  id,
  title,
  data,
  color,
  toolTipCustomFormatter,
  showLegend,
  unit,
  leftUnit = true,
  showLabel = false,
  marginLeft,
  width,
  height,
}) {
  Highcharts.AST.allowedAttributes.push("data-container");
  /*   Highcharts.setOptions({
    colors: topColors
  }); */
  const seriesData = data;

  /*   const colors = Highcharts.getOptions().colors.map(
    (c, i) =>
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      topColors[i]
  ); */

  const options = {
    chart: {
      styledMode: false,
      type: "pie",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      marginLeft:
        showLegend == null || showLegend == false
          ? null
          : marginLeft != null
          ? marginLeft
          : -150,
      width: width || (showLegend == true ? null : showLabel == true ? null : 250),
      height: height || 325
      //colors: topColors,
    },
    title: {
      text: title,
    },
    //colors: topColors,
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        return toolTipCustomFormatter != null
          ? toolTipCustomFormatter(this.point.index)
          : `<div class="bg-white p-4 flex flex-col shadow-md rounded-lg">
              <div class="text-xs font-semibold text-my-tailwind-gray-600">${
                this.point?.name
              }: ${leftUnit == true ? unit || "$" : ""}${formatNumber(
              this.y
            )} ${leftUnit == false ? unit || "$" : ""}</div>
            </div>`;
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        size: "200",
        //colors,

        //: colors,
        dataLabels: {
          enabled: showLabel == true ? true : false, //showLegend == null || showLegend == false ? true : false,
          format: `<b>{point.name}</b>: {point.percentage:.1f} %`,
          style: {
            color:
              (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
              "black",
          },
        },
        showInLegend: showLegend || false,
      },
    },
    legend: {
      width: "30%",
      align: "right",
      margin: 10,
      itemMarginTop: 5,
      verticalAlign: "center",
      x: 0,
      y: 50,
    },
    series: [
      {
        name: "Values",
        //colorByPoint: true,
        data: seriesData,
      },
    ],
  };

  return (
    <div className="">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ id: id }}
      />
    </div>
  );
}

export default SimplePieChart;
