import React from "react";
import ToolTipRe from "../../ui/redesign/ToolTip";
import WrapperCard from "../../ui/redesign/WrapperCard";
import RevenueTable from "./RevenueTable";

const PSICSComparison = ({ allData, color, hack }) => {
  return (
    <div>
      <div>
        <WrapperCard wideBorder={true}>
          <RevenueTable allData={allData} color={color} hack={hack}/>
        </WrapperCard>
      </div>
    </div>
  );
};

export default PSICSComparison;
