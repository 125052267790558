import React from "react";
import { Link } from "react-router-dom";
import { AllRoutes } from "../../pages/Routes";
import WrapperCard from "../ui/redesign/WrapperCard";

const FeatureSoon = () => {
  return (
    <div className="mx-auto w-fit h-fit my-auto rounded-2xl">
      <div className="flex flex-col py-8 px-20 justify-center items-center mx-auto">
        <img className="my-8" src="/redesign/general/Soon.svg" />
        <p className="text-lg font-bold leading-7 text-center text-gray-600">
          Coming Soon!
        </p>
        <p className="text-sm leading-tight text-center text-gray-500">
          We will notify you once this feature is ready! 🚀
        </p>
        <Link
          to={AllRoutes.contact_us}
          className="inline-flex space-x-1 items-center justify-center w-64 px-6 py-3 mt-8 bg-gray-100 shadow border rounded-md border-gray-500"
        >
          <p className="text-base font-medium leading-normal text-gray-600">
            Contact Us
          </p>
        </Link>
      </div>
    </div>
  );
};

export default FeatureSoon;
