import React, { useEffect, useRef } from "react";
import IWAI from "../components/redesign/portfolio_impact/IWAI";
import TabOverviewSelector from "../components/redesign/portfolio_impact/TabOverviewSelector";
import Tabs from "../components/redesign/portfolio_impact/Tabs";
import ToolTipRe from "../components/ui/redesign/ToolTip";
import PortfolioOverview from "./portfolio_impact/PortfolioOverview";
import { useHistory } from "react-router-dom";
import { AllRoutes } from "./Routes";
import EnvironmentalImpact from "./portfolio_impact/EnvironmentalImpact";
import CustomerImpact from "./portfolio_impact/CustomerImpact";
import PortfolioInformation from "./portfolio_impact/PortfolioInformation";
import EmployeesImpact from "./portfolio_impact/EmployeesImpact";

import { useDataContext } from "../context/PortfolioContext";
import { useQuery } from "react-query";
import {
  fetchPortfolioOverview,
  fetchImpactCategory,
  API_ENUMS,
  fetchCompanyOverview,
} from "../api/api";
import LoadingErrorWrapper from "../api/LoadingErrorWrapper";
import FeatureSoon from "../components/redesign/FeatureSoon";
import NoComparisonData from "../components/redesign/NoComparisonData";
import { privateCompanyOverview } from "./company_impact/private/PrivateCompanyData";
import Shepherd from "shepherd.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PortfolioImpact = () => {
  const {
    year,
    initialYear,
    companyList,
    setAllYears,
    setCompanyList,
    setResponse,
    setCurrentType,
    handleClearData,
    setSelectedCompanies,
    isCompanyData,
  } = useDataContext();
  const history = useHistory();
  const location = useLocation();
  const printRef = useRef();
  const { selectedCompanies, privateCompany } = useDataContext();

  const impactKeyByRoute = () => {
    switch (history.location.pathname) {
      case AllRoutes.portfolio_overview:
        return API_ENUMS.overview;
      case AllRoutes.environmental_impact:
        return API_ENUMS.environment;
      case AllRoutes.employees_impact:
        return API_ENUMS.employee;
      case AllRoutes.customer_impact:
        return API_ENUMS.customer;
      case AllRoutes.portfolio_information:
        return API_ENUMS.information;

      default:
        break;
    }
  };

  const { isLoading, error, data } = useQuery(
    [impactKeyByRoute(), selectedCompanies, history.location.pathname, year],
    () => {
      const key = impactKeyByRoute();
      return key == API_ENUMS.overview
        ? fetchPortfolioOverview(selectedCompanies, year)
        : fetchImpactCategory(key, selectedCompanies, year);
    }
  );
  /*   const { isLoading, error, data } = useQuery(
    "PROBAAA",
    fetchPortfolioOverview
  ); */
  useEffect(() => {
    if (data) {
      if (history.location.pathname == AllRoutes.portfolio_overview) {
        setCompanyList(
          data["details"].map((c) => ({
            name: c.portfolioName,
            percentage: c.benchmarkCoverage * 100,
            value: c.totalValue[year],
          }))
        );
        setAllYears(
          Object.keys(data["details"]?.[0].totalValue)
            .map((i, idx) => ({
              id: idx,
              name: `${i}`,
            }))
            .reverse()
        );
      } else if (history.location.pathname == AllRoutes.portfolio_information) {
        setCompanyList(data.details);
        setAllYears(
          Object.keys(
            data?.details[0].totalValueDrilldownByStakeholder.customer
          )
            .map((i, idx) => ({
              id: idx,
              name: `${i}`,
            }))
            .reverse()
        );
      } else {
        setCompanyList(
          data.portfolioComparison.map((c) => ({
            name: c.portfolioName,
            percentage: c.benchmarkCoverage * 100,
            value:
              c.portfolioImpactResults.totalValueDrilldownByStakeholder[
                impactKeyByRoute()
              ][year],
          }))
        );
        setAllYears(
          Object.keys(
            data.portfolioComparison?.[0].portfolioImpactResults.totalValue
          )
            .map((i, idx) => ({
              id: idx,
              name: `${i}`,
            }))
            .reverse()
        );
      }
      setResponse(data);
      //console.log("setresp");
      setCurrentType(history.location.pathname);
      /* TODO ONLY SAVE THE YEAR setResponse(data.map((c)=>({
        
        EUTaxonomy: c.EUTaxonomy?.[year],

      }))); */
    } else {
      if (isCompanyData()) {
        setSelectedCompanies([]);
        setCompanyList([]);
      }
    }
  }, [data, year]);

  useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const shouldTourContinue = urlParams.get("stc");

    if (shouldTourContinue === "t" && !isLoading) {
      if (Shepherd.activeTour.getCurrentStep().id === "step2") {
        Shepherd.activeTour.next();
      } else if (Shepherd.activeTour.getCurrentStep().id === "step12") {
        Shepherd.activeTour.next();
      }
    }
  }, [location, isLoading, selectedCompanies.length]);

  if (isLoading || error)
    return <LoadingErrorWrapper isLoading={isLoading} error={error} />;

  if (history.location.pathname == AllRoutes.portfolio_information)
    return <PortfolioInformation />;

  const pageByRoute = () => {
    switch (history.location.pathname) {
      case AllRoutes.portfolio_overview:
        return <PortfolioOverview />;
      case AllRoutes.environmental_impact:
        return <EnvironmentalImpact />;
      case AllRoutes.employees_impact:
        return <EmployeesImpact />;
      case AllRoutes.customer_impact:
        return <CustomerImpact />;
      case AllRoutes.portfolio_information:
        return <PortfolioInformation />;

      default:
        break;
    }
  };
  const titleByRoute = () => {
    switch (history.location.pathname) {
      case AllRoutes.portfolio_overview:
        return "Portfolio Overview";
      case AllRoutes.environmental_impact:
        return "Impact on environment";
      case AllRoutes.employees_impact:
        return "Impact on employees";
      case AllRoutes.customer_impact:
        return "Impact on customers";
      case AllRoutes.portfolio_information:
        return "Portfolio Information";

      default:
        break;
    }
  };

  return (
    <div
      className="w-full bg-white"
      ref={printRef}
      id={titleByRoute().toString().replaceAll(" ", "")}
    >
      <Tabs idx={1} />
      <div className="p-4 md:p-8 flex flex-col gap-y-8 bg-gray-50">
        <TabOverviewSelector
          //onExport={printRef.current}
          onExport={titleByRoute().toString().replaceAll(" ", "")}
          title={titleByRoute()}
        />
        <ToolTipRe />

        {selectedCompanies.length < 2 ? <NoComparisonData /> : pageByRoute()}
        {/*<IWAI
          title={
            "We are aligned with impact-weighted accounting principles. Check out ..."
          }
        /> TEMPORARY REMOVE*/}
      </div>
    </div>
  );
};

export default PortfolioImpact;
