import React from "react";
import { useDataContext } from "../../../context/PortfolioContext";
import NoDataForYear from "../NoDataForYear";

const CompanyTab = () => {
  const { year, response } = useDataContext();

  if (response.currentYear != year) {
    return <NoDataForYear />;
  }

  const result = Object.entries(response.Companies).map(
    ([portfolio, companies]) => ({
      portfolio,
      companies: Object.entries(companies[year]).map(([name, value]) => ({
        name,
        value,
      })),
    })
  );

  return (
    <>
      {result.map((portfolio) => (
        <div
          className="h-[400px] col-span-1 overflow-y-scroll supervisortable-dark  rounded-2xl border-[2px] border-my-tailwind-gray-100 pb-6 pt-2 "
          style={{
            height: "400px",
          }}
        >
          {portfolio.companies.map((c, idx) => (
            <div
              className={`${
                idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
              } w-full grid-flow-col grid grid-cols-4 col-span-8 `}
            >
              <div
                className={`col-span-3 my-auto ${
                  idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                }`}
              >
                <p className="text-xs font-semibold leading-tight text-gray-500 px-2 py-4">
                  {c.name}
                </p>
              </div>

              <div
                className={`col-span-1 my-auto ${
                  idx % 2 == 0 ? "" : "bg-my-tailwind-gray-50"
                }`}
              >
                <p className="text-xs font-semibold leading-tight  text-gray-500 px-2 py-4 text-right">
                  {(c.value * 100).toFixed(2)}%
                </p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default CompanyTab;
