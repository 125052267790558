import React from "react";
import { useDataContext } from "../../context/PortfolioContext";
import { isSimpleCompany } from "../../services/utilities";
import PrimaryButton from "../ui/redesign/PrimaryButton";

const NoComparisonData = () => {
  const { triggerSearchDropdown } = useDataContext();
  return (
    <div className="mx-auto w-fit h-fit my-auto rounded-2xl">
      <div className="flex flex-col py-8 px-20 justify-center items-center mx-auto">
        <img className="my-8" src="/redesign/general/NoComparisonData.svg" />
        <p className="text-3xl font-bold leading-9 text-gray-600">
          This is where all the data will appear.
        </p>
        <p className="text-base leading-normal text-center my-4 text-gray-600">
          Please select {isSimpleCompany() ? "the company" : "portfolios"} to
          benchmark {isSimpleCompany() ? "it" : "them"} 🚀{" "}
        </p>

        <PrimaryButton
          onClick={triggerSearchDropdown}
          text={isSimpleCompany() ? "Select company" : "Select portfolio"}
          iconUrl={"/redesign/general/plus_circle_white.svg"}
        />
      </div>
    </div>
  );
};

export default NoComparisonData;
