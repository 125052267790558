import React, { useEffect, useState } from "react";
import { set, useForm, useFormContext } from "react-hook-form";
import FormStep from "./FormStep";
import WrapperCard from "../../../components/ui/redesign/WrapperCard";
import PrimaryButton from "../../../components/ui/redesign/PrimaryButton";
import formData from "./FormData";
import { useLoadingAndError } from "../../../services/hooks";
import LoadingWrapper from "../../../api/LoadingWrapper";
import { useDataContext } from "../../../context/PortfolioContext";
import YearForm from "./YearForm";

const MultiStepForm = ({ currentStep, nextStep, prevStep }) => {
  const { setPrivateCompany, setCompanyList, addCompany, setCurrentType } =
    useDataContext();
  const [selectedYear, setSelectedYear] = useState(2023);
  const [formDataByYear, setFormDataByYear] = useState({});

  // Define the useForm hook outside of any conditional logic
  const {
    handleSubmit,
    control,
    reset,
    formState,
    register,
    getValues,
    setValue,
    watch,
  } = useForm();

  const { toggleLoading, isLoading } = useLoadingAndError();

  // Effect to load or initialize form data for the selected year
  useEffect(() => {
    if (formDataByYear[selectedYear]) {
      // If there is already data for the selected year, load it
      reset(formDataByYear[selectedYear]);
    } else {
      // If there's no data for the selected year, initialize default values
      reset();
      reset({
        totalRevenue: [{ revenue: null, segment: null }],
      });
    }
  }, [selectedYear, reset, formDataByYear]);

  const validateCurrentStep = () => {
    const currentFields = formData[currentStep].fields.map((f) => f.name);
    const currentErrors = currentFields.some(
      (field) => formState.errors[field]
    );
    if (!currentErrors) {
      if (currentStep === 1) {
        nextStep();
        toggleLoading();
        //sleep for 2 seconds
        setTimeout(() => {
          setPrivateCompany("xd");
          //setCompanyList([{ name: "xd" }]);
          addCompany("xd", "company");
          setCurrentType("company");
          toggleLoading();
        }, 2000);
      } else {
        //sleep for 2 seconds
        toggleLoading();
        setTimeout(() => {
          nextStep();
          toggleLoading();
        }, 2000);
      }
    } else {
      // Handle validation failure (e.g., show an error message)
    }
  };

  // Update formDataByYear when the year changes or when the form is submitted
  const handleYearChange = (newYear) => {
    const currentFormData = getValues();
    setFormDataByYear((prev) => ({ ...prev, [selectedYear]: currentFormData }));
    setSelectedYear(newYear);
    if (currentStep != 0) prevStep();
  };

  const onSubmit = (data) => {
    // Save the current year's form data
    setFormDataByYear((prev) => ({ ...prev, [selectedYear]: data }));
    validateCurrentStep();
    console.log(data, formDataByYear);
  };

  if (isLoading)
    return (
      <div className="m-auto justify-center flex flex-col items-center h-64">
        {currentStep == 2 && (
          <p className="text-xl mb-4 text-gray-500 text-center">
            Please do not close this window.
            <br /> We are currently calculating your result
          </p>
        )}
        <LoadingWrapper isLoading={isLoading} />
      </div>
    );

  return (
    <WrapperCard className={"mx-auto my-4"}>
      {currentStep != 2 && (
        <YearForm
          selectedYear={selectedYear}
          setSelectedYear={handleYearChange}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="p-4">
        <FormStep
          key={`${currentStep}-${selectedYear}`}
          year={selectedYear}
          step={currentStep}
          control={control}
          formDataValues={formData}
          nextStep={nextStep}
          watch={watch}
          prevStep={prevStep}
          register={register}
          formState={formState}
          getValues={getValues}
          validateCurrentStep={validateCurrentStep}
          setValue={setValue}
        />
      </form>
    </WrapperCard>
  );
};

export default MultiStepForm;
