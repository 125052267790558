import React, { useState } from "react";
import { Controller } from "react-hook-form";
import ToggleSwitch from "../../../components/redesign/Switch";
import ToolTipRe from "../../../components/ui/redesign/ToolTip";

function SwitchPanel({
  control,
  name,
  search,
  options,
  title,
  description,
  getValues,
  setValue,
  watch,
}) {
  const [filter, setFilter] = useState("");

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(filter.toLowerCase())
  );

  const handleReset = () => {
    options.forEach((option) => {
      setValue(`${name}.${option.value}`, false);
    });
    setFilter("");
  };

  // Toggle all functionality
  const handleToggleAll = () => {
    const allSelected =
      Object.values(watch(name)).filter(Boolean).length ===
      filteredOptions.length;
    filteredOptions.forEach((option) => {
      setValue(`${name}.${option.value}`, !allSelected);
    });
  };

  return (
    <div className="flex flex-col w-full mb-4">
      <div className="w-full flex justify-between items-center">
        {title && <p className="font-bold text-gray-600 text-base">{title}</p>}
        {description && (
          <div className="flex w-fit justify-end mr-2">
            <ToolTipRe
              width={200}
              onTop={false}
              offset={"right-0 top-10"}
              toolTipBaseClass={"w-6 h-6 cursor-pointer"}
              toolTipBase={
                <img src="/redesign/portfolio_impact/info_outline.svg" />
              }
            >
              <p className="text-sm font-medium leading-normal text-gray-600">
                {description}
              </p>
            </ToolTipRe>
          </div>
        )}
      </div>
      {/*    {search && (
        <input
          type="text"
          className="p-2 mb-4 border rounded"
          placeholder="Search..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      )}
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={handleToggleAll}
          className="cursor-pointer text-my-rift-primary-green-500"
        >
          Toggle All
        </button>
        <button
          onClick={handleReset}
          className="text-red-500 hover:text-red-700"
        >
          Reset
        </button>
      </div> */}
      <div className="flex flex-col gap-y-2 p-2">
        {filteredOptions.map((option, idx) => (
          <Controller
            key={option.value}
            name={`${name}.${option.value}`}
            control={control}
            render={({ field }) => (
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-x-2">
                  {name.includes("sdg") && (
                    <img
                      className="w-8 h-8 rounded-lg"
                      src={`./img/sdg/SDG_${idx + 1}.png`}
                    />
                  )}
                  <p className="text-gray-700 leading-5 text-start">
                    {option.label}
                  </p>
                </div>
                <ToggleSwitch
                  toggle={field.value}
                  setToggle={(value) => field.onChange(value)}
                  title=""
                />
              </div>
            )}
          />
        ))}
      </div>
    </div>
  );
}

export default SwitchPanel;
