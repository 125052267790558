import React from "react";
import { useDataContext } from "../../../context/PortfolioContext";
import HorizontalBarChart from "../charts/HorizontalBarChart";
import NoDataForYear from "../NoDataForYear";

const EUSusTab = () => {
  const { year, response } = useDataContext();

  const result = Object.entries(response.EUSustainableActivity).map(
    ([portfolio, companies]) => ({
      portfolio,
      companies: Object.entries(companies[year]).map(([name, value]) => ({
        name,
        value,
      })),
    })
  );

  if (response.currentYear != year) {
    return <NoDataForYear />;
  }

  return (
    <>
      {result.map((portfolio, idx) => (
        <div className="max-h-[400px] overflow-y-scroll supervisortable-dark  rounded-2xl border-[2px] border-my-tailwind-gray-100 pb-6 px-4 pt-4">
          <HorizontalBarChart
            data={portfolio.companies.map((p) => ({
              name: p.name,
              data: [p.value * 100],
            }))}
            color={
              idx == 0
                ? "bg-my-tailwind-light-blue-500"
                : "bg-my-tailwind-purple-500"
            }
          />
        </div>
      ))}
    </>
  );
};

export default EUSusTab;
