import { API_ENUMS } from "../../../api/api";

export function privateCompanyResponseByType(type) {
  switch (type) {
    case API_ENUMS.overview:
      return privateCompanyOverview();
    case API_ENUMS.environment:
      return privateCompanyEnvironmentData();
    case API_ENUMS.employee:
      return privateCompanyEmployeeData();
    case API_ENUMS.customer:
      return privateCompanyCustomerImpact();
  }
}

export function privateCompanyOverview() {
  const value = {
    currentYear: 2022,
    data: [
      {
        portfolioName: "B&G Foods, Inc.",
        transparencyScore: 1,
        totalValue: {
          2015: -0.01999999999999999,
          2016: 2.6020852139652106e-17,
          2017: 0.030000000000000047,
          2018: 0.01999999999999999,
          2019: 0.010000000000000035,
          2020: 0.00999999999999998,
          2021: -0.010000000000000038,
          2022: -0.029999999999999992,
        },
        totalValueDrilldownByStakeholder: {
          customer: {
            2015: 0.2,
            2016: 0.2,
            2017: 0.23,
            2018: 0.24,
            2019: 0.23,
            2020: 0.24,
            2021: 0.24,
            2022: 0.24,
          },
          employee: {
            2015: 0.029999999999999992,
            2016: 0.029999999999999992,
            2017: 0.029999999999999992,
            2018: 0.029999999999999992,
            2019: 0.029999999999999992,
            2020: 0.029999999999999992,
            2021: 0.029999999999999992,
            2022: 0.019999999999999993,
          },
          environment: {
            2015: -0.25,
            2016: -0.23,
            2017: -0.23,
            2018: -0.25,
            2019: -0.25,
            2020: -0.26,
            2021: -0.28,
            2022: -0.29000000000000004,
          },
        },
        ImpactResultSDGAlignment: {
          SDGCategoryLevelImpactByYear: {
            1: {},
            2: {
              2015: 0,
              2016: 0,
              2017: 0.23,
              2018: 0.24,
              2019: 0.23,
              2020: 0.24,
              2021: 0.24,
              2022: 0.24,
            },
            3: {
              2015: 0,
              2016: 0,
              2017: 0,
              2018: 0,
              2019: 0,
              2020: 0,
              2021: 0,
              2022: 0,
            },
            4: {},
            5: {
              2015: -0.03,
              2016: -0.03,
              2017: -0.03,
              2018: -0.03,
              2019: -0.03,
              2020: -0.03,
              2021: -0.03,
              2022: -0.03,
            },
            6: {
              2015: 0,
              2016: 0,
              2017: 0,
              2018: 0,
              2019: 0,
              2020: 0,
              2021: 0,
              2022: 0,
            },
            7: {},
            8: {
              2015: 0.06,
              2016: 0.06,
              2017: 0.06,
              2018: 0.06,
              2019: 0.06,
              2020: 0.06,
              2021: 0.06,
              2022: 0.06,
            },
            9: {},
            10: {},
            11: {},
            12: {
              2015: -0.01,
              2016: -0.01,
              2017: -0.01,
              2018: -0.01,
              2019: -0.01,
              2020: -0.01,
              2021: -0.01,
              2022: -0.01,
            },
            13: {
              2015: -0.18,
              2016: -0.16,
              2017: -0.16,
              2018: -0.17,
              2019: -0.17,
              2020: -0.18,
              2021: -0.2,
              2022: -0.21,
            },
            14: {
              2015: -0.06,
              2016: -0.06,
              2017: -0.06,
              2018: -0.07,
              2019: -0.07,
              2020: -0.07,
              2021: -0.07,
              2022: -0.07,
            },
            15: {},
            16: {
              2015: 0,
              2016: 0,
              2017: 0,
              2018: 0,
              2019: 0,
              2020: 0,
              2021: 0,
              2022: 0,
            },
            17: {},
          },
          totalSDGImpactByYear: {
            2015: -0.23,
            2016: -0.21,
            2017: 0.03,
            2018: 0.02,
            2019: 0.01,
            2020: 0,
            2021: -0.02,
            2022: -0.03,
          },
        },
        benchmarkRevenueCategories: {
          2017: [
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.5000467610200137,
              productCategoryRevenue: 834106000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2017,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 834106000,
                  "Product Category Revenue Shares": 0.5000467610200137,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.4999532389799863,
              productCategoryRevenue: 833950000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2017,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 833950000,
                  "Product Category Revenue Shares": 0.4999532389799863,
                },
              ],
            },
          ],
          2018: [
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.5326729634446636,
              productCategoryRevenue: 905951000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2018,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 905951000,
                  "Product Category Revenue Shares": 0.5326729634446636,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.4673270365553363,
              productCategoryRevenue: 794813000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2018,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 794813000,
                  "Product Category Revenue Shares": 0.4673270365553363,
                },
              ],
            },
          ],
          2019: [
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.5117633313137567,
              productCategoryRevenue: 849739000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2019,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 849739000,
                  "Product Category Revenue Shares": 0.5117633313137567,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.4882366686862433,
              productCategoryRevenue: 810675000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2019,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 810675000,
                  "Product Category Revenue Shares": 0.4882366686862433,
                },
              ],
            },
          ],
          2020: [
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.504580242277463,
              productCategoryRevenue: 992968000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2020,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 992968000,
                  "Product Category Revenue Shares": 0.504580242277463,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.495419757722537,
              productCategoryRevenue: 974941000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2020,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 974941000,
                  "Product Category Revenue Shares": 0.495419757722537,
                },
              ],
            },
          ],
          2021: [
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.5584832492325889,
              productCategoryRevenue: 1148389000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2021,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 1148389000,
                  "Product Category Revenue Shares": 0.5584832492325889,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.4415167507674112,
              productCategoryRevenue: 907875000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2021,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 907875000,
                  "Product Category Revenue Shares": 0.4415167507674112,
                },
              ],
            },
          ],
          2022: [
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.6253273231622746,
              productCategoryRevenue: 1352583000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2022,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 1352583000,
                  "Product Category Revenue Shares": 0.6253273231622746,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.37467267683772537,
              productCategoryRevenue: 810417000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2022,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 810417000,
                  "Product Category Revenue Shares": 0.37467267683772537,
                },
              ],
            },
          ],
        },
        EUTaxonomy: {
          2015: null,
          2016: null,
          2017: 0,
          2018: 0,
          2019: 0,
          2020: 0,
          2021: 0,
          2022: 0.46,
        },
      },
      {
        portfolioName: "Food: Major Diversified",
        transparencyScore: 1,
        totalValue: {
          2015: 0.09000000000000005,
          2016: 0.09000000000000005,
          2017: 0.09000000000000005,
          2018: 0.09000000000000005,
          2019: 0.09000000000000005,
          2020: 0.07999999999999999,
          2021: 0.09,
          2022: 0.049999999999999996,
        },
        totalValueDrilldownByStakeholder: {
          customer: {
            2015: 0.28,
            2016: 0.27,
            2017: 0.27,
            2018: 0.28,
            2019: 0.28,
            2020: 0.29,
            2021: 0.29,
            2022: 0.28,
          },
          employee: {
            2015: 0.039999999999999994,
            2016: 0.05,
            2017: 0.05,
            2018: 0.04000000000000001,
            2019: 0.04000000000000001,
            2020: 0.039999999999999994,
            2021: 0.039999999999999994,
            2022: 0.04,
          },
          environment: {
            2015: -0.23,
            2016: -0.23,
            2017: -0.23,
            2018: -0.23,
            2019: -0.23,
            2020: -0.25,
            2021: -0.24000000000000002,
            2022: -0.27,
          },
        },
        ImpactResultSDGAlignment: {
          SDGCategoryLevelImpactByYear: {
            1: {},
            2: {
              2015: 0.1419112002834107,
              2016: 0.1775720149980845,
              2017: 0.1952359071790439,
              2018: 0.1940389869469084,
              2019: 0.2007423743235854,
              2020: 0.2014186172533957,
              2021: 0.184344409710812,
              2022: 0.2099305192718649,
            },
            3: {
              2015: 0.0045320927406042,
              2016: 0.0124757391002229,
              2017: 0.0129612915866863,
              2018: 0.0113099164140836,
              2019: 0.0103707710888864,
              2020: 0.0101100224627174,
              2021: 0.0116723601178629,
              2022: 0.02157478201509,
            },
            4: {},
            5: {
              2015: -0.0233082500866908,
              2016: -0.0257995694718119,
              2017: -0.026628513923835,
              2018: -0.0274453676712632,
              2019: -0.0255215457741485,
              2020: -0.0236028336203356,
              2021: -0.0216714215135733,
              2022: -0.0207481567596859,
            },
            6: {
              2015: -0.0022535190308881,
              2016: -0.0022552336467967,
              2017: -0.002245899470415,
              2018: -0.0023179024766979,
              2019: -0.0023649144354421,
              2020: -0.0022175472321841,
              2021: -0.0022349172362179,
              2022: -0.0025298400834538,
            },
            7: {
              2015: 0,
              2016: 0,
              2017: 0,
              2018: 0,
              2019: 0,
              2020: 0,
              2021: 0,
              2022: 0.0689381397054802,
            },
            8: {
              2015: 0.0555887356050446,
              2016: 0.0649478035961795,
              2017: 0.0692909601289393,
              2018: 0.0706094838059323,
              2019: 0.0675321459147009,
              2020: 0.0621814298260658,
              2021: 0.0611809849147968,
              2022: 0.0667122703011802,
            },
            9: {
              2015: 0.216441837686597,
              2016: 0.2104521239554747,
              2017: 0.2330217189260762,
              2018: 0.2801852496689227,
              2019: 0.2831712296357036,
              2020: 0.1843673282531943,
              2021: 0.2147857271741073,
              2022: 0.2449793154038196,
            },
            10: {},
            11: {},
            12: {
              2015: -0.0145321404618773,
              2016: -0.0141638329394747,
              2017: -0.0134503266261795,
              2018: -0.0135104609484202,
              2019: -0.012892997782269,
              2020: -0.0134488416117572,
              2021: -0.0136236765533806,
              2022: -0.0142398726831482,
            },
            13: {
              2015: -0.2080979350852531,
              2016: -0.1736750236097857,
              2017: -0.1744362547536763,
              2018: -0.175440231489347,
              2019: -0.1768126742050903,
              2020: -0.1819911497667329,
              2021: -0.1961294358808268,
              2022: -0.1921778052387824,
            },
            14: {
              2015: -0.053671659187,
              2016: -0.0535667660421767,
              2017: -0.0546064195224602,
              2018: -0.0574696868275723,
              2019: -0.0582692156864883,
              2020: -0.0604268902102489,
              2021: -0.058445695445144,
              2022: -0.0782540907586302,
            },
            15: {},
            16: {
              2015: 0,
              2016: -1.411446100870238e-7,
              2017: 0,
              2018: 0,
              2019: 0,
              2020: 0,
              2021: 0,
              2022: 0,
            },
            17: {},
          },
          totalSDGImpactByYear: {
            2015: -0.2,
            2016: -0.19,
            2017: -0.18,
            2018: -0.19,
            2019: -0.2,
            2020: -0.21,
            2021: -0.01,
            2022: -0.24,
          },
        },
        benchmarkRevenueCategories: {
          2015: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.430479487727066,
              productCategoryRevenue: 65895733421,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2015,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 18338000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2015,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4446000000,
                  "Product Category Revenue Shares": 0.5501113585746102,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2015,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 43111733421,
                  "Product Category Revenue Shares": 0.4865461508106474,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.2003015369529566,
              productCategoryRevenue: 30661197709,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2015,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 16964757709,
                  "Product Category Revenue Shares": 0.9025781250064551,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2015,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 11930900000,
                  "Product Category Revenue Shares": 0.720330131436748,
                },
                {
                  ISIN: "US4052171000",
                  Year: 2015,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 1765540000,
                  "Product Category Revenue Shares": 0.6566970985841626,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.13287957642949758,
              productCategoryRevenue: 20340567658,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2015,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 19206567658,
                  "Product Category Revenue Shares": 0.2167595877676359,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2015,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 1134000000,
                  "Product Category Revenue Shares": 0.1403118040089087,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.09684348974362234,
              productCategoryRevenue: 14824336503,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2015,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 14824336503,
                  "Product Category Revenue Shares": 0.16730303542708075,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.07489820991913664,
              productCategoryRevenue: 11465058418,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2015,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 11465058418,
                  "Product Category Revenue Shares": 0.12939122599463596,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.04857934550597475,
              productCategoryRevenue: 7436293000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US1344291091",
                  Year: 2015,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 2502000000,
                  "Product Category Revenue Shares": 0.30957683741648107,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2015,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 4632200000,
                  "Product Category Revenue Shares": 0.27966986856325204,
                },
                {
                  ISIN: "US4052171000",
                  Year: 2015,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 302093000,
                  "Product Category Revenue Shares": 0.11236426056763678,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.011962294984356312,
              productCategoryRevenue: 1831130690,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2015,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 1831130690,
                  "Product Category Revenue Shares": 0.09742187499354496,
                },
              ],
            },
            {
              productCategoryName: "Personal Care Products",
              productCategoryRevenueShare: 0.003267511667231364,
              productCategoryRevenue: 500175000,
              productCategoryDescription:
                "Personal care products maintain health and hygiene. They majorly include shower gels, moisturizers, soaps, cleansers, toothpaste, shampoos, and other hygeine-related products.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2015,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Personal Care Products",
                  "Category Revenue": 500175000,
                  "Product Category Revenue Shares": 0.18604136484267336,
                },
              ],
            },
            {
              productCategoryName: "Laundry, Dish and Home Care Products",
              productCategoryRevenueShare: 0.0007885470701584371,
              productCategoryRevenue: 120707000,
              productCategoryDescription:
                "Cleaning agents (usually liquids, powders, sprays, or granules) used to remove dirt, including dust, stains, bad smells and clutter on surfaces, textiles, and housewares. Plus other home cleaning products such as micro-fibre cloths, brushes, sponges.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2015,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category":
                    "Laundry, Dish and Home Care Products",
                  "Category Revenue": 120707000,
                  "Product Category Revenue Shares": 0.04489727600552722,
                },
              ],
            },
          ],
          2016: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.48577953073012947,
              productCategoryRevenue: 75313507489,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US1344291091",
                  Year: 2016,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 7961000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US5007541064",
                  Year: 2016,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26487000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2016,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 40865507489,
                  "Product Category Revenue Shares": 0.4758296169602452,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.2009389310571829,
              productCategoryRevenue: 31152847602,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2016,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 19085947602,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2016,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 12066900000,
                  "Product Category Revenue Shares": 0.7725387008796527,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.12254309995672419,
              productCategoryRevenue: 18998640520,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2016,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 18998640520,
                  "Product Category Revenue Shares": 0.22121628720334313,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.09310848510357528,
              productCategoryRevenue: 14435203928,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2016,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 14435203928,
                  "Product Category Revenue Shares": 0.16808056421793252,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.07471339870851024,
              productCategoryRevenue: 11583296037,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2016,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 11583296037,
                  "Product Category Revenue Shares": 0.13487353161847912,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.022916554443877933,
              productCategoryRevenue: 3552900000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US3703341046",
                  Year: 2016,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3552900000,
                  "Product Category Revenue Shares": 0.22746129912034724,
                },
              ],
            },
          ],
          2017: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.4552591519676295,
              productCategoryRevenue: 72324180605,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2017,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26232000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2017,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4340000000,
                  "Product Category Revenue Shares": 0.743532636628405,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2017,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 41752180605,
                  "Product Category Revenue Shares": 0.4640108696833964,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.1979738107304842,
              productCategoryRevenue: 31450863932,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2017,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 19405263932,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2017,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 12045600000,
                  "Product Category Revenue Shares": 0.7652664481207593,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.12873416148162567,
              productCategoryRevenue: 20451192919,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2017,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 20451192919,
                  "Product Category Revenue Shares": 0.2272833580163162,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.0962415279216547,
              productCategoryRevenue: 15289290983,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Recalls",
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2017,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 15289290983,
                  "Product Category Revenue Shares": 0.16991680681259452,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.07861059978565525,
              productCategoryRevenue: 12488375449,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2017,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 12488375449,
                  "Product Category Revenue Shares": 0.1387889654876929,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.032680832958128736,
              productCategoryRevenue: 5191800000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US1344291091",
                  Year: 2017,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 1497000000,
                  "Product Category Revenue Shares": 0.256467363371595,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2017,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3694800000,
                  "Product Category Revenue Shares": 0.2347335518792407,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.005250448564153651,
              productCategoryRevenue: 834106000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2017,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 834106000,
                  "Product Category Revenue Shares": 0.5000467610200137,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.005249466590668257,
              productCategoryRevenue: 833950000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2017,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 833950000,
                  "Product Category Revenue Shares": 0.4999532389799863,
                },
              ],
            },
          ],
          2018: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.4296405130097319,
              productCategoryRevenue: 72793997018,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2018,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26268000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2018,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4236000000,
                  "Product Category Revenue Shares": 0.6403628117913832,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2018,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 42289997018,
                  "Product Category Revenue Shares": 0.4463522129511665,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.20370838785526155,
              productCategoryRevenue: 34514314477,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2018,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 20777479477,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US4052171000",
                  Year: 2018,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 1842535000,
                  "Product Category Revenue Shares": 0.7496778582527487,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2018,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 11894300000,
                  "Product Category Revenue Shares": 0.7052569788677276,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.13221899261901107,
              productCategoryRevenue: 22401865427,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2018,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 22401865427,
                  "Product Category Revenue Shares": 0.23644178086178927,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.09899912360982112,
              productCategoryRevenue: 16773422642,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2018,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 16773422642,
                  "Product Category Revenue Shares": 0.17703605682060589,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.0783834795742877,
              productCategoryRevenue: 13280513838,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2018,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 13280513838,
                  "Product Category Revenue Shares": 0.14016994936643837,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.04516729318894247,
              productCategoryRevenue: 7652695000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US1344291091",
                  Year: 2018,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 2379000000,
                  "Product Category Revenue Shares": 0.3596371882086168,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2018,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 4970900000,
                  "Product Category Revenue Shares": 0.29474302113227235,
                },
                {
                  ISIN: "US4052171000",
                  Year: 2018,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 302795000,
                  "Product Category Revenue Shares": 0.12319912896614775,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.005347051519995978,
              productCategoryRevenue: 905951000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2018,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 905951000,
                  "Product Category Revenue Shares": 0.5326729634446636,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.004691099253450312,
              productCategoryRevenue: 794813000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2018,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 794813000,
                  "Product Category Revenue Shares": 0.4673270365553363,
                },
              ],
            },
            {
              productCategoryName: "Personal Care Products",
              productCategoryRevenueShare: 0.0011582658725931211,
              productCategoryRevenue: 196245000,
              productCategoryDescription:
                "Personal care products maintain health and hygiene. They majorly include shower gels, moisturizers, soaps, cleansers, toothpaste, shampoos, and other hygeine-related products.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Recalls",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2018,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Personal Care Products",
                  "Category Revenue": 196245000,
                  "Product Category Revenue Shares": 0.07984680415449946,
                },
              ],
            },
            {
              productCategoryName: "Laundry, Dish and Home Care Products",
              productCategoryRevenueShare: 0.0006857934969048135,
              productCategoryRevenue: 116194000,
              productCategoryDescription:
                "Cleaning agents (usually liquids, powders, sprays, or granules) used to remove dirt, including dust, stains, bad smells and clutter on surfaces, textiles, and housewares. Plus other home cleaning products such as micro-fibre cloths, brushes, sponges.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2018,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category":
                    "Laundry, Dish and Home Care Products",
                  "Category Revenue": 116194000,
                  "Product Category Revenue Shares": 0.04727620862660405,
                },
              ],
            },
          ],
          2019: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.4244199944595716,
              productCategoryRevenue: 71620323552,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2019,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 24977000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2019,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4323000000,
                  "Product Category Revenue Shares": 0.5332428765264586,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2019,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 42320323552,
                  "Product Category Revenue Shares": 0.44005487857145376,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.1942179678586964,
              productCategoryRevenue: 32774030157,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2019,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 20207130157,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2019,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 12566900000,
                  "Product Category Revenue Shares": 0.7129508810547694,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.14296236825346992,
              productCategoryRevenue: 24124714207,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2019,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 24124714207,
                  "Product Category Revenue Shares": 0.25085342667307425,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.09228740795576547,
              productCategoryRevenue: 15573380387,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2019,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 15573380387,
                  "Product Category Revenue Shares": 0.1619350099421552,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.08386518152952674,
              productCategoryRevenue: 14152140602,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2019,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 14152140602,
                  "Product Category Revenue Shares": 0.14715668481331678,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.05240751394088471,
              productCategoryRevenue: 8843700000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US1344291091",
                  Year: 2019,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3784000000,
                  "Product Category Revenue Shares": 0.46675712347354137,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2019,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 5059700000,
                  "Product Category Revenue Shares": 0.2870491189452305,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.005035529075908662,
              productCategoryRevenue: 849739000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2019,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 849739000,
                  "Product Category Revenue Shares": 0.5117633313137567,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.004804036926176455,
              productCategoryRevenue: 810675000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2019,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 810675000,
                  "Product Category Revenue Shares": 0.4882366686862433,
                },
              ],
            },
          ],
          2020: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.4173377103571882,
              productCategoryRevenue: 69191850905,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2020,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26185000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2020,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4747000000,
                  "Product Category Revenue Shares": 0.546197215510298,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2020,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 38259850905,
                  "Product Category Revenue Shares": 0.4259511755531326,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.18372767623434083,
              productCategoryRevenue: 30460841821,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2020,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 17723041821,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2020,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 12737800000,
                  "Product Category Revenue Shares": 0.7026976333645942,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.1429598886244404,
              productCategoryRevenue: 23701810437,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2020,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 23701810437,
                  "Product Category Revenue Shares": 0.26387489181402646,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.08993446264383669,
              productCategoryRevenue: 14910543131,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2020,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 14910543131,
                  "Product Category Revenue Shares": 0.16600073509317134,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.07810892548816961,
              productCategoryRevenue: 12949946752,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Recalls",
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2020,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 12949946752,
                  "Product Category Revenue Shares": 0.1441731975396696,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.061219801870098754,
              productCategoryRevenue: 10149841000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US82900L1026",
                  Year: 2020,
                  "Company Name": "Simply Good Foods Co",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 816641000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2020,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3944000000,
                  "Product Category Revenue Shares": 0.453802784489702,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2020,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 5389200000,
                  "Product Category Revenue Shares": 0.29730236663540577,
                },
              ],
            },
            {
              productCategoryName: "Personal Care Products",
              productCategoryRevenueShare: 0.012388325562972015,
              productCategoryRevenue: 2053903000,
              productCategoryDescription:
                "Personal care products maintain health and hygiene. They majorly include shower gels, moisturizers, soaps, cleansers, toothpaste, shampoos, and other hygeine-related products.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Recalls",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2020,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Personal Care Products",
                  "Category Revenue": 2053903000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.0059891878329274536,
              productCategoryRevenue: 992968000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2020,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 992968000,
                  "Product Category Revenue Shares": 0.504580242277463,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.005880456142617008,
              productCategoryRevenue: 974941000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2020,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 974941000,
                  "Product Category Revenue Shares": 0.495419757722537,
                },
              ],
            },
            {
              productCategoryName: "Animal Feed",
              productCategoryRevenueShare: 0.002453565243409047,
              productCategoryRevenue: 406785000,
              productCategoryDescription:
                "Agricultural produce processed into animal feed",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US08862E1091",
                  Year: 2020,
                  "Company Name": "Beyond Meat, Inc.",
                  "RGS Revenue Category": "Animal Feed",
                  "Category Revenue": 406785000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
          ],
          2021: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.33897696656627674,
              productCategoryRevenue: 73404454267,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2021,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26042000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2021,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4621000000,
                  "Product Category Revenue Shares": 0.5451864086833412,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2021,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 41253654267,
                  "Product Category Revenue Shares": 0.39500275583358657,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2021,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 1487800000,
                  "Product Category Revenue Shares": 0.0820764605284934,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.1565542421450344,
              productCategoryRevenue: 33901355671,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2021,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 18994555671,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2021,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 14906800000,
                  "Product Category Revenue Shares": 0.8223533954873945,
                },
              ],
            },
            {
              productCategoryName:
                "Agricultural Produce Processing and Storage",
              productCategoryRevenueShare: 0.15631501312968776,
              productCategoryRevenue: 33849551339,
              productCategoryDescription:
                "Processing and storage of any agricultural produce,",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "SGXE65760014",
                  Year: 2021,
                  "Company Name": "Olam Group Limited",
                  "RGS Revenue Category":
                    "Agricultural Produce Processing and Storage",
                  "Category Revenue": 33849551339,
                  "Product Category Revenue Shares": 0.9679129489710298,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.1327731847744412,
              productCategoryRevenue: 28751638403,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2021,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 28751638403,
                  "Product Category Revenue Shares": 0.27529625207046343,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.09414900086293178,
              productCategoryRevenue: 20387686214,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2021,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 18655286214,
                  "Product Category Revenue Shares": 0.17862392062777588,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2021,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 1732400000,
                  "Product Category Revenue Shares": 0.0955701439841121,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.07286326557068835,
              productCategoryRevenue: 15778323523,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Recalls",
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2021,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 15778323523,
                  "Product Category Revenue Shares": 0.15107707146817412,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.02244599277857894,
              productCategoryRevenue: 4860613000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US82900L1026",
                  Year: 2021,
                  "Company Name": "Simply Good Foods Co",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 1005613000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2021,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3855000000,
                  "Product Category Revenue Shares": 0.4548135913166588,
                },
              ],
            },
            {
              productCategoryName: "Personal Care Products",
              productCategoryRevenueShare: 0.009098725708798385,
              productCategoryRevenue: 1970302000,
              productCategoryDescription:
                "Personal care products maintain health and hygiene. They majorly include shower gels, moisturizers, soaps, cleansers, toothpaste, shampoos, and other hygeine-related products.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Recalls",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2021,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Personal Care Products",
                  "Category Revenue": 1970302000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.005303185256880046,
              productCategoryRevenue: 1148389000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2021,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 1148389000,
                  "Product Category Revenue Shares": 0.5584832492325889,
                },
              ],
            },
            {
              productCategoryName: "Oil Seeds Production",
              productCategoryRevenueShare: 0.005181961671469047,
              productCategoryRevenue: 1122138393,
              productCategoryDescription: "Farming and production of oil seeds",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "SGXE65760014",
                  Year: 2021,
                  "Company Name": "Olam Group Limited",
                  "RGS Revenue Category": "Oil Seeds Production",
                  "Category Revenue": 1122138393,
                  "Product Category Revenue Shares": 0.03208705102897028,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.00419250734297348,
              productCategoryRevenue: 907875000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2021,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 907875000,
                  "Product Category Revenue Shares": 0.4415167507674112,
                },
              ],
            },
            {
              productCategoryName: "Animal Feed",
              productCategoryRevenueShare: 0.0021459541922398745,
              productCategoryRevenue: 464700000,
              productCategoryDescription:
                "Agricultural produce processed into animal feed",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US08862E1091",
                  Year: 2021,
                  "Company Name": "Beyond Meat, Inc.",
                  "RGS Revenue Category": "Animal Feed",
                  "Category Revenue": 464700000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
          ],
          2022: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.38773029116876107,
              productCategoryRevenue: 73053676754,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2022,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26485000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2022,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4607000000,
                  "Product Category Revenue Shares": 0.5380752160710115,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2022,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 40115976754,
                  "Product Category Revenue Shares": 0.37519062950329496,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2022,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 1845700000,
                  "Product Category Revenue Shares": 0.09717893096331241,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.19477121554865395,
              productCategoryRevenue: 36697554320,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2022,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 21809854320,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2022,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 14887700000,
                  "Product Category Revenue Shares": 0.783860199654606,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.15155884491588711,
              productCategoryRevenue: 28555754136,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2022,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 28555754136,
                  "Product Category Revenue Shares": 0.267071930865023,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.12077775230901173,
              productCategoryRevenue: 22756176335,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2022,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 20496776335,
                  "Product Category Revenue Shares": 0.19169914428545212,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2022,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 2259400000,
                  "Product Category Revenue Shares": 0.11896086938208163,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.09422394997846323,
              productCategoryRevenue: 17753077696,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2022,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 17753077696,
                  "Product Category Revenue Shares": 0.1660382953462299,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.027193773825849227,
              productCategoryRevenue: 5123678000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US82900L1026",
                  Year: 2022,
                  "Company Name": "Simply Good Foods Co",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 1168678000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2022,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3955000000,
                  "Product Category Revenue Shares": 0.46192478392898856,
                },
              ],
            },
            {
              productCategoryName: "Personal Care Products",
              productCategoryRevenueShare: 0.010040637012576667,
              productCategoryRevenue: 1891793000,
              productCategoryDescription:
                "Personal care products maintain health and hygiene. They majorly include shower gels, moisturizers, soaps, cleansers, toothpaste, shampoos, and other hygeine-related products.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2022,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Personal Care Products",
                  "Category Revenue": 1891793000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.007178795424437021,
              productCategoryRevenue: 1352583000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2022,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 1352583000,
                  "Product Category Revenue Shares": 0.6253273231622746,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.004301264951197802,
              productCategoryRevenue: 810417000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2022,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 810417000,
                  "Product Category Revenue Shares": 0.37467267683772537,
                },
              ],
            },
            {
              productCategoryName: "Animal Feed",
              productCategoryRevenueShare: 0.0022234748651621928,
              productCategoryRevenue: 418933000,
              productCategoryDescription:
                "Agricultural produce processed into animal feed",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US08862E1091",
                  Year: 2022,
                  "Company Name": "Beyond Meat, Inc.",
                  "RGS Revenue Category": "Animal Feed",
                  "Category Revenue": 418933000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
          ],
        },
        EUTaxonomy: {
          2015: 0,
          2016: 0,
          2017: 0,
          2018: 0,
          2019: 0,
          2020: 0,
          2021: 0,
          2022: 0.22,
        },
      },
    ],
  };
  return value;
}

export function privateCompanyEnvironmentData() {
  return {
    currentYear: 2022,
    portfolioComparison: [
      {
        portfolioName: "B&G Foods, Inc.",
        transparencyScore: 1,
        portfolioImpactResults: {
          totalValue: {
            2015: -0.01999999999999999,
            2016: 2.6020852139652106e-17,
            2017: 0.030000000000000047,
            2018: 0.01999999999999999,
            2019: 0.010000000000000035,
            2020: 0.00999999999999998,
            2021: -0.010000000000000038,
            2022: -0.029999999999999992,
          },
          totalValueDrilldownByStakeholder: {
            customer: {
              2015: 0.2,
              2016: 0.2,
              2017: 0.23,
              2018: 0.24,
              2019: 0.23,
              2020: 0.24,
              2021: 0.24,
              2022: 0.24,
            },
            employee: {
              2015: 0.029999999999999992,
              2016: 0.029999999999999992,
              2017: 0.029999999999999992,
              2018: 0.029999999999999992,
              2019: 0.029999999999999992,
              2020: 0.029999999999999992,
              2021: 0.029999999999999992,
              2022: 0.019999999999999993,
            },
            environment: {
              2015: -0.25,
              2016: -0.23,
              2017: -0.23,
              2018: -0.25,
              2019: -0.25,
              2020: -0.26,
              2021: -0.28,
              2022: -0.29000000000000004,
            },
          },
          stakeholderSpecificData: {
            leaderCompanies: null,
            laggardCompanies: null,
            pillarLevelImpactValues: {
              2015: {
                "GHG Emissions per $ of revenue": -0.18,
                "Operational Water Consumed per $ of revenue": 0,
                "Plastic Waste per $ of revenue": -0.06,
                "Food Waste per $ of revenue": -0.01,
              },
              2016: {
                "GHG Emissions per $ of revenue": -0.16,
                "Operational Water Consumed per $ of revenue": 0,
                "Plastic Waste per $ of revenue": -0.06,
                "Food Waste per $ of revenue": -0.01,
              },
              2017: {
                "GHG Emissions per $ of revenue": -0.16,
                "Operational Water Consumed per $ of revenue": 0,
                "Plastic Waste per $ of revenue": -0.06,
                "Food Waste per $ of revenue": -0.01,
              },
              2018: {
                "GHG Emissions per $ of revenue": -0.17,
                "Operational Water Consumed per $ of revenue": 0,
                "Plastic Waste per $ of revenue": -0.07,
                "Food Waste per $ of revenue": -0.01,
              },
              2019: {
                "GHG Emissions per $ of revenue": -0.17,
                "Operational Water Consumed per $ of revenue": 0,
                "Plastic Waste per $ of revenue": -0.07,
                "Food Waste per $ of revenue": -0.01,
              },
              2020: {
                "GHG Emissions per $ of revenue": -0.18,
                "Operational Water Consumed per $ of revenue": 0,
                "Plastic Waste per $ of revenue": -0.07,
                "Food Waste per $ of revenue": -0.01,
              },
              2021: {
                "GHG Emissions per $ of revenue": -0.2,
                "Operational Water Consumed per $ of revenue": 0,
                "Plastic Waste per $ of revenue": -0.07,
                "Food Waste per $ of revenue": -0.01,
              },
              2022: {
                "GHG Emissions per $ of revenue": -0.21,
                "Operational Water Consumed per $ of revenue": 0,
                "Plastic Waste per $ of revenue": -0.07,
                "Food Waste per $ of revenue": -0.01,
              },
            },
          },
        },
        portfolioImpactPillarDrilldown: {
          2015: {
            categories: [
              "GHG Emissions per $ of revenue",
              "Operational Water Consumed per $ of revenue",
              "Plastic Waste per $ of revenue",
              "Food Waste per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [-0.18, 0, -0.06, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [-0.16, 0, -0.06, -0.01],
              },
            ],
            drilldown: [
              {
                categories: [
                  "Scope 1 Emissions per $ of revenue",
                  "Scope 2 Emissions per $ of revenue",
                  "Scope 3 Emissions per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [-0.01, -0.01, -0.16],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [-0.01, -0.01, -0.14],
                  },
                ],
                drilldown: [
                  null,
                  null,
                  {
                    categories: [
                      "Purchased goods and services per $ of revenue",
                      "Capital goods per $ of revenue",
                      "Fuel-and-energy-related activities per $ of revenue",
                      "Upstream transportation and distribution per $ of revenue",
                      "Waste generated in operations per $ of revenue",
                      "Business travel per $ of revenue",
                      "Employee commuting per $ of revenue",
                      "Upstream leased assets per $ of revenue",
                      "Downstream transportation and distribution per $ of revenue",
                      "Processing of sold products per $ of revenue",
                      "Use of sold products per $ of revenue",
                      "End of life treatment of sold products per $ of revenue",
                      "Downstream leased assets per $ of revenue",
                      "Franchises per $ of revenue",
                      "Investments per $ of revenue",
                    ],
                    data: [
                      {
                        Name: "B&G Foods, Inc.",
                        data: [
                          -0.09, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.02,
                          -0.01, 0, 0, -0.01,
                        ],
                      },
                      {
                        Name: "Food: Major Diversified",
                        data: [
                          -0.07, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.03,
                          0, 0, 0, -0.01,
                        ],
                      },
                    ],
                    drilldown: [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  },
                ],
              },
              null,
              null,
              null,
            ],
          },
          2016: {
            categories: [
              "GHG Emissions per $ of revenue",
              "Operational Water Consumed per $ of revenue",
              "Plastic Waste per $ of revenue",
              "Food Waste per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [-0.16, 0, -0.06, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [-0.16, 0, -0.06, -0.01],
              },
            ],
            drilldown: [
              {
                categories: [
                  "Scope 1 Emissions per $ of revenue",
                  "Scope 2 Emissions per $ of revenue",
                  "Scope 3 Emissions per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [-0.01, -0.01, -0.14],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [-0.01, -0.01, -0.14],
                  },
                ],
                drilldown: [
                  null,
                  null,
                  {
                    categories: [
                      "Purchased goods and services per $ of revenue",
                      "Capital goods per $ of revenue",
                      "Fuel-and-energy-related activities per $ of revenue",
                      "Upstream transportation and distribution per $ of revenue",
                      "Waste generated in operations per $ of revenue",
                      "Business travel per $ of revenue",
                      "Employee commuting per $ of revenue",
                      "Upstream leased assets per $ of revenue",
                      "Downstream transportation and distribution per $ of revenue",
                      "Processing of sold products per $ of revenue",
                      "Use of sold products per $ of revenue",
                      "End of life treatment of sold products per $ of revenue",
                      "Downstream leased assets per $ of revenue",
                      "Franchises per $ of revenue",
                      "Investments per $ of revenue",
                    ],
                    data: [
                      {
                        Name: "B&G Foods, Inc.",
                        data: [
                          -0.08, 0, 0, -0.01, 0, 0, 0, 0, -0.01, 0, -0.02,
                          -0.01, 0, 0, -0.01,
                        ],
                      },
                      {
                        Name: "Food: Major Diversified",
                        data: [
                          -0.07, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.03,
                          0, 0, 0, -0.01,
                        ],
                      },
                    ],
                    drilldown: [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  },
                ],
              },
              null,
              null,
              null,
            ],
          },
          2017: {
            categories: [
              "GHG Emissions per $ of revenue",
              "Operational Water Consumed per $ of revenue",
              "Plastic Waste per $ of revenue",
              "Food Waste per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [-0.16, 0, -0.06, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [-0.16, 0, -0.06, -0.01],
              },
            ],
            drilldown: [
              {
                categories: [
                  "Scope 1 Emissions per $ of revenue",
                  "Scope 2 Emissions per $ of revenue",
                  "Scope 3 Emissions per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [-0.01, -0.01, -0.14],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [-0.01, -0.01, -0.14],
                  },
                ],
                drilldown: [
                  null,
                  null,
                  {
                    categories: [
                      "Purchased goods and services per $ of revenue",
                      "Capital goods per $ of revenue",
                      "Fuel-and-energy-related activities per $ of revenue",
                      "Upstream transportation and distribution per $ of revenue",
                      "Waste generated in operations per $ of revenue",
                      "Business travel per $ of revenue",
                      "Employee commuting per $ of revenue",
                      "Upstream leased assets per $ of revenue",
                      "Downstream transportation and distribution per $ of revenue",
                      "Processing of sold products per $ of revenue",
                      "Use of sold products per $ of revenue",
                      "End of life treatment of sold products per $ of revenue",
                      "Downstream leased assets per $ of revenue",
                      "Franchises per $ of revenue",
                      "Investments per $ of revenue",
                    ],
                    data: [
                      {
                        Name: "B&G Foods, Inc.",
                        data: [
                          -0.08, 0, 0, -0.01, 0, 0, 0, 0, -0.01, 0, -0.02,
                          -0.01, 0, 0, -0.01,
                        ],
                      },
                      {
                        Name: "Food: Major Diversified",
                        data: [
                          -0.07, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.03,
                          0, 0, 0, -0.01,
                        ],
                      },
                    ],
                    drilldown: [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  },
                ],
              },
              null,
              null,
              null,
            ],
          },
          2018: {
            categories: [
              "GHG Emissions per $ of revenue",
              "Operational Water Consumed per $ of revenue",
              "Plastic Waste per $ of revenue",
              "Food Waste per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [-0.16999999999999998, 0, -0.07, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [-0.15, 0, -0.06, -0.01],
              },
            ],
            drilldown: [
              {
                categories: [
                  "Scope 1 Emissions per $ of revenue",
                  "Scope 2 Emissions per $ of revenue",
                  "Scope 3 Emissions per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [-0.02, -0.01, -0.13999999999999999],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [-0.01, -0.01, -0.13],
                  },
                ],
                drilldown: [
                  null,
                  null,
                  {
                    categories: [
                      "Purchased goods and services per $ of revenue",
                      "Capital goods per $ of revenue",
                      "Fuel-and-energy-related activities per $ of revenue",
                      "Upstream transportation and distribution per $ of revenue",
                      "Waste generated in operations per $ of revenue",
                      "Business travel per $ of revenue",
                      "Employee commuting per $ of revenue",
                      "Upstream leased assets per $ of revenue",
                      "Downstream transportation and distribution per $ of revenue",
                      "Processing of sold products per $ of revenue",
                      "Use of sold products per $ of revenue",
                      "End of life treatment of sold products per $ of revenue",
                      "Downstream leased assets per $ of revenue",
                      "Franchises per $ of revenue",
                      "Investments per $ of revenue",
                    ],
                    data: [
                      {
                        Name: "B&G Foods, Inc.",
                        data: [
                          -0.08, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.01,
                          -0.01, 0, 0, -0.01,
                        ],
                      },
                      {
                        Name: "Food: Major Diversified",
                        data: [
                          -0.07, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.02,
                          0, 0, 0, -0.01,
                        ],
                      },
                    ],
                    drilldown: [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  },
                ],
              },
              null,
              null,
              null,
            ],
          },
          2019: {
            categories: [
              "GHG Emissions per $ of revenue",
              "Operational Water Consumed per $ of revenue",
              "Plastic Waste per $ of revenue",
              "Food Waste per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [-0.16999999999999998, 0, -0.07, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [-0.15, 0, -0.06, -0.01],
              },
            ],
            drilldown: [
              {
                categories: [
                  "Scope 1 Emissions per $ of revenue",
                  "Scope 2 Emissions per $ of revenue",
                  "Scope 3 Emissions per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [-0.02, -0.01, -0.13999999999999999],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [-0.01, -0.01, -0.13],
                  },
                ],
                drilldown: [
                  null,
                  null,
                  {
                    categories: [
                      "Purchased goods and services per $ of revenue",
                      "Capital goods per $ of revenue",
                      "Fuel-and-energy-related activities per $ of revenue",
                      "Upstream transportation and distribution per $ of revenue",
                      "Waste generated in operations per $ of revenue",
                      "Business travel per $ of revenue",
                      "Employee commuting per $ of revenue",
                      "Upstream leased assets per $ of revenue",
                      "Downstream transportation and distribution per $ of revenue",
                      "Processing of sold products per $ of revenue",
                      "Use of sold products per $ of revenue",
                      "End of life treatment of sold products per $ of revenue",
                      "Downstream leased assets per $ of revenue",
                      "Franchises per $ of revenue",
                      "Investments per $ of revenue",
                    ],
                    data: [
                      {
                        Name: "B&G Foods, Inc.",
                        data: [
                          -0.08, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.01,
                          -0.01, 0, 0, -0.01,
                        ],
                      },
                      {
                        Name: "Food: Major Diversified",
                        data: [
                          -0.07, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.02,
                          0, 0, 0, -0.01,
                        ],
                      },
                    ],
                    drilldown: [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  },
                ],
              },
              null,
              null,
              null,
            ],
          },
          2020: {
            categories: [
              "GHG Emissions per $ of revenue",
              "Operational Water Consumed per $ of revenue",
              "Plastic Waste per $ of revenue",
              "Food Waste per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [-0.19, 0, -0.07, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [-0.16999999999999998, 0, -0.07, -0.01],
              },
            ],
            drilldown: [
              {
                categories: [
                  "Scope 1 Emissions per $ of revenue",
                  "Scope 2 Emissions per $ of revenue",
                  "Scope 3 Emissions per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [-0.02, -0.01, -0.16],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [-0.01, -0.01, -0.15],
                  },
                ],
                drilldown: [
                  null,
                  null,
                  {
                    categories: [
                      "Purchased goods and services per $ of revenue",
                      "Capital goods per $ of revenue",
                      "Fuel-and-energy-related activities per $ of revenue",
                      "Upstream transportation and distribution per $ of revenue",
                      "Waste generated in operations per $ of revenue",
                      "Business travel per $ of revenue",
                      "Employee commuting per $ of revenue",
                      "Upstream leased assets per $ of revenue",
                      "Downstream transportation and distribution per $ of revenue",
                      "Processing of sold products per $ of revenue",
                      "Use of sold products per $ of revenue",
                      "End of life treatment of sold products per $ of revenue",
                      "Downstream leased assets per $ of revenue",
                      "Franchises per $ of revenue",
                      "Investments per $ of revenue",
                    ],
                    data: [
                      {
                        Name: "B&G Foods, Inc.",
                        data: [
                          -0.09, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.01,
                          -0.01, -0.01, 0, -0.01,
                        ],
                      },
                      {
                        Name: "Food: Major Diversified",
                        data: [
                          -0.08, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.02,
                          0, -0.01, 0, -0.01,
                        ],
                      },
                    ],
                    drilldown: [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  },
                ],
              },
              null,
              null,
              null,
            ],
          },
          2021: {
            categories: [
              "GHG Emissions per $ of revenue",
              "Operational Water Consumed per $ of revenue",
              "Plastic Waste per $ of revenue",
              "Food Waste per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [-0.20000000000000004, 0, -0.07, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [-0.16999999999999998, 0, -0.06, -0.01],
              },
            ],
            drilldown: [
              {
                categories: [
                  "Scope 1 Emissions per $ of revenue",
                  "Scope 2 Emissions per $ of revenue",
                  "Scope 3 Emissions per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [-0.02, -0.01, -0.17000000000000004],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [-0.01, -0.01, -0.15],
                  },
                ],
                drilldown: [
                  null,
                  null,
                  {
                    categories: [
                      "Purchased goods and services per $ of revenue",
                      "Capital goods per $ of revenue",
                      "Fuel-and-energy-related activities per $ of revenue",
                      "Upstream transportation and distribution per $ of revenue",
                      "Waste generated in operations per $ of revenue",
                      "Business travel per $ of revenue",
                      "Employee commuting per $ of revenue",
                      "Upstream leased assets per $ of revenue",
                      "Downstream transportation and distribution per $ of revenue",
                      "Processing of sold products per $ of revenue",
                      "Use of sold products per $ of revenue",
                      "End of life treatment of sold products per $ of revenue",
                      "Downstream leased assets per $ of revenue",
                      "Franchises per $ of revenue",
                      "Investments per $ of revenue",
                    ],
                    data: [
                      {
                        Name: "B&G Foods, Inc.",
                        data: [
                          -0.11, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.01,
                          -0.01, 0, 0, -0.01,
                        ],
                      },
                      {
                        Name: "Food: Major Diversified",
                        data: [
                          -0.08, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.02,
                          0, -0.01, 0, -0.01,
                        ],
                      },
                    ],
                    drilldown: [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  },
                ],
              },
              null,
              null,
              null,
            ],
          },
          2022: {
            categories: [
              "GHG Emissions per $ of revenue",
              "Operational Water Consumed per $ of revenue",
              "Plastic Waste per $ of revenue",
              "Food Waste per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [-0.22000000000000003, 0, -0.07, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [-0.19, 0, -0.07, -0.01],
              },
            ],
            drilldown: [
              {
                categories: [
                  "Scope 1 Emissions per $ of revenue",
                  "Scope 2 Emissions per $ of revenue",
                  "Scope 3 Emissions per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [-0.03, -0.01, -0.18000000000000002],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [-0.01, -0.01, -0.17],
                  },
                ],
                drilldown: [
                  null,
                  null,
                  {
                    categories: [
                      "Purchased goods and services per $ of revenue",
                      "Capital goods per $ of revenue",
                      "Fuel-and-energy-related activities per $ of revenue",
                      "Upstream transportation and distribution per $ of revenue",
                      "Waste generated in operations per $ of revenue",
                      "Business travel per $ of revenue",
                      "Employee commuting per $ of revenue",
                      "Upstream leased assets per $ of revenue",
                      "Downstream transportation and distribution per $ of revenue",
                      "Processing of sold products per $ of revenue",
                      "Use of sold products per $ of revenue",
                      "End of life treatment of sold products per $ of revenue",
                      "Downstream leased assets per $ of revenue",
                      "Franchises per $ of revenue",
                      "Investments per $ of revenue",
                    ],
                    data: [
                      {
                        Name: "B&G Foods, Inc.",
                        data: [
                          -0.11, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.02,
                          -0.01, 0, 0, -0.01,
                        ],
                      },
                      {
                        Name: "Food: Major Diversified",
                        data: [
                          -0.1, 0, 0, -0.01, 0, 0, 0, 0, -0.01, -0.01, -0.02,
                          -0.01, 0, 0, -0.01,
                        ],
                      },
                    ],
                    drilldown: [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  },
                ],
              },
              null,
              null,
              null,
            ],
          },
        },
      },

      {
        portfolioName: "Food: Major Diversified",
        transparencyScore: 1,
        totalValue: {
          2015: 0.09000000000000005,
          2016: 0.09000000000000005,
          2017: 0.09000000000000005,
          2018: 0.09000000000000005,
          2019: 0.09000000000000005,
          2020: 0.07999999999999999,
          2021: 0.09,
          2022: 0.049999999999999996,
        },
        totalValueDrilldownByStakeholder: {
          customer: {
            2015: 0.28,
            2016: 0.27,
            2017: 0.27,
            2018: 0.28,
            2019: 0.28,
            2020: 0.29,
            2021: 0.29,
            2022: 0.28,
          },
          employee: {
            2015: 0.039999999999999994,
            2016: 0.05,
            2017: 0.05,
            2018: 0.04000000000000001,
            2019: 0.04000000000000001,
            2020: 0.039999999999999994,
            2021: 0.039999999999999994,
            2022: 0.04,
          },
          environment: {
            2015: -0.23,
            2016: -0.23,
            2017: -0.23,
            2018: -0.23,
            2019: -0.23,
            2020: -0.25,
            2021: -0.24000000000000002,
            2022: -0.27,
          },
        },
        ImpactResultSDGAlignment: {
          SDGCategoryLevelImpactByYear: {
            1: {},
            2: {
              2015: 0,
              2016: 0,
              2017: 0,
              2018: 0,
              2019: 0,
              2020: 0,
              2021: 0.09,
              2022: 0,
            },
            3: {
              2015: 0,
              2016: 0,
              2017: 0,
              2018: 0,
              2019: 0,
              2020: 0,
              2021: 0.01,
              2022: 0,
            },
            4: {},
            5: {
              2015: -0.02,
              2016: -0.02,
              2017: -0.02,
              2018: -0.02,
              2019: -0.03,
              2020: -0.02,
              2021: -0.02,
              2022: -0.01,
            },
            6: {
              2015: 0,
              2016: 0,
              2017: 0,
              2018: 0,
              2019: 0,
              2020: 0,
              2021: 0,
              2022: 0,
            },
            7: {},
            8: {
              2015: 0.06,
              2016: 0.07,
              2017: 0.07,
              2018: 0.07,
              2019: 0.07,
              2020: 0.06,
              2021: 0.06,
              2022: 0.05,
            },
            9: {
              2015: 0,
              2016: 0,
              2017: 0,
              2018: 0,
              2019: 0,
              2020: 0,
              2021: 0.11,
              2022: 0,
            },
            10: {},
            11: {},
            12: {
              2015: -0.01,
              2016: -0.01,
              2017: -0.01,
              2018: -0.01,
              2019: -0.01,
              2020: -0.01,
              2021: -0.01,
              2022: -0.01,
            },
            13: {
              2015: -0.16,
              2016: -0.16,
              2017: -0.16,
              2018: -0.16,
              2019: -0.16,
              2020: -0.17,
              2021: -0.17,
              2022: -0.19,
            },
            14: {
              2015: -0.06,
              2016: -0.06,
              2017: -0.06,
              2018: -0.06,
              2019: -0.06,
              2020: -0.07,
              2021: -0.06,
              2022: -0.07,
            },
            15: {},
            16: {
              2015: 0,
              2016: 0,
              2017: 0,
              2018: 0,
              2019: 0,
              2020: 0,
              2021: 0,
              2022: 0,
            },
            17: {},
          },
          totalSDGImpactByYear: {
            2015: -0.2,
            2016: -0.19,
            2017: -0.18,
            2018: -0.19,
            2019: -0.2,
            2020: -0.21,
            2021: -0.01,
            2022: -0.24,
          },
        },
        benchmarkRevenueCategories: {
          2015: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.430479487727066,
              productCategoryRevenue: 65895733421,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2015,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 18338000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2015,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4446000000,
                  "Product Category Revenue Shares": 0.5501113585746102,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2015,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 43111733421,
                  "Product Category Revenue Shares": 0.4865461508106474,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.2003015369529566,
              productCategoryRevenue: 30661197709,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2015,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 16964757709,
                  "Product Category Revenue Shares": 0.9025781250064551,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2015,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 11930900000,
                  "Product Category Revenue Shares": 0.720330131436748,
                },
                {
                  ISIN: "US4052171000",
                  Year: 2015,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 1765540000,
                  "Product Category Revenue Shares": 0.6566970985841626,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.13287957642949758,
              productCategoryRevenue: 20340567658,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2015,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 19206567658,
                  "Product Category Revenue Shares": 0.2167595877676359,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2015,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 1134000000,
                  "Product Category Revenue Shares": 0.1403118040089087,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.09684348974362234,
              productCategoryRevenue: 14824336503,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2015,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 14824336503,
                  "Product Category Revenue Shares": 0.16730303542708075,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.07489820991913664,
              productCategoryRevenue: 11465058418,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2015,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 11465058418,
                  "Product Category Revenue Shares": 0.12939122599463596,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.04857934550597475,
              productCategoryRevenue: 7436293000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US1344291091",
                  Year: 2015,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 2502000000,
                  "Product Category Revenue Shares": 0.30957683741648107,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2015,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 4632200000,
                  "Product Category Revenue Shares": 0.27966986856325204,
                },
                {
                  ISIN: "US4052171000",
                  Year: 2015,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 302093000,
                  "Product Category Revenue Shares": 0.11236426056763678,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.011962294984356312,
              productCategoryRevenue: 1831130690,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2015,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 1831130690,
                  "Product Category Revenue Shares": 0.09742187499354496,
                },
              ],
            },
            {
              productCategoryName: "Personal Care Products",
              productCategoryRevenueShare: 0.003267511667231364,
              productCategoryRevenue: 500175000,
              productCategoryDescription:
                "Personal care products maintain health and hygiene. They majorly include shower gels, moisturizers, soaps, cleansers, toothpaste, shampoos, and other hygeine-related products.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2015,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Personal Care Products",
                  "Category Revenue": 500175000,
                  "Product Category Revenue Shares": 0.18604136484267336,
                },
              ],
            },
            {
              productCategoryName: "Laundry, Dish and Home Care Products",
              productCategoryRevenueShare: 0.0007885470701584371,
              productCategoryRevenue: 120707000,
              productCategoryDescription:
                "Cleaning agents (usually liquids, powders, sprays, or granules) used to remove dirt, including dust, stains, bad smells and clutter on surfaces, textiles, and housewares. Plus other home cleaning products such as micro-fibre cloths, brushes, sponges.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2015,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category":
                    "Laundry, Dish and Home Care Products",
                  "Category Revenue": 120707000,
                  "Product Category Revenue Shares": 0.04489727600552722,
                },
              ],
            },
          ],
          2016: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.48577953073012947,
              productCategoryRevenue: 75313507489,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US1344291091",
                  Year: 2016,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 7961000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US5007541064",
                  Year: 2016,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26487000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2016,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 40865507489,
                  "Product Category Revenue Shares": 0.4758296169602452,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.2009389310571829,
              productCategoryRevenue: 31152847602,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2016,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 19085947602,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2016,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 12066900000,
                  "Product Category Revenue Shares": 0.7725387008796527,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.12254309995672419,
              productCategoryRevenue: 18998640520,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2016,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 18998640520,
                  "Product Category Revenue Shares": 0.22121628720334313,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.09310848510357528,
              productCategoryRevenue: 14435203928,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2016,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 14435203928,
                  "Product Category Revenue Shares": 0.16808056421793252,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.07471339870851024,
              productCategoryRevenue: 11583296037,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2016,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 11583296037,
                  "Product Category Revenue Shares": 0.13487353161847912,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.022916554443877933,
              productCategoryRevenue: 3552900000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US3703341046",
                  Year: 2016,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3552900000,
                  "Product Category Revenue Shares": 0.22746129912034724,
                },
              ],
            },
          ],
          2017: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.4552591519676295,
              productCategoryRevenue: 72324180605,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2017,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26232000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2017,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4340000000,
                  "Product Category Revenue Shares": 0.743532636628405,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2017,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 41752180605,
                  "Product Category Revenue Shares": 0.4640108696833964,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.1979738107304842,
              productCategoryRevenue: 31450863932,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2017,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 19405263932,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2017,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 12045600000,
                  "Product Category Revenue Shares": 0.7652664481207593,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.12873416148162567,
              productCategoryRevenue: 20451192919,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2017,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 20451192919,
                  "Product Category Revenue Shares": 0.2272833580163162,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.0962415279216547,
              productCategoryRevenue: 15289290983,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Recalls",
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2017,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 15289290983,
                  "Product Category Revenue Shares": 0.16991680681259452,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.07861059978565525,
              productCategoryRevenue: 12488375449,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2017,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 12488375449,
                  "Product Category Revenue Shares": 0.1387889654876929,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.032680832958128736,
              productCategoryRevenue: 5191800000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US1344291091",
                  Year: 2017,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 1497000000,
                  "Product Category Revenue Shares": 0.256467363371595,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2017,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3694800000,
                  "Product Category Revenue Shares": 0.2347335518792407,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.005250448564153651,
              productCategoryRevenue: 834106000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2017,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 834106000,
                  "Product Category Revenue Shares": 0.5000467610200137,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.005249466590668257,
              productCategoryRevenue: 833950000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2017,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 833950000,
                  "Product Category Revenue Shares": 0.4999532389799863,
                },
              ],
            },
          ],
          2018: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.4296405130097319,
              productCategoryRevenue: 72793997018,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2018,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26268000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2018,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4236000000,
                  "Product Category Revenue Shares": 0.6403628117913832,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2018,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 42289997018,
                  "Product Category Revenue Shares": 0.4463522129511665,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.20370838785526155,
              productCategoryRevenue: 34514314477,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2018,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 20777479477,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US4052171000",
                  Year: 2018,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 1842535000,
                  "Product Category Revenue Shares": 0.7496778582527487,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2018,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 11894300000,
                  "Product Category Revenue Shares": 0.7052569788677276,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.13221899261901107,
              productCategoryRevenue: 22401865427,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2018,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 22401865427,
                  "Product Category Revenue Shares": 0.23644178086178927,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.09899912360982112,
              productCategoryRevenue: 16773422642,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2018,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 16773422642,
                  "Product Category Revenue Shares": 0.17703605682060589,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.0783834795742877,
              productCategoryRevenue: 13280513838,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2018,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 13280513838,
                  "Product Category Revenue Shares": 0.14016994936643837,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.04516729318894247,
              productCategoryRevenue: 7652695000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US1344291091",
                  Year: 2018,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 2379000000,
                  "Product Category Revenue Shares": 0.3596371882086168,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2018,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 4970900000,
                  "Product Category Revenue Shares": 0.29474302113227235,
                },
                {
                  ISIN: "US4052171000",
                  Year: 2018,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 302795000,
                  "Product Category Revenue Shares": 0.12319912896614775,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.005347051519995978,
              productCategoryRevenue: 905951000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2018,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 905951000,
                  "Product Category Revenue Shares": 0.5326729634446636,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.004691099253450312,
              productCategoryRevenue: 794813000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2018,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 794813000,
                  "Product Category Revenue Shares": 0.4673270365553363,
                },
              ],
            },
            {
              productCategoryName: "Personal Care Products",
              productCategoryRevenueShare: 0.0011582658725931211,
              productCategoryRevenue: 196245000,
              productCategoryDescription:
                "Personal care products maintain health and hygiene. They majorly include shower gels, moisturizers, soaps, cleansers, toothpaste, shampoos, and other hygeine-related products.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Recalls",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2018,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Personal Care Products",
                  "Category Revenue": 196245000,
                  "Product Category Revenue Shares": 0.07984680415449946,
                },
              ],
            },
            {
              productCategoryName: "Laundry, Dish and Home Care Products",
              productCategoryRevenueShare: 0.0006857934969048135,
              productCategoryRevenue: 116194000,
              productCategoryDescription:
                "Cleaning agents (usually liquids, powders, sprays, or granules) used to remove dirt, including dust, stains, bad smells and clutter on surfaces, textiles, and housewares. Plus other home cleaning products such as micro-fibre cloths, brushes, sponges.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Recalls",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2018,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category":
                    "Laundry, Dish and Home Care Products",
                  "Category Revenue": 116194000,
                  "Product Category Revenue Shares": 0.04727620862660405,
                },
              ],
            },
          ],
          2019: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.4244199944595716,
              productCategoryRevenue: 71620323552,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2019,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 24977000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2019,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4323000000,
                  "Product Category Revenue Shares": 0.5332428765264586,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2019,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 42320323552,
                  "Product Category Revenue Shares": 0.44005487857145376,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.1942179678586964,
              productCategoryRevenue: 32774030157,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2019,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 20207130157,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2019,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 12566900000,
                  "Product Category Revenue Shares": 0.7129508810547694,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.14296236825346992,
              productCategoryRevenue: 24124714207,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2019,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 24124714207,
                  "Product Category Revenue Shares": 0.25085342667307425,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.09228740795576547,
              productCategoryRevenue: 15573380387,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2019,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 15573380387,
                  "Product Category Revenue Shares": 0.1619350099421552,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.08386518152952674,
              productCategoryRevenue: 14152140602,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2019,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 14152140602,
                  "Product Category Revenue Shares": 0.14715668481331678,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.05240751394088471,
              productCategoryRevenue: 8843700000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US1344291091",
                  Year: 2019,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3784000000,
                  "Product Category Revenue Shares": 0.46675712347354137,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2019,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 5059700000,
                  "Product Category Revenue Shares": 0.2870491189452305,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.005035529075908662,
              productCategoryRevenue: 849739000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2019,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 849739000,
                  "Product Category Revenue Shares": 0.5117633313137567,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.004804036926176455,
              productCategoryRevenue: 810675000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2019,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 810675000,
                  "Product Category Revenue Shares": 0.4882366686862433,
                },
              ],
            },
          ],
          2020: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.4173377103571882,
              productCategoryRevenue: 69191850905,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2020,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26185000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2020,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4747000000,
                  "Product Category Revenue Shares": 0.546197215510298,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2020,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 38259850905,
                  "Product Category Revenue Shares": 0.4259511755531326,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.18372767623434083,
              productCategoryRevenue: 30460841821,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2020,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 17723041821,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2020,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 12737800000,
                  "Product Category Revenue Shares": 0.7026976333645942,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.1429598886244404,
              productCategoryRevenue: 23701810437,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2020,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 23701810437,
                  "Product Category Revenue Shares": 0.26387489181402646,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.08993446264383669,
              productCategoryRevenue: 14910543131,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2020,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 14910543131,
                  "Product Category Revenue Shares": 0.16600073509317134,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.07810892548816961,
              productCategoryRevenue: 12949946752,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Recalls",
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2020,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 12949946752,
                  "Product Category Revenue Shares": 0.1441731975396696,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.061219801870098754,
              productCategoryRevenue: 10149841000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US82900L1026",
                  Year: 2020,
                  "Company Name": "Simply Good Foods Co",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 816641000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2020,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3944000000,
                  "Product Category Revenue Shares": 0.453802784489702,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2020,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 5389200000,
                  "Product Category Revenue Shares": 0.29730236663540577,
                },
              ],
            },
            {
              productCategoryName: "Personal Care Products",
              productCategoryRevenueShare: 0.012388325562972015,
              productCategoryRevenue: 2053903000,
              productCategoryDescription:
                "Personal care products maintain health and hygiene. They majorly include shower gels, moisturizers, soaps, cleansers, toothpaste, shampoos, and other hygeine-related products.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Recalls",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2020,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Personal Care Products",
                  "Category Revenue": 2053903000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.0059891878329274536,
              productCategoryRevenue: 992968000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2020,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 992968000,
                  "Product Category Revenue Shares": 0.504580242277463,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.005880456142617008,
              productCategoryRevenue: 974941000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2020,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 974941000,
                  "Product Category Revenue Shares": 0.495419757722537,
                },
              ],
            },
            {
              productCategoryName: "Animal Feed",
              productCategoryRevenueShare: 0.002453565243409047,
              productCategoryRevenue: 406785000,
              productCategoryDescription:
                "Agricultural produce processed into animal feed",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US08862E1091",
                  Year: 2020,
                  "Company Name": "Beyond Meat, Inc.",
                  "RGS Revenue Category": "Animal Feed",
                  "Category Revenue": 406785000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
          ],
          2021: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.33897696656627674,
              productCategoryRevenue: 73404454267,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2021,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26042000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2021,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4621000000,
                  "Product Category Revenue Shares": 0.5451864086833412,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2021,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 41253654267,
                  "Product Category Revenue Shares": 0.39500275583358657,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2021,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 1487800000,
                  "Product Category Revenue Shares": 0.0820764605284934,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.1565542421450344,
              productCategoryRevenue: 33901355671,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2021,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 18994555671,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2021,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 14906800000,
                  "Product Category Revenue Shares": 0.8223533954873945,
                },
              ],
            },
            {
              productCategoryName:
                "Agricultural Produce Processing and Storage",
              productCategoryRevenueShare: 0.15631501312968776,
              productCategoryRevenue: 33849551339,
              productCategoryDescription:
                "Processing and storage of any agricultural produce,",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "SGXE65760014",
                  Year: 2021,
                  "Company Name": "Olam Group Limited",
                  "RGS Revenue Category":
                    "Agricultural Produce Processing and Storage",
                  "Category Revenue": 33849551339,
                  "Product Category Revenue Shares": 0.9679129489710298,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.1327731847744412,
              productCategoryRevenue: 28751638403,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2021,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 28751638403,
                  "Product Category Revenue Shares": 0.27529625207046343,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.09414900086293178,
              productCategoryRevenue: 20387686214,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2021,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 18655286214,
                  "Product Category Revenue Shares": 0.17862392062777588,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2021,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 1732400000,
                  "Product Category Revenue Shares": 0.0955701439841121,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.07286326557068835,
              productCategoryRevenue: 15778323523,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Recalls",
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2021,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 15778323523,
                  "Product Category Revenue Shares": 0.15107707146817412,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.02244599277857894,
              productCategoryRevenue: 4860613000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US82900L1026",
                  Year: 2021,
                  "Company Name": "Simply Good Foods Co",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 1005613000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2021,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3855000000,
                  "Product Category Revenue Shares": 0.4548135913166588,
                },
              ],
            },
            {
              productCategoryName: "Personal Care Products",
              productCategoryRevenueShare: 0.009098725708798385,
              productCategoryRevenue: 1970302000,
              productCategoryDescription:
                "Personal care products maintain health and hygiene. They majorly include shower gels, moisturizers, soaps, cleansers, toothpaste, shampoos, and other hygeine-related products.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Recalls",
                "Plastic Waste",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2021,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Personal Care Products",
                  "Category Revenue": 1970302000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.005303185256880046,
              productCategoryRevenue: 1148389000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2021,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 1148389000,
                  "Product Category Revenue Shares": 0.5584832492325889,
                },
              ],
            },
            {
              productCategoryName: "Oil Seeds Production",
              productCategoryRevenueShare: 0.005181961671469047,
              productCategoryRevenue: 1122138393,
              productCategoryDescription: "Farming and production of oil seeds",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "SGXE65760014",
                  Year: 2021,
                  "Company Name": "Olam Group Limited",
                  "RGS Revenue Category": "Oil Seeds Production",
                  "Category Revenue": 1122138393,
                  "Product Category Revenue Shares": 0.03208705102897028,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.00419250734297348,
              productCategoryRevenue: 907875000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2021,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 907875000,
                  "Product Category Revenue Shares": 0.4415167507674112,
                },
              ],
            },
            {
              productCategoryName: "Animal Feed",
              productCategoryRevenueShare: 0.0021459541922398745,
              productCategoryRevenue: 464700000,
              productCategoryDescription:
                "Agricultural produce processed into animal feed",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US08862E1091",
                  Year: 2021,
                  "Company Name": "Beyond Meat, Inc.",
                  "RGS Revenue Category": "Animal Feed",
                  "Category Revenue": 464700000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
          ],
          2022: [
            {
              productCategoryName: "Food Services",
              productCategoryRevenueShare: 0.38773029116876107,
              productCategoryRevenue: 73053676754,
              productCategoryDescription:
                "Services related to food preparation and catering for consumers, businesses and other public institutions",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US5007541064",
                  Year: 2022,
                  "Company Name": "Kraft Heinz Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 26485000000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2022,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 4607000000,
                  "Product Category Revenue Shares": 0.5380752160710115,
                },
                {
                  ISIN: "CH0038863350",
                  Year: 2022,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 40115976754,
                  "Product Category Revenue Shares": 0.37519062950329496,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2022,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Services",
                  "Category Revenue": 1845700000,
                  "Product Category Revenue Shares": 0.09717893096331241,
                },
              ],
            },
            {
              productCategoryName: "Food Retail",
              productCategoryRevenueShare: 0.19477121554865395,
              productCategoryRevenue: 36697554320,
              productCategoryDescription:
                "Retailers which derive a significant and dominant proportion of revenues from food products, household products, convienence products and other groceries",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "GB0006731235",
                  Year: 2022,
                  "Company Name": "Associated British Foods plc",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 21809854320,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2022,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Food Retail",
                  "Category Revenue": 14887700000,
                  "Product Category Revenue Shares": 0.783860199654606,
                },
              ],
            },
            {
              productCategoryName: "Soft Drinks",
              productCategoryRevenueShare: 0.15155884491588711,
              productCategoryRevenue: 28555754136,
              productCategoryDescription:
                "All non-alcoholic beverages, excluding tap water",
              relevantImpactPillars: [
                "Plastic Waste",
                "Downstream Scope 3 GHG Emissions",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2022,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Soft Drinks",
                  "Category Revenue": 28555754136,
                  "Product Category Revenue Shares": 0.267071930865023,
                },
              ],
            },
            {
              productCategoryName: "Pet Food",
              productCategoryRevenueShare: 0.12077775230901173,
              productCategoryRevenue: 22756176335,
              productCategoryDescription: "All food for household pets.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2022,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 20496776335,
                  "Product Category Revenue Shares": 0.19169914428545212,
                },
                {
                  ISIN: "US3703341046",
                  Year: 2022,
                  "Company Name": "General Mills, Inc.",
                  "RGS Revenue Category": "Pet Food",
                  "Category Revenue": 2259400000,
                  "Product Category Revenue Shares": 0.11896086938208163,
                },
              ],
            },
            {
              productCategoryName:
                "Dietary Supplements and Specialized Nutrition",
              productCategoryRevenueShare: 0.09422394997846323,
              productCategoryRevenue: 17753077696,
              productCategoryDescription:
                "Dietary supplements for personal health and fitness, as well as nutrition solutions for infants and other at-risk individual. This includes products such as probiotics, protein isolates, infant formulas, and multivitamins.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "CH0038863350",
                  Year: 2022,
                  "Company Name": "Nestle S.A.",
                  "RGS Revenue Category":
                    "Dietary Supplements and Specialized Nutrition",
                  "Category Revenue": 17753077696,
                  "Product Category Revenue Shares": 0.1660382953462299,
                },
              ],
            },
            {
              productCategoryName: "Snacks",
              productCategoryRevenueShare: 0.027193773825849227,
              productCategoryRevenue: 5123678000,
              productCategoryDescription:
                "All sweet and salty snacks and confectionery.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US82900L1026",
                  Year: 2022,
                  "Company Name": "Simply Good Foods Co",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 1168678000,
                  "Product Category Revenue Shares": 1,
                },
                {
                  ISIN: "US1344291091",
                  Year: 2022,
                  "Company Name": "Campbell Soup Company",
                  "RGS Revenue Category": "Snacks",
                  "Category Revenue": 3955000000,
                  "Product Category Revenue Shares": 0.46192478392898856,
                },
              ],
            },
            {
              productCategoryName: "Personal Care Products",
              productCategoryRevenueShare: 0.010040637012576667,
              productCategoryRevenue: 1891793000,
              productCategoryDescription:
                "Personal care products maintain health and hygiene. They majorly include shower gels, moisturizers, soaps, cleansers, toothpaste, shampoos, and other hygeine-related products.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US4052171000",
                  Year: 2022,
                  "Company Name": "Hain Celestial Group, Inc.",
                  "RGS Revenue Category": "Personal Care Products",
                  "Category Revenue": 1891793000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
            {
              productCategoryName: "Bakery Products",
              productCategoryRevenueShare: 0.007178795424437021,
              productCategoryRevenue: 1352583000,
              productCategoryDescription:
                "Products which are primarily made of a cereal like wheat, rye and oat. Proucts include bread, breakfast cereals, pastries and dough",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2022,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Bakery Products",
                  "Category Revenue": 1352583000,
                  "Product Category Revenue Shares": 0.6253273231622746,
                },
              ],
            },
            {
              productCategoryName: "Food Ingredients",
              productCategoryRevenueShare: 0.004301264951197802,
              productCategoryRevenue: 810417000,
              productCategoryDescription:
                "All spices, herbs, sweeteners, other condiments, and miscellaneous ingredients, as well as food additives such as colourings and preservatives.",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US05508R1068",
                  Year: 2022,
                  "Company Name": "B&G Foods, Inc.",
                  "RGS Revenue Category": "Food Ingredients",
                  "Category Revenue": 810417000,
                  "Product Category Revenue Shares": 0.37467267683772537,
                },
              ],
            },
            {
              productCategoryName: "Animal Feed",
              productCategoryRevenueShare: 0.0022234748651621928,
              productCategoryRevenue: 418933000,
              productCategoryDescription:
                "Agricultural produce processed into animal feed",
              relevantImpactPillars: [
                "Downstream Scope 3 GHG Emissions",
                "Plastic Waste",
                "Food Waste",
              ],
              companyList: [
                {
                  ISIN: "US08862E1091",
                  Year: 2022,
                  "Company Name": "Beyond Meat, Inc.",
                  "RGS Revenue Category": "Animal Feed",
                  "Category Revenue": 418933000,
                  "Product Category Revenue Shares": 1,
                },
              ],
            },
          ],
        },
        EUTaxonomy: {
          2015: 0,
          2016: 0,
          2017: 0,
          2018: 0,
          2019: 0,
          2020: 0,
          2021: 0,
          2022: 0.31,
        },
      },
    ],
  };
}

export function privateCompanyEmployeeData() {
  return {
    currentYear: 2022,

    portfolioComparison: [
      {
        portfolioName: "B&G Foods, Inc.",
        transparencyScore: 1,
        portfolioImpactResults: {
          totalValue: {
            2015: -0.01999999999999999,
            2016: 2.6020852139652106e-17,
            2017: 0.030000000000000047,
            2018: 0.01999999999999999,
            2019: 0.010000000000000035,
            2020: 0.00999999999999998,
            2021: -0.010000000000000038,
            2022: -0.029999999999999992,
          },
          totalValueDrilldownByStakeholder: {
            customer: {
              2015: 0.2,
              2016: 0.2,
              2017: 0.23,
              2018: 0.24,
              2019: 0.23,
              2020: 0.24,
              2021: 0.24,
              2022: 0.24,
            },
            employee: {
              2015: 0.029999999999999992,
              2016: 0.029999999999999992,
              2017: 0.029999999999999992,
              2018: 0.029999999999999992,
              2019: 0.029999999999999992,
              2020: 0.029999999999999992,
              2021: 0.029999999999999992,
              2022: 0.019999999999999993,
            },
            environment: {
              2015: -0.25,
              2016: -0.23,
              2017: -0.23,
              2018: -0.25,
              2019: -0.25,
              2020: -0.26,
              2021: -0.28,
              2022: -0.29000000000000004,
            },
          },
          stakeholderSpecificData: {
            leaderCompanies: null,
            laggardCompanies: null,
            pillarLevelImpactValues: {
              2015: {
                "Wages per $ of revenue": 0.06,
                "Diversity per $ of revenue": -0.02,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2016: {
                "Wages per $ of revenue": 0.06,
                "Diversity per $ of revenue": -0.02,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2017: {
                "Wages per $ of revenue": 0.06,
                "Diversity per $ of revenue": -0.02,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2018: {
                "Wages per $ of revenue": 0.06,
                "Diversity per $ of revenue": -0.02,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2019: {
                "Wages per $ of revenue": 0.06,
                "Diversity per $ of revenue": -0.02,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2020: {
                "Wages per $ of revenue": 0.06,
                "Diversity per $ of revenue": -0.02,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2021: {
                "Wages per $ of revenue": 0.06,
                "Diversity per $ of revenue": -0.02,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2022: {
                "Wages per $ of revenue": 0.06,
                "Diversity per $ of revenue": -0.02,
                "Equal Opportunity per $ of revenue": -0.02,
              },
            },
          },
        },
        portfolioImpactPillarDrilldown: {
          2015: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.06, -0.01, -0.01],
              },
            ],
            drilldown: [null, null, null],
          },
          2016: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.07, -0.01, -0.01],
              },
            ],
            drilldown: [null, null, null],
          },
          2017: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.07, -0.01, -0.01],
              },
            ],
            drilldown: [null, null, null],
          },
          2018: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.07, -0.01, -0.02],
              },
            ],
            drilldown: [null, null, null],
          },
          2019: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.07, -0.01, -0.02],
              },
            ],
            drilldown: [null, null, null],
          },
          2020: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.06, -0.01, -0.01],
              },
            ],
            drilldown: [null, null, null],
          },
          2021: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.06, -0.01, -0.01],
              },
            ],
            drilldown: [null, null, null],
          },
          2022: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.02],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.05, -0.01, 0],
              },
            ],
            drilldown: [null, null, null],
          },
        },
      },
      {
        portfolioName: "Food: Major Diversified",
        transparencyScore: 1,
        portfolioImpactResults: {
          totalValue: {
            2015: 0.09000000000000005,
            2016: 0.09000000000000005,
            2017: 0.09000000000000005,
            2018: 0.09000000000000005,
            2019: 0.09000000000000005,
            2020: 0.07999999999999999,
            2021: 0.09,
            2022: 0.049999999999999996,
          },
          totalValueDrilldownByStakeholder: {
            customer: {
              2015: 0.28,
              2016: 0.27,
              2017: 0.27,
              2018: 0.28,
              2019: 0.28,
              2020: 0.29,
              2021: 0.29,
              2022: 0.28,
            },
            employee: {
              2015: 0.039999999999999994,
              2016: 0.05,
              2017: 0.05,
              2018: 0.04000000000000001,
              2019: 0.04000000000000001,
              2020: 0.039999999999999994,
              2021: 0.039999999999999994,
              2022: 0.04,
            },
            environment: {
              2015: -0.23,
              2016: -0.23,
              2017: -0.23,
              2018: -0.23,
              2019: -0.23,
              2020: -0.25,
              2021: -0.24000000000000002,
              2022: -0.27,
            },
          },
          stakeholderSpecificData: {
            leaderCompanies: {
              2015: {
                "Campbell Soup Company": 0.15,
                "General Mills, Inc.": 0.13,
                "Hain Celestial Group, Inc.": 0.07,
                "Kraft Heinz Company": 0.06,
                "B&G Foods, Inc.": 0.03,
                "Alicorp SAA": 0,
                "Associated British Foods plc": 0,
                "Dongsuh Companies, Inc.": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle S.A.": 0,
                "Nestle Nigeria Plc": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Standard Foods Corporation": 0,
                "Tiger Brands Limited": -0.01,
                "Yihai International Holding Ltd.": -0.02,
                "Ausnutria Dairy Corp. Ltd.": -0.05,
              },
              2016: {
                "Beyond Meat, Inc.": 0.15,
                "Campbell Soup Company": 0.15,
                "General Mills, Inc.": 0.13,
                "Hain Celestial Group, Inc.": 0.07,
                "Kraft Heinz Company": 0.06,
                "B&G Foods, Inc.": 0.03,
                "Alicorp SAA": 0,
                "Associated British Foods plc": 0,
                "Dongsuh Companies, Inc.": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle S.A.": 0,
                "Nestle Nigeria Plc": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Standard Foods Corporation": 0,
                "Yihai International Holding Ltd.": -0.01,
                "Tiger Brands Limited": -0.01,
                "Ausnutria Dairy Corp. Ltd.": -0.05,
              },
              2017: {
                "Campbell Soup Company": 0.21,
                "Beyond Meat, Inc.": 0.17,
                "General Mills, Inc.": 0.15,
                "Hain Celestial Group, Inc.": 0.08,
                "Kraft Heinz Company": 0.06,
                "B&G Foods, Inc.": 0.03,
                "Simply Good Foods Co": 0.02,
                "Dongsuh Companies, Inc.": 0.01,
                "Alicorp SAA": 0,
                "Associated British Foods plc": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle S.A.": 0,
                "Nestle Nigeria Plc": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Standard Foods Corporation": 0,
                "Tiger Brands Limited": -0.01,
                "Yihai International Holding Ltd.": -0.02,
                "Ausnutria Dairy Corp. Ltd.": -0.06,
              },
              2018: {
                "Campbell Soup Company": 0.23,
                "Beyond Meat, Inc.": 0.16,
                "General Mills, Inc.": 0.15,
                "Hain Celestial Group, Inc.": 0.07,
                "Kraft Heinz Company": 0.06,
                "B&G Foods, Inc.": 0.03,
                "Simply Good Foods Co": 0.02,
                "Dongsuh Companies, Inc.": 0.01,
                "Nayuki Holdings Ltd.": 0.01,
                "Yihai International Holding Ltd.": 0.01,
                "Alicorp SAA": 0,
                "Associated British Foods plc": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle S.A.": 0,
                "Nestle Nigeria Plc": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Standard Foods Corporation": 0,
                "Tiger Brands Limited": -0.01,
                "Ausnutria Dairy Corp. Ltd.": -0.06,
              },
              2019: {
                "Beyond Meat, Inc.": 0.17,
                "General Mills, Inc.": 0.15,
                "Campbell Soup Company": 0.15,
                "Hain Celestial Group, Inc.": 0.08,
                "Kraft Heinz Company": 0.05,
                "B&G Foods, Inc.": 0.03,
                "Yihai International Holding Ltd.": 0.02,
                "Simply Good Foods Co": 0.02,
                "Dongsuh Companies, Inc.": 0.01,
                "Alicorp SAA": 0,
                "Associated British Foods plc": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle S.A.": 0,
                "Nestle Nigeria Plc": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Standard Foods Corporation": 0,
                "Tiger Brands Limited": -0.01,
                "Nayuki Holdings Ltd.": -0.02,
                "Ausnutria Dairy Corp. Ltd.": -0.06,
              },
              2020: {
                "Beyond Meat, Inc.": 0.16,
                "General Mills, Inc.": 0.13,
                "Campbell Soup Company": 0.12,
                "Hain Celestial Group, Inc.": 0.07,
                "Kraft Heinz Company": 0.05,
                "Yihai International Holding Ltd.": 0.03,
                "B&G Foods, Inc.": 0.03,
                "Simply Good Foods Co": 0.02,
                "Nestle (Malaysia) Bhd.": 0.01,
                "Dongsuh Companies, Inc.": 0.01,
                "Alicorp SAA": 0,
                "Associated British Foods plc": 0,
                "Nayuki Holdings Ltd.": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle S.A.": 0,
                "Nestle Nigeria Plc": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Standard Foods Corporation": 0,
                "Tiger Brands Limited": -0.01,
                "Ausnutria Dairy Corp. Ltd.": -0.05,
              },
              2021: {
                "Beyond Meat, Inc.": 0.17,
                "General Mills, Inc.": 0.14,
                "Campbell Soup Company": 0.12,
                "Hain Celestial Group, Inc.": 0.08,
                "Kraft Heinz Company": 0.05,
                "B&G Foods, Inc.": 0.03,
                "Yihai International Holding Ltd.": 0.02,
                "Simply Good Foods Co": 0.02,
                "Nestle (Malaysia) Bhd.": 0.01,
                "Dongsuh Companies, Inc.": 0.01,
                "Tiger Brands Limited": 0.01,
                "Associated British Foods plc": 0,
                "Alicorp SAA": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle S.A.": 0,
                "Nestle Nigeria Plc": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Standard Foods Corporation": 0,
                "Nayuki Holdings Ltd.": -0.01,
                "Ausnutria Dairy Corp. Ltd.": -0.04,
              },
              2022: {
                "Campbell Soup Company": 0.11,
                "General Mills, Inc.": 0.11,
                "Hain Celestial Group, Inc.": 0.08,
                "Kraft Heinz Company": 0.05,
                "Yihai International Holding Ltd.": 0.02,
                "Simply Good Foods Co": 0.02,
                "B&G Foods, Inc.": 0.02,
                "Nestle (Malaysia) Bhd.": 0.01,
                "Associated British Foods plc": 0,
                "Nestle S.A.": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Tiger Brands Limited": -0.01,
              },
            },
            laggardCompanies: {
              2015: {
                "Ausnutria Dairy Corp. Ltd.": -0.05,
                "Yihai International Holding Ltd.": -0.02,
                "Tiger Brands Limited": -0.01,
                "Standard Foods Corporation": 0,
                "Dongsuh Companies, Inc.": 0,
                "Associated British Foods plc": 0,
                "Alicorp SAA": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle Nigeria Plc": 0,
                "Nestle S.A.": 0,
                "B&G Foods, Inc.": 0.03,
                "Kraft Heinz Company": 0.06,
                "Hain Celestial Group, Inc.": 0.07,
                "General Mills, Inc.": 0.13,
                "Campbell Soup Company": 0.15,
              },
              2016: {
                "Ausnutria Dairy Corp. Ltd.": -0.05,
                "Yihai International Holding Ltd.": -0.01,
                "Tiger Brands Limited": -0.01,
                "Standard Foods Corporation": 0,
                "Dongsuh Companies, Inc.": 0,
                "Associated British Foods plc": 0,
                "Alicorp SAA": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Nestle Nigeria Plc": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle S.A.": 0,
                "B&G Foods, Inc.": 0.03,
                "Kraft Heinz Company": 0.06,
                "Hain Celestial Group, Inc.": 0.07,
                "General Mills, Inc.": 0.13,
                "Campbell Soup Company": 0.15,
                "Beyond Meat, Inc.": 0.15,
              },
              2017: {
                "Ausnutria Dairy Corp. Ltd.": -0.06,
                "Yihai International Holding Ltd.": -0.02,
                "Tiger Brands Limited": -0.01,
                "Standard Foods Corporation": 0,
                "Associated British Foods plc": 0,
                "Alicorp SAA": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle Nigeria Plc": 0,
                "Nestle S.A.": 0,
                "Dongsuh Companies, Inc.": 0.01,
                "Simply Good Foods Co": 0.02,
                "B&G Foods, Inc.": 0.03,
                "Kraft Heinz Company": 0.06,
                "Hain Celestial Group, Inc.": 0.08,
                "General Mills, Inc.": 0.15,
                "Beyond Meat, Inc.": 0.17,
                "Campbell Soup Company": 0.21,
              },
              2018: {
                "Ausnutria Dairy Corp. Ltd.": -0.06,
                "Tiger Brands Limited": -0.01,
                "Standard Foods Corporation": 0,
                "Associated British Foods plc": 0,
                "Alicorp SAA": 0,
                "Olam Group Limited": 0,
                "RFG Holdings Ltd.": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle Nigeria Plc": 0,
                "Nestle S.A.": 0,
                "Yihai International Holding Ltd.": 0.01,
                "Nayuki Holdings Ltd.": 0.01,
                "Dongsuh Companies, Inc.": 0.01,
                "Simply Good Foods Co": 0.02,
                "B&G Foods, Inc.": 0.03,
                "Kraft Heinz Company": 0.06,
                "Hain Celestial Group, Inc.": 0.07,
                "General Mills, Inc.": 0.15,
                "Beyond Meat, Inc.": 0.16,
                "Campbell Soup Company": 0.23,
              },
              2019: {
                "Ausnutria Dairy Corp. Ltd.": -0.06,
                "Nayuki Holdings Ltd.": -0.02,
                "Tiger Brands Limited": -0.01,
                "Standard Foods Corporation": 0,
                "Associated British Foods plc": 0,
                "Alicorp SAA": 0,
                "Olam Group Limited": 0,
                "RFG Holdings Ltd.": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle Nigeria Plc": 0,
                "Nestle S.A.": 0,
                "Dongsuh Companies, Inc.": 0.01,
                "Yihai International Holding Ltd.": 0.02,
                "Simply Good Foods Co": 0.02,
                "B&G Foods, Inc.": 0.03,
                "Kraft Heinz Company": 0.05,
                "Hain Celestial Group, Inc.": 0.08,
                "Campbell Soup Company": 0.15,
                "General Mills, Inc.": 0.15,
                "Beyond Meat, Inc.": 0.17,
              },
              2020: {
                "Ausnutria Dairy Corp. Ltd.": -0.05,
                "Tiger Brands Limited": -0.01,
                "Standard Foods Corporation": 0,
                "Nayuki Holdings Ltd.": 0,
                "Associated British Foods plc": 0,
                "Alicorp SAA": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Nestle Nigeria Plc": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle S.A.": 0,
                "Dongsuh Companies, Inc.": 0.01,
                "Nestle (Malaysia) Bhd.": 0.01,
                "Simply Good Foods Co": 0.02,
                "B&G Foods, Inc.": 0.03,
                "Yihai International Holding Ltd.": 0.03,
                "Kraft Heinz Company": 0.05,
                "Hain Celestial Group, Inc.": 0.07,
                "Campbell Soup Company": 0.12,
                "General Mills, Inc.": 0.13,
                "Beyond Meat, Inc.": 0.16,
              },
              2021: {
                "Ausnutria Dairy Corp. Ltd.": -0.04,
                "Nayuki Holdings Ltd.": -0.01,
                "Standard Foods Corporation": 0,
                "Associated British Foods plc": 0,
                "Alicorp SAA": 0,
                "Olam Group Limited": 0,
                "RFG Holdings Ltd.": 0,
                "Ichitan Group Public Co., Ltd.": 0,
                "Nestle Nigeria Plc": 0,
                "Nestle S.A.": 0,
                "Dongsuh Companies, Inc.": 0.01,
                "Nestle (Malaysia) Bhd.": 0.01,
                "Tiger Brands Limited": 0.01,
                "Yihai International Holding Ltd.": 0.02,
                "Simply Good Foods Co": 0.02,
                "B&G Foods, Inc.": 0.03,
                "Kraft Heinz Company": 0.05,
                "Hain Celestial Group, Inc.": 0.08,
                "Campbell Soup Company": 0.12,
                "General Mills, Inc.": 0.14,
                "Beyond Meat, Inc.": 0.17,
              },
              2022: {
                "Tiger Brands Limited": -0.01,
                "Associated British Foods plc": 0,
                "Nestle S.A.": 0,
                "RFG Holdings Ltd.": 0,
                "Olam Group Limited": 0,
                "Nestle (Malaysia) Bhd.": 0.01,
                "Yihai International Holding Ltd.": 0.02,
                "Simply Good Foods Co": 0.02,
                "B&G Foods, Inc.": 0.02,
                "Kraft Heinz Company": 0.05,
                "Hain Celestial Group, Inc.": 0.08,
                "Campbell Soup Company": 0.11,
                "General Mills, Inc.": 0.11,
              },
            },
            pillarLevelImpactValues: {
              2015: {
                "Wages per $ of revenue": 0.06,
                "Diversity per $ of revenue": -0.01,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2016: {
                "Wages per $ of revenue": 0.07,
                "Diversity per $ of revenue": -0.01,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2017: {
                "Wages per $ of revenue": 0.07,
                "Diversity per $ of revenue": -0.01,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2018: {
                "Wages per $ of revenue": 0.07,
                "Diversity per $ of revenue": -0.01,
                "Equal Opportunity per $ of revenue": -0.02,
              },
              2019: {
                "Wages per $ of revenue": 0.07,
                "Diversity per $ of revenue": -0.01,
                "Equal Opportunity per $ of revenue": -0.02,
              },
              2020: {
                "Wages per $ of revenue": 0.06,
                "Diversity per $ of revenue": -0.01,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2021: {
                "Wages per $ of revenue": 0.06,
                "Diversity per $ of revenue": -0.01,
                "Equal Opportunity per $ of revenue": -0.01,
              },
              2022: {
                "Wages per $ of revenue": 0.05,
                "Diversity per $ of revenue": -0.01,
                "Equal Opportunity per $ of revenue": 0,
              },
            },
          },
        },
        portfolioImpactPillarDrilldown: {
          2015: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.06, -0.01, -0.01],
              },
            ],
            drilldown: [null, null, null],
          },
          2016: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.07, -0.01, -0.01],
              },
            ],
            drilldown: [null, null, null],
          },
          2017: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.07, -0.01, -0.01],
              },
            ],
            drilldown: [null, null, null],
          },
          2018: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.07, -0.01, -0.02],
              },
            ],
            drilldown: [null, null, null],
          },
          2019: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.07, -0.01, -0.02],
              },
            ],
            drilldown: [null, null, null],
          },
          2020: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.06, -0.01, -0.01],
              },
            ],
            drilldown: [null, null, null],
          },
          2021: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.01],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.06, -0.01, -0.01],
              },
            ],
            drilldown: [null, null, null],
          },
          2022: {
            categories: [
              "Wages per $ of revenue",
              "Diversity per $ of revenue",
              "Equal Opportunity per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.06, -0.02, -0.02],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.05, -0.01, 0],
              },
            ],
            drilldown: [null, null, null],
          },
        },
      },
    ],
  };
}

export function privateCompanyCustomerImpact() {
  return {
    currentYear: 2022,

    portfolioComparison: [
      {
        portfolioName: "B&G Foods, Inc.",
        transparencyScore: 1,
        portfolioImpactResults: {
          totalValue: {
            2015: -0.01999999999999999,
            2016: 2.6020852139652106e-17,
            2017: 0.030000000000000047,
            2018: 0.01999999999999999,
            2019: 0.010000000000000035,
            2020: 0.00999999999999998,
            2021: -0.010000000000000038,
            2022: -0.029999999999999992,
          },
          totalValueDrilldownByStakeholder: {
            customer: {
              2015: 0.2,
              2016: 0.2,
              2017: 0.23,
              2018: 0.24,
              2019: 0.23,
              2020: 0.24,
              2021: 0.24,
              2022: 0.24,
            },
            employee: {
              2015: 0.029999999999999992,
              2016: 0.029999999999999992,
              2017: 0.029999999999999992,
              2018: 0.029999999999999992,
              2019: 0.029999999999999992,
              2020: 0.029999999999999992,
              2021: 0.029999999999999992,
              2022: 0.019999999999999993,
            },
            environment: {
              2015: -0.25,
              2016: -0.23,
              2017: -0.23,
              2018: -0.25,
              2019: -0.25,
              2020: -0.26,
              2021: -0.28,
              2022: -0.29000000000000004,
            },
          },
          stakeholderSpecificData: {
            leaderCompanies: null,
            laggardCompanies: null,
            pillarLevelImpactValues: {
              2015: {
                "Customer Utility per $ of revenue": 0.2,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2016: {
                "Customer Utility per $ of revenue": 0.2,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2017: {
                "Customer Utility per $ of revenue": 0.23,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2018: {
                "Customer Utility per $ of revenue": 0.24,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2019: {
                "Customer Utility per $ of revenue": 0.23,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2020: {
                "Customer Utility per $ of revenue": 0.24,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2021: {
                "Customer Utility per $ of revenue": 0.24,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2022: {
                "Customer Utility per $ of revenue": 0.24,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
            },
          },
        },
        portfolioImpactPillarDrilldown: {
          2015: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.2, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.28, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2016: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.2, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.27, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2017: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.23, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.27, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2018: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.24, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.28, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2019: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.23, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.28, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2020: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.24, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.29, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2021: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.24, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.29, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2022: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.24, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.28, 0, 0],
              },
            ],
            /* drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ], */
          },
        },
      },
      {
        portfolioName: "Food: Major Diversified",
        transparencyScore: 1,
        portfolioImpactResults: {
          totalValue: {
            2015: 0.09000000000000005,
            2016: 0.09000000000000005,
            2017: 0.09000000000000005,
            2018: 0.09000000000000005,
            2019: 0.09000000000000005,
            2020: 0.07999999999999999,
            2021: 0.09,
            2022: 0.049999999999999996,
          },
          totalValueDrilldownByStakeholder: {
            customer: {
              2015: 0.28,
              2016: 0.27,
              2017: 0.27,
              2018: 0.28,
              2019: 0.28,
              2020: 0.29,
              2021: 0.29,
              2022: 0.28,
            },
            employee: {
              2015: 0.039999999999999994,
              2016: 0.05,
              2017: 0.05,
              2018: 0.04000000000000001,
              2019: 0.04000000000000001,
              2020: 0.039999999999999994,
              2021: 0.039999999999999994,
              2022: 0.04,
            },
            environment: {
              2015: -0.23,
              2016: -0.23,
              2017: -0.23,
              2018: -0.23,
              2019: -0.23,
              2020: -0.25,
              2021: -0.24000000000000002,
              2022: -0.27,
            },
          },
          stakeholderSpecificData: {
            leaderCompanies: {
              2015: {
                "Ichitan Group Public Co., Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Alicorp SAA": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "Nestle Nigeria Plc": 0.33,
                "Nestle S.A.": 0.28,
                "B&G Foods, Inc.": 0.2,
                "Kraft Heinz Company": 0.19,
                "Standard Foods Corporation": 0.19,
                "General Mills, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Campbell Soup Company": 0.17,
                "Olam Group Limited": 0.17,
                "Associated British Foods plc": 0.14,
              },
              2016: {
                "Yihai International Holding Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Alicorp SAA": 0.46,
                "Ichitan Group Public Co., Ltd.": 0.46,
                "Nestle Nigeria Plc": 0.33,
                "Nestle S.A.": 0.28,
                "B&G Foods, Inc.": 0.2,
                "Kraft Heinz Company": 0.19,
                "Standard Foods Corporation": 0.19,
                "General Mills, Inc.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Campbell Soup Company": 0.17,
                "Olam Group Limited": 0.17,
                "Associated British Foods plc": 0.14,
              },
              2017: {
                "Ichitan Group Public Co., Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Alicorp SAA": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "Nestle Nigeria Plc": 0.33,
                "Nestle S.A.": 0.28,
                "B&G Foods, Inc.": 0.23,
                "Simply Good Foods Co": 0.19,
                "Kraft Heinz Company": 0.19,
                "Standard Foods Corporation": 0.19,
                "General Mills, Inc.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Campbell Soup Company": 0.17,
                "Olam Group Limited": 0.17,
                "Associated British Foods plc": 0.14,
              },
              2018: {
                "Ichitan Group Public Co., Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
                "Nayuki Holdings Ltd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Alicorp SAA": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "Nestle Nigeria Plc": 0.33,
                "Nestle S.A.": 0.28,
                "B&G Foods, Inc.": 0.24,
                "Simply Good Foods Co": 0.19,
                "Kraft Heinz Company": 0.19,
                "Standard Foods Corporation": 0.19,
                "General Mills, Inc.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Campbell Soup Company": 0.17,
                "Olam Group Limited": 0.17,
                "Associated British Foods plc": 0.14,
              },
              2019: {
                "Ichitan Group Public Co., Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
                "Nayuki Holdings Ltd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Alicorp SAA": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "Nestle Nigeria Plc": 0.33,
                "Nestle S.A.": 0.28,
                "B&G Foods, Inc.": 0.23,
                "Simply Good Foods Co": 0.19,
                "Kraft Heinz Company": 0.19,
                "Standard Foods Corporation": 0.19,
                "General Mills, Inc.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Campbell Soup Company": 0.17,
                "Olam Group Limited": 0.17,
                "Associated British Foods plc": 0.14,
              },
              2020: {
                "Ichitan Group Public Co., Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
                "Nayuki Holdings Ltd.": 0.46,
                "Nestle (Malaysia) Bhd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Alicorp SAA": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "Nestle Nigeria Plc": 0.33,
                "Nestle S.A.": 0.28,
                "B&G Foods, Inc.": 0.24,
                "Simply Good Foods Co": 0.19,
                "Kraft Heinz Company": 0.19,
                "Standard Foods Corporation": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "General Mills, Inc.": 0.19,
                "Campbell Soup Company": 0.17,
                "Olam Group Limited": 0.17,
                "Associated British Foods plc": 0.14,
              },
              2021: {
                "Ichitan Group Public Co., Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
                "Nayuki Holdings Ltd.": 0.46,
                "Nestle (Malaysia) Bhd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Alicorp SAA": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "Nestle Nigeria Plc": 0.33,
                "Nestle S.A.": 0.28,
                "B&G Foods, Inc.": 0.24,
                "Simply Good Foods Co": 0.19,
                "Kraft Heinz Company": 0.19,
                "Standard Foods Corporation": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "General Mills, Inc.": 0.19,
                "Campbell Soup Company": 0.17,
                "Olam Group Limited": 0.17,
                "Associated British Foods plc": 0.14,
              },
              2022: {
                "Yihai International Holding Ltd.": 0.46,
                "Nestle (Malaysia) Bhd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
                "Nestle S.A.": 0.28,
                "B&G Foods, Inc.": 0.24,
                "General Mills, Inc.": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Kraft Heinz Company": 0.19,
                "Simply Good Foods Co": 0.19,
                "Campbell Soup Company": 0.17,
                "Olam Group Limited": 0.17,
                "Associated British Foods plc": 0.14,
              },
            },
            laggardCompanies: {
              2015: {
                "Associated British Foods plc": 0.14,
                "Olam Group Limited": 0.17,
                "Campbell Soup Company": 0.17,
                "Standard Foods Corporation": 0.19,
                "Kraft Heinz Company": 0.19,
                "General Mills, Inc.": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "B&G Foods, Inc.": 0.2,
                "Nestle S.A.": 0.28,
                "Nestle Nigeria Plc": 0.33,
                "Ichitan Group Public Co., Ltd.": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
                "Alicorp SAA": 0.46,
              },
              2016: {
                "Associated British Foods plc": 0.14,
                "Olam Group Limited": 0.17,
                "Campbell Soup Company": 0.17,
                "Standard Foods Corporation": 0.19,
                "Kraft Heinz Company": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "General Mills, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "B&G Foods, Inc.": 0.2,
                "Nestle S.A.": 0.28,
                "Nestle Nigeria Plc": 0.33,
                "Alicorp SAA": 0.46,
                "Ichitan Group Public Co., Ltd.": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
              },
              2017: {
                "Associated British Foods plc": 0.14,
                "Olam Group Limited": 0.17,
                "Campbell Soup Company": 0.17,
                "Standard Foods Corporation": 0.19,
                "Simply Good Foods Co": 0.19,
                "Kraft Heinz Company": 0.19,
                "General Mills, Inc.": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "B&G Foods, Inc.": 0.23,
                "Nestle S.A.": 0.28,
                "Nestle Nigeria Plc": 0.33,
                "Alicorp SAA": 0.46,
                "Ichitan Group Public Co., Ltd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
              },
              2018: {
                "Associated British Foods plc": 0.14,
                "Olam Group Limited": 0.17,
                "Campbell Soup Company": 0.17,
                "Standard Foods Corporation": 0.19,
                "Simply Good Foods Co": 0.19,
                "Kraft Heinz Company": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "General Mills, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "B&G Foods, Inc.": 0.24,
                "Nestle S.A.": 0.28,
                "Nestle Nigeria Plc": 0.33,
                "Alicorp SAA": 0.46,
                "Nayuki Holdings Ltd.": 0.46,
                "Ichitan Group Public Co., Ltd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
              },
              2019: {
                "Associated British Foods plc": 0.14,
                "Olam Group Limited": 0.17,
                "Campbell Soup Company": 0.17,
                "Standard Foods Corporation": 0.19,
                "Simply Good Foods Co": 0.19,
                "Kraft Heinz Company": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "General Mills, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "B&G Foods, Inc.": 0.23,
                "Nestle S.A.": 0.28,
                "Nestle Nigeria Plc": 0.33,
                "Alicorp SAA": 0.46,
                "Nayuki Holdings Ltd.": 0.46,
                "Ichitan Group Public Co., Ltd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
              },
              2020: {
                "Associated British Foods plc": 0.14,
                "Olam Group Limited": 0.17,
                "Campbell Soup Company": 0.17,
                "Standard Foods Corporation": 0.19,
                "Simply Good Foods Co": 0.19,
                "Kraft Heinz Company": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "General Mills, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "B&G Foods, Inc.": 0.24,
                "Nestle S.A.": 0.28,
                "Nestle Nigeria Plc": 0.33,
                "Nestle (Malaysia) Bhd.": 0.46,
                "Nayuki Holdings Ltd.": 0.46,
                "Ichitan Group Public Co., Ltd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
                "Alicorp SAA": 0.46,
              },
              2021: {
                "Associated British Foods plc": 0.14,
                "Olam Group Limited": 0.17,
                "Campbell Soup Company": 0.17,
                "Standard Foods Corporation": 0.19,
                "Simply Good Foods Co": 0.19,
                "Kraft Heinz Company": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Beyond Meat, Inc.": 0.19,
                "General Mills, Inc.": 0.19,
                "Dongsuh Companies, Inc.": 0.19,
                "Ausnutria Dairy Corp. Ltd.": 0.19,
                "B&G Foods, Inc.": 0.24,
                "Nestle S.A.": 0.28,
                "Nestle Nigeria Plc": 0.33,
                "Nestle (Malaysia) Bhd.": 0.46,
                "Nayuki Holdings Ltd.": 0.46,
                "Ichitan Group Public Co., Ltd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Yihai International Holding Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
                "Alicorp SAA": 0.46,
              },
              2022: {
                "Associated British Foods plc": 0.14,
                "Campbell Soup Company": 0.17,
                "Olam Group Limited": 0.17,
                "General Mills, Inc.": 0.19,
                "Hain Celestial Group, Inc.": 0.19,
                "Kraft Heinz Company": 0.19,
                "Simply Good Foods Co": 0.19,
                "B&G Foods, Inc.": 0.24,
                "Nestle S.A.": 0.28,
                "Yihai International Holding Ltd.": 0.46,
                "Nestle (Malaysia) Bhd.": 0.46,
                "RFG Holdings Ltd.": 0.46,
                "Tiger Brands Limited": 0.46,
              },
            },
            pillarLevelImpactValues: {
              2015: {
                "Customer Utility per $ of revenue": 0.28,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2016: {
                "Customer Utility per $ of revenue": 0.27,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2017: {
                "Customer Utility per $ of revenue": 0.27,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2018: {
                "Customer Utility per $ of revenue": 0.28,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2019: {
                "Customer Utility per $ of revenue": 0.28,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2020: {
                "Customer Utility per $ of revenue": 0.29,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2021: {
                "Customer Utility per $ of revenue": 0.29,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
              2022: {
                "Customer Utility per $ of revenue": 0.28,
                "Data Breaches per $ of revenue": 0,
                "Recalls per $ of revenue": 0,
              },
            },
          },
        },
        portfolioImpactPillarDrilldown: {
          2015: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.2, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.28, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2016: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.2, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.27, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2017: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.23, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.27, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2018: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.24, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.28, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2019: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.23, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.28, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2020: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.24, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.29, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2021: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.24, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.29, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
          2022: {
            categories: [
              "Customer Utility per $ of revenue",
              "Data Breaches per $ of revenue",
              "Recalls per $ of revenue",
            ],
            data: [
              {
                Name: "B&G Foods, Inc.",
                data: [0.24, 0, 0],
              },
              {
                Name: "Food: Major Diversified",
                data: [0.28, 0, 0],
              },
            ],
            drilldown: [
              null,
              null,
              {
                categories: [
                  "FDA Medical Device Recalls per $ of revenue",
                  "Appliance Recalls per $ of revenue",
                  "Machinery Recalls per $ of revenue",
                ],
                data: [
                  {
                    Name: "B&G Foods, Inc.",
                    data: [0, 0, 0],
                  },
                  {
                    Name: "Food: Major Diversified",
                    data: [0, 0, 0],
                  },
                ],
                drilldown: [null, null, null],
              },
            ],
          },
        },
      },
    ],
  };
}
