import { createSlice } from '@reduxjs/toolkit';

const trendingSlice = createSlice({
  name: 'trending',
  initialState: {
    items: [],
    isLoading: false,
    showGuide: false,
    guideStep: 1
  },
  reducers: {
    setItems(state, action) {
      state.items = action.payload;
      state.isLoading = false;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setShowGuide(state, action) {
      state.showGuide = action.payload;
      // save preference to local storage
      localStorage.setItem('disableTrendingGuide', !action.payload);
    },
    setGuideStep(state, action) {
      state.guideStep = action.payload;
    }
  },
});

export const trendingActions = trendingSlice.actions;

export default trendingSlice;