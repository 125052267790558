import {
  STEP1_REGIONS,
  STEP1_COUNTRIES,
  STEP1_SECTORS,
  STEP1_INDUSTRIES,
  STEP2_TEMP,
  STEPEXCL_MARKETCAP,
  STEPEXCLUSION_CUSTOM,
  STEP1_EXCLUSIONS_BUSINESS_INVOLVMENTS,
  STEPTHEME_CUSTOM,
  STEP4_TEMP,
  STEPSLIDER_TEMP,
  STEPSDG,
  STEP_REVIEW,
} from "./FormPredefValues";

export const stepperData = [
  {
    stepNumber: "01",
    title: "Theme Selection",
    subSteps: [
      {
        title: "Themes",
        subSteps: [
          {
            title: "",
            form: [STEPTHEME_CUSTOM, STEP4_TEMP],
          },
        ],
      },
    ],
  },
  {
    stepNumber: "02",
    title: "Impacts",
    subSteps: [
      {
        title: "Impacts",
        subSteps: [
          { title: "EU Taxonomy", form: [STEPSLIDER_TEMP] },
          { title: "SDG", form: [STEPSDG] },
        ],
      },
    ],
  },
  {
    stepNumber: "03",
    title: "Create Universe",
    subSteps: [
      {
        title: "Geography",
        subSteps: [
          {
            title: "Regions",
            form: [STEP1_REGIONS],
          },
          { title: "Countries", form: [STEP1_COUNTRIES] },
        ],
      },
      {
        title: "Sector",
        subSteps: [
          { title: "Sector", form: [STEP1_SECTORS] },
          { title: "Industry", form: [STEP1_INDUSTRIES] },
          { title: "Company", form: [STEP2_TEMP] },
        ],
      },
      {
        title: "Market cap",
        subSteps: [
          {
            title: "",
            form: [STEPEXCL_MARKETCAP],
          },
        ],
      },
      {
        title: "Exclusions",
        subSteps: [
          {
            title: "",
            form: [STEPEXCLUSION_CUSTOM, STEP1_EXCLUSIONS_BUSINESS_INVOLVMENTS],
          },
        ],
      },
    ],
  },
  {
    stepNumber: "04",
    title: "Review",
    subSteps: [
      { title: "Review filters", subSteps: [{ form: [STEP_REVIEW] }] },
    ],
  },
  {
    stepNumber: "05",
    title: "Results",
    subSteps: [],
  },
];
