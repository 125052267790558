import React, { useState } from "react";
import WrapperCard from "../../components/ui/redesign/WrapperCard";
import ColumnChart from "../../components/redesign/charts/ColumnChart";
import HistoryLineChart from "../../components/redesign/charts/HistoryLineChart";
import ImpactStakeHoldersAndHistory from "../../components/redesign/portfolio_impact/ImpactStakeHolderAndHistory";
import LeadersLaggards from "../environmental_impact/LeadersLaggards";
import RawDataSection from "../environmental_impact/RawDataSection";
import DrillDownChart from "../../components/redesign/charts/DrillDownColumnChart";
import { useDataContext } from "../../context/PortfolioContext";
import { AllRoutes } from "../Routes";
import { API_ENUMS } from "../../api/api";
import { useHistory } from "react-router-dom";
import NoDataForYear from "../../components/redesign/NoDataForYear";

const EnvironmentalImpact = () => {
  const { response, year, companyList, currentType } = useDataContext();
  const [selected, setSelected] = useState(1);

  const history = useHistory();
  if (
    !response ||
    currentType != history.location.pathname ||
    companyList.length != 2
  )
    return <></>;

  if (response.currentYear != year) {
    return <NoDataForYear />;
  }

  const portfolio1 = companyList?.[0]?.name;
  const portfolio2 = companyList?.[1]?.name;

  const historyYears = Object.keys(
    response?.portfolioComparison?.[0].portfolioImpactResults.totalValue
  );
  const historyTotalValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults.totalValue
  );
  const historyTotalValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults.totalValue
  );
  const historyEnvironmentValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.environment
  );
  const historyEnvironmentValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.environment
  );
  const historyEmployeeValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.employee
  );
  const historyEmployeeValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.employee
  );
  const historyCustomerValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.customer
  );
  const historyCustomerValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.customer
  );

  const historyAllData = [
    [historyTotalValue1, historyTotalValue2],
    [historyEnvironmentValue1, historyEnvironmentValue2],
    [historyEmployeeValue1, historyEmployeeValue2],
    [historyCustomerValue1, historyCustomerValue2],
  ];

  const envAllCategory = Object.keys(
    response?.portfolioComparison?.[0]?.portfolioImpactResults
      .stakeholderSpecificData.pillarLevelImpactValues[year]
  );
  const envCategoryData1 = Object.values(
    response?.portfolioComparison?.[0]?.portfolioImpactResults
      .stakeholderSpecificData.pillarLevelImpactValues[year]
  );
  const envCategoryData2 = Object.values(
    response?.portfolioComparison?.[1]?.portfolioImpactResults
      .stakeholderSpecificData.pillarLevelImpactValues[year]
  );

  const envAllDrill =
    response?.portfolioComparison?.[0]?.portfolioImpactPillarDrilldown[year];

  const laggards1 = Object.entries(
    response?.portfolioComparison?.[0]?.portfolioImpactResults
      .stakeholderSpecificData.laggardCompanies[year]
  ).map(([name, value]) => ({ name, value }));
  const leaders1 = Object.entries(
    response?.portfolioComparison?.[0]?.portfolioImpactResults
      .stakeholderSpecificData.leaderCompanies[year]
  ).map(([name, value]) => ({ name, value }));
  const laggards2 = Object.entries(
    response?.portfolioComparison?.[1]?.portfolioImpactResults
      .stakeholderSpecificData.laggardCompanies[year]
  ).map(([name, value]) => ({ name, value }));
  const leaders2 = Object.entries(
    response?.portfolioComparison?.[1]?.portfolioImpactResults
      .stakeholderSpecificData.leaderCompanies[year]
  ).map(([name, value]) => ({ name, value }));

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="col-span-1">
        <ImpactStakeHoldersAndHistory title={"Impacts by Stakeholders"} />
        <WrapperCard wideBorder={true}>
          <DrillDownChart
            categories={envAllCategory}
            data1={envCategoryData1}
            data2={envCategoryData2}
            allData={envAllDrill}
            portfolio1={portfolio1}
            portfolio2={portfolio2}
          />
        </WrapperCard>
      </div>
      <div className="col-span-1">
        <ImpactStakeHoldersAndHistory title={"Impacts History"} />
        <WrapperCard wideBorder={true}>
          <HistoryLineChart
            historyYears={historyYears}
            data={historyAllData[selected]}
            selected={selected}
            setSelected={setSelected}
            portfolio1={portfolio1}
            portfolio2={portfolio2}
            defaultSelectedCategory={1}
          />
        </WrapperCard>
      </div>

      <WrapperCard wideBorder={true}>
        <LeadersLaggards
          title={"Leaders in Environmental Impact"}
          isPositive={true}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
          data1={leaders1}
          data2={leaders2}
        />
      </WrapperCard>
      <WrapperCard wideBorder={true}>
        <LeadersLaggards
          title={"Laggards in Environmental Impact"}
          isPositive={false}
          portfolio1={portfolio1}
          portfolio2={portfolio2}
          data1={laggards1}
          data2={laggards2}
        />
      </WrapperCard>
      <div className="col-span-1 md:col-span-2">
        <WrapperCard wideBorder={true}>
          <RawDataSection
            title={"Underlying Raw data"}
            compare1={"ESG Portfolio"}
            compare2={"Benchmark portfolio"}
          />
        </WrapperCard>
      </div>
    </div>
  );
};

export default EnvironmentalImpact;
