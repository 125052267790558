import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PortfolioImpact from "../../PortfolioImpact";
import CompanyImpactDialog from "../../company_impact/CompanyImpactDialog";
import PrimaryButton from "../../../components/ui/redesign/PrimaryButton";

function CompareTableCells({ text }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([
    "S&P 500",
    "DJIA",
  ]); // Example IDs
  const [initialTab, setInitialTab] = useState("/portfolio_overview");

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <PrimaryButton onClick={openModal} text={text} />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed w-full inset-0 z-[100] overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-6xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="mt-0">
                  <CompanyImpactDialog
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    company="Apple Inc."
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default CompareTableCells;
