import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CustomEvents from "highcharts-custom-events";
//import borderRadius from "highcharts-border-radius";
import { useEffect, useState } from "react";
import ChartLegend from "../../ui/redesign/ChartLegend";
import { formatNumber } from "../../../services/utilities";

CustomEvents(Highcharts);

//borderRadius(Highcharts);

function SimpleColumnChart({
  id,
  title,
  yTitle,
  data,
  portfolio1,
  color,
  toolTipCustomFormatter,
  currencyType,
  narrow,
  height,
  showXLabel,
}) {
  Highcharts.AST.allowedAttributes.push("data-container");

  const options = {
    chart: {
      styledMode: false,
      type: "column",
      height: height || 350,
    },
    title: {
      text: "",
    },
    yAxis: {
      title: {
        text: "",
      },
      plotLines: [
        {
          color: "#FF0000",
          width: 1,
          value: 0,
          zIndex: 2,
          dashStyle: "ShortDash",
        },
      ],
      labels: {
        style: {
          color: "#D1D5DB;",
        },
        formatter: function () {
          // Use a custom formatter function to style the label for the 0 level differently
          if (this.value === 0) {
            return (
              '<span style="font-size: 14px; line-height: 20px;color: #9CA3AF;">' +
              this.value +
              "</span>"
            );
          } else {
            return (
              '<span style="font-size: 14px; line-height: 20px;color: #D1D5DB;">' +
              formatNumber(this.value) +
              "</span>"
            );
          }
        },
      },
      gridLineColor: "#E5E7EB",
    },
    xAxis: {
      gridLineWidth: 0,
      gridLineColor: "#00000000",
      lineColor: "#E5E7EB",
      text: "",
      labels: {
        enabled: showXLabel || false,
      },
      categories: data.categories,
    },
    plotOptions: {
      column: {
        pointWidth: narrow ? 10 : 26,
        customRadius: 31, // Set a default value for the border radius
        pointPadding: 0.005,
        grouping: true,
        borderWidth: 0,
        point: {
          events: {
            mouseOver: function (e) {
              var targetX = e.target.x,
                from = targetX - 0.5,
                to = targetX + 0.5;

              this.series.chart.xAxis[0].addPlotBand({
                id: "plot-band-1",
                from: from,
                to: to,
                zIndex: 1,
                color: {
                  linearGradient: {
                    x1: 0,
                    x2: 1,
                    y1: 0,
                    y2: 1,
                  },
                  stops: [
                    [0, "rgb(235, 242, 255)"],
                    [1, "rgb(235, 242, 255)"],
                  ],
                },
              });
            },
            mouseOut: function (e) {
              this.series.chart.xAxis[0].removePlotBand("plot-band-1");
            },
          },
        },
      },
      /*  series: {
              borderRadiusTopLeft: 8,
              borderRadiusTopRight: 8,
            }, */
    },
    legend: { enabled: false },
    tooltip: {
      shared: true,
      useHTML: true,
      borderWidth: 0,
      padding: 8,
      shadow: false,
      backgroundColor: "transparent",

      formatter: function () {
        const primary = this.points[0];
        const index = this.points[0].point.index;

        return toolTipCustomFormatter != null
          ? toolTipCustomFormatter(index)
          : `<div class="bg-white p-4 flex flex-col shadow-md rounded-lg">
          <div class="w-fit flex gap-x-2 mb-4 items-center">
            <div class="leading-tight text-center text-xs font-medium  text-gray-600">
              ${primary.x}
            </div>
          </div>
          <div class="flex items-center gap-x-1 w-fit">
            <div style="background-color: ${
              primary.color
            }" class="w-5 h-1.5  rounded-full" ></div>
            <div class="text-xs leading-none text-gray-600">
              ${primary.series.name}:
            </div>
            <div class="text-gray-600 text-xs font-semibold"> ${
              (currencyType || "$") + " " + formatNumber(primary.y, 2)
            }</div>
          </div>
         
        </div>`;
      },
    },
    series: [
      {
        name: portfolio1,
        data: data?.data[0],
        color: color,
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ id: id }}
      />
    </div>
  );
}

export default SimpleColumnChart;
