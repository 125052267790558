import React, { useEffect, useState } from "react";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import PortfolioImpact from "../PortfolioImpact";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import WrapperCard from "../../components/ui/redesign/WrapperCard";
import PortfolioCard, {
  PortfolioCardType,
} from "../../components/redesign/portfolio_impact/PortfolioCard";
import HistoryLineChart from "../../components/redesign/charts/HistoryLineChart";
import LoadingErrorWrapper from "../../api/LoadingErrorWrapper";
import LeadersLaggards from "../environmental_impact/LeadersLaggards";
import ImpactTableCompare from "../portfolio_impact/ImpactTableCompare";
import SimplePieChart from "../../components/redesign/charts/SimplePieChart";
import ThemeTable from "../../components/redesign/psics/ThemeTable";
import { applyFont } from "../../services/font-report";
import { useDataContext } from "../../context/PortfolioContext";
import PrimaryButton from "../../components/ui/redesign/PrimaryButton";
import SimplePieChartReport from "../../components/redesign/charts/SimplePieChartReport";
import PsicsTabGeographics from "../../components/redesign/psics/PsicsTabGeographic";
import CategoryOtherAreaChart from "../../components/redesign/charts/CategoryOtherAreaChart";
import { promethosMock } from "../../mock/promethos";

const addHeaderFooter = (pdf, pageNumber, portfolioName) => {
  //get the current base url
  const baseUrl = window.location.origin;
  const logo = `${baseUrl}/img/report/rgs_new.png`;

  pdf.addImage(logo, "png", 10, 10, 100, 50); // Adjust dimensions as needed

  // Center: Bold Text
  pdf.setFont("helvetica", "bold"); // Set font to bold
  pdf.setFontSize(16); // Set a larger font size
  const headerCenterX = pdf.internal.pageSize.getWidth() / 2;
  const firstLineY = 30; // Y position of the first line
  pdf.text(portfolioName, headerCenterX, firstLineY, { align: "center" });

  // Adding the second line of the header: "Portfolio Impact Report"
  const secondLineY = firstLineY + 20; // Y position of the second line, adjust spacing as needed
  pdf.text("Impact Report", headerCenterX, secondLineY, {
    align: "center",
  });

  // Right Corner: Current Date
  pdf.setFont("helvetica", "normal"); // Switch back to normal font for other text
  pdf.setFontSize(10); // Reset to a smaller font size for the date

  // Right Corner: Current Date
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(10);
  const dateStr = new Date().toLocaleDateString();
  pdf.text(dateStr, pdf.internal.pageSize.getWidth() - 50, 30, {
    align: "right",
  }); // Adjust positioning as needed

  pdf.setLineWidth(3); // Adjust thickness as needed

  // Set draw color for the divider (optional)
  pdf.setDrawColor(0, 0, 0); // RGB for black, adjust as needed

  // Draw the line spanning the full width of the page
  // Start from the left edge to the right edge of the page
  pdf.line(0, 70, pdf.internal.pageSize.getWidth(), 70);

  // Reset line width back to default if you're drawing other lines later
  pdf.setLineWidth(1);

  const footerStartY = pdf.internal.pageSize.getHeight() - 70; // 70 units tall footer

  // Set draw color for the footer divider (optional)
  pdf.setDrawColor(0, 0, 0); // RGB for black, adjust as needed

  // Draw the footer divider line spanning the full width of the page
  pdf.line(0, footerStartY, pdf.internal.pageSize.getWidth(), footerStartY);

  // Reset line width back to default if you're drawing other lines later
  pdf.setLineWidth(1);

  // Footer
  // Left Corner: Page Number
  pdf.text(`Page ${pageNumber}`, 10, pdf.internal.pageSize.getHeight() - 20); // Adjust positioning as needed

  // Right Corner: Logo Image
  // Right Corner: Logo Image
  const logoWidth = 100;
  const logoHeight = 45;
  const logoXPosition = pdf.internal.pageSize.getWidth() - logoWidth - 10; // Adjust for right margin
  const logoYPosition = pdf.internal.pageSize.getHeight() - logoHeight - 20 + 5; // Positioned above the bottom margin

  pdf.addImage(
    logo,
    "png",
    logoXPosition,
    logoYPosition,
    logoWidth,
    logoHeight
  );

  // Adding Company Name as Text next to the Logo, but on the left side
  const companyName = "Richmond Global Sciences"; // Update with your actual company name
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(10);
  const textWidth =
    (pdf.getStringUnitWidth(companyName) * pdf.internal.getFontSize()) /
    pdf.internal.scaleFactor;
  const textXPosition = logoXPosition - textWidth - 15; // Position text left of the logo with some spacing
  const textYPosition = logoYPosition + logoHeight / 2 + 5; // Vertically center text with the logo, adjust based on font size

  pdf.text(companyName, textXPosition, textYPosition);

  // Adding a clickable link over the company name text
  pdf.link(textXPosition, textYPosition - 10, textWidth, 10, {
    url: "https://esg.rgsciences.com/",
  });
};

const Reporting = ({ data, nextStep, currentStep }) => {
  const { year, isFullWidth, setIsFullWidth } = useDataContext();
  let response = data.overview["details"];

  const portfolio1 = response?.[0]?.portfolioName;
  const portfolio2 = response?.[1]?.portfolioName;

  const exportPDFWithMultiplePages = async (portfolioName) => {
    // nextStep();
    //window scroll to the top
    setIsFullWidth(true); // TODO IF as needed
    const element = document.getElementById("scrolltop");
    element.scrollIntoView();

    setloading(true);
    //sleep 2 seconds
    await new Promise((r) => setTimeout(r, 2000));
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      compress: true, // Enable PDF compression
    });
    applyFont(pdf);

    const marginLeft = 20; // Adjust as needed
    const marginRight = 20; // Adjust as needed
    const pageWidth = pdf.internal.pageSize.getWidth();
    const contentWidth = pageWidth - marginLeft - marginRight;
    // Example: Assume you have 4 segments/pages to capture and add
    for (let i = 1; i < 7; i++) {
      // Adjust your content or viewport here to capture the current segment
      // For example, you might scroll to the next segment or adjust visibility

      let element = document.getElementById(`reporting-${i}`);
      //element.style.width = "2000px";
      //element.style.height = "2008px";
      const canvas = await html2canvas(element, {
        scale: 2, // or higher depending on desired quality
      }); // Replace this with your actual logic to capture the current segment
      const imgData = canvas.toDataURL("image/png");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfRealHeight = pdf.internal.pageSize.getHeight();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      if (i > 1) {
        pdf.addPage();
      }

      /*     pdf.addImage(
        canvas.toDataURL("image/jpeg", 1),
        "JPEG",
        10,
        10,
        pdfWidth,
        pdfRealHeight
      ); */

      pdf.addImage(imgData, "PNG", marginLeft, 90, contentWidth, pdfHeight); // Adjust size and position as needed

      // Add custom header and footer
      addHeaderFooter(pdf, i + 1, portfolioName); // Passing the current page number
    }

    pdf.save("financial_report.pdf");
    setIsFullWidth(false);
    setloading(false);

    nextStep();
  };

  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (currentStep == 2 && !loading) {
      exportPDFWithMultiplePages(portfolio1);
    }
  }, [currentStep, loading]);

  if (!data) return <LoadingErrorWrapper isLoading={true} error={null} />;
  const companyList = data.overview["details"].map((c) => ({
    name: c.portfolioName,
    percentage: c.benchmarkCoverage * 100,
    value: c.totalValue[year],
  }));

  const historyYears = Object.keys(response?.[0].totalValue);
  const historyTotalValue1 = Object.values(response?.[0].totalValue);
  const historyTotalValue2 = Object.values(response?.[1].totalValue);
  const historyEnvironmentValue1 = Object.values(
    response?.[0].totalValueDrilldownByStakeholder?.environment
  );
  const historyEnvironmentValue2 = Object.values(
    response?.[1].totalValueDrilldownByStakeholder?.environment
  );
  const historyEmployeeValue1 = Object.values(
    response?.[0].totalValueDrilldownByStakeholder?.employee
  );
  const historyEmployeeValue2 = Object.values(
    response?.[1].totalValueDrilldownByStakeholder?.employee
  );
  const historyCustomerValue1 = Object.values(
    response?.[0].totalValueDrilldownByStakeholder?.customer
  );
  const historyCustomerValue2 = Object.values(
    response?.[1].totalValueDrilldownByStakeholder?.customer
  );

  const historyAllData = [
    [historyTotalValue1, historyTotalValue2],
    [historyEnvironmentValue1, historyEnvironmentValue2],
    [historyEmployeeValue1, historyEmployeeValue2],
    [historyCustomerValue1, historyCustomerValue2],
  ];

  //ENVIRONMENTAL IMPACT
  response = data.environment;

  const handleImpactData = (data, type) => {
    return data.portfolioComparison.map((c) => ({
      name: c.portfolioName,
      percentage: c.benchmarkCoverage * 100,
      value:
        c.portfolioImpactResults.totalValueDrilldownByStakeholder[type][year],
    }));
  };

  const environmentCompany = handleImpactData(data.environment, "environment");

  const environmentYears = Object.keys(
    response?.portfolioComparison?.[0].portfolioImpactResults.totalValue
  );
  const ehistoryTotalValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults.totalValue
  );
  const ehistoryTotalValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults.totalValue
  );
  const ehistoryEnvironmentValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.environment
  );
  const ehistoryEnvironmentValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.environment
  );
  const ehistoryEmployeeValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.employee
  );
  const ehistoryEmployeeValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.employee
  );
  const ehistoryCustomerValue1 = Object.values(
    response?.portfolioComparison?.[0].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.customer
  );
  const ehistoryCustomerValue2 = Object.values(
    response?.portfolioComparison?.[1].portfolioImpactResults
      .totalValueDrilldownByStakeholder?.customer
  );

  const environmentHistoryData = [
    [ehistoryEnvironmentValue1, ehistoryEnvironmentValue2],
    [ehistoryEmployeeValue1, ehistoryEmployeeValue2],
    [ehistoryCustomerValue1, ehistoryCustomerValue2],
  ];

  //EMPLOYEE IMPACT
  response = data.employee;

  const employeeCompany = handleImpactData(data.employee, "employee");

  const employeeHistoryYears = Object.keys(
    response?.portfolioComparison?.[0].portfolioImpactResults.totalValue
  );

  const employeeHistoryData = [
    [ehistoryEmployeeValue1, ehistoryEmployeeValue2],
  ];

  //CUSTOMER IMPACT
  response = data.customer;

  const customerCompany = handleImpactData(data.customer, "customer");
  const customerYears = Object.keys(
    response?.portfolioComparison?.[0].portfolioImpactResults.totalValue
  );

  const customerHistoryData = [
    [ehistoryCustomerValue1, ehistoryCustomerValue2],
  ];

  const handleLeadersLaggards = (response) => {
    let tempYear = year;
    const laggards1 = Object.entries(
      response?.portfolioComparison?.[0]?.portfolioImpactResults
        .stakeholderSpecificData.laggardCompanies[tempYear]
    ).map(([name, value]) => ({ name, value }));
    const leaders1 = Object.entries(
      response?.portfolioComparison?.[0]?.portfolioImpactResults
        .stakeholderSpecificData.leaderCompanies[tempYear]
    ).map(([name, value]) => ({ name, value }));
    const laggards2 = Object.entries(
      response?.portfolioComparison?.[1]?.portfolioImpactResults
        .stakeholderSpecificData.laggardCompanies[tempYear]
    ).map(([name, value]) => ({ name, value }));
    const leaders2 = Object.entries(
      response?.portfolioComparison?.[1]?.portfolioImpactResults
        .stakeholderSpecificData.leaderCompanies[tempYear]
    ).map(([name, value]) => ({ name, value }));

    return {
      leaders1,
      laggards1,
      leaders2,
      laggards2,
    };
  };

  const {
    leaders1: environmentLeaders1,
    laggards1: environmentLaggards1,
    leaders2: environmentLeaders2,
    laggards2: environmentLaggards2,
  } = handleLeadersLaggards(data.environment);

  //leaders laggards,

  const {
    leaders1: employeeLeaders1,
    laggards1: employeeLaggards1,
    leaders2: employeeLeaders2,
    laggards2: employeeLaggards2,
  } = handleLeadersLaggards(data.employee);

  const {
    leaders1: customerLeaders1,
    laggards1: customerLaggards1,
    leaders2: customerLeaders2,
    laggards2: customerLaggards2,
  } = handleLeadersLaggards(data.customer);

  const calculatePercentageDifference = (value1, value2) => {
    if (value2 === 0) {
      return NaN;
    }
    // Correctly handle negative values to show decrease as negative percentage
    const difference = value1 - value2;
    const percentageDifference = (difference / Math.abs(value2)) * 100;
    return percentageDifference;
  };

  const data1 =
    data.overview.details?.[0].totalValueDrilldownByImpactPillar[year];
  const data2 =
    data.overview.details?.[1].totalValueDrilldownByImpactPillar[year];

  // Prepare table data
  const tableData = Object.keys(data1)
    .map((key) => {
      if (data2.hasOwnProperty(key)) {
        // Only consider common keys
        const value1 = data1[key];
        const value2 = data2[key];
        const percentageDifference = calculatePercentageDifference(
          value1,
          value2
        );
        return {
          name: key,
          value1: value1.toFixed(2),
          value2: value2.toFixed(2),
          percentageDifference: percentageDifference.toFixed(2),
        };
      }
      return null;
    })
    .filter(Boolean);

  response = data.psics.details;
  const numberOf = 10;

  const benchMarkByType = "benchmarkRevenueCategoriesWithOtherCategories";

  const portfolio1Categories1 = response?.[0].psics.benchmarkRevenueCategories[
    year
  ]
    .slice(0, numberOf)
    .map((item) => item.productCategoryName);
  const portfolio2Categories2 = response?.[1].psics.benchmarkRevenueCategories[
    year
  ]
    .slice(0, numberOf)
    .map((item) => item.productCategoryName);
  const portfolio1Categories1Pie = response?.[0].psics[benchMarkByType][
    year
  ].map((item) => item.productCategoryName);
  const portfolio2Categories2Pie = response?.[1].psics[benchMarkByType][
    year
  ].map((item) => item.productCategoryName);

  const portfolio1Data1 = response?.[0].psics.benchmarkRevenueCategories[year]
    .slice(0, numberOf)
    .map((item) => item.productCategoryRevenue);
  const portfolio2Data2 = response?.[1].psics.benchmarkRevenueCategories[year]
    .slice(0, numberOf)
    .map((item) => item.productCategoryRevenue);
  const portfolio1Data1Pie = response?.[0].psics[benchMarkByType][year].map(
    (item) => item.productCategoryRevenue
  );
  const portfolio2Data2Pie = response?.[1].psics[benchMarkByType][year].map(
    (item) => item.productCategoryRevenue
  );

  const chartData1Pie = {
    data: [portfolio1Data1Pie],
    categories: portfolio1Categories1Pie,
  };
  const chartData2Pie = {
    data: [portfolio2Data2Pie],
    categories: portfolio2Categories2Pie,
  };
  const pieChartData1 = chartData1Pie.data[0].map((value, index) => ({
    name: chartData1Pie.categories[index],
    y: value,
    // color: topColors[index],
  }));

  const pieChartData2 = chartData2Pie.data[0].map((value, index) => ({
    name: chartData2Pie.categories[index],
    y: value,
    // color: topColors[index],
  }));

  const impactTheme1 = response?.[0].psics.impactThemeCoverages;
  const impactTheme2 = response?.[1].psics.impactThemeCoverages;

  const impactTheme1Table = Object.keys(impactTheme1?.[year])
    .map((key) => {
      return {
        name: key,
        value: impactTheme1?.[year][key] * 100,
      };
    })
    .filter(
      (item) =>
        item.name !== "AI" &&
        item.name !== "AI Opportunity" &&
        item.name !== "Artificial Intelligence/Machine Learning (old)" &&
        item.name !== "AI Value Chain"
    );

  const impactTheme2Table = Object.keys(impactTheme2?.[year])
    .map((key) => {
      return {
        name: key,
        value: impactTheme2?.[year][key] * 100,
      };
    })
    .filter((item) => item.name !== "AI" && item.name !== "AI Opportunity");

  // GEO data

  const chartData1 = Object.keys(
    data?.psics?.overview?.geographicalDrilldownByCountries?.[year]?.[0]
  );

  const chartTitle1 = Object.values(
    data?.psics?.overview?.geographicalDrilldownByCountries?.[year]?.[0]
  ).map((p) => p * 100);

  const categoryChartData1 = chartData1.map((title, index) => ({
    name: title,
    data: [chartTitle1[index]],
  }));

  const geoPieChartData1 = categoryChartData1.map((item) => ({
    name: item.name,
    y: item.data?.[0], // Assuming each item only has one data point
  }));

  const chartData2 = Object.keys(
    data?.psics?.overview?.geographicalDrilldownByCountries?.[year]?.[1]
  );

  const chartTitle2 = Object.values(
    data?.psics?.overview?.geographicalDrilldownByCountries?.[year]?.[1]
  ).map((p) => p * 100);

  const categoryChartData2 = chartData2.map((title, index) => ({
    name: title,
    data: [chartTitle2[index]],
  }));

  const geoPieChartData2 = categoryChartData2.map((item) => ({
    name: item.name,
    y: item.data?.[0], // Assuming each item only has one data point
  }));

  // Area chart

  const rawData1 = response?.[0].psics.benchmarkRevenueCategories;
  const rawData2 = response?.[1].psics.benchmarkRevenueCategories;

  const topCategories1 = rawData1[year]
    .sort((a, b) => b.productCategoryRevenue - a.productCategoryRevenue)
    .slice(0, 3)
    .map((category) => category.productCategoryName);

  const transformedData1 = Object.keys(rawData1).reduce((acc, year) => {
    // Initialize revenue accumulator for top categories and others
    const yearData = { OTHER: 0 };
    topCategories1.forEach((category) => (yearData[category] = 0));

    rawData1[year].forEach(
      ({ productCategoryName, productCategoryRevenue }) => {
        if (topCategories1.includes(productCategoryName)) {
          yearData[productCategoryName] += productCategoryRevenue;
        } else {
          yearData["OTHER"] += productCategoryRevenue;
        }
      }
    );

    acc[year] = yearData;
    return acc;
  }, {});
  const topCategories2 = rawData2[year]
    .sort((a, b) => b.productCategoryRevenue - a.productCategoryRevenue)
    .slice(0, 3)
    .map((category) => category.productCategoryName);

  const transformedData2 = Object.keys(rawData2).reduce((acc, year) => {
    // Initialize revenue accumulator for top categories and others
    const yearData = { OTHER: 0 };
    topCategories2.forEach((category) => (yearData[category] = 0));

    rawData2[year].forEach(
      ({ productCategoryName, productCategoryRevenue }) => {
        if (topCategories2.includes(productCategoryName)) {
          yearData[productCategoryName] += productCategoryRevenue;
        } else {
          yearData["OTHER"] += productCategoryRevenue;
        }
      }
    );

    acc[year] = yearData;
    return acc;
  }, {});

  const promethos = promethosMock(portfolio1 + "_" + portfolio2);

  return (
    <div className="w-full relative mb-16">
      <div className="flex flex-col w-full items-start">
        {/*  {
          <button onClick={() => generatePDF(getTargetElement, options)}>
            Generate PDF
          </button>
        } */}
        {/*      <button className="sticky top-0 z-50 w-full bg-white">
          <PrimaryButton
            className={"m-4"}
            full={true}
            onClick={exportPDFWithMultiplePages}
            text={"DOWNLOAD REPORT AS PDF"}
          />
        </button> */}
        <div id="scrolltop" className="w-full h-1" />
        <div key={isFullWidth} id="reporting-id" className="w-full p-4">
          <p className="text-2xl font-semibold text-gray-500 text-center my-4">
            Preview of the report
          </p>
          <div className="flex flex-col gap-y-12">
            <div id="reporting-1" className="bg-gray-50 px-3 pb-2">
              <div className="h-8 px-2 mb-1 bg-my-rift-primary-green-500 text-white font-bold text-left w-full flex items-center">
                Investment Strategy
              </div>
              <div className="text-base text-gray-600 mb-4">
                {promethos.strategy}
              </div>
              <div className="h-8 px-2 mb-1 bg-my-rift-primary-green-500 text-white font-bold text-left w-full flex items-center">
                Portfolio Impact Characteristics
              </div>
              <div className="font-bold text-lg text-gray-600 mt-8 mb-2">
                Total Impact
              </div>
              <div className="grid grid-cols-4 gap-x-6 gap-y-6">
                <div className="col-span-2 gap-y-4">
                  <WrapperCard>
                    <PortfolioCard
                      title={companyList?.[0]?.name}
                      value={companyList?.[0]?.value}
                      percentage={companyList?.[0]?.percentage}
                      isPrimary={true}
                      closeable={false}
                      isEmpty={false}
                      type={PortfolioCardType.simple}
                    />
                  </WrapperCard>
                </div>
                <div className="col-span-2">
                  <WrapperCard>
                    <PortfolioCard
                      title={companyList?.[1]?.name}
                      value={companyList?.[1]?.value}
                      percentage={companyList?.[1]?.percentage}
                      isPrimary={false}
                      closeable={false}
                      isEmpty={false}
                      type={PortfolioCardType.simple}
                    />
                  </WrapperCard>
                </div>
                <div className="col-span-2">
                  <p className="text-base text-gray-600">{promethos.total}</p>
                </div>
                <div className="col-span-2">
                  <WrapperCard className={"p-2 px-0"}>
                    <HistoryLineChart
                      height={250}
                      historyYears={historyYears}
                      data={historyAllData[0]}
                      portfolio1={portfolio1}
                      portfolio2={portfolio2}
                      defaultSelectedCategory={0}
                      hideSelector={true}
                    />
                  </WrapperCard>
                </div>
              </div>
              <div className="font-bold text-lg text-gray-600 mt-8 mb-2">
                Environmental Impact
              </div>
              <div className="grid grid-cols-4 gap-x-6 gap-y-6">
                <div className="col-span-2 gap-y-4">
                  <WrapperCard>
                    <PortfolioCard
                      title={environmentCompany?.[0]?.name}
                      value={environmentCompany?.[0]?.value}
                      percentage={environmentCompany?.[0]?.percentage}
                      isPrimary={true}
                      closeable={false}
                      isEmpty={false}
                      type={PortfolioCardType.simple}
                    />
                  </WrapperCard>
                </div>
                <div className="col-span-2">
                  <WrapperCard>
                    <PortfolioCard
                      title={environmentCompany?.[1]?.name}
                      value={environmentCompany?.[1]?.value}
                      percentage={environmentCompany?.[1]?.percentage}
                      isPrimary={false}
                      closeable={false}
                      isEmpty={false}
                      type={PortfolioCardType.simple}
                    />
                  </WrapperCard>
                </div>
                <div className="col-span-2">
                  <p className="text-base text-gray-600">
                    {promethos.environmental}
                  </p>
                </div>
                <div className="col-span-2">
                  <WrapperCard className={"p-2 px-0"}>
                    <HistoryLineChart
                      height={250}
                      historyYears={environmentYears}
                      data={historyAllData[1]}
                      portfolio1={portfolio1}
                      portfolio2={portfolio2}
                      defaultSelectedCategory={0}
                      hideSelector={true}
                    />
                  </WrapperCard>
                </div>
              </div>
            </div>
            <div id="reporting-2" className="bg-gray-50 px-3 pb-2">
              <div className="font-bold text-lg text-gray-600 mt-8 mb-2">
                Employment Impact
              </div>
              <div className="grid grid-cols-4 gap-x-6 gap-y-6">
                <div className="col-span-2 gap-y-4">
                  <WrapperCard>
                    <PortfolioCard
                      title={employeeCompany?.[0]?.name}
                      value={employeeCompany?.[0]?.value}
                      percentage={employeeCompany?.[0]?.percentage}
                      isPrimary={true}
                      closeable={false}
                      isEmpty={false}
                      type={PortfolioCardType.simple}
                    />
                  </WrapperCard>
                </div>
                <div className="col-span-2">
                  <WrapperCard>
                    <PortfolioCard
                      title={employeeCompany?.[1]?.name}
                      value={employeeCompany?.[1]?.value}
                      percentage={employeeCompany?.[1]?.percentage}
                      isPrimary={false}
                      closeable={false}
                      isEmpty={false}
                      type={PortfolioCardType.simple}
                    />
                  </WrapperCard>
                </div>
                <div className="col-span-2">
                  <p className="text-base text-gray-600">
                    {promethos.employment}
                  </p>
                </div>
                <div className="col-span-2">
                  <WrapperCard className={"p-2 px-0"}>
                    <HistoryLineChart
                      height={250}
                      historyYears={employeeHistoryYears}
                      data={historyAllData[2]}
                      portfolio1={portfolio1}
                      portfolio2={portfolio2}
                      defaultSelectedCategory={0}
                      hideSelector={true}
                    />
                  </WrapperCard>
                </div>
              </div>
              <div className="font-bold text-lg text-gray-600 mt-8 mb-2">
                Customer Impact
              </div>
              <div className="grid grid-cols-4 gap-x-6 gap-y-6">
                <div className="col-span-2 gap-y-4">
                  <WrapperCard>
                    <PortfolioCard
                      title={customerCompany?.[0]?.name}
                      value={customerCompany?.[0]?.value}
                      percentage={customerCompany?.[0]?.percentage}
                      isPrimary={true}
                      closeable={false}
                      isEmpty={false}
                      type={PortfolioCardType.simple}
                    />
                  </WrapperCard>
                </div>
                <div className="col-span-2">
                  <WrapperCard>
                    <PortfolioCard
                      title={customerCompany?.[1]?.name}
                      value={customerCompany?.[1]?.value}
                      percentage={customerCompany?.[1]?.percentage}
                      isPrimary={false}
                      closeable={false}
                      isEmpty={false}
                      type={PortfolioCardType.simple}
                    />
                  </WrapperCard>
                </div>
                <div className="col-span-2">
                  <p className="text-base text-gray-600">
                    {promethos.customer}
                  </p>
                </div>
                <div className="col-span-2">
                  <WrapperCard className={"p-2 px-0"}>
                    <HistoryLineChart
                      height={250}
                      historyYears={customerYears}
                      data={historyAllData[3]}
                      portfolio1={portfolio1}
                      portfolio2={portfolio2}
                      defaultSelectedCategory={0}
                      hideSelector={true}
                    />
                  </WrapperCard>
                </div>
              </div>
            </div>
            <div id="reporting-3" className="bg-gray-50 px-3">
              <ImpactTableCompare
                data={tableData}
                portfolio1={portfolio1}
                portfolio2={portfolio2}
              />
              <p className="font-semibold italic mt-1 mb-4">
                *Results are assumed investment of 1 USDbn in each, portfolio
                and benchmark
              </p>
            </div>
            <div id="reporting-4" className="bg-gray-50 px-3">
              <div className="h-8 px-2 mb-1 bg-my-rift-primary-green-500 text-white font-bold text-left w-full flex items-center">
                Impact Leaders and Laggards{" "}
              </div>
              <div className="font-bold text-lg text-gray-600 mt-8 mb-2">
                Environmental Impact
              </div>
              <div className="grid grid-cols-4 gap-x-6 gap-y-6">
                <div className="col-span-2">
                  <WrapperCard wideBorder={true} className="min-h-[22rem]">
                    <LeadersLaggards
                      title={"Leaders in Environmental Impact"}
                      isPositive={true}
                      portfolio1={portfolio1}
                      portfolio2={portfolio2}
                      data1={environmentLeaders1}
                      data2={environmentLeaders2}
                    />
                  </WrapperCard>
                </div>
                <div className="col-span-2">
                  <WrapperCard wideBorder={true} className="min-h-[22rem]">
                    <LeadersLaggards
                      title={"Laggards in Environmental Impact"}
                      isPositive={false}
                      portfolio1={portfolio1}
                      portfolio2={portfolio2}
                      data1={environmentLaggards1}
                      data2={environmentLaggards2}
                    />
                  </WrapperCard>
                </div>
              </div>
              <div className="font-bold text-lg text-gray-600 mt-8 mb-2">
                Employment Impact
              </div>
              <div className="grid grid-cols-4 gap-x-6 gap-y-6">
                <div className="col-span-2">
                  <WrapperCard wideBorder={true} className="min-h-[22rem]">
                    <LeadersLaggards
                      title={"Leaders in Employment Impact"}
                      isPositive={true}
                      portfolio1={portfolio1}
                      portfolio2={portfolio2}
                      data1={employeeLeaders1}
                      data2={employeeLeaders2}
                    />
                  </WrapperCard>
                </div>
                <div className="col-span-2">
                  <WrapperCard wideBorder={true} className="min-h-[22rem]">
                    <LeadersLaggards
                      title={"Laggards in Employment Impact"}
                      isPositive={false}
                      portfolio1={portfolio1}
                      portfolio2={portfolio2}
                      data1={employeeLaggards1}
                      data2={employeeLaggards2}
                    />
                  </WrapperCard>
                </div>
              </div>
              <div className="font-bold text-lg text-gray-600 mt-8 mb-2">
                Customer Impact
              </div>
              <div className="grid grid-cols-4 gap-x-6 gap-y-6 mb-4">
                <div className="col-span-2">
                  <WrapperCard wideBorder={true} className="min-h-[22rem]">
                    <LeadersLaggards
                      title={"Leaders in Customer Impact"}
                      isPositive={true}
                      portfolio1={portfolio1}
                      portfolio2={portfolio2}
                      data1={customerLeaders1}
                      data2={customerLeaders2}
                    />
                  </WrapperCard>
                </div>
                <div className="col-span-2">
                  <WrapperCard wideBorder={true} className="min-h-[22rem]">
                    <LeadersLaggards
                      title={"Laggards in Customer Impact"}
                      isPositive={false}
                      portfolio1={portfolio1}
                      portfolio2={portfolio2}
                      data1={customerLaggards1}
                      data2={customerLaggards2}
                    />
                  </WrapperCard>
                </div>
              </div>
            </div>
            <div id="reporting-5" className="bg-gray-50 px-3 pb-10">
              <div className="h-8 px-2 mb-1 bg-my-rift-primary-green-500 text-white font-bold text-left w-full flex items-center">
                Portfolio Product & Service (PSICS) Breakdown*
              </div>
              <div className="text-base text-gray-600 mb-4">
                {promethos.strategy}
              </div>
              <div className="grid grid-cols-2 gap-x-6 mb-2">
                <WrapperCard>
                  <SimplePieChart
                    id={"reportsimple1"}
                    height={300}
                    //width={300}
                    portfolio1={portfolio1}
                    data={pieChartData1}
                    color={"#0EA5E9"}
                    showLegend={false}
                    showLabel={true}
                    marginLeft={0}
                  />
                  <p className="text-center font-semibold">{portfolio1}</p>
                </WrapperCard>
                <WrapperCard>
                  <SimplePieChart
                    id={"reportsimple2"}
                    height={300}
                    //width={300}
                    portfolio1={portfolio2}
                    data={pieChartData2}
                    color={"#0EA5E9"}
                    showLegend={false}
                    showLabel={true}
                    marginLeft={0}
                  />
                  <p className="text-center font-semibold">{portfolio2}</p>
                </WrapperCard>
              </div>
              <div className="mt-4 h-8 px-2 mb-1 bg-my-rift-primary-green-500 text-white font-bold text-left w-full flex items-center">
                Revenue breakdown by geographies and income regions
              </div>
              <div className="text-base text-gray-600 mb-4"></div>
              <div className="grid grid-cols-2 gap-x-6 mb-2">
                <WrapperCard className="">
                  <SimplePieChart
                    id={"geoSimple1"}
                    height={300}
                    portfolio1={portfolio2}
                    data={geoPieChartData1}
                    color={"#0EA5E9"}
                    showLegend={false}
                    showLabel={true}
                    marginLeft={0}
                  />
                  <p className="text-center font-semibold">{portfolio1}</p>
                </WrapperCard>
                <WrapperCard>
                  <SimplePieChart
                    id={"geoSimple2"}
                    height={300}
                    portfolio1={portfolio2}
                    data={geoPieChartData2}
                    color={"#0EA5E9"}
                    showLegend={false}
                    showLabel={true}
                    marginLeft={0}
                  />
                  <p className="text-center font-semibold">{portfolio2}</p>
                </WrapperCard>
              </div>

              <div className="mt-4 h-8 px-2 mb-1 bg-my-rift-primary-green-500 text-white font-bold text-left w-full flex items-center">
                Historical revenues ($) from top 3 products & services
              </div>
              <div className="text-base text-gray-600 mb-4"></div>
              <div className="grid grid-cols-2 gap-x-6">
                <WrapperCard>
                  <CategoryOtherAreaChart
                    data={transformedData1}
                    topCategories={topCategories1}
                  />
                </WrapperCard>
                <WrapperCard>
                  <CategoryOtherAreaChart
                    data={transformedData2}
                    topCategories={topCategories2}
                  />
                </WrapperCard>
              </div>
            </div>

            <div id="reporting-6" className="bg-gray-50 px-3">
              <div className="mt-4 h-8 px-2  mb-1 bg-my-rift-primary-green-500 text-white font-bold text-left w-full flex items-center">
                Exposure to Impact Themes*
              </div>
              <div className="text-base text-gray-600 mb-4">
                The portfolio's themes showcase contributions to solutions
                across various sectors, aligning with long-term emerging
                economic and societal trends. This alignment could offer
                investors a competitive advantage.
              </div>
              <div className="grid grid-cols-2 gap-x-6">
                <WrapperCard className="pb-5">
                  <ThemeTable
                    height={"100%"}
                    isReport={true}
                    data={impactTheme1Table}
                    isPercentage={true}
                    keyTitle={"Impact theme"}
                    valueTitle={"Aligned revenues"}
                  />
                </WrapperCard>
                <WrapperCard className="pb-5">
                  <ThemeTable
                    height={"100%"}
                    isReport={true}
                    data={impactTheme2Table}
                    isPercentage={true}
                    keyTitle={"Impact theme"}
                    valueTitle={"Aligned revenues"}
                  />
                </WrapperCard>
              </div>
              <div className="h-2 w-full"></div>
            </div>
          </div>
        </div>
      </div>
      {/*  {
        <div className="bg-white h-screen w-screen absolute z-30 top-0">
          <p className="mb-4 mx-auto w-full p-4 text-gray-600 text-xl"></p>

          <button
            className="text-3xl"
            onClick={() => exportPDFWithMultiplePages()}
          >
            Generate PDF
          </button>
        </div>
      } */}

      {loading && (
        <div className="bg-white h-screen w-screen absolute z-50 top-0">
          <p className="mb-4 mx-auto w-full p-4 text-gray-600 text-xl">
            Please do not close this page. We are generating your report!
          </p>

          <LoadingErrorWrapper isLoading={loading} />
        </div>
      )}
    </div>
  );
};

export default Reporting;
