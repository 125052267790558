import * as React from "react";
const SvgData = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#data_svg__a)">
      <path
        fill="currentColor"
        d="M13 3.87v.02c0 .67.45 1.23 1.08 1.43C16.93 6.21 19 8.86 19 12c0 .52-.06 1.01-.17 1.49-.14.64.12 1.3.69 1.64l.01.01c.86.5 1.98.05 2.21-.91.17-.72.26-1.47.26-2.23 0-4.5-2.98-8.32-7.08-9.57-.95-.29-1.92.44-1.92 1.44m-2.06 15.05c-2.99-.43-5.42-2.86-5.86-5.84a6.996 6.996 0 0 1 4.83-7.76c.64-.19 1.09-.76 1.09-1.43v-.02c0-1-.97-1.73-1.93-1.44a10.02 10.02 0 0 0-6.98 10.96c.59 4.38 4.13 7.92 8.51 8.51 3.14.42 6.04-.61 8.13-2.53.74-.68.61-1.89-.26-2.39-.58-.34-1.3-.23-1.8.22-1.47 1.34-3.51 2.05-5.73 1.72"
      />
    </g>
    <defs>
      <clipPath id="data_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgData;
