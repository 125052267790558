import React from "react";
import ReactDOM from "react-dom/client";
import store from "./store/index";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

/* import app */
import App from "./App";

/* Global styles */
import "./styles/global.scss";
import { QueryClientProvider, QueryClient } from "react-query";

import { DataProvider } from "./context/PortfolioContext";
import { CustomPortfolioProvider } from "./context/CustomPortfolioContext";
import { SnackbarProvider } from "notistack";
import { UserProvider } from "./context/UserContext";
import { TourProvider } from "./context/TourContext";

// React v18 root element creation
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

root.render(
  <Router>
    <Provider store={store}>
      <SnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <DataProvider>
            <UserProvider>
              <CustomPortfolioProvider>
                <TourProvider>
                  <App />
                </TourProvider>
              </CustomPortfolioProvider>
            </UserProvider>
          </DataProvider>
        </QueryClientProvider>
      </SnackbarProvider>
    </Provider>
  </Router>
);
