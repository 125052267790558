import React, { useEffect, useState } from "react";
import { useDataContext } from "../../context/PortfolioContext";
import SearchSummary from "./components/SearchSummary";
import Stepper from "../../components/redesign/Stepper";
import PrimaryButton from "../../components/ui/redesign/PrimaryButton";
import CreatePortfolioContent from "./components/CreatePortfolioContent";
import { useForm } from "react-hook-form";
import { stepperData } from "./form/stepperData";

export const FULL_STEP = 4;

const CreatePortfolioPage = () => {
  const { setIsFullWidth } = useDataContext();
  const [currentStep, setCurrentStep] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [pos, setPos] = useState(0);
  const [subStepData, setSubStepData] = useState(
    Array.from({ length: FULL_STEP }, () => ({ subStep: 0, subSubStep: 0 }))
  );

  const { control, getValues, watch, setValue } = useForm({
    defaultValues: {
      regions: {},
    },
  });

  const rotateCube = (direction) => {
    const angle = direction === "left" ? 90 : -90;
    setPos((prevPos) => prevPos + angle);
  };

  const handleStepChange = (index) => {
    setPos(index * -90);
    setCurrentStep(index);
    setSubStepData((prevState) => ({
      ...prevState,
      [index]: { subStep: 0, subSubStep: 0 },
    }));
  };

  const handleSubStepChange = (step, subStep, subSubStep) => {
    setPos((prevPos) =>
      step === 4 ? prevPos : prevPos + (currentStep - step) * 90
    );
    setCurrentStep(step);
    setSubStepData((prevState) => ({
      ...prevState,
      [step]: { subStep, subSubStep },
    }));
  };

  const handleNextStep = () => {
    if (currentStep < FULL_STEP - 1) {
      rotateCube("right");
      setCurrentStep(currentStep + 1);
      setSubStepData((prevState) => ({
        ...prevState,
        [currentStep + 1]: { subStep: 0, subSubStep: 0 },
      }));
    } else {
      rotateCube("right");
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      rotateCube("left");
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubStepNext = () => {
    const { subStep } = subStepData[currentStep];
    if (subStep < stepperData[currentStep].subSteps.length - 1) {
      setSubStepData((prevState) => ({
        ...prevState,
        [currentStep]: {
          ...prevState[currentStep],
          subStep: subStep + 1,
          subSubStep: 0,
        },
      }));
    } else {
      handleNextStep();
    }
  };

  const handleSubStepPrev = () => {
    const { subStep } = subStepData[currentStep];
    if (subStep > 0) {
      setSubStepData((prevState) => ({
        ...prevState,
        [currentStep]: {
          ...prevState[currentStep],
          subStep: subStep - 1,
          subSubStep: 0,
        },
      }));
    } else {
      handlePrevStep();
    }
  };

  const handleSubSubStepNext = () => {
    const { subStep, subSubStep } = subStepData[currentStep];
    if (
      subSubStep <
      stepperData[currentStep].subSteps[subStep].subSteps.length - 1
    ) {
      setSubStepData((prevState) => ({
        ...prevState,
        [currentStep]: {
          ...prevState[currentStep],
          subSubStep: subSubStep + 1,
        },
      }));
    } else {
      handleSubStepNext();
    }
  };

  const handleSubSubStepPrev = () => {
    const { subStep, subSubStep } = subStepData[currentStep];
    if (subSubStep > 0) {
      setSubStepData((prevState) => ({
        ...prevState,
        [currentStep]: {
          ...prevState[currentStep],
          subSubStep: subSubStep - 1,
        },
      }));
    } else {
      handleSubStepPrev();
    }
  };

  useEffect(() => {
    setIsFullWidth(true);
    return () => {
      setIsFullWidth(false);
    };
  }, []);

  useEffect(() => {
    const cube = document.getElementById("cube");
    if (cube) {
      cube.style.transform = `rotateY(${pos}deg)`;
    }
  }, [pos]);

  return (
    <div className="grid grid-cols-12 w-full gap-x-12! mx-6 relative">
      <div className="col-span-2 flex flex-col justify-center items-center">
        <Stepper
          changeStep={handleStepChange}
          steps={stepperData}
          activeStep={currentStep}
          className={"gap-12 p-4 mx-auto w-full flex-col"}
        />
        <div
          className={`${
            currentStep < FULL_STEP ? "visible" : "invisible"
          } h-14 flex py-2 justify-left gap-4 w-fit`}
        >
          <PrimaryButton
            onClick={handleSubSubStepPrev}
            iconUrl={"/img/icons/arrow-left.svg"}
            text={"Previous"}
            className={currentStep > 0 ? "visible" : "invisible"}
          />
          <PrimaryButton
            onClick={handleSubSubStepNext}
            iconRight={true}
            iconUrl={"/img/icons/arrow-right.svg"}
            text={"Next"}
            className={"px-8"}
          />
        </div>
      </div>
      <div className="col-span-4 flex items-center justify-center relative transition-all duration-1000 ease-in-out cube-container">
        <div className="cube" id="cube">
          {Array.from({ length: FULL_STEP }, (_, index) => index).map(
            (step) => (
              <CreatePortfolioContent
                key={step}
                stepperData={stepperData}
                handleSubSubStepPrev={handleSubSubStepPrev}
                currentStep={currentStep}
                control={control}
                getValues={getValues}
                watch={watch}
                setValue={setValue}
                index={step}
                subStepData={subStepData}
                setSubStepData={setSubStepData}
                handleEdit={handleSubStepChange}
                isFlipped={isFlipped}
                setIsFlipped={setIsFlipped}
              />
            )
          )}
        </div>
      </div>
      <div className="col-span-6 ml-3 px-1 h-full py-[3.25rem]">
        <div className="flex flex-col gap-y-3 justify-center h-full flip-card">
          <SearchSummary
            getValues={getValues}
            handleEdit={handleSubStepChange}
            isFlipped={isFlipped}
            aboveStep={currentStep}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatePortfolioPage;
