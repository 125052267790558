import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDataContext } from "../../../context/PortfolioContext";
import WrapperCard from "../../../components/ui/redesign/WrapperCard";

const CustomCompanySearch = ({
  name,
  placeholder,
  control,
  setValue,
  getValues,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const { allSearchData } = useDataContext();

  useEffect(() => {
    setSelectedItems(getValues(name) || []);
  }, []);

  // Filter data based on input
  useEffect(() => {
    if (inputValue) {
      const filtered = allSearchData.filter((item) =>
        item.id.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [inputValue, allSearchData]);

  const handleSelect = (suggestion) => {
    setInputValue(""); // Clear input field after selection
    setShowSuggestions(false);
    if (!selectedItems.find((item) => item.id === suggestion.id)) {
      const newSelections = [...selectedItems, { id: suggestion.id }];
      setSelectedItems(newSelections);
      setValue(name, newSelections); // Update react-hook-form state
    }
  };

  const handleRemoveItem = (id) => {
    const newSelections = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(newSelections);
    setValue(name, newSelections);
  };

  return (
    <div className="relative">
      <p className="font-bold text-gray-600 text-base mb-1">
        {"Search for specific company"}
      </p>

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className="relative">
            <input
              {...field}
              placeholder={placeholder ? placeholder : "Type here..."}
              className="text-base leading-normal w-full py-1 pl-4 pr-3 rounded-lg text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none ring-1 ring-my-rift-primary-green-600 mb-2"
              autoComplete="off"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            {showSuggestions && (
              <div className="absolute top-full mt-1 w-full z-50 overflow-y-auto max-h-60 shadow-lg bg-white rounded-xl border border-gray-300">
                {suggestions.map((suggestion) => (
                  <div
                    key={suggestion.id}
                    className="hover:bg-gray-100 p-2 flex gap-x-1 cursor-pointer"
                    onClick={() => handleSelect(suggestion)}
                  >
                    {selectedItems.some((item) => item.id === suggestion.id) ? (
                      <img
                        className="bg-my-rift-primary-green-400 rounded-xl flex-shrink-0 w-6 h-6"
                        src="/icon/step_done.svg"
                      />
                    ) : (
                      <div className="w-6" />
                    )}
                    {suggestion.id}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      />
      {/* Display selected items */}
      {selectedItems.map((item) => (
        <div
          key={item.id}
          className="bg-gray-100 rounded p-1 mt-2 flex justify-between items-center"
        >
          {item.id}
          <button
            onClick={() => handleRemoveItem(item.id)}
            className="text-red-500 hover:text-red-700"
          >
            <img src="/redesign/portfolio_impact/cancel.svg" alt="" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default CustomCompanySearch;
