import React from "react";

const ShowMore = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="w-fit flex items-center gap-x-1 cursor-pointer"
    >
      <p className="text-xs font-bold leading-4 text-my-rift-primary-green-600">
        View more
      </p>
      <img src="/redesign/general/double_arrow.svg" />
    </div>
  );
};

export default ShowMore;
