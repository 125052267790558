export const AllRoutes = {
  dashboard: "/",
  opening: "/tour",
  signIn: "/auth/sign-in",
  signUp: "/auth/sign-up",
  public_equity_portfolio: "/public-equities-portfolio",
  portfolio_impact: "/public-equities-portfolio/portfolio-impact",
  portfolio_overview:
    "/public-equities-portfolio/portfolio-impact/portfolio-overview",
  employees_impact:
    "/public-equities-portfolio/portfolio-impact/employees-impact",
  environmental_impact:
    "/public-equities-portfolio/portfolio-impact/environmental-impact",
  customer_impact:
    "/public-equities-portfolio/portfolio-impact/customer-impact",
  portfolio_information:
    "/public-equities-portfolio/portfolio-impact/portfolio-information",
  public_equity_company: "/public-equities-company",
  private_equity: "/private-equities",
  company_impact: "/public-equities-company/company-impact",
  private_company_impact: "/private-equities/company-impact",
  company_overview: "/public-equities-company/company-impact/company-overview",
  company_employees_impact:
    "/public-equities-company/company-impact/company-employees-impact",
  company_environmental_impact:
    "/public-equities-company/company-impact/company-environmental-impact",
  company_customer_impact:
    "/public-equities-company/company-impact/company-customer-impact",
  private_company_overview: "/private-equities/company-impact/company-overview",
  private_company_employees_impact:
    "/private-equities/company-impact/company-employees-impact",
  private_company_environmental_impact:
    "/private-equities/company-impact/company-environmental-impact",
  private_company_customer_impact:
    "/private-equities/company-impact/company-customer-impact",
  company_information:
    "/public-equities-company/company-impact/company-information",
  portfolio_eu_taxonomy: "/public-equities-portfolio/eu-taxonomy",
  portfolio_sdg_alignment: "/public-equities-portfolio/sdg-alignment",
  portfolio_psics: "/public-equities-portfolio/psics",
  portfolio_raw_data: "/public-equities-portfolio/raw-data",
  company_eu_taxonomy: "/public-equities-company/eu-taxonomy",
  company_sdg_alignment: "/public-equities-company/sdg-alignment",
  company_psics: "/public-equities-company/psics",
  company_raw_data: "/public-equities-company/raw-data",
  contact_us: "/contact-us",
  upgrade: "/upgrade",
  fixed_income: "/fixed-income",
  abs_climate: "/fixed-income/abs-climate-analytics",
  clo_climate: "/fixed-income/clo-climate-analytics",
  impact_monitoring: "/private-equity/impact-monitoring-and-reporting",
  create_portfolio: "/create-portfolio-temp-only",
  upload_portfolio: "/upload-portfolio",
  my_portfolio: "/my-portfolios/:id",
  reporting: "/reporting",
};
