import { configureStore } from "@reduxjs/toolkit";

import industrySlice from "./industry/slice";
import companySlice from "./company/slice";
import trendingSlice from "./trending/slice";
import portfolioSlice from "./portfolio/slice";
import themeSlice from "./theme/slice";
import generalSlice from "./general/slice";

const store = configureStore({
  reducer: {
    industry: industrySlice.reducer,
    company: companySlice.reducer,
    trending: trendingSlice.reducer,
    portfolio: portfolioSlice.reducer,
    theme: themeSlice.reducer,
    general: generalSlice.reducer
  },
});

export default store;
