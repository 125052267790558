import { Disclosure } from "@headlessui/react";

export default function FAQAccordion() {
  const FAQs = [
    {
      question: "What is PSICS?",
      answer: (
        <>
          350+ unique Product Categories with associated Revenue Data for 1,500+
          publicly listed companies. <br />
          <br /> RGS' Products and Services Industry Classification System
          (PSICS) is a unique, structured taxonomy offering precise
          classifications for the products and services of global publicly
          listed companies. We have developed standardized industry-level
          definitions of products and services to overcome the challenge of
          non-standardized company disclosures of business lines. <br />
          <br />
          We have also connected these standardized products and services to
          main Environmental and Social Impacts to highlight key risks &
          opportunities.`,
        </>
      ),
    },
    {
      question: "Why is PSICS useful?",
      answer: (
        <>
          Enables a better understanding of where companies make revenues across
          their product ranges and the external ESG impacts they create as a
          result.
          <br />
          <br />
          The RGS PSICS tool provides a systematic approach to analyzing a
          company’s exposure to specific products and services. Users can create
          more granular peer groups, identify industry competitors and exposure
          to ESG risks & opportunities based on a company’s product portfolio.
          Use case examples include fundamental company analysis, portfolio
          construction, thematic investing and index creation or integration of
          ESG Impacts in the equity research process.
        </>
      ),
    },
    {
      question: "How is PSICS working?",
      answer: (
        <>
          We invite you to try our demo below! Search company names and see how
          each company's revenue is broken down into standardized product groups
          with associated revenue data. We also took the extra step to provide
          you with the relevant Environmental and Social Impacts. Are you
          interested in other companies offering the same products and services?
          Just click on a product category name, and we will provide you with a
          list of global publicly listed companies and their exposure to the
          given product category. Contact us to learn more or to access the full
          dataset.
        </>
      ),
    },
  ];

  return (
    <div className="w-full">
      <div className="w-full rounded-2xl bg-white shadow-my-sm">
        {FAQs.map((item) => (
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`flex w-full justify-start gap-x-6 items-center rounded-lg p-4 text-left text-sm font-medium  ${
                    open ? "bg-[#FAFAFA]" : "bg-white"
                  } `}
                >
                  <img
                    src="/redesign/portfolio_impact/chevron.svg"
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-3.5 w-3.5 `}
                  />
                  <p className="text-sm font-semibold leading-tight text-gray-600">
                    {item.question}
                  </p>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-4 pl-14 text-sm leading-tight text-gray-600 bg-[#FAFAFA]">
                  {item.answer}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}
