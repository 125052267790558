import React, { useState } from "react";
import { useQuery } from "react-query";
import { twMerge } from "tailwind-merge";
import { fetchAbsPortfolio } from "../api/api";
import SvgAbsSearch from "../assets/icon/AbsSearch";
import SvgChart from "../assets/icon/Chart";
import SvgData from "../assets/icon/Data";
import WrapperCard from "../components/ui/redesign/WrapperCard";
import AbsChartView from "./abs/AbsChartView";
import AbsDataView from "./abs/AbsDataView";
import AbsTable from "./abs/AbsTable";
import LoadingErrorWrapper from "../api/LoadingErrorWrapper";

export const total41 = {
  totalCount: 69771,
  minEwltp: 0.0,
  averageEwltp: 113.97,
  averageZScore: 0.095,
};
export const total39 = {
  totalCount: 72306,
  minEwltp: 0.0,
  averageEwltp: 113.98,
  averageZScore: 0.093,
};

const AbsPortfolio = () => {
  /*   const {
    isLoading,
    error,
    data: datatodo,
  } = useQuery("abs_portfolio", () => {
    return fetchAbsPortfolio();
  }); */
  const {
    isLoading,
    error,
    data: data2,
  } = useQuery(["abs_portfolio", ""], () => {
    return fetchAbsPortfolio(); //TODO API CALL NOT WORKING YET
  });

  const [inputValue, setInputValue] = useState("");
  const [selectedCar, setSelectedCar] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showTable, setShowTable] = useState(true);

  const handleSelectCar = (car) => {
    setSelectedCar(car);
  };

  if (isLoading || error)
    return <LoadingErrorWrapper isLoading={isLoading} error={error} />;

  return (
    <div className="grid grid-cols-2 w-full h-fit pb-4">
      <div className="col-span-2 h-fit mt-4 flex justify-between mx-4">
        <div>
          <div
            onClick={() => {
              if (selectedRows.length == 0) return;
              setShowTable((prev) => !prev);
            }}
            className={twMerge(
              "rounded-2xl h-fit cursor-pointer shadow px-2 py-1 flex items-center gap-x-2",

              showTable == true && "bg-my-rift-primary-green-500"
            )}
          >
            <SvgAbsSearch className="w-8 h-8  fill-my-rift-primary-green-500" />
            <p
              className={twMerge(
                "text-base",
                showTable == true ? "text-white" : "text-my-tailwind-gray-500"
              )}
            >
              ABS List
            </p>
          </div>
        </div>
        <div className="flex gap-x-2">
          <div
            onClick={() => setSelectedTab(0)}
            className={twMerge(
              "rounded-2xl h-fit cursor-pointer shadow px-2 py-1 flex items-center gap-x-2",

              selectedTab == 0 && "bg-my-rift-primary-green-500"
            )}
          >
            <SvgChart
              className={`w-8 h-8 ${
                selectedTab != 0
                  ? "text-my-rift-primary-green-500"
                  : "text-white"
              } `}
            />
            <p
              className={twMerge(
                "text-base",
                selectedTab == 0 ? "text-white" : "text-my-tailwind-gray-500"
              )}
            >
              Charts
            </p>
          </div>
          <div
            onClick={() => setSelectedTab(1)}
            className={twMerge(
              "px-2 rounded-2xl cursor-pointer  h-fit shadow py-1 flex items-center gap-x-2",
              selectedTab == 1 ? "bg-my-rift-primary-green-500" : ""
            )}
          >
            <SvgData
              className={`w-8 h-8 ${
                selectedTab != 1
                  ? "text-my-rift-primary-green-500"
                  : "text-white"
              } `}
            />
            <p
              className={twMerge(
                "text-base",
                selectedTab == 1 ? "text-white" : "text-my-tailwind-gray-500"
              )}
            >
              Data
            </p>
          </div>
        </div>
      </div>
      {showTable == true && (
        <div className="col-span-1 h-fit">
          <WrapperCard wideBorder={true} className={"ml-8 my-8 "}>
            <AbsTable
              selectedRows={selectedRows}
              handleSelectedRows={(e) => setSelectedRows(e)}
            />
          </WrapperCard>
        </div>
      )}
      {selectedRows.length > 0 ? (
        <div
          key={showTable}
          className={`${
            showTable == true ? "col-span-1" : "col-span-2"
          }  h-fit`}
        >
          {selectedTab == 0 ? (
            <AbsChartView selectedRows={selectedRows} />
          ) : (
            <AbsDataView selectedRows={selectedRows} />
          )}
        </div>
      ) : (
        <WrapperCard
          className={`${
            showTable == true ? "col-span-1" : "col-span-2"
          }  h-fit mt-8 sticky top-8 p-2 mx-8 my-4 flex items-center justify-center flex-col`}
        >
          <p className="font-semibold text-my-tailwind-gray-500 mb-2">
            Select ABS by ticking the checkbox
          </p>
          <img className="w-32" src="/redesign/general/Soon.svg" />
        </WrapperCard>
      )}
      <div></div>
    </div>
  );
};

export default AbsPortfolio;
