import { Disclosure } from "@headlessui/react";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

const data39 = [
  {
    make: "AUDI",
    totalCount: 16259,
    minEwltp: 0.0,
    averageEwltp: 139.36,
    averageZScore: 0.458,
    years: [
      {
        year: 2019,
        count: 2,
        minEwltp: 147.76,
        averageEwltp: 163.43,
        averageZScore: 0.443,
      },
      {
        year: 2020,
        count: 7,
        minEwltp: 115.98,
        averageEwltp: 140.04,
        averageZScore: 0.197,
      },
      {
        year: 2021,
        count: 464,
        minEwltp: 0.0,
        averageEwltp: 127.36,
        averageZScore: 0.243,
      },
      {
        year: 2022,
        count: 8723,
        minEwltp: 0.0,
        averageEwltp: 143.37,
        averageZScore: 0.601,
      },
      {
        year: 2023,
        count: 7009,
        minEwltp: 0.0,
        averageEwltp: 143.37,
        averageZScore: 0.601,
      },
    ],
  },
  {
    make: "BMW",
    totalCount: 55,
    minEwltp: 0.0,
    averageEwltp: 116.07,
    averageZScore: 0.132,
    years: [
      {
        year: 2022,
        count: 36,
        minEwltp: 0.0,
        averageEwltp: 107.69,
        averageZScore: -0.012,
      },
      {
        year: 2023,
        count: 19,
        minEwltp: 115.61,
        averageEwltp: 139.1,
        averageZScore: 0.527,
      },
    ],
  },
  {
    make: "FIAT",
    totalCount: 1,
    minEwltp: 144.43,
    averageEwltp: 144.43,
    averageZScore: 0.619,
    years: [
      {
        year: 2022,
        count: 1,
        minEwltp: 144.43,
        averageEwltp: 144.43,
        averageZScore: 0.619,
      },
    ],
  },
  {
    make: "FORD",
    totalCount: 194,
    minEwltp: 55.71,
    averageEwltp: 155.25,
    averageZScore: 0.792,
    years: [
      {
        year: 2021,
        count: 3,
        minEwltp: 130.71,
        averageEwltp: 131.56,
        averageZScore: 0.321,
      },
      {
        year: 2022,
        count: 67,
        minEwltp: 55.71,
        averageEwltp: 161.22,
        averageZScore: 0.907,
      },
      {
        year: 2023,
        count: 124,
        minEwltp: 122.46,
        averageEwltp: 158.15,
        averageZScore: 0.854,
      },
    ],
  },
  {
    make: "MERCEDES",
    totalCount: 18,
    minEwltp: 0.0,
    averageEwltp: 94.06,
    averageZScore: -0.262,
    years: [
      {
        year: 2021,
        count: 3,
        minEwltp: 111.66,
        averageEwltp: 115.47,
        averageZScore: 0.023,
      },
      {
        year: 2022,
        count: 9,
        minEwltp: 0.0,
        averageEwltp: 114.53,
        averageZScore: 0.106,
      },
      {
        year: 2023,
        count: 6,
        minEwltp: 0.0,
        averageEwltp: 65.03,
        averageZScore: -0.744,
      },
    ],
  },
  {
    make: "NISSAN",
    totalCount: 1,
    minEwltp: 250.3,
    averageEwltp: 250.3,
    averageZScore: 2.436,
    years: [
      {
        year: 2023,
        count: 1,
        minEwltp: 250.3,
        averageEwltp: 250.3,
        averageZScore: 2.436,
      },
    ],
  },
  {
    make: "OPEL",
    totalCount: 71,
    minEwltp: 74.2,
    averageEwltp: 93.1,
    averageZScore: -0.276,
    years: [
      {
        year: 2021,
        count: 1,
        minEwltp: 92.96,
        averageEwltp: 92.96,
        averageZScore: -0.393,
      },
      {
        year: 2022,
        count: 40,
        minEwltp: 74.2,
        averageEwltp: 97.98,
        averageZScore: -0.178,
      },
      {
        year: 2023,
        count: 29,
        minEwltp: 74.2,
        averageEwltp: 85.01,
        averageZScore: -0.401,
      },
    ],
  },
  {
    make: "POLESTAR",
    totalCount: 1,
    minEwltp: 0.0,
    averageEwltp: 0.0,
    averageZScore: -2.113,
    years: [
      {
        year: 2021,
        count: 1,
        minEwltp: 0.0,
        averageEwltp: 0.0,
        averageZScore: -2.113,
      },
    ],
  },
  {
    make: "PORSCHE",
    totalCount: 2,
    minEwltp: 235.69,
    averageEwltp: 235.69,
    averageZScore: 2.185,
    years: [
      {
        year: 2022,
        count: 1,
        minEwltp: 235.69,
        averageEwltp: 235.69,
        averageZScore: 2.185,
      },
      {
        year: 2023,
        count: 1,
        minEwltp: null,
        averageEwltp: null,
        averageZScore: null,
      },
    ],
  },
  {
    make: "RENAULT",
    totalCount: 5,
    minEwltp: 27.78,
    averageEwltp: 108.64,
    averageZScore: 0.004,
    years: [
      {
        year: 2022,
        count: 5,
        minEwltp: 27.78,
        averageEwltp: 108.64,
        averageZScore: 0.004,
      },
    ],
  },
  {
    make: "SEAT",
    totalCount: 9840,
    minEwltp: 0.0,
    averageEwltp: 120.37,
    averageZScore: 0.131,
    years: [
      {
        year: 2020,
        count: 5,
        minEwltp: 131.63,
        averageEwltp: 158.05,
        averageZScore: 0.564,
      },
      {
        year: 2021,
        count: 241,
        minEwltp: 0.0,
        averageEwltp: 104.44,
        averageZScore: -0.181,
      },
      {
        year: 2022,
        count: 5596,
        minEwltp: 0.0,
        averageEwltp: 119.9,
        averageZScore: 0.198,
      },
      {
        year: 2023,
        count: 3979,
        minEwltp: 0.0,
        averageEwltp: 119.9,
        averageZScore: 0.198,
      },
    ],
  },
  {
    make: "SKODA",
    totalCount: 12010,
    minEwltp: 0.0,
    averageEwltp: 117.82,
    averageZScore: 0.055,
    years: [
      {
        year: 2019,
        count: 1,
        minEwltp: 188.02,
        averageEwltp: 188.02,
        averageZScore: 1.118,
      },
      {
        year: 2020,
        count: 14,
        minEwltp: 116.83,
        averageEwltp: 137.32,
        averageZScore: 0.142,
      },
      {
        year: 2021,
        count: 383,
        minEwltp: 0.0,
        averageEwltp: 101.7,
        averageZScore: -0.232,
      },
      {
        year: 2022,
        count: 5527,
        minEwltp: 0.0,
        averageEwltp: 115.19,
        averageZScore: 0.117,
      },
      {
        year: 2023,
        count: 6062,
        minEwltp: 0.0,
        averageEwltp: 115.19,
        averageZScore: 0.117,
      },
    ],
  },
  {
    make: "VW",
    totalCount: 29171,
    minEwltp: 0.0,
    averageEwltp: 120.39,
    averageZScore: 0.139,
    years: [
      {
        year: 2020,
        count: 21,
        minEwltp: 112.85,
        averageEwltp: 136.9,
        averageZScore: 0.133,
      },
      {
        year: 2021,
        count: 877,
        minEwltp: 0.0,
        averageEwltp: 123.25,
        averageZScore: 0.167,
      },
      {
        year: 2022,
        count: 15956,
        minEwltp: 0.0,
        averageEwltp: 115.75,
        averageZScore: 0.127,
      },
      {
        year: 2023,
        count: 12261,
        minEwltp: 0.0,
        averageEwltp: 115.75,
        averageZScore: 0.127,
      },
    ],
  },
  {
    make: "VWN",
    totalCount: 4678,
    minEwltp: 0.0,
    averageEwltp: 170.8,
    averageZScore: 1.064,
    years: [
      {
        year: 2019,
        count: 2,
        minEwltp: 161.27,
        averageEwltp: 212.87,
        averageZScore: 1.8,
      },
      {
        year: 2020,
        count: 12,
        minEwltp: 160.85,
        averageEwltp: 211.46,
        averageZScore: 1.653,
      },
      {
        year: 2021,
        count: 187,
        minEwltp: 135.54,
        averageEwltp: 213.67,
        averageZScore: 1.84,
      },
      {
        year: 2022,
        count: 2354,
        minEwltp: 0.0,
        averageEwltp: 127.58,
        averageZScore: 0.33,
      },
      {
        year: 2023,
        count: 2112,
        minEwltp: 0.0,
        averageEwltp: 150.65,
        averageZScore: 0.725,
      },
    ],
  },
];

const data41 = [
  {
    make: "AUDI",
    totalCount: 15884,
    minEwltp: 0.0,
    averageEwltp: 137.53,
    averageZScore: 0.454,
    years: [
      {
        year: 2020,
        count: 3,
        minEwltp: 98.46,
        averageEwltp: 141.14,
        averageZScore: 0.22,
      },
      {
        year: 2021,
        count: 91,
        minEwltp: 0.0,
        averageEwltp: 123.66,
        averageZScore: 0.175,
      },
      {
        year: 2022,
        count: 2091,
        minEwltp: 0.0,
        averageEwltp: 143.26,
        averageZScore: 0.599,
      },
      {
        year: 2023,
        count: 13699,
        minEwltp: 0.0,
        averageEwltp: 143.26,
        averageZScore: 0.599,
      },
    ],
  },
  {
    make: "BMW",
    totalCount: 30,
    minEwltp: 0.0,
    averageEwltp: 112.88,
    averageZScore: 0.077,
    years: [
      {
        year: 2022,
        count: 7,
        minEwltp: 99.32,
        averageEwltp: 115.56,
        averageZScore: 0.123,
      },
      {
        year: 2023,
        count: 23,
        minEwltp: 0.0,
        averageEwltp: 110.87,
        averageZScore: 0.043,
      },
    ],
  },
  {
    make: "FIAT",
    totalCount: 1,
    minEwltp: 144.43,
    averageEwltp: 144.43,
    averageZScore: 0.619,
    years: [
      {
        year: 2022,
        count: 1,
        minEwltp: 144.43,
        averageEwltp: 144.43,
        averageZScore: 0.619,
      },
    ],
  },
  {
    make: "FORD",
    totalCount: 49,
    minEwltp: 55.71,
    averageEwltp: 142.67,
    averageZScore: 0.596,
    years: [
      {
        year: 2021,
        count: 1,
        minEwltp: 234.57,
        averageEwltp: 234.57,
        averageZScore: 2.227,
      },
      {
        year: 2022,
        count: 15,
        minEwltp: 55.71,
        averageEwltp: 102.12,
        averageZScore: -0.107,
      },
      {
        year: 2023,
        count: 33,
        minEwltp: 122.46,
        averageEwltp: 150.11,
        averageZScore: 0.716,
      },
    ],
  },
  {
    make: "KIA",
    totalCount: 1,
    minEwltp: 106.13,
    averageEwltp: 106.13,
    averageZScore: -0.039,
    years: [
      {
        year: 2022,
        count: 1,
        minEwltp: 106.13,
        averageEwltp: 106.13,
        averageZScore: -0.039,
      },
    ],
  },
  {
    make: "LAND ROVER",
    totalCount: 1,
    minEwltp: 167.37,
    averageEwltp: 167.37,
    averageZScore: 1.012,
    years: [
      {
        year: 2022,
        count: 1,
        minEwltp: 167.37,
        averageEwltp: 167.37,
        averageZScore: 1.012,
      },
    ],
  },
  {
    make: "MAXUS",
    totalCount: 1,
    minEwltp: 0.0,
    averageEwltp: 0.0,
    averageZScore: -1.86,
    years: [
      {
        year: 2023,
        count: 1,
        minEwltp: 0.0,
        averageEwltp: 0.0,
        averageZScore: -1.86,
      },
    ],
  },
  {
    make: "MERCEDES",
    totalCount: 12,
    minEwltp: 0.0,
    averageEwltp: 92.32,
    averageZScore: -0.276,
    years: [
      {
        year: 2022,
        count: 4,
        minEwltp: 96.72,
        averageEwltp: 96.72,
        averageZScore: -0.2,
      },
      {
        year: 2023,
        count: 8,
        minEwltp: 0.0,
        averageEwltp: 91.69,
        averageZScore: -0.286,
      },
    ],
  },
  {
    make: "NISSAN",
    totalCount: 13,
    minEwltp: 132.61,
    averageEwltp: 132.61,
    averageZScore: 0.416,
    years: [
      {
        year: 2023,
        count: 13,
        minEwltp: 132.61,
        averageEwltp: 132.61,
        averageZScore: 0.416,
      },
    ],
  },
  {
    make: "OPEL",
    totalCount: 161,
    minEwltp: 74.2,
    averageEwltp: 102.0,
    averageZScore: -0.109,
    years: [
      {
        year: 2022,
        count: 12,
        minEwltp: 82.38,
        averageEwltp: 93.93,
        averageZScore: -0.248,
      },
      {
        year: 2023,
        count: 149,
        minEwltp: 74.2,
        averageEwltp: 106.04,
        averageZScore: -0.04,
      },
    ],
  },
  {
    make: "PEUGEOT",
    totalCount: 3,
    minEwltp: 165.53,
    averageEwltp: 165.53,
    averageZScore: 0.981,
    years: [
      {
        year: 2022,
        count: 1,
        minEwltp: 165.53,
        averageEwltp: 165.53,
        averageZScore: 0.981,
      },
      {
        year: 2023,
        count: 2,
        minEwltp: "NA",
        averageEwltp: "NA",
        averageZScore: "NA",
      },
    ],
  },
  {
    make: "PORSCHE",
    totalCount: 1,
    minEwltp: 235.69,
    averageEwltp: 235.69,
    averageZScore: 2.185,
    years: [
      {
        year: 2023,
        count: 1,
        minEwltp: 235.69,
        averageEwltp: 235.69,
        averageZScore: 2.185,
      },
    ],
  },
  {
    make: "RENAULT",
    totalCount: 1,
    minEwltp: 190.35,
    averageEwltp: 190.35,
    averageZScore: 1.407,
    years: [
      {
        year: 2023,
        count: 1,
        minEwltp: 190.35,
        averageEwltp: 190.35,
        averageZScore: 1.407,
      },
    ],
  },
  {
    make: "SEAT",
    totalCount: 10502,
    minEwltp: 0.0,
    averageEwltp: 116.55,
    averageZScore: 0.111,
    years: [
      {
        year: 2020,
        count: 1,
        minEwltp: 171.78,
        averageEwltp: 171.78,
        averageZScore: 0.844,
      },
      {
        year: 2021,
        count: 57,
        minEwltp: 83.35,
        averageEwltp: 134.29,
        averageZScore: 0.371,
      },
      {
        year: 2022,
        count: 1301,
        minEwltp: 0.0,
        averageEwltp: 106.58,
        averageZScore: -0.031,
      },
      {
        year: 2023,
        count: 9143,
        minEwltp: 0.0,
        averageEwltp: 106.58,
        averageZScore: -0.031,
      },
    ],
  },
  {
    make: "SKODA",
    totalCount: 10375,
    minEwltp: 0.0,
    averageEwltp: 113.46,
    averageZScore: 0.028,
    years: [
      {
        year: 2020,
        count: 3,
        minEwltp: 131.95,
        averageEwltp: 157.99,
        averageZScore: 0.563,
      },
      {
        year: 2021,
        count: 88,
        minEwltp: 0.0,
        averageEwltp: 98.86,
        averageZScore: -0.284,
      },
      {
        year: 2022,
        count: 1319,
        minEwltp: 0.0,
        averageEwltp: 115.19,
        averageZScore: 0.117,
      },
      {
        year: 2023,
        count: 8965,
        minEwltp: 0.0,
        averageEwltp: 115.19,
        averageZScore: 0.117,
      },
    ],
  },
  {
    make: "VOLVO",
    totalCount: 1,
    minEwltp: 132.33,
    averageEwltp: 132.33,
    averageZScore: 0.411,
    years: [
      {
        year: 2023,
        count: 1,
        minEwltp: 132.33,
        averageEwltp: 132.33,
        averageZScore: 0.411,
      },
    ],
  },
  {
    make: "VW",
    totalCount: 29570,
    minEwltp: 0.0,
    averageEwltp: 114.13,
    averageZScore: 0.026,
    years: [
      {
        year: 2019,
        count: 1,
        minEwltp: 150.1,
        averageEwltp: 150.1,
        averageZScore: 0.076,
      },
      {
        year: 2020,
        count: 6,
        minEwltp: 112.85,
        averageEwltp: 140.12,
        averageZScore: 0.199,
      },
      {
        year: 2021,
        count: 199,
        minEwltp: 0.0,
        averageEwltp: 115.04,
        averageZScore: 0.015,
      },
      {
        year: 2022,
        count: 3910,
        minEwltp: 0.0,
        averageEwltp: 108.52,
        averageZScore: 0.002,
      },
      {
        year: 2023,
        count: 25454,
        minEwltp: 0.0,
        averageEwltp: 108.52,
        averageZScore: 0.002,
      },
    ],
  },
  {
    make: "VWN",
    totalCount: 3165,
    minEwltp: 0.0,
    averageEwltp: 166.36,
    averageZScore: 0.956,
    years: [
      {
        year: 2019,
        count: 2,
        minEwltp: 161.27,
        averageEwltp: 180.76,
        averageZScore: 0.919,
      },
      {
        year: 2020,
        count: 10,
        minEwltp: 161.02,
        averageEwltp: 224.53,
        averageZScore: 1.919,
      },
      {
        year: 2021,
        count: 44,
        minEwltp: 135.83,
        averageEwltp: 198.64,
        averageZScore: 1.562,
      },
      {
        year: 2022,
        count: 566,
        minEwltp: 0.0,
        averageEwltp: 127.58,
        averageZScore: 0.33,
      },
      {
        year: 2023,
        count: 2543,
        minEwltp: 0.0,
        averageEwltp: 150.65,
        averageZScore: 0.725,
      },
    ],
  },
];

const AbsDetailTable = ({ idx }) => {
  const carsData = idx == true ? data41 : data39;

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div>
      <div className="w-full rounded-lg max-h-[400px] overflow-y-auto relative">
        <div className="grid grid-cols-6 gap-x-1 bg-gray-200 p-4 rounded-t-lg sticky top-0 z-10">
          <div className="col-span-1">
            <p className="text-sm font-semibold leading-tight text-left text-gray-600">
              Make
            </p>
          </div>
          <div className="col-span-1">
            <p className="text-sm font-semibold leading-tight text-left text-gray-600">
              Year
            </p>
          </div>
          <div className="col-span-1">
            <p className="text-sm font-semibold text-left leading-tight text-gray-600">
              # of vehicles
            </p>
          </div>
          <div className="col-span-1">
            <p className="text-sm font-semibold text-left leading-tight text-gray-600">
              Min Tailpipe Emissions
            </p>
          </div>
          <div className="col-span-1">
            <p className="text-sm font-semibold text-left leading-tight text-gray-600">
              Avg Tailpipe emissions
            </p>
          </div>
          <div className="col-span-1">
            <p className="text-sm font-semibold text-left leading-tight text-gray-600">
              Avg Z score
            </p>
          </div>
        </div>
        {carsData.map((car, idx) => (
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`${
                    idx == carsData.length - 1 ? "rounded-b-lg" : ""
                  }  w-full justify-start gap-x-6 items-center text-left text-sm font-medium cursor-pointer ${
                    open ? "bg-gray-100" : "bg-white"
                  } `}
                >
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`relative w-full ${
                            idx == carsData.length - 1 ? "rounded-b-lg" : ""
                          } `}
                        >
                          <div
                            className={twMerge(
                              "grid grid-cols-6 w-full p-4 gap-x-1 bg-gray-100",
                              car.averageZScore > 0
                                ? "bg-red-200"
                                : "bg-my-rift-primary-green-200"
                            )}
                          >
                            <div className="col-span-1">
                              <p className="text-sm text-left leading-tight text-gray-600">
                                {car.make}
                              </p>
                            </div>
                            <div className="col-span-1">
                              <p className="text-xs text-left leading-tight text-gray-600">
                                {car.years[0].year +
                                  " - " +
                                  car.years[car.years.length - 1].year}
                              </p>
                            </div>
                            <div className="col-span-1">
                              <p className="text-sm text-left leading-tight text-gray-600">
                                {car.totalCount}
                              </p>
                            </div>
                            <div className="col-span-1">
                              <p className="text-sm leading-tight text-left text-gray-600">
                                {car.minEwltp}
                              </p>
                            </div>
                            <div className="col-span-1">
                              <p className="text-sm leading-tight text-left text-gray-600">
                                {car.averageEwltp}
                              </p>
                            </div>
                            <div className="col-span-1">
                              <p className="text-sm leading-tight text-left text-gray-600">
                                {car.averageZScore}
                              </p>
                            </div>
                          </div>
                          <img
                            src="/redesign/portfolio_impact/chevron.svg"
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-3.5 w-3.5 absolute right-2 top-6 `}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="block  text-sm leading-tight text-gray-600 bg-gray-50">
                          <div>
                            {car.years.map((year, idx) => (
                              <div
                                className={twMerge(
                                  "grid grid-cols-6 gap-x-1 p-4",
                                  year.averageZScore > 0
                                    ? "bg-red-100"
                                    : "bg-my-rift-primary-green-100"
                                )}
                              >
                                <div className="col-span-1"></div>
                                <div className="col-span-1">
                                  <p className="text-sm text-left leading-tight text-gray-600">
                                    {year.year}
                                  </p>
                                </div>
                                <div className="col-span-1">
                                  <p className="text-sm  leading-tight text-gray-600">
                                    {year.count}
                                  </p>
                                </div>
                                <div className="col-span-1">
                                  <p className="text-sm leading-tight text-gray-600">
                                    {year.minEwltp}
                                  </p>
                                </div>
                                <div className="col-span-1">
                                  <p className="text-sm leading-tight text-gray-600">
                                    {year.averageEwltp}
                                  </p>
                                </div>
                                <div className="col-span-1">
                                  <p className="text-sm leading-tight text-gray-600">
                                    {year.averageZScore}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </Disclosure.Button>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
};

export default AbsDetailTable;
